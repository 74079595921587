// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import shopApi from '../../api/shop';
import { url } from '../../services/utils';
// blocks
import BlockLoader from '../blocks/BlockLoader';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
//import WidgetCategories from '../widgets/WidgetCategories';
//import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
//import categories from '../../data/shopWidgetCategories';
import theme from '../../data/theme';
import { Config } from '../../config';

function ShopPageProductDetail(props) {
    const { productSlug, layout, sidebarPosition, location } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);

    // history.listen((location, action) => {
    //     console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
    //     console.log(`The last navigation action was ${action}`)
    // });

    // Load product
    useEffect(() => {
        // let canceled = false;
        // let final_item_code = (productSlug).split("_")[1];
        // //let final_item_code = (location.pathname).split("_")[1];
        // console.log("final_item_code", final_item_code);

        let canceled = false;
        setIsLoading(true);
        let final_item_code = (productSlug).split("_")[1];
         shopApi.getProductByFinalItemCode(final_item_code).then((product) => {
            if (canceled) {
                return;
            }

            setProduct(product.data);

            shopApi.g4EventPush("view_item", product.data);

            loadRelatedProducts(product.data.product_id, final_item_code);
            setIsLoading(false);
        });

        return () => {
            canceled = true;
        };
    }, [productSlug, setIsLoading]);

    const loadRelatedProducts = (category_id, final_item_code) => {
        let canceled = false;
        // let headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');
        // if(localStorage.getItem(Config.x_token) !== null){
        //     headers.append('device_token', Config.device_token);
        //     headers.append('device_type', Config.device_type);
        //     headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        // }

        // setIsLoading(true);
        // fetch(Config.URL+"/category-related-items", {
        //     method: "POST",
        //     headers: headers,
        //     body: JSON.stringify({ category_id: category_id, final_item_code : final_item_code, records_per_page: 10 })
        // })
        // .then(response => response.json())
        // .then(response => {
        //     if(response.status){
        //         setRelatedProducts(response.data)
        //     }
        //     setIsLoading(false);
        // })
        // .catch(err => {
        //     setIsLoading(false);
        //     setRelatedProducts([])
        //     console.log("Product err: ", err);
        //     return;
        // });



        shopApi.getRelatedProducts(category_id, final_item_code, 10).then((products) => {
            if (canceled) {
                return;
            }
            if(products.status){
                setRelatedProducts(products.data);
            }
        });

        if(localStorage.getItem(Config.x_token) !== null){
            shopApi.getRecentlyViwed({records_per_page: 10}).then((recentlyViwedProducts) => {
                if (canceled) {
                    return;
                }

                if(recentlyViwedProducts.status){
                    var recentlyViwedProductsArr = [];
                    recentlyViwedProducts.data.filter((item, itemIndex) => {
                        recentlyViwedProductsArr.push(item);
                        if(recentlyViwedProducts.data.length -1 == itemIndex){
                            shopApi.g4EventPush("view_item_list", recentlyViwedProductsArr, true);
                        }
                    });
                }
            });
        }
    }

    //Load related products.
    // useEffect(() => {
    //     let canceled = false;
    //     if(categoryID !== ''){
    //         shopApi.getRelatedProducts(categoryID, final_item_code, records_per_page = 10).then((products) => {
    //             if (canceled) {
    //                 return;
    //             }
    //             setRelatedProducts(products.data);
    //         });
    //     }

    //     return () => {
    //         canceled = true;
    //     };
    // }, [setRelatedProducts]);

    // Load latest products.
    // useEffect(() => {
    //     let canceled = false;

    //     if (layout !== 'sidebar') {
    //         setLatestProducts([]);
    //     } else {
    //         shopApi.getLatestProducts({ limit: 5 }).then((result) => {
    //             if (canceled) {
    //                 return;
    //             }

    //             setLatestProducts(result);
    //         });
    //     }

    //     return () => {
    //         canceled = true;
    //     };
    // }, [layout]);

    if (isLoading) {
        return(
            <>
                <div className="page-header">
                    <div className="page-header__container container">
                    <div className="page-header__breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Skeleton count={1} height={15} width={50} />
                                </li>
                                <li className="breadcrumb-item">
                                    <Skeleton count={1} height={15} width={50} />
                                </li>
                                <li className="breadcrumb-item">
                                    <Skeleton count={1} height={15} width={200} />
                                </li>
                            </ol>
                        </nav>
                    </div>
                    </div>
                </div>
                <div className="block">
                    <div className="container">
                        <div className="product product--layout--standard">
                            <div className="product__content">
                            <div className="product__gallery">
                                <div className="product-gallery">
                                    <div className="product-gallery__featured">
                                        <div className="slick-prevent-click">
                                        <div className="slick-slider slick-initialized" dir="ltr">
                                            <div className="slick-list">
                                                <div className="slick-track" style={{width: 3186, opacity: 1}}>
                                                    <div data-index="0" className="slick-slide slick-active slick-current" aria-hidden="false" style={{outline: 'none', width: 531}}>
                                                    <div>
                                                        <div dir="ltr" className="correct-slick-active" style={{width: '100%', display: 'inline-block'}}>
                                                            <div className="product-image product-image--location--gallery">
                                                                    <Skeleton count={1} height={500} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="product-gallery__carousel">
                                        <div className="slick-prevent-click">
                                        <div className="slick-slider slick-initialized" dir="ltr">
                                            <div className="slick-list">
                                                <div className="slick-track" style={{width: 654, opacity: 1}}>
                                                    <div data-index="0" className="slick-slide slick-active slick-current"  aria-hidden="false" style={{outline: 'none', width: 109}}>
                                                        <div>
                                                            <div dir="ltr" className="correct-slick-active"  style={{width: '100%', display: 'inline-block'}}>
                                                                <Skeleton count={1} height={90} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div data-index="0" className="slick-slide slick-active slick-current"  aria-hidden="false" style={{outline: 'none', width: 109}}>
                                                        <div>
                                                            <div dir="ltr" className="correct-slick-active"  style={{width: '100%', display: 'inline-block'}}>
                                                                <Skeleton count={1} height={90} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div data-index="0" className="slick-slide slick-active slick-current"  aria-hidden="false" style={{outline: 'none', width: 109}}>
                                                        <div>
                                                            <div dir="ltr" className="correct-slick-active"  style={{width: '100%', display: 'inline-block'}}>
                                                                <Skeleton count={1} height={90} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div data-index="0" className="slick-slide slick-active slick-current"  aria-hidden="false" style={{outline: 'none', width: 109}}>
                                                        <div>
                                                            <div dir="ltr" className="correct-slick-active"  style={{width: '100%', display: 'inline-block'}}>
                                                                <Skeleton count={1} height={90} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div data-index="0" className="slick-slide slick-active slick-current"  aria-hidden="false" style={{outline: 'none', width: 109}}>
                                                        <div>
                                                            <div dir="ltr" className="correct-slick-active"  style={{width: '100%', display: 'inline-block'}}>
                                                                <Skeleton count={1} height={90} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product__info">
                                <div className="product__wishlist-compare">
                                    <button type="button" data-toggle="tooltip" data-placement="right" title="Wishlist" className="btn btn-sm btn-svg-icon btn-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                        <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z"></path>
                                        </svg>
                                    </button>
                                </div>
                                <h1 className="product__name"><Skeleton count={1} height={30} /></h1>
                                <div className="product__rating">
                                    <div className="product__rating-stars">
                                        <div className="rating">
                                        <div className="rating__body">
                                            <Skeleton count={1} height={20} width={200} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product__description"><Skeleton count={1} height={15} width={150} /><br /></div>
                                <ul className="product__meta">
                                    <li className="product__meta-availability"><Skeleton count={1} height={15} width={250} /></li>
                                </ul>
                            </div>
                            <div className="product__sidebar">
                                <div className="product__prices"><span className="product__new-price"><Skeleton count={1} height={30} width={100} /></span></div>
                                <form className="product__options">
                                    <div className="form-group product__option">
                                        <div className="product__option-label">Color</div>
                                        <div className="input-radio-color">
                                        <div className="input-radio-color__list">
                                            <Skeleton count={1} height={30} width={30} />
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group product__option">
                                        <div className="product__option-label">Size</div>
                                        <div className="input-radio-color">
                                        <div className="input-radio-color__list">
                                            <Skeleton count={1} height={30} width={30} />
                                        </div>
                                        </div>
                                    </div>

                                    <div className="form-group product__option">
                                        <label className="product__option-label">Quantity</label>
                                        <div className="product__actions">
                                        <div className="product__actions-item">
                                            <div className="input-number product__quantity">
                                                <Skeleton count={1} height={50} width={100} />
                                            </div>
                                        </div>
                                        <div className="product__actions-item product__actions-item--addtocart">
                                            <Skeleton count={1} height={50} width={180} />
                                        </div>
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            <Skeleton count={1} height={50} width={50} />
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
        //return <BlockLoader />;
    }

    const category = {
        name: product.category_name
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: (product.category_name).toLowerCase(), url: url.category(category) },
        { title: (product.full_name).toLowerCase(), url: '' },
    ];

    //console.log("relatedProducts", relatedProducts);

    let content;

    content = (
        <React.Fragment>
            <div className="block">
                <div className="container">
                    <Product product={product} layout={layout} />
                    <ProductTabs product={product} />
                </div>
            </div>

            {relatedProducts.length > 0 && (
                <BlockProductsCarousel
                    title="Related Products"
                    layout="grid-5"
                    products={relatedProducts}
                />
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{capitalizeFirstLetter((product.full_name).toLowerCase())}</title>
                <meta name="title" content={capitalizeFirstLetter((product.full_name).toLowerCase())} />
                <meta name="keywords" content="" />
                <meta name="description" content={capitalizeFirstLetter((product.full_name).toLowerCase())} />
                <meta name="twitter:title" content={capitalizeFirstLetter((product.full_name).toLowerCase())} />
                <meta name="twitter:description" content={capitalizeFirstLetter((product.full_name).toLowerCase())} />
                <meta property="twitter:image" content={product.product_images[0]} />
                <meta property="og:title" content={capitalizeFirstLetter((product.full_name).toLowerCase())} />
                <meta property="og:image" content={product.product_images[0]} />
                <meta property="og:url" content={Config.DOMAIN + url.product_detail(product)} />
                <link rel="canonical" href={Config.DOMAIN + url.product_detail(product)} />
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProductDetail.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProductDetail.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPageProductDetail;
