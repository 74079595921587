// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import env from "react-dotenv";
// application
import Pagination from '../shared/Pagination';

// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import Currency from '../shared/Currency';
import shopApi from '../../api/shop';

import { Config } from '../../config';

export default class AccountPageOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            orders: [],
            page: 1,
            pdf: '',
            total_pages: 0,
            total_records: 0,
            invoice_id: ''
        };
    }

    componentDidMount() {
        this.getOrders();
    }

    getOrders(){
        this.setState({ isLoading: true });

        let postJSONData = {
            records_per_page : 5,
            page: this.state.page
        }
        shopApi.getCustomerOrders(postJSONData).then((response) => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({
                        orders: response.data,
                        total_pages: response.total_pages,
                        total_records: response.total_records
                     });
                }
            }
        });

    }

    handlePageChange = (page) => {
        this.setState({ page });
        var $this = this;
        setTimeout(() => {
            $this.getOrders();
        },500);
    };

    handleSetValue(event, order){
        if(event.target.value === 'view_details'){
            this.props.history.push({ pathname : '/account/order/view', id: order.id});
        }
        if(event.target.value === 'request_for_return'){
            this.props.history.push({ pathname : '/request-for-return', order_no: order.order_no});
        }
        if(event.target.value === 'download_invoice'){
            this.setState({ isLoading: true });
            setTimeout(() => {
                document.getElementById("invoice_download_"+order.order_no).submit();
                this.setState({ isLoading: false });
            }, 1000);
        }
    };

    render() {

        const orders = this.state.orders.map((order) => {
            let orderStatus = 'Pending';
            if(order.web_order_status === 1){
                orderStatus = 'Approved';
            }
            if(order.web_order_status === 2){
                orderStatus = 'Rejected';
            }
            if(order.web_order_status === 3){
                orderStatus = 'In Preparing';
            }
            if(order.web_order_status === 4){
                orderStatus = 'In Transit';
            }
            if(order.web_order_status === 5){
                orderStatus = 'Delivered';
            }
            if(order.web_order_status === 6){
                orderStatus = 'Canceled';
            }
            if(order.is_return === 1){
                orderStatus = 'Partially Return';
            }
            if(order.is_return === 2){
                orderStatus = 'Full Return';
            }
            let isProductFound = false;
            return (
            <tr key={order.id}>
                <td width="40%"><Link hrefLang={"en"}  to={{ pathname: "/account/order/view", id: order.id}}>#{order.order_no}</Link>
                    <br/>
                    <form name={'invoice_download_'+order.order_no} id={'invoice_download_'+order.order_no} method="POST" action={Config.POS+'/download-invoice'} style={{display: 'none'}}>
                        <input type="hidden" name="id" value={order.order_no} />
                        <input type="hidden" name="random_id" value={order.customer_id} />
                    </form>
                    <span style={{fontSize: 12, color: '#000'}}>Order Items:</span><br/>
                    <ul style={{marginLeft: -20}}>
                    {order.order_items.map((order_item, index) => {
                        if(order_item.item_details !== undefined){
                            if(order_item.item_details.is_gift_card !== undefined && order_item.item_details.is_gift_card){
                                return(
                                    <React.Fragment key={index}>
                                        <li style={{width: '100%', float: 'left'}}>
                                            <span style={{fontSize: 10}}>Gift Card</span><br/>
                                            <span style={{fontSize: 9}}>Item Code: {order_item.item_details.card_no}</span><br/>
                                        </li>
                                    </React.Fragment>
                                )
                            } else {
                                isProductFound = true;
                                return(
                                    <React.Fragment key={index}>
                                        <li style={{width: '100%', float: 'left'}}>
                                            <span style={{fontSize: 10}}>{order_item.item_details.full_name}</span><br/>
                                            <span style={{fontSize: 9}}>Item Code: {order_item.item_details.item_code}</span><br/>
                                        </li>
                                    </React.Fragment>
                                )
                            }
                        }
                    })
                    }
                    </ul>
                </td>
                <td width="15%">{moment(order.date_created).format("YYYY/MM/DD")}</td>
                <td width="15%">{orderStatus}</td>
                <td width="15%"><Currency value={order.total_amount} /></td>
                <td width="20%">
                <select onChange={(event) => { this.handleSetValue(event, order) }} id="order-actions" name="actions" className="form-control form-control-select2">
                    <option value="">Actions</option>
                    <option value="view_details">View Details</option>
                    {(order.web_order_status > 0 && order.web_order_status !== 2 && order.web_order_status !== 6) &&
                        <option value="download_invoice">Download Invoice</option>
                    }
                    {(order.web_order_status === 5 || order.web_order_status === 6) &&
                        <>
                            {isProductFound &&
                            <option value="request_for_return">Request For Return</option>
                            }
                        </>
                    }
                </select>
                </td>
            </tr>
        )
        });

        return (
            <div className="card">
                <Helmet>
                    <title>{`My Orders | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>My Orders</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table order-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>#Order</th>
                                    <th>Order Date</th>
                                    <th>Status</th>
                                    <th>Order Total</th>
                                    <th width="20%">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    <Pagination current={this.state.page} total={this.state.total_pages} onPageChange={this.handlePageChange} />
                </div>
            </div>
        );
    }
}
