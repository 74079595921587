// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import PageHeader from '../shared/PageHeader';
import PromotionsView from './PromotionsView';
import shopApi from '../../api/shop';
import { sidebarClose } from '../../store/sidebar';
import { url } from '../../services/utils';
import { Config } from '../../config';

// data stubs
import theme from '../../data/theme';
const initialState = {
    init: false,
    /**
     * Indicates that the category list is loading.
     */
    promotionListIsLoading: true,
    /**
     * Brands list.
     */
    brandList: null
};

function reducer(state, action) {
    switch (action.type) {
    case 'FETCH_PROMOTION_LIST':
        return { ...state, promotionListIsLoading: true };
    case 'FETCH_PROMOTION_SUCCESS':
        return {
            ...state,
            init: true,
            promotionListIsLoading: false,
            promotionList: action.promotionList,
        };
    default:
        throw new Error();
    }
}

function init(state) {
    return { ...state };
}

function ShopPagePromotions(props) {
    const {
        columns,
        viewMode
    } = props;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [metaTags, setMetaTags] = useState({});

    // Load categories.
    useEffect(() => {
        let request;
        let canceled = false;

        dispatch({ type: 'FETCH_PROMOTION_LIST' });

        request = shopApi.getAllPromotions();

        request.then((promotionList) => {
            if (canceled) {
                return;
            }
            dispatch({ type: 'FETCH_PROMOTION_SUCCESS', promotionList });
        });

        request = shopApi.getMetaTagsBySlug('promotions');
        request.then((metaTags) => {
            if (canceled) {
                return;
            }
            setMetaTags(metaTags);
        });

        return () => {
            canceled = true;
        };
    }, [dispatch, '']);

    if (state.promotionListIsLoading && !state.promotionList) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Promotions', url: url.promotions() },
    ];
    let pageTitle = metaTags.data.h1_title;
    let content;


    const promotionView = (
        <PromotionsView
            isLoading={state.promotionListIsLoading}
            promotionList={state.promotionList}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
        />
    );

    content = (
        <div className="container">
            <div className="block">{promotionView}</div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.data.meta_title}</title>
                <meta name="title" content={metaTags.data.meta_title} />
                <meta name="description" content={metaTags.data.meta_desc} />
                <meta name="twitter:title" content={metaTags.data.meta_title} />
                {state.promotionList &&
                    <meta property="twitter:image" content={state.promotionList.data[0].promotion_image} />
                }
                <meta name="twitter:description" content={metaTags.data.meta_desc} />
                <meta property="og:title" content={metaTags.data.meta_title} />
                <meta property="og:description" content={metaTags.data.meta_desc} />
                {state.promotionList &&
                    <meta property="og:image" content={state.promotionList.data[0].promotion_image} />
                }
                <meta property="og:url" content={ Config.DOMAIN + url.promotions() } />
                <link rel="canonical" href={ Config.DOMAIN + url.promotions() } />
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPagePromotions.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPagePromotions.defaultProps = {
    columns: 4,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.promotion,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPagePromotions);