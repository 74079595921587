// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';
import { url } from '../../services/utils';

function Suggestions(props) {
    const {
        value,
        context,
        className,
        products,
        cartAddItem,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    /**
     * [handleSubmitForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}       
     */
    //  const handleSubmitForm = (event) => {
    //     //event.preventDefault();
    //     //alert(value);
    //     if(value !==''){
    //         props.history.push("/category/all?search="+value);
    //         //event.preventDefault();
    //     }
    // }

    //console.log("props  sugg", props);

    
    const list = ((products.data !== undefined && products.data !== null && products.data.length > 0) && products.data.map((product) => (
        <li key={product.id} className="suggestions__item">
            {product.product_main_image && product.product_main_image !== '' && (
                <div className="suggestions__item-image product-image">
                    <div className="product-image__body">
                        <img className="product-image__img" src={product.product_main_image} alt={(product.full_name).toLowerCase()} />
                    </div>
                </div>
            )}
            <div className="suggestions__item-info">
                <Link hrefLang={"en"}  className="suggestions__item-name" to={url.product_detail(product)}>
                    {(product.full_name).toLowerCase()}
                </Link>
                <div className="suggestions__item-meta">SKU: {product.final_item_code}</div>
            </div>
            <div className="suggestions__item-price">
                <Currency value={product.selling_price} />
            </div>
            {/* {context === 'header' && (
                <div className="suggestions__item-actions">
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                title="Add to cart"
                                className={classNames('btn btn-primary btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Cart16Svg />
                            </button>
                        )}
                    />
                </div>
            )} */}
        </li>
    )));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {list}
                {(products.data !== undefined && products.data === null && products.status == 0) &&
                    <li className="suggestions__item">No items found!</li>
                }
                {/* <li className="suggestions__item" style={{float: 'right'}}> 
                    <form onSubmit={handleSubmitForm}>
                        <button className="btn btn-primary" type="submit">
                            <span>View All</span>
                        </button>
                    </form>
                </li> */}
            </ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
