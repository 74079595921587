// react
import React, { useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import AccountPageForgotPassword from './account/AccountPageForgotPassword';
import AccountPageResetPassword from './account/AccountPageResetPassword';
import AccountPageSetPassword from './account/AccountPageSetPassword';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';

import PageCart from './shop/ShopPageCart';
import PageCheckout from './shop/ShopPageCheckout';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageBrands from './shop/ShopPageBrands';
import ShopPageCategories from './shop/ShopPageCategories';
import ShopPagePromotions from './shop/ShopPagePromotions';
import ShopPageOrderProcess from './shop/ShopPageOrderProcess';
import ShopPageOrderStatus from './shop/ShopPageOrderStatus';
import ShopPageOrderCancel from './shop/ShopPageOrderCancel';
import ShopPageProductDetail from './shop/ShopPageProductDetail';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageComponents from './site/SitePageComponents';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageFaq from './site/SitePageFaq';
import SitePageShippingInformation from './site/SitePageShippingInformation';
import SitePagePrivacyPolicy from './site/SitePagePrivacyPolicy';
import SitePageReturnPolicy from './site/SitePageReturnPolicy';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePageCareers from './site/SitePageCareers';
import SitePageRequestForReturn from './site/SitePageRequestForReturn';
import SitePageSuitMaintenance from './site/SitePageSuitMaintenance';
import SitePageStoreLocator from './site/SitePageStoreLocator';

import AccountPageEmailVerificationFailed from './account/AccountPageEmailVerificationFailed';
import AccountPageEmailVerificationSuccess from './account/AccountPageEmailVerificationSuccess';
import AccountPageEmailNotFound from './account/AccountPageEmailNotFound';

function Layout(props) {
    const { match, headerLayout, homeComponent, leftMenuItems } = props;

    //Google analytics
    // ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <React.Fragment>
            <ToastContainer autoClose={5000} hideProgressBar />
            <Quickview />
            <MobileMenu leftMenuItems={leftMenuItems} />
            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>
                <header className="site__header d-lg-block d-none">
                    <Header leftMenuItems={leftMenuItems} layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        <Route exact path="/" component={homeComponent} />
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        <Route
                            exact
                            path="/promotions"
                            render={(props) => (
                                <ShopPagePromotions {...props} columns={4} viewMode="grid" />
                            )}
                        />

                        <Route
                            exact
                            path="/brand"
                            render={(props) => (
                                <ShopPageBrands {...props} columns={4} viewMode="grid" />
                            )}
                        />
                        <Route
                            exact
                            path="/brand/:name"
                            render={(props) => (
                                <ShopPageCategory {...props}
                                    brandSlug={(((props.location.pathname).split("/")[2]).toLowerCase()).split("?")[0]}
                                    categorySlug=''
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    search={(props.location !== undefined && props.location.search !== undefined && props.location.search !== null && props.location.search !== '' ? (props.location.search).split("=")[1] : '')}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/category"
                            render={(props) => (
                                <ShopPageCategories {...props} columns={4} viewMode="grid" />
                            )}
                        />
                         <Route
                            exact
                            path="/category/:name"
                            render={(props) => (
                                <ShopPageCategory {...props}
                                    brandSlug=''
                                    categorySlug={(((props.location.pathname).split("/")[2]).toLowerCase()).split("?")[0]}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    search={(props.location !== undefined && props.location.search !== undefined && props.location.search !== null && props.location.search !== '' ? (props.location.search).split("=")[1] : '')}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/category/:category_name/:productSlug"
                            render={(props) => (
                                <ShopPageProductDetail
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                />
                            )}
                        />

                        <Route exact path="/cart" component={PageCart} />
                        <Route exact path="/checkout" component={PageCheckout} />
                        <Route exact path="/order-process" component={ShopPageOrderProcess} />
                        <Route exact path="/order-cancel" component={ShopPageOrderCancel} />
                        <Route exact path="/order-status/:order_number" component={ShopPageOrderStatus} />
                        <Route exact path="/wishlist" component={PageWishlist} />
                        <Route exact path="/track-order" component={ShopPageTrackOrder} />

                        {/* Email verification routes */}
                        <Route exact path="/email-verification-success" component={AccountPageEmailVerificationSuccess} />
                        <Route exact path="/email-verification-failed" component={AccountPageEmailVerificationFailed} />
                        <Route exact path="/email-not-found" component={AccountPageEmailNotFound} />

                        <Route
                            exact
                            path="/blog"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/:slug"
                            render={(props) => (
                                <BlogPagePost {...props} layout="full" />
                            )}
                        />

                        {/*
                        // Account
                        */}
                        <Route exact path="/account/login" component={AccountPageLogin} />
                        <Route exact path="/account/forgot-password" component={AccountPageForgotPassword} />
                        <Route exact path="/account/reset-password" component={SitePageNotFound} />
                        <Route exact path="/account/reset-password/:token" component={AccountPageResetPassword} />
                        <Route exact path="/account/set-password" component={SitePageNotFound} />
                        <Route exact path="/account/set-password/:token" component={AccountPageSetPassword} />
                        <Route path="/account" component={AccountLayout} />

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/about-us" />
                        <Route exact path="/about-us" component={SitePageAboutUs} />
                        <Route exact path="/components" component={SitePageComponents} />
                        <Route exact path="/contact-us" component={SitePageContactUs} />
                        <Route exact path="/contact-us-alt" component={SitePageContactUsAlt} />
                        {/* <Route exact path="/not-found" component={SitePageNotFound} /> */}
                        <Route exact path="/faq" component={SitePageFaq} />
                        <Route exact path="/shipping-information" component={SitePageShippingInformation} />
                        <Route exact path="/privacy-policy" component={SitePagePrivacyPolicy} />
                        <Route exact path="/return-policy" component={SitePageReturnPolicy} />
                        <Route exact path="/terms-conditions" component={SitePageTerms} />
                        <Route exact path="/careers" component={SitePageCareers} />
                        <Route exact path="/request-for-return" component={SitePageRequestForReturn} />
                        <Route exact path="/suit-maintenance" component={SitePageSuitMaintenance} />
                        <Route exact path="/store-location" component={SitePageStoreLocator} />
                        {/*
                        // Page Not Found
                        */}
                        <Route path="*" component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
