// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg, FiPaymentSecurity48Svg } from '../../svg';
import { toast } from 'react-toastify';
import lottie from "lottie-web";
import Lottie from 'react-lottie';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';
import { Config } from '../../config';
import animationOrderFail from '../../lotties/order_fail';

export default class ShopPageOrderCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'You have cancelled your payment!',
            message: 'Transaction not processed.'
        };
    }

    componentDidMount(){
    }

    render() {

        const defaultOptions = {
            loop: true,
            autoplay: this.state.showAnimation,
            animationData: animationOrderFail,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Order Cancel | ${theme.name}`}</title>
                </Helmet>

                <div className="container">
                    <div className="order-success__body">

                        <div className="order-success__header">
                            <Lottie
                                options={defaultOptions}
                                height={300}
                                width={300}
                            />
                            <h1 className="order-success__title">{this.state.title}</h1>
                            <div className="order-success__subtitle">{this.state.message}</div>
                            <div className="order-success__actions">
                                <Link hrefLang={"en"}  to="/" className="btn btn-xs btn-secondary">Continue Shopping</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );

    }
}
