// react
import React, { useState } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import shopApi from '../../api/shop';
import { Config } from '../../config';

import { cartRefresh } from '../../store/cart';
import { wishlistRefesh } from '../../store/wishlist';

export default function IndicatorAccount(props) {
    const profile_picture = 'images/avatars/user_placeholder.jpeg';
    var [closePopup, setClosePopup] = useState(false);

    //const { cartRefresh, wishlistRefesh } = props;
    
    const onClickClose = () => {
        setClosePopup(true);
        setTimeout(() => {
            setClosePopup(false);
        }, 1000);
    }  
    
    const logoutUser = () => {
        let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));              
            
            fetch(Config.URL+"/logout", {
                method: "POST",
                headers: headers,
                body: null
            })
            .then(response => response.json())
            .then(response => {
                cartRefresh();
                wishlistRefesh();
            })
            .catch(err => {
                //console.log("Logout err: ", err);
            });
            localStorage.removeItem(Config.x_token);
            localStorage.removeItem(Config.web_user);
    }

    //console.log("shopApi.getAuth()", shopApi.getAuth());

    const dropdown = (
            <>
            {/* <form className="account-menu__form"> */}
                {/* <div className="account-menu__form-title">Log In to Your Account</div> */}
                {/* <div className="form-group">
                    <label htmlFor="header-signin-email" className="sr-only">Email address</label>
                    <input
                        id="header-signin-email"
                        type="email"
                        className="form-control form-control-sm"
                        placeholder="Email address"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="header-signin-password" className="sr-only">Password</label>
                    <div className="account-menu__form-forgot">
                        <input
                            id="header-signin-password"
                            type="password"
                            className="form-control form-control-sm"
                            placeholder="Password"
                        />
                        <Link hrefLang={"en"}  to="/account/login" className="account-menu__form-forgot-link">Forgot?</Link>
                    </div>
                </div> */}
                {/* <div className="form-group account-menu__form-button">
                    <button type="submit" className="btn btn-primary btn-sm">Login</button>
                </div> */}
                {shopApi.getAuth() === null &&
                    <>
                        <div>&nbsp;</div>
                        <div className="account-menu__form-link">
                            <Link hrefLang={"en"}  onClick={() => { onClickClose() }} to="/account/login">Create An Account/Login</Link>
                        </div>
                        <div>&nbsp;</div>
                    </>
                }
            {/* </form> */}

            {shopApi.getAuth() !== null &&
                <>
                    <div className="account-menu__divider" />
                    <Link hrefLang={"en"}  onClick={() => { onClickClose() }} to="/account/profile" className="account-menu__user">
                        <div className="account-menu__user-avatar">
                            <img src={(shopApi.getAuth() !== null && JSON.parse(shopApi.getAuth()).profile_image !==undefined && JSON.parse(shopApi.getAuth()).profile_image !==null && JSON.parse(shopApi.getAuth()).profile_image !=='' ? JSON.parse(shopApi.getAuth()).profile_image : profile_picture )} alt="" />
                        </div>
                        <div className="account-menu__user-info">
                            <div className="account-menu__user-name">{ (shopApi.getAuth() !== null ? JSON.parse(shopApi.getAuth()).first_name +" "+JSON.parse(shopApi.getAuth()).last_name : '' )}</div>
                            {/* <div className="account-menu__user-email"><Link hrefLang={"en"}  to="/account/profile">View Profile</Link></div> */}
                        </div>
                    </Link>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li onClick={() => { onClickClose() }}><Link hrefLang={"en"}  to="/account/dashboard">Dashboard</Link></li>
                        {/* <li><Link hrefLang={"en"}  to="/account/profile">Edit Profile</Link></li> */}
                        <li onClick={() => { onClickClose() }}><Link hrefLang={"en"}  to="/account/orders">Order History</Link></li>
                        <li onClick={() => { onClickClose() }}><Link hrefLang={"en"}  to="/account/addresses">Addresses</Link></li>
                        <li onClick={() => { onClickClose() }}><Link hrefLang={"en"}  to="/account/password">Password</Link></li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li onClick={() => { logoutUser() }}><Link hrefLang={"en"}  onClick={() => { onClickClose() }} to="/account/login">Logout</Link></li>
                    </ul>
                </>
            }
            </>
       
    );

    return (
        <Indicator url="/account" dropdown={dropdown} closePopup={closePopup} isAccountPopup={true} icon={<Person20Svg />} />
    );
}
