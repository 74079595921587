// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import HomeProductCard from '../shared/HomeProductCard';
import StroykaSlick from '../shared/StroykaSlick';
import { Config } from '../../config';

const slickSettings = {
    'grid-4': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-4-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 474,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-5': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    horizontal: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockHomeProductsCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            products: []
        };
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    async componentDidMount() {
        await this.getProducts();
    }

    getProducts(){
        this.setState({ loading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.web_user) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        fetch(Config.URL+this.props.URL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({records_per_page: 10})
        })
        .then(response => response.json())
        .then(response => {
            //console.log(this.props.URL+": ", response);
            if(response.status){
                this.setState({ loading: false });
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                } else {
                    this.setState({ products: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ loading: false });
            //console.log("getBestSellerProducts err: ", err);
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    productsColumns() {
        const columns = [];
        const { rows } = this.props;
        //let { products } = this.props;
        let products = this.state.products;

        if (rows > 0) {
            products = products.slice();

            while (products.length > 0) {
                columns.push(products.splice(0, rows));
            }
        }

        return columns;
    }

    render() {
        const {
            layout,
            title,
            withSidebar,
            onGroupClick,
            groups,
        } = this.props;
        let loading = this.state.loading;

        const columns = this.productsColumns().map((column, index) => {
            const products = column.map((product) => (
                <div key={product.id} className="block-products-carousel__cell">
                    <HomeProductCard product={product} />
                </div>
            ));

            return (
                <div key={index} className="block-products-carousel__column">
                    {products}
                </div>
            );
        });

        const blockClasses = classNames('block block-products-carousel', {
            'block-products-carousel--loading': loading,
            'block-products-carousel--has-items': columns.length > 0,
        });
        const containerClasses = classNames({
            container: !withSidebar,
        });

        return (
            <>
            {this.state.products.length > 0 &&
                <div className={blockClasses} data-layout={layout}>
                    <div className={containerClasses}>
                        <BlockHeader
                            title={title}
                            groups={groups}
                            arrows
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                            onGroupClick={onGroupClick}
                        />

                        <div className="block-products-carousel__slider">
                            <div className="block-products-carousel__preloader" />

                            <StroykaSlick
                                ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {columns}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

BlockHomeProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    URL: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};

BlockHomeProductsCarousel.defaultProps = {
    URL: '',
    layout: 'grid-4',
    rows: 1,
    products: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined,
};








// // react
// import React, { Component } from 'react';

// // third-party
// import classNames from 'classnames';
// import PropTypes from 'prop-types';

// // application
// import BlockHeader from '../shared/BlockHeader';
// import ProductCard from '../shared/ProductCard';
// import HomeProductCard from '../shared/HomeProductCard';
// import StroykaSlick from '../shared/StroykaSlick';

// const slickSettings = {
//     'grid-4': {
//         dots: false,
//         arrows: false,
//         infinite: true,
//         speed: 400,
//         slidesToShow: 4,
//         slidesToScroll: 4,
//         responsive: [
//             {
//                 breakpoint: 992,
//                 settings: {
//                     slidesToShow: 4,
//                     slidesToScroll: 4,
//                 },
//             },
//             {
//                 breakpoint: 991,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                 },
//             },
//             {
//                 breakpoint: 767,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                 },
//             },
//             {
//                 breakpoint: 479,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     },
//     'grid-4-sm': {
//         dots: false,
//         arrows: false,
//         infinite: true,
//         speed: 400,
//         slidesToShow: 4,
//         slidesToScroll: 4,
//         responsive: [
//             {
//                 breakpoint: 1199,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                 },
//             },
//             {
//                 breakpoint: 767,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                 },
//             },
//             {
//                 breakpoint: 474,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     },
//     'grid-5': {
//         dots: false,
//         arrows: false,
//         infinite: true,
//         speed: 400,
//         slidesToShow: 5,
//         slidesToScroll: 5,
//         responsive: [
//             {
//                 breakpoint: 1199,
//                 settings: {
//                     slidesToShow: 4,
//                     slidesToScroll: 4,
//                 },
//             },
//             {
//                 breakpoint: 991,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                 },
//             },
//             {
//                 breakpoint: 767,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                 },
//             },
//             {
//                 breakpoint: 479,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     },
//     horizontal: {
//         dots: false,
//         arrows: false,
//         infinite: true,
//         speed: 400,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         responsive: [
//             {
//                 breakpoint: 991,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                 },
//             },
//             {
//                 breakpoint: 767,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     },
// };

// export default class BlockHomeProductsCarousel extends Component {
//     handleNextClick = () => {
//         if (this.slickRef) {
//             this.slickRef.slickNext();
//         }
//     };

//     handlePrevClick = () => {
//         if (this.slickRef) {
//             this.slickRef.slickPrev();
//         }
//     };

//     setSlickRef = (ref) => {
//         this.slickRef = ref;
//     };

//     render() {
//         const {
//             layout,
//             title,
//             withSidebar,
//             onGroupClick,
//             groups,
//             loading,
//         } = this.props;

//         console.log("products.data", this.props.products.data);

//         let products = '';
//         if(this.props.products.status !== undefined && this.props.products.status){
//             products = this.props.products.data.map((product) => (
//                 <div key={product.id} className="block-products-carousel__cell">
//                     <HomeProductCard product={product} />
//                 </div>
//             ));
//         }

//         const blockClasses = classNames('block block-products-carousel', {
//             'block-products-carousel--loading': loading,
//             'block-products-carousel--has-items': (this.props.products.data !== undefined ? this.props.products.data.length > 0 : 0),
//         });
//         const containerClasses = classNames({
//             container: !withSidebar,
//         });

//         return (
//             <div className={blockClasses} data-layout={layout}>
//                 <div className={containerClasses}>
//                     <BlockHeader
//                         title={title}
//                         groups={groups}
//                         arrows
//                         onNext={this.handleNextClick}
//                         onPrev={this.handlePrevClick}
//                         onGroupClick={onGroupClick}
//                     />

//                     <div className="block-products-carousel__slider">
//                         <div className="block-products-carousel__preloader" />

//                         <StroykaSlick
//                             ref={this.setSlickRef}
//                             {...slickSettings[layout]}
//                         >
//                             <div className="block-products-carousel__column">
//                                 {products}
//                             </div>
//                         </StroykaSlick>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// BlockHomeProductsCarousel.propTypes = {
//     title: PropTypes.string.isRequired,
//     layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
//     rows: PropTypes.number,
//     products: PropTypes.array,
//     groups: PropTypes.array,
//     withSidebar: PropTypes.bool,
//     loading: PropTypes.bool,
//     onGroupClick: PropTypes.func,
// };

// BlockHomeProductsCarousel.defaultProps = {
//     layout: 'grid-4',
//     rows: 1,
//     products: [],
//     groups: [],
//     withSidebar: false,
//     loading: false,
//     onGroupClick: undefined,
// };
