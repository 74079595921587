// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem, cartRefresh } from '../../store/cart';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';
import { LogoSmallSvg } from '../../svg';

function IndicatorCart(props) {
    const { cart, cartRemoveItem, cartRefresh } = props;
    let dropdown;
    let totals;
    const [handleButtonClickOutSideToggle, setHandleButtonClickOutSide] = useState(false);
    //console.log("cart", cart);

    //Load cart.
    //useEffect(() => {

        // shopApi.getCartItems().then((cartItems) => {
        //     console.log("cartItems", cartItems);
        //     if(cartItems.status == 1){
        //         cartRefresh()
        //     }
        // });

        // return () => {
        //     canceled = true;
        // };
    //}, []);

    if (cart.extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => {
            if(extraLine.price > 0){
                return (
                    <tr key={index}>
                        <th>{extraLine.title}</th>
                        <td><Currency value={extraLine.price} /></td>
                    </tr>
                )
            }
        });

        totals = (
            <React.Fragment>
                {/* <tr>
                    <th>Subtotal</th>
                    <td><Currency value={cart.subtotal} /></td>
                </tr>
                <tr>
                    <th>Discount</th>
                    <td><Currency value={cart.total_discount} /></td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td><Currency value={parseFloat(cart.subtotal) - parseFloat(cart.total_discount)} /></td>
                </tr> */}
                {extraLines}
            </React.Fragment>
        );
    }


    let items = '';
    if(cart.items.status !== undefined && cart.items.status && cart.items.status == 1){
        items = cart.items.data.map((item) => {
            let options;
            let image;
            let price;

            if(item.product_details !== ''){

                item.product = item.product_details;

                if(item.gift_card_id > 0){

                    image = (
                        <div className="product-image dropcart__product-image">
                            <div className="product-image__body">
                                <img className="product-image__img" src={item.product.image} alt={"Gift Card " + item.product.balance} title={"Gift Card " + item.product.balance} />
                            </div>
                        </div>
                    );

                    const removeButton = (
                        <AsyncAction
                            action={() => cartRemoveItem(item.id)}
                            render={({ run, loading }) => {
                                const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                });

                                return (
                                    <button type="button" onClick={run} className={classes}>
                                        <Cross10Svg />
                                    </button>
                                );
                            }}
                        />
                    );

                    return (
                        <div key={item.id} className="dropcart__product">
                            {image}
                            <div className="dropcart__product-info">
                                <div className="dropcart__product-name">
                                    <Link hrefLang={"en"}  to="/">Gift Card</Link>
                                </div>
                                <ul className="dropcart__product-options">
                                    <li>{item.product.card_no}</li>
                                </ul>
                                <div className="dropcart__product-meta">
                                    <span className="dropcart__product-quantity">1</span>
                                    {' × '}
                                    <Currency value={item.product.balance} />
                                </div>
                            </div>
                            {removeButton}
                        </div>
                    );

                } else {

                    let discount = item.product.discounted_amount;
                    item.compareAtPrice = false;
                    if(item.product.discount !== undefined && item.product.discount !== null && item.product.discount !== '' && item.product.discount > 0){
                        // let pp = parseFloat(item.product.selling_price) * parseInt(discount) / 100;
                        // let fpp = parseFloat(item.product.selling_price) - parseFloat(pp);
                        item.compareAtPrice = (item.product.selling_price) - ((item.product.selling_price) * (item.product.discount) / 100);
                        //totals += fpp * item.quantity;
                    }

                    if (item.compareAtPrice) {
                        price = (
                            <div className="product-card__prices">
                                <span className="product-card__new-price"><Currency value={item.compareAtPrice} /></span>
                                {' '}
                                <span className="product-card__old-price"><Currency value={item.product.selling_price} /></span>
                            </div>
                        );
                    } else {
                        price = (
                            <div className="product-card__prices">
                                <Currency value={item.product.selling_price} />
                            </div>
                        );
                    }

                    //if (item.options) {
                        options = (
                            // <ul className="dropcart__product-options">
                            //     {item.options.map((option, index) => (
                            //         <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                            //     ))}
                            // </ul>
                            <ul className="dropcart__product-options">
                                {item.product.brand_name && <li>Brand : {item.product.brand_name}</li>}
                                {item.product.color_name && <li>Color : {item.product.color_name}</li>}
                                {item.product.size_name && <li>Size : {item.product.size_name}</li>}
                                {/* {product.style_name && <li>Style : {product.style_name}</li>} */}
                                {item.product.final_item_code && <li>SKU : {item.product.final_item_code}</li>}
                                {/* {product.express_delivery_image !=='' && <img src={product.express_delivery_image} style={{width: 160, height: 'auto'}} />} */}
                            </ul>
                        );
                    //}

                    if (item.product.product_main_image !== '') {
                        image = (
                            <div className="product-image dropcart__product-image">
                                <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="product-image__body">
                                    <img className="product-image__img" src={item.product.product_main_image} alt={item.product.full_name} title={item.product.full_name} />
                                </Link>
                            </div>
                        );
                    }

                    const removeButton = (
                        <AsyncAction
                            action={() => cartRemoveItem(item.id)}
                            render={({ run, loading }) => {
                                const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                });

                                return (
                                    <button type="button" onClick={run} className={classes}>
                                        <Cross10Svg />
                                    </button>
                                );
                            }}
                        />
                    );

                    return (
                        <div key={item.id} className="dropcart__product">
                            {image}
                            <div className="dropcart__product-info">
                                <div className="dropcart__product-name">
                                    <Link hrefLang={"en"}  to={url.product_detail(item.product)}>{item.product.full_name}</Link>
                                </div>
                                {options}
                                <div className="dropcart__product-meta">
                                    <span className="dropcart__product-quantity">{item.qty}</span>
                                    {' × '}
                                    {price}
                                </div>
                            </div>
                            {removeButton}
                        </div>
                    );

                }
            } else {
                const removeButton = (
                    <AsyncAction
                        action={() => cartRemoveItem(item.id)}
                        render={({ run, loading }) => {
                            const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                                'btn-loading': loading,
                            });

                            return (
                                <button type="button" onClick={run} className={classes}>
                                    <Cross10Svg />
                                </button>
                            );
                        }}
                    />
                );

                return (
                    <div key={item.id} className="dropcart__product">
                        <div className="dropcart__product-info">
                            <div className="dropcart__product-name">
                                Invalid cart product. Please remove this!
                            </div>
                            <ul className="dropcart__product-options">
                                <li>-</li>
                            </ul>
                            <div className="dropcart__product-meta">
                                <span className="dropcart__product-quantity">0</span>
                            </div>
                        </div>
                        {removeButton}
                    </div>
                );
            }
        });
    }

    if(cart.items.status !== undefined && cart.items.status && cart.items.status == 1){
        dropdown = (
            <>
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                        </tbody>
                    </table>
                </div>
            </>
        );
    } else {
        dropdown = (
            <div>
                Your shopping cart is empty. <br/><br/> Click got button to view the Gift Cards!
            </div>
        );
    }
    return (
        <Indicator url="/cart" cartLength={(cart.items.status !== undefined && cart.items.status && cart.items.status == 1 ? true : false)} dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartRefresh
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
