// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

function FilterRadio(props) {
    const { data, value, onChangeValue } = props;    

    const updateValue = (newValue) => {
        onChangeValue({ filter: data, value: newValue });
    };

    const handleChange = (event) => {
        if (event.target.checked && event.target.value !== value) {
            updateValue(event.target.value);
        }
    };

    const itemsList = data.filter_items.map((item, itemKey) => {
        let count;

        if (item.count) {
            count = <span className="filter-list__counter">{item.count}</span>;
        }

        const itemClasses = classNames('filter-list__item', {
            'filter-list__item--disabled': item.count === 0,
        });

        return (
            <React.Fragment key={"filter_radio_"+itemKey}>
            <label      
                id={"Item_id: "+item.id}          
                className={itemClasses}
            >
                <span className="filter-list__input input-radio">
                    <span className="input-radio__body">
                        <input
                            className="input-radio__input"
                            type="radio"
                            name={data.slug}
                            //value={(data.slug === 'sorting_by' ? item.slug : item.id)}
                            value={item.id}
                            //checked={(value !== undefined && value !=='' ? (data.slug === 'sorting_by' ? value == (item.slug) : value == (item.id).toString()) : false)}
                            checked={(value !== undefined && value !=='' ? value == (item.id).toString() : false)}
                            //disabled={item.count === 0}
                            onChange={handleChange}
                        />
                        <span className="input-radio__circle" />
                    </span>
                </span>
                {(item.name && data.slug === 'discount') ? 
                    <>
                    <div className='row'>
                        <div className='col-12'>
                            <img style={{borderRadius: 5, width: 90}} src={item.promotion_image} />
                        </div>
                        <div className='col-12'>
                            <span className="filter-list__title" style={{fontSize: 12, fontWeight: 'bold'}}>{item.name && (item.name).toLowerCase()}</span>
                        </div>
                    </div>
                    <span className="filter-list__counter">{item.discount}%</span>
                    </>
                :
                    <span className="filter-list__title">{item.name && (item.name).toLowerCase()}</span>                
                }                
            </label>
            </React.Fragment>
        );
    });

    return (
        <div className="filter-list">
            <div className="filter-list__list">
                {itemsList}
            </div>
        </div>
    );
}

FilterRadio.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.string,
    /**
     * Value.
     */
     value: PropTypes.array,
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
};

export default FilterRadio;
