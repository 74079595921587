import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import shopApi from '../../api/shop';
import { Config } from '../../config';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_LOGIN_REQUIRED, CART_REFRESH, CHECKOUT_ITEMS } from './cartActionTypes';

// toast.configure({
//     autoClose: 2000,
//     draggable: true,
//     hideProgressBar: false,
//     progress: 0.2
// });

const Msg = (props) => {
    return (
      <>
        {props.message}
        <br />
        <Link hrefLang={"en"}  style={{color: "#fff", fontWeight: 'bold', fontSize: 16}} to="/account/login">Login here!</Link>
      </>
    );
  };

export function cartLoginRequired() {
    return {
        type: CART_LOGIN_REQUIRED
    };
}

export function cartRefreshSuccess(response) {
    return {
        type: CART_REFRESH,
        response
    };
}

export function checkOutItemSuccess(response) {
    return {
        type: CHECKOUT_ITEMS,
        response
    };
}

export function cartAddItemSuccess(product, options = [], quantity = 1, response) {
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        response
    };
}

export function cartRemoveItemSuccess(itemId, response) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
        response: response
    };
}

export function cartUpdateQuantitiesSuccess(response) {
    return {
        type: CART_UPDATE_QUANTITIES,
        response,
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                //dispatch(cartAddItemSuccess(product, options, quantity));
                let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                if(localStorage.getItem(Config.x_token) !== null){
                    headers.append('device_token', Config.device_token);
                    headers.append('device_type', Config.device_type);
                    headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
                }

                fetch(Config.URL+"/add-to-cart", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({
                        product_id: product.id,
                        qty: quantity
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status){
                        if(response.status == 2){
                            toast.error(response.message);
                        } else {

                            shopApi.g4EventPush("add_to_cart", product);

                            toast.success(`Product "${(product.full_name).toLowerCase()}" added to cart successfully!`);
                            shopApi.getCartItems().then(async (responseCartItems) => {
                                dispatch(cartAddItemSuccess(product, options, quantity, responseCartItems));
                            });

                        }
                    } else {
                        if(localStorage.getItem(Config.x_token) === null){
                            toast.error(<Msg message="You must have to login to use add to cart!" />);
                            dispatch(cartLoginRequired());
                        } else {
                            toast.error(response.message);
                            dispatch(cartLoginRequired());
                        }
                    }
                })
                .catch(err => {
                    //console.log("Add to cart err: ", err);
                    return;
                });
                resolve();
            }, 500);
        })
    );

    // return (dispatch) => (
    //     new Promise((resolve) => {
    //         setTimeout(() => {
    //             dispatch(cartAddItemSuccess(product, options, quantity));
    //             resolve();
    //         }, 500);
    //     })
    // );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {

            let product = '';
            shopApi.getCartItems().then(async (responseCartItems) => {
                //console.log("responseCartItems: ", responseCartItems);
                product = responseCartItems.data.filter((item) => item.id == itemId);
                if(product[0].gift_card_id > 0){
                    let gift_card_data = {};
                    gift_card_data.final_item_code = product[0].product_details.id;
                    gift_card_data.full_name = product[0].product_details.card_no;
                    gift_card_data.category_name = '';
                    gift_card_data.brand_name = '';
                    gift_card_data.color_name = '';
                    gift_card_data.selling_price = product[0].product_details.balance;
                    gift_card_data.discount_price = 0;
                    gift_card_data.size_name = '';
                    gift_card_data.gift_card_id = product[0].product_details.id;
                    product[0].product_details = gift_card_data;
                }

                setTimeout(() => {
                    //dispatch(cartRemoveItemSuccess(itemId));
                    let headers = new Headers();
                    headers.append('Content-Type', 'application/json');
                    headers.append('Accept', 'application/json');
                    if(localStorage.getItem(Config.x_token) !== null){
                        headers.append('device_token', Config.device_token);
                        headers.append('device_type', Config.device_type);
                        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
                    }

                    fetch(Config.URL+"/remove-from-cart", {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            cart_id: itemId
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(response.status){
                            if(response.status == 2){
                                toast.error(response.message);
                            } else {
                                shopApi.g4EventPush("remove_from_cart", product[0].product_details);
                                shopApi.getCartItems().then(async (responseCartItems) => {
                                    dispatch(cartRemoveItemSuccess(itemId, responseCartItems));
                                });
                            }
                        } else {
                            toast.error(<Msg message="You must login to use remove from cart!" />);
                            dispatch(cartLoginRequired());
                        }
                    })
                    .catch(err => {
                        //console.log("Remove from cart err: ", err);
                        return;
                    });
                    resolve();
                }, 500);

            });
        })
    );
}

export function cartUpdateQuantities(product, quantity) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                if(localStorage.getItem(Config.x_token) !== null){
                    headers.append('device_token', Config.device_token);
                    headers.append('device_type', Config.device_type);
                    headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
                }

                fetch(Config.URL+"/add-to-cart", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({
                        product_id: product.id,
                        qty: quantity
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status){
                        if(response.status == 2){
                            toast.error(response.message);
                        } else {
                            //toast.success(`Quantity for "${(product.full_name).toLowerCase()}" has been applied successfully!`);
                            toast.success(`Quantity updated successfully!`);
                            shopApi.getCartItems().then(async (responseCartItems) => {
                                dispatch(cartUpdateQuantitiesSuccess(responseCartItems));
                            });
                        }
                    } else {
                        if(localStorage.getItem(Config.x_token) === null){
                            toast.error(<Msg message="You must login to use update cart quantity!" />);
                            dispatch(cartLoginRequired());
                        } else {
                            toast.error(response.message);
                            dispatch(cartLoginRequired());
                        }
                    }
                })
                .catch(err => {
                    //console.log("Update cart qty error: ", err);
                    return;
                });
                resolve();
            }, 500);
        })
    );
    // return (dispatch) => (
    //     new Promise((resolve) => {
    //         setTimeout(() => {
    //             dispatch(cartUpdateQuantitiesSuccess(quantities));
    //             resolve();
    //         }, 500);
    //     })
    // );
}

export function cartRefresh() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                shopApi.getCartItems().then(async (responseCartItems) => {
                    dispatch(cartRefreshSuccess(responseCartItems));
                });
                resolve();
            }, 500);
        })
    );
}

export function checkOutItems(shipping_id, is_collection) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                shopApi.getCheckoutItems(shipping_id, is_collection).then(async (responseCheckoutItems) => {
                    dispatch(checkOutItemSuccess(responseCheckoutItems));
                });
                resolve();
            }, 500);
        })
    );
}
