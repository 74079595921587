// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';

// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
//import brands from '../../data/shopBrands';
import { Config } from '../../config';
import shopApi from '../../api/shop';

import { url } from '../../services/utils';

// const slickSettingss = {
//     dots: false,
//     arrows: false,
//     infinite: true,
//     speed: 400,
//     slidesToShow: 6,
//     slidesToScroll: 6,
//     responsive: [
//         {
//             breakpoint: 1199,
//             settings: {
//                 slidesToShow: 5,
//                 slidesToScroll: 5,
//             },
//         },
//         {
//             breakpoint: 991,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 4,
//             },
//         },
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 3,
//             },
//         },
//         {
//             breakpoint: 575,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 2,
//             },
//         },
//     ],
// };

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockBrands extends Component {
//export default function BlockBrands() {

    constructor(props) {
        super(props);

        this.state = {
            brands: []
        };
    }

    async componentDidMount() {
        await this.getBrands();
    }

    getBrands(){
        shopApi.getBrands().then((response) => {
            if(response.status){                
                this.setState({ brands: response.data });
            }
        });        
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        
        const { title, layout, groups, onGroupClick } = this.props;

        const brandsList = this.state.brands.map((brand, index) => (
            <div key={index} className="block-brands__item">
                <Link hrefLang={"en"}  to={{ pathname : url.brand(brand) }}>
                    <LazyLoad height={200} height={200} offset={[-200, 0]} placeholder={<PlaceholderComponent />} debounce={500} >
                        <img alt={brand.name} title={brand.name} src={brand.image} />
                    </LazyLoad>
                </Link>
            </div>
        ));
        
        return (
            <>
            {this.state.brands.length > 0 && 
                <div className={`block block-brands block-brands--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            groups={groups}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                            onGroupClick={onGroupClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {brandsList}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

BlockBrands.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
};

BlockBrands.defaultProps = {
    layout: 'list-sm'    
};
