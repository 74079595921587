/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from 'query-string';
import { getCategories, getCategoryBySlug } from '../fake-server/endpoints/categories';
import ReactGA from 'react-ga';

import {
    getDiscountedProducts,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductBySlug,
    getProductsList,
    getRelatedProducts,
    getSuggestions, getTopRatedProducts,
} from '../fake-server/endpoints/products';

import { Config } from '../config';

const shopApi = {

    g4EventPush : (event_name, product, is_array = false, custom_param = '') => {

        let u_id = '';
        if(localStorage.getItem(Config.web_user) !== null){
            var u_data = JSON.parse(Config.getData(localStorage.getItem(Config.web_user)));
            u_id = u_data.id;
        }
        var product_data = product;
        let discount = product_data.discount !== undefined ? product_data.discount : 0;
        product_data.discount_price = 0;
        if(discount > 0){
            let pp = parseFloat(product_data.selling_price) * parseInt(discount) / 100;
            let fpp = parseFloat(product_data.selling_price) - parseFloat(pp);
            product_data.compareAtPrice = fpp;
            product_data.price = fpp;
            product_data.discount_price = pp;
        }

        let object = {
            item_id: product_data.final_item_code,
            item_name: product_data.full_name,
            item_category: product_data.category_name,
            item_brand: product_data.brand_name,
            item_variant: product_data.color_name,
            price: product_data.selling_price,
            item_category3: product_data.discount_price,
            item_category2: product_data.size_name,
            currency: "ZAR",
            search_term: '',
            item_category4: u_id,
            item_category5: product_data.gift_card_id > 0 ? 'GiftCard' : 'Product',
        };

        if(is_array){
            let object = [];
            product.map((productObj, productObjKey) => {
                let product_data = productObj;

                if(product_data.discount_price !== undefined){
                    let discount = product_data.discount !== undefined ? product_data.discount : 0;
                    product_data.discount_price = 0;
                    if(discount > 0){
                        let pp = parseFloat(product_data.selling_price) * parseInt(discount) / 100;
                        let fpp = parseFloat(product_data.selling_price) - parseFloat(pp);
                        product_data.compareAtPrice = fpp;
                        product_data.price = fpp;
                        product_data.discount_price = pp;
                    }
                }

                let productArr = {
                    item_id: product_data.final_item_code,
                    item_name: product_data.full_name,
                    item_category: product_data.category_name,
                    item_brand: product_data.brand_name,
                    item_variant: product_data.color_name,
                    price: product_data.selling_price,
                    item_category3: product_data.discount_price,
                    item_category2: product_data.size_name,
                    currency: "ZAR",
                    search_term: '',
                    item_category4: u_id,
                    item_category5: product_data.gift_card_id > 0 ? 'GiftCard' : 'Product',
                };
                object.push(productArr);
                if(productObjKey === product.length -1){
                    let datalayerJSON = {
                        'event': event_name,
                        'items': object
                    }
                    if(custom_param !== ''){
                        datalayerJSON.status = custom_param;
                    }
                    dataLayer.push(datalayerJSON);
                }
            })
        } else {
            dataLayer.push({
                'event': event_name,
                'items': [object]
            });
        }

    },

    g4CheckoutEventPush : (event_name, product, totalsObj) => {

        let u_id = '';
        if(localStorage.getItem(Config.web_user) !== null){
            var u_data = JSON.parse(Config.getData(localStorage.getItem(Config.web_user)));
            u_id = u_data.id;
        }

        let object = [];
        product.map((productObj, productObjKey) => {
            let product_data = productObj;

            if(product_data.discount_price !== undefined){
                let discount = product_data.discount !== undefined ? product_data.discount : 0;
                product_data.discount_price = 0;
                if(discount > 0){
                    let pp = parseFloat(product_data.selling_price) * parseInt(discount) / 100;
                    let fpp = parseFloat(product_data.selling_price) - parseFloat(pp);
                    product_data.compareAtPrice = fpp;
                    product_data.price = fpp;
                    product_data.discount_price = pp;
                }
            }

            let productArr = {
                item_id: product_data.final_item_code,
                item_name: product_data.full_name,
                item_category: product_data.category_name,
                item_brand: product_data.brand_name,
                item_variant: product_data.color_name,
                price: product_data.selling_price,
                item_category3: product_data.discount_price,
                item_category2: product_data.size_name,
                currency: "ZAR",
                search_term: '',
                item_category4: u_id,
                item_category5: product_data.gift_card_id > 0 ? 'GiftCard' : 'Product',
            };
            object.push(productArr);
            if(productObjKey === product.length -1){
                let datalayerJSON = {
                    'event': event_name,
                    'user_id': u_id,
                    'coupon_code': '',
                    'discount_amount': totalsObj.discount_amount,
                    'items': object,
                    'total_amount_ex_vat': totalsObj.total_amount_exc_vat,
                    'total_vat_amount': totalsObj.total_vat_amt,
                    'total_amount': totalsObj.total_amount,
                    'pay_by_gift_card': totalsObj.pay_by_gift_card,
                    'delivery_charge': totalsObj.shipping_charge,
                    'sub_amount': totalsObj.sub_amount,
                    'payable_amount': totalsObj.amount_payable,
                    'purchase_gift_card': totalsObj.purchase_gift_card
                }
                dataLayer.push(datalayerJSON);
            }
        })

    },

    g4PurchaseEventPush : (event_name, product, totalsObj, order_status, payment_method) => {

        let u_id = '';
        if(localStorage.getItem(Config.web_user) !== null){
            var u_data = JSON.parse(Config.getData(localStorage.getItem(Config.web_user)));
            u_id = u_data.id;
        }

        let object = [];
        product.map((productObj, productObjKey) => {
            let product_data = productObj;

            if(product_data.discount_price !== undefined){
                let discount = product_data.discount !== undefined ? product_data.discount : 0;
                product_data.discount_price = 0;
                if(discount > 0){
                    let pp = parseFloat(product_data.selling_price) * parseInt(discount) / 100;
                    let fpp = parseFloat(product_data.selling_price) - parseFloat(pp);
                    product_data.compareAtPrice = fpp;
                    product_data.price = fpp;
                    product_data.discount_price = pp;
                }
            }

            let productArr = {
                item_id: product_data.final_item_code,
                item_name: product_data.full_name,
                item_category: product_data.category_name,
                item_brand: product_data.brand_name,
                item_variant: product_data.color_name,
                price: product_data.selling_price,
                item_category3: product_data.discount_price,
                item_category2: product_data.size_name,
                currency: "ZAR",
                search_term: '',
                item_category4: u_id,
                item_category5: product_data.gift_card_id > 0 ? 'GiftCard' : 'Product',
            };
            object.push(productArr);
            if(productObjKey === product.length -1){
                let datalayerJSON = {
                    'event': event_name,
                    'user_id': u_id,
                    'coupon_code': '',
                    'discount_amount': totalsObj.discount_amount,
                    'items': object,
                    'total_amount_ex_vat': totalsObj.total_amount_exc_vat,
                    'total_vat_amount': totalsObj.total_vat_amt,
                    'total_amount': totalsObj.total_amount,
                    'pay_by_gift_card': totalsObj.pay_by_gift_card,
                    'delivery_charge': totalsObj.shipping_charge,
                    'sub_amount': totalsObj.sub_amount,
                    'payable_amount': totalsObj.amount_payable,
                    'purchase_gift_card': totalsObj.purchase_gift_card,
                    'payment_method': payment_method,
                    'order_status' : order_status,
                }
                dataLayer.push(datalayerJSON);
            }
        })

    },

    g4SingleEventPush : (event_name, value) => {
        dataLayer.push({
            'event': event_name,
            'data': {
                'url' : value
            }
        });
    },

    g4NewsLetterEventPush : (event_name, value) => {
        dataLayer.push({
            'event': event_name,
            'data': {
                'email' : value
            }
        });
    },

    g4SocialEventPush : (event_name, domain, url) => {
        dataLayer.push({
            'event': event_name,
            'domain': domain,
            'url': url
        });
    },

    /**
     * Returns auth of user.
     */
    getAuth: () => {
        return (localStorage.getItem(Config.web_user) !== null ? Config.getData(localStorage.getItem(Config.web_user)) : null);
    },

     /**
     * Returns payment methods
     * @return {Promise<object>}
     */
      getPaymentMethods: (enabled) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/get-payment-methods", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },

    /**
     * Trigger gTag Event For Purchase Item
     * @param {string} order_number
     * @return {Promise<object>}
     */
     triggerPurchaseGTag: (order_number) => {
        window.gtag("event", "conversion", {
            'send_to': 'AW-575316799/jwXwCKjdh-EBEL_GqpIC',
            'value': 1.0,
            'transaction_id': order_number,
        });
    },

    /**
     * Trigger gTag Event For Contact US
     * @return {Promise<object>}
     */
     triggerContactUsGTag: () => {
        window.gtag("event", "conversion", {
            'send_to': "AW-575316799/So8SCMaXjIcDEL_GqpIC",
            'value': 1.0
        })
    },

    /**
     * Returns all gift crds
     * @return {Promise<object>}
     */
     getGiftCardList: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/gift-card-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },

    /**
     * Returns notify me
     * @return {Promise<object>}
     */
     notifyMe: (id) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/notify-me", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ product_id: id })
        })
        .then(response => response.json())
    },

    /**
     * Returns customer gift crds
     * @return {Promise<object>}
     */
     getGiftCards: (records_per_page, type, page) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/my-gift-cards", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ records_per_page: records_per_page, type: type, page: page })
        })
        .then(response => response.json())
    },

    /**
     * Returns customer track order history
     * @return {Promise<object>}
     */
     getTrackOrder: (order_number) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/track-order", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ order_number })
        })
        .then(response => response.json())
    },

    /**
     * Returns customer orders
     * @return {Promise<object>}
     */
     getCustomerOrders: (postJSONData) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/order-list", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
    },

    /**
     * Returns customer order details
     * @return {Promise<object>}
     */
     getCustomerOrderDetails: (postJSONData) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/track-order", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
    },

    /**
     * Returns customer orders
     * @return {Promise<object>}
     */
    getRecentlyViwed: (postJSONData) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/get-recent-view", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
    },

    /**
     * Returns download customer invoice
     * @return {Promise<object>}
     */
     getCustomerInvoice: (id) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        return fetch(Config.URL+"/generate-invoice", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ order_id: id })
        })
        .then(response => response)
    },

    /**
     * Returns left menu categories
     * @return {Promise<object>}
     */
     getLeftMenu: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-top-categories", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },

    /**
     * Returns newsletter subscription
     * @return {Promise<object>}
     */
     subscribeToNewsLetter: (email) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/newsletter-subscribe", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ email: email })
        })
        .then(response => response.json())
    },

    /**
     * Returns search product items
     * @return {Promise<object>}
     */
     searchGlobalProducts: (search_text, options) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/search-products", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ search_text: search_text, records_per_page: options.limit})
        })
        .then(response => response.json())
    },

    /**
     * Returns wishlist items
     * @return {Promise<object>}
     */
     getWishListItems: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/view-wishlist", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ records_per_page: 10 })
        })
        .then(response => response.json())
    },

    /**
     * Returns add item to wishlist
     * @param {string} product_id
     * @return {Promise<object>}
     */
     addWishListItem: (product_id) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/add-to-wishlist", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ product_id: product_id })
        })
        .then(response => response.json())
    },

    /**
     * Returns apply gift card response
     * @param {string} card_no
     * @return {Promise<object>}
     */
     getStores: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/store-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },
    /**
     * Returns user address by type response
     * @return {Promise<object>}
     */
     getAddresses: (type) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/address-list", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ type: type })
        })
        .then(response => response.json())
    },
    /**
     * Returns place order response
     * @param {Object} placeOrderJSON
     * @return {Promise<object>}
     */
     placeOrder: (placeOrderJSON) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/place-order", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(placeOrderJSON)
        })
        .then(response => response.json())
    },
    /**
     * Returns array of categories.
     * @return {Promise<Array<object>>}
     */
    getCategories: () => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getCategories(options);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-categories", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },
    /**
     * Returns order status response
     * @param {string} order_number
     * @return {Promise<object>}
     */
     getOrderStatus: (order_number) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/check-order-status", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ order_number: order_number })
        })
        .then(response => response.json())
    },
    /**
     * Returns apply gift card response
     * @param {string} card_no
     * @return {Promise<object>}
     */
     applyGiftCard: (card_no) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/apply-gift-card", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ card_no: card_no })
        })
        .then(response => response.json())
    },
    /**
     * Returns verify gift card OTP response
     * @param {string} OTP
     * @param {string} OTPVerifyAuth
     * @return {Promise<object>}
     */
     verifyGiftCardOTP: (OTP, OTPVerifyAuth) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', OTPVerifyAuth);

        return fetch(Config.URL+"/gift-card-verify-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ otp: OTP })
        })
        .then(response => response.json())
    },
    /**
     * Returns apply gift amount response
     * @param {string} gift_card_no
     * @param {int} amount
     * @return {Promise<object>}
     */
     applyGiftCardAmount: (gift_card_no, amount) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/apply-card-amount", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ gift_card_no: gift_card_no, amount: amount })
        })
        .then(response => response.json())
    },
    /**
     * Returns remove gift amount response
     * @param {string} gift_card_no
     * @param {int} amount
     * @return {Promise<object>}
     */
     removeGiftCardAmount: (cart_pay_by_id) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/remove-gift-card", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ cart_pay_by_id: cart_pay_by_id })
        })
        .then(response => response.json())
    },
    /**
     * Returns generate gift response
     * @return {Promise<object>}
     */
     generateGiftCard: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/generate-gift-card", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },
    /**
     * Returns generate gift response
     * @param {string} gift_card_id
     * @param {int} amount
     * @return {Promise<object>}
     */
     addGiftCard: (gift_card_id, amount) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/add-gift-card", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ gift_card_id: gift_card_id, amount: amount })
        })
        .then(response => response.json())
    },
    /**
     * Returns apply coupon code response
     * @param {string} coupon_code
     * @return {Promise<object>}
     */
     applyCollaboratorCode: (coupon_code) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/apply-coupon", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ coupon_code: coupon_code })
        })
        .then(response => response.json())
    },
    /**
     * Returns remove coupon code response
     * @param {string} coupon_code
     * @return {Promise<object>}
     */
     removeCollaboratorCode: (coupon_code) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/remove-coupon", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ coupon_code: coupon_code })
        })
        .then(response => response.json())
    },
    /**
     * Returns cart items
     * @return {Promise<object>}
     */
     getCartItems: () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/view-cart", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
    },

    /**
     * Returns checkout items
     * @param {int} shipping_id
     * @return {Promise<object>}
     */
     getCheckoutItems: (shipping_id, is_collection) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/checkout", {
            method: "POST",
            headers: headers,
            body: (shipping_id ? JSON.stringify({ shipping_id : shipping_id, is_collection: is_collection }) : JSON.stringify({ is_collection: is_collection }))
        })
        .then(response => response.json())
    },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getCategoryBySlug: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getCategoryBySlug(slug, options);
        let category_slug = ((slug).replace(/-/g, " ")).toUpperCase();
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-category-by-name", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ category_name : category_slug })
        })
        .then(response => response.json());
    },
    /**
     * Returns brand by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getBrandBySlug: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        let brand_slug = ((slug).replace(/-/g, " ")).toUpperCase();
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-brand-by-name", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ brand_name : brand_slug })
        })
        .then(response => response.json());
    },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */
    getProductBySlug: (slug) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/screwdriver-a2017.json
         *
         * where:
         * - screwdriver-a2017 = slug
         */
        // return fetch(`https://example.com/api/products/${slug}.json`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductBySlug(slug);
    },
    /**
     * Returns object of product.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
     getProductByFinalItemCode: (final_item_code) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getRelatedProducts(slug, options);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/get-product-details", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ final_item_code : final_item_code })
        })
        .then(response => response.json())
    },
    /**
     * Returns array of related products.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
    getRelatedProducts: (category_id, final_item_code, records_per_page) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getRelatedProducts(slug, options);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/category-related-items", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ category_id: category_id, final_item_code : final_item_code, records_per_page: records_per_page })
        })
        .then(response => response.json())
    },

    /**
     * Return brands list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     *
     * @return {Promise<object>}
     */
     getAllBrands: async () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-all-brands", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json());
    },

    /**
     * Return brands list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     *
     * @return {Promise<object>}
     */
     getBrands: async () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/brand-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json());
    },
    /**
     * Return video list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     *
     * @return {Promise<object>}
     */
     getVideos: async () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/home-videos", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json());
    },
    /**
     * Return promotions list.
     * @return {Promise<object>}
     */
     getAllPromotions: async () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        return fetch(Config.URL+"/promotion-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json());
    },
    /**
     * Return categories list.
     * @return {Promise<object>}
     */
     getAllCategories: async () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-all-product-categories", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json());
    },

    /**
     * Return meta tags list.
     *
     * @param {string?} page_name
     *
     * @return {Promise<object>}
     */
     getMetaTagsBySlug: async (page_name) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(Config.URL+"/get-page-data", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ page_name: page_name })
        })
        .then(response => response.json());
    },
    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: async (options = {}, filters = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        // const params = { ...options };
        //
        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        //
        // return fetch(`https://example.com/api/products.json?${qs.stringify(params)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.

        //return getProductsList(options, filters);
        // console.log("options: ", options);
        // console.log("filters: ", filters);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        if(localStorage.getItem(Config.x_token) !== null){
            headers.append('device_token', Config.device_token);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        }

        let postJSON = {
            "search_text": (options.search !== undefined && options.search != '' ? options.search : ""),
            "discount_type": (filters.discount !== undefined && filters.discount !== '' ? "true" : "false"),
            "discount": (filters.discount !== undefined && filters.discount !== '' ? [filters.discount] : 0),
            "brands": (filters.brands !== undefined && filters.brands !== '' ? [filters.brands] : []),
            "category": (filters.category_id !== undefined ? [filters.category_id] : []),
            "color": (filters.color !== undefined && filters.color !== '' ? [filters.color] : []),
            "style": (filters.style !== undefined && filters.style !== '' ? [filters.style] : []),
            "size": (filters.size !== undefined && filters.size !== '' ? [filters.size] : []),
            "page": (options.page !== undefined && options.page !== '' ? options.page : 1),
            "sorting_by": (filters.sorting_by !== undefined && filters.sorting_by !== '' ? filters.sorting_by : []),
            "min_price": (filters.price !== undefined && filters.price !== '' ? (filters.price).split("-")[0] : ""),
            "max_price": (filters.price !== undefined && filters.price !== '' ? (filters.price).split("-")[1] : ""),
            "records_per_page": (options.limit !== undefined && options.limit !== '' ? options.limit : 9)
        };

        return fetch(Config.URL+"/product-listing", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSON)
        })
        .then(response => response.json());
        //return getProductsList(options, filters);
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getFeaturedProducts(options);
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getLatestProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(options);
    },
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getTopRatedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRatedProducts(options);
    },
    /**
     * Returns an array of discounted products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getDiscountedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getDiscountedProducts(options);
    },
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getPopularProducts(options);
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: (query, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getSuggestions(query, options);
    },
};

export default shopApi;
