// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import "react-datepicker/dist/react-datepicker.css";
import ReactPasswordStrength from 'react-password-strength';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPagePassword extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: {},
            pageHeading: 'Change Password',
            password_o : 'password',
            password_n : 'password',
            password_c : 'password',
            nPasswordScore: 0,
            cPasswordScore: 0,
            basicFormFields: {
                old_password: '',
                n_password: '',
                c_password: ''
            },
            basicFormFieldsError: {
                isEmptyOldPassword: false,
                isEmptyNewPassword: false,
                isEmptyConfirmPassword: false,
                isNotMatched: false,
                isNotValidNewPassword: false,
                isNotValidConfirmPassword: false,
            },
            password1Length: false,
            contains1Numbers: false,
            isUpper1Case: false,
            contains1Symbols: false,
            password2Length: false,
            contains2Numbers: false,
            isUpper2Case: false,
            contains2Symbols: false
        }
    }

    // check to see if there is any number
    checkForNumbers(string, input){
        var matches = string.match(/\d+/g);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for upper case
    checkForUpperCase(string, input){
        var matches = string.match(/[A-Z]/);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for symbols
    checkForSymbols(string, input){
        var symbols = new RegExp(/[^A-Z a-z0-9]/);
        this.setState({
            [input]: symbols.test(string) ? true : false
        });
    }

    // handle password
    handleChangeSetPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        this.checkForNumbers(targetValue, 'contains1Numbers');
        this.checkForUpperCase(targetValue, 'isUpper1Case');
        this.checkForSymbols(targetValue, 'contains1Symbols');
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['n_password']: targetValue }
        }));
        this.setState({
            password1Length: targetValue.length < 8 ? false : true
        });
    }

    // handle password
    handleChangeSetCPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        this.checkForNumbers(targetValue, 'contains2Numbers');
        this.checkForUpperCase(targetValue, 'isUpper2Case');
        this.checkForSymbols(targetValue, 'contains2Symbols');
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['c_password']: targetValue }
        }));
        this.setState({
            password2Length: targetValue.length < 8 ? false : true
        });
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    if(response.data.password == ''){
                        this.setState({ pageHeading: "Set Password" });
                    }
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err getProfile: ", err);
            toast.error(err);
        });
    }

    isValidPassword(password){
        const passwrordRegExp = new RegExp("(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$");
        return passwrordRegExp.test(password);
    };

    /**
     * [validateUpdateProfileForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateUpdateProfileForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyOldPassword: false,
            isEmptyNewPassword: false,
            isEmptyConfirmPassword: false,
            isNotMatched: false,
            isNotValidNewPassword: false,
            isNotValidConfirmPassword: false,
        };

        var old_password = this.state.basicFormFields.old_password;
        var new_password = this.state.basicFormFields.n_password;
        var confirm_password = this.state.basicFormFields.c_password;
        var nPasswordScore = this.state.nPasswordScore;
        var cPasswordScore = this.state.cPasswordScore;

        if(this.state.pageHeading === "Change Password"){
            if (old_password === '' || old_password === null) {
                basicFormFieldsError.isEmptyOldPassword = true;
                isReturn = false;
            }
        }
        if (new_password === '' || new_password === null) {
            basicFormFieldsError.isEmptyNewPassword = true;
            isReturn = false;
        }
        if (confirm_password === '' || confirm_password === null) {
            basicFormFieldsError.isEmptyConfirmPassword = true;
            isReturn = false;
        }
        if(new_password !== ''){
            if(!this.state.contains1Numbers || !this.state.isUpper1Case || !this.state.contains1Symbols || !this.state.password1Length){
                basicFormFieldsError.isNotValidNewPassword = true;
                isReturn = false;
            }
        }
        if(confirm_password !== ''){
            if(!this.state.contains2Numbers || !this.state.isUpper2Case || !this.state.contains2Symbols || !this.state.password2Length){
                basicFormFieldsError.isNotValidConfirmPassword = true;
                isReturn = false;
            }
        }
        if (new_password !== '' && confirm_password !== '' && new_password !== confirm_password){
            basicFormFieldsError.isNotMatched = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    updateProfile(){
        let isValidated = this.validateUpdateProfileForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};
        let passwordRoute = Config.URL+"/update-password";
        if(this.state.pageHeading === "Set Password"){
            passwordRoute = Config.URL+"/set-password";
            delete updateProfileJSON.old_password;
        }

        fetch(passwordRoute, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    localStorage.setItem('password', Config.setData(updateProfileJSON.n_password));
                    this.props.history.push("/account/dashboard");
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err updateProfile: ", err);
            toast.error(err);
        });
    }

    /**
     * [handleSetValueCurrentPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueCurrentPassword = e => {
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['old_password']: e.password }
        }))
    }
    /**
     * [handleSetValueNewPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueNewPassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ nPasswordScore: 3 });
        } else {
            this.setState({ nPasswordScore: 4 });
        }
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['n_password']: e.password }
        }))
    }
    /**
     * [handleSetValueConfirmPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueConfirmPassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ cPasswordScore: 3 });
        } else {
            this.setState({ cPasswordScore: 4 });
        }
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['c_password']: e.password }
        }))
    }

    changePasswordOType(password_o){
        this.setState({ password_o });
    }

    changePasswordNType(password_n){
        this.setState({ password_n });
    }

    changePasswordCType(password_c){
        this.setState({ password_c });
    }


    render() {
        let {
            password1Length,
            contains1Numbers,
            isUpper1Case,
            contains1Symbols,
            password2Length,
            contains2Numbers,
            isUpper2Case,
            contains2Symbols
        } = this.state

        return (
            <div className="card">
                <Helmet>
                    <title>{`${this.state.pageHeading} | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>{this.state.pageHeading}</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-6 col-xl-6">
                            {this.state.pageHeading === "Change Password" &&
                            <div className="form-group">
                                <label htmlFor="password-current">Current Password</label>
                                <ReactPasswordStrength
                                className=""
                                minLength={8}
                                minScore={2}
                                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                changeCallback={this.handleSetValueCurrentPassword}
                                inputProps={{ type: this.state.password_o, name: "old_password", autoComplete: "off", className: "form-control", placeholder: "Please enter your current password" }}
                                />
                                <div className="pass-wrapper pass-wrapper-signup-password">
                                    {this.state.password_o == 'password' ?
                                        <i onClick={() => { this.changePasswordOType('text') }} className="fa fa-eye"></i>
                                    :
                                        <i onClick={() => { this.changePasswordOType('password') }} className="fa fa-eye-slash"></i>
                                    }
                                </div>
                                {this.state.basicFormFieldsError.isEmptyOldPassword ?
                                <span className="errorInput">Please enter current password</span>
                                : null
                                }
                            </div>
                            }
                            <div className="form-group">
                                <label htmlFor="password-new">New Password</label>
                                {/* <ReactPasswordStrength
                                className=""
                                minLength={8}
                                minScore={2}
                                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                changeCallback={this.handleSetValueNewPassword}
                                inputProps={{ type: this.state.password_n, name: "n_password", autoComplete: "off", className: "form-control"}}
                                /> */}
                                <input
                                    id="new-password"
                                    type={this.state.password_n}
                                    name="n_password"
                                    onChange={this.handleChangeSetPassword}
                                    className="form-control"
                                    placeholder="Please enter new password"
                                />
                                <div className="pass-wrapper pass-wrapper-signup-password">
                                    {this.state.password_n == 'password' ?
                                        <i onClick={() => { this.changePasswordNType('text') }} className="fa fa-eye"></i>
                                    :
                                        <i onClick={() => { this.changePasswordNType('password') }} className="fa fa-eye-slash"></i>
                                    }
                                </div>
                                <div>
                                    <div style={{fontSize: 14}} className={password1Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                    <div style={{fontSize: 14}} className={contains1Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                    <div style={{fontSize: 14}} className={isUpper1Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                    <div style={{fontSize: 14}} className={contains1Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                </div>
                                {/* <p style={{fontSize: 10}}>Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required</p> */}
                                {this.state.basicFormFieldsError.isEmptyNewPassword ?
                                <span className="errorInput">Please enter new password</span>
                                : null
                                }
                                {this.state.basicFormFieldsError.isNotValidNewPassword ?
                                <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="password-confirm">Confirm Password</label>
                                {/* <ReactPasswordStrength
                                className=""
                                minLength={8}
                                minScore={2}
                                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                changeCallback={this.handleSetValueConfirmPassword}
                                inputProps={{ type: this.state.password_c, name: "c_password", autoComplete: "off", className: "form-control"}}
                                /> */}
                                <input
                                    id="c-password"
                                    type={this.state.password_c}
                                    name="c_password"
                                    onChange={this.handleChangeSetCPassword}
                                    className="form-control"
                                    placeholder="Please enter confirm password"
                                />
                                <div className="pass-wrapper pass-wrapper-signup-password">
                                    {this.state.password_c == 'password' ?
                                        <i onClick={() => { this.changePasswordCType('text') }} className="fa fa-eye"></i>
                                    :
                                        <i onClick={() => { this.changePasswordCType('password') }} className="fa fa-eye-slash"></i>
                                    }
                                </div>
                                <div>
                                    <div style={{fontSize: 14}} className={password2Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                    <div style={{fontSize: 14}} className={contains2Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                    <div style={{fontSize: 14}} className={isUpper2Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                    <div style={{fontSize: 14}} className={contains2Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                </div>
                                {this.state.basicFormFieldsError.isEmptyConfirmPassword ?
                                <span className="errorInput">Please enter confirm password</span>
                                : null
                                }
                                {this.state.basicFormFieldsError.isNotValidConfirmPassword ?
                                <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                : null
                                }
                                {this.state.basicFormFieldsError.isNotMatched ?
                                <span className="errorInput">Password and confirm password must be same</span>
                                : null
                                }
                            </div>

                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPagePassword;