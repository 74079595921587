// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

import ReactPasswordStrength from 'react-password-strength';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';

const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Reset Password', url: '' },
];


class AccountPageResetPassword extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            formFields: {
                n_password: '',
                c_password: ''
            },
            validationFormErrors: {
                isEmptyNPassword: false,
                isEmptyCPassword: false,
                isNotValidLenghtPassword: false,
                isNotValidLenghtCPassword: false,
                isNotValidPassword: false,
                isNotValidCPassword: false,
                isNotSameBothPassword: false,
            },
            verificationAuth: props.match.params.token,
            passwordScore: 0,
            cPasswordScore: 0,
            password1Length: false,
            contains1Numbers: false,
            isUpper1Case: false,
            contains1Symbols: false,
            password2Length: false,
            contains2Numbers: false,
            isUpper2Case: false,
            contains2Symbols: false
        }

    }

    // check to see if there is any number
    checkForNumbers(string, input){
        var matches = string.match(/\d+/g);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for upper case
    checkForUpperCase(string, input){
        var matches = string.match(/[A-Z]/);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for symbols
    checkForSymbols(string, input){
        var symbols = new RegExp(/[^A-Z a-z0-9]/);
        this.setState({
            [input]: symbols.test(string) ? true : false
        });
    }

    // handle password
    handleChangeSetPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        this.checkForNumbers(targetValue, 'contains1Numbers');
        this.checkForUpperCase(targetValue, 'isUpper1Case');
        this.checkForSymbols(targetValue, 'contains1Symbols');
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, ['n_password']: targetValue }
        }));
        this.setState({
            password1Length: targetValue.length < 8 ? false : true
        });
    }

    // handle password
    handleChangeSetCPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        this.checkForNumbers(targetValue, 'contains2Numbers');
        this.checkForUpperCase(targetValue, 'isUpper2Case');
        this.checkForSymbols(targetValue, 'contains2Symbols');
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, ['c_password']: targetValue }
        }));
        this.setState({
            password2Length: targetValue.length < 8 ? false : true
        });
    }

    componentDidMount() {
        if (this.state.verificationAuth === undefined || this.state.verificationAuth == '') {
            toast.error("Invalid reset password link found!");
            this.props.history.push("/");
        }
    }

    isValidPassword(password){
        const passwrordRegExp = new RegExp("(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$");
        return passwrordRegExp.test(password);
    };

    /**
     * [validateResetPasswordForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateResetPasswordForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyNPassword: false,
            isEmptyCPassword: false,
            isNotValidLenghtPassword: false,
            isNotValidLenghtCPassword: false,
            isNotSameBothPassword: false,
            isNotValidPassword: false,
            isNotValidCPassword: false
        };

        var n_password = this.state.formFields.n_password;
        var c_password = this.state.formFields.c_password;
        var passwordScore = this.state.passwordScore;
        var cPasswordScore = this.state.cPasswordScore;
        if (n_password === '' || n_password === null) {
            validationFormErrors.isEmptyNPassword = true;
            isReturn = false;
        }
        if (c_password === '' || c_password === null) {
            validationFormErrors.isEmptyCPassword = true;
            isReturn = false;
        }
        if(n_password !== ''){
            if(!this.state.contains1Numbers || !this.state.isUpper1Case || !this.state.contains1Symbols || !this.state.password1Length){
                validationFormErrors.isNotValidPassword = true;
                isReturn = false;
            }
        }
        if (n_password !== '' && n_password.length < 8) {
            validationFormErrors.isNotValidPassword = true;
            isReturn = false;
        }
        if(c_password !== ''){
            if(!this.state.contains2Numbers || !this.state.isUpper2Case || !this.state.contains2Symbols || !this.state.password2Length){
                validationFormErrors.isNotValidCPassword = true;
                isReturn = false;
            }
        }
        if (c_password !== '' && c_password.length < 8) {
            validationFormErrors.isNotValidCPassword = true;
            isReturn = false;
        }
        if (n_password !== '' && c_password !== '' && n_password !== c_password) {
            validationFormErrors.isNotSameBothPassword = true;
            isReturn = false;
        }

        this.setState({ validationFormErrors });
        return isReturn;
    }

    updatePassword(){
        let isValidated = this.validateResetPasswordForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let postCredentials = {...this.state.formFields};
        postCredentials._token = this.state.verificationAuth;

        fetch(Config.URL+"/reset-password-update", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.props.history.push("/account/login");
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
        });
    }

    /**
     * [handleSubmitForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    handleSubmitForm(event) {
        event.preventDefault();
    }

    /**
     * [handleSetValuePassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValuePassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ passwordScore: 3 });
        } else {
            this.setState({ passwordScore: 4 });
        }
        //if(e.score === 4 && e.isValid){
            this.setState(prevState => ({
                formFields: { ...prevState.formFields, ['n_password']: e.password }
            }));
        //}
    }

    /**
     * [handleSetValueCPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValueCPassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ cPasswordScore: 3 });
        } else {
            this.setState({ cPasswordScore: 4 });
        }
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, ['c_password']: e.password }
        }));
    }

    render() {

        let {
            password1Length,
            contains1Numbers,
            isUpper1Case,
            contains1Symbols,
            password2Length,
            contains2Numbers,
            isUpper2Case,
            contains2Symbols
        } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Reset Password | ${theme.name}`}</title>
                </Helmet>

                {/* <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop> */}

                <PageHeader header="Reset Password" breadcrumb={breadcrumb} />

                <div className="block">
                    <div className="container reset-password">
                        <div className="row">

                            <div className="col-md-6 d-flex mt-4 mt-md-0">
                                <div className="card flex-grow-1 mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Reset Your Password</h3>
                                        <form onSubmit={this.handleSubmitForm}>
                                            <div className="form-group">
                                                <label htmlFor="n_password">New Password</label>

                                                <input
                                                    id="new-password"
                                                    type="password"
                                                    name="n_password"
                                                    onChange={this.handleChangeSetPassword}
                                                    className="form-control"
                                                    placeholder="Please enter new password"
                                                />
                                                <div>
                                                    <div style={{fontSize: 14}} className={password1Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                                    <div style={{fontSize: 14}} className={contains1Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                                    <div style={{fontSize: 14}} className={isUpper1Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                                    <div style={{fontSize: 14}} className={contains1Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                                </div>
                                                {this.state.validationFormErrors.isEmptyNPassword ?
                                                    <span className="errorInput">Please enter new password</span>
                                                : null
                                                }
                                                {this.state.validationFormErrors.isNotValidPassword ?
                                                <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                                : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="c_password">Confirm Password</label>
                                                <input
                                                    id="c-password"
                                                    type="password"
                                                    name="c_password"
                                                    onChange={this.handleChangeSetCPassword}
                                                    className="form-control"
                                                    placeholder="Please enter confirm password"
                                                />

                                                <div>
                                                    <div style={{fontSize: 14}} className={password2Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                                    <div style={{fontSize: 14}} className={contains2Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                                    <div style={{fontSize: 14}} className={isUpper2Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                                    <div style={{fontSize: 14}} className={contains2Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                                </div>
                                                {this.state.validationFormErrors.isEmptyCPassword ?
                                                    <span className="errorInput">Please enter confirm password</span>
                                                : null
                                                }
                                                {this.state.validationFormErrors.isNotSameBothPassword ?
                                                <span className="errorInput">Password and confirm password must be same <br></br></span>
                                                : null
                                                }
                                                {this.state.validationFormErrors.isNotValidCPassword ?
                                                <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                                : null
                                                }
                                                {this.state.isLoading ?
                                                <span className="errorInput">Please wait while we are processing your request...<br></br></span>
                                                : null
                                                }
                                            </div>
                                            <button type="submit" onClick={() => { this.updatePassword() }} className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

export default AccountPageResetPassword;