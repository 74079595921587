// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import CategoryCard from '../shared/CategoryCard';
import { sidebarOpen } from '../../store/sidebar';


function CategoriesView(props) {
    const {
        isLoading,
        categoryList,
        layout,
        grid,        
    } = props;    

    let [categoryListItems, setCategories] = useState(categoryList.data);

    const categoriesListItems = categoryListItems.map((category) => (
        <div key={category.id} className="products-list__item categories">
            <CategoryCard category={category} />
        </div>
    ));

    const rootClasses = classNames('products-view', {
        'products-view--loading': isLoading,
    });

    const filiterItems = (event) => {
        let value = event.target.value.toLowerCase();
        let results = [];
        results = categoryList.data.filter((data) => {
            return ((data.name).toLowerCase()).search(value) != -1;
        });      
        setCategories(results);
    }
    
    let content;

    if (categoriesListItems) {
        content = (            
            <div className="products-view__content">
                <span style={{float: 'right', width: '100%', textAlign: 'right'}}>
                    <input onChange={(e) => filiterItems(e)} style={{float: 'right', width: '24%', textAlign: 'left'}} className="form-control" name="search" placeholder={'Search over '+categoryList.data.length+' categories'} type="search" autoComplete="off" />
                </span>
                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">
                        {categoriesListItems}
                    </div>
                </div>

                {/* <div className="products-view__pagination">
                    <Pagination
                        current={options.page || productsList.page}
                        siblings={2}
                        total={productsList.totalRecords}
                        onPageChange={handlePageChange}
                    />
                </div> */}
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">No matching items</div>                
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
        </div>
    );
}

CategoriesView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    categoriesList: PropTypes.object,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

CategoriesView.defaultProps = {
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(CategoriesView);
