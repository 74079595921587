// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
        leftMenuItems
    } = props;

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item, type) => {
        // if (item.data) {
        //     if (item.data.type === 'language') {
        //         changeLocale(item.data.locale);
        //         closeMobileMenu();
        //     }
        //     if (item.data.type === 'currency') {
        //         const currency = currencies.find((x) => x.currency.code === item.data.code);

        //         if (currency) {
        //             changeCurrency(currency.currency);
        //             closeMobileMenu();
        //         }
        //     }
        // }
        if (type === 'brand') {
            closeMobileMenu();
        }
        if (type === 'category') {
            closeMobileMenu();
            // props.history.push("/track-order");            
        }
        if (type === 'otherLink') {
            closeMobileMenu();
            //props.history.push(item);
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Shop By Category</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">

                    <ul className={`mobile-links mobile-links--level--1`}>
                        <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title">                    
                                <button
                                    type="button"
                                    className="mobile-links__item-link"
                                    onClick={() => handleItemClick('home', 'otherLink')}
                                >
                                    <Link hrefLang={"en"}  style={{color: '#333'}} to={'/'}>
                                        Home
                                    </Link>
                                </button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title">                    
                                <button
                                    type="button"
                                    className="mobile-links__item-link"
                                    onClick={() => handleItemClick('blog', 'otherLink')}
                                >
                                    <Link hrefLang={"en"}  style={{color: '#333'}} to={'/blog'}>
                                        Blog
                                    </Link>
                                </button>
                                </div>
                            </div>
                        </li>                        
                    </ul>
                    <MobileLinks links={leftMenuItems} onItemClick={handleItemClick} />
                    
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
