// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';


// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';

import { Config } from '../../config';
import { NavItem } from 'reactstrap';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockSpecialOffers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            specialOffers: []
        };
    }

    async componentDidMount() {
        await this.getSpecialOffers();
    }

    getSpecialOffers(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        
        fetch(Config.URL+"/promotion-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getSpecialOffers response:", response);
            if(response.status){                
                this.setState({ specialOffers: response.data });
            }
        })
        .catch(err => {
            //console.log("getSpecialOffers err: ", err);
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        
        const { title, layout, groups, onGroupClick } = this.props;

        const specialOffersList = this.state.specialOffers.map((specialOffer, index) => (
            <div key={index} className="block-brands__item block-special_offers">
                <div style={{backgroundColor: specialOffer.background_color, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                    <span className="special_offer_discount">{(specialOffer.discount).toFixed(0)}% OFF</span>
                    {(specialOffer.short_desc != null && specialOffer.short_desc != 'null') &&
                        <span className="special_offer_sub_title">{(specialOffer.short_desc).toLowerCase()}</span>
                    }
                    {(specialOffer.short_desc == null || specialOffer.short_desc == '') &&
                        <span className="special_offer_sub_title">Special Offer</span>
                    }
                    <Link hrefLang={"en"}  title={specialOffer.name} to={'/category/all?filter_discount='+specialOffer.id}>
                        {specialOffer.image !== '' && 
                            <LazyLoad height={200} offset={[0, 0]} placeholder={<PlaceholderComponent />} debounce={500} >
                                <img alt={specialOffer.name} title={specialOffer.name} src={specialOffer.promotion_image} />
                            </LazyLoad>
                        }                    
                        <span className="special_offer_title" style={{backgroundColor: specialOffer.background_color, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                            {(specialOffer.name).toLowerCase()}
                        </span>
                    </Link>
                </div>
            </div>
        ));
        
        return (
            <>
            {this.state.specialOffers.length > 0 && 
                <div className={`block block-brands block-brands--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            groups={groups}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                            onGroupClick={onGroupClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {specialOffersList}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

BlockSpecialOffers.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
};

BlockSpecialOffers.defaultProps = {
    layout: 'list-sm'    
};
