// react
import React from 'react';

// third-party
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function BlogPost(props) {
    const { layout, post } = props;

    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <h1 className="post-header__title" dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                <div className="post-header__meta">
                    <div className="post-header__meta-item">
                        By {post.author}
                    </div>
                    <div className="post-header__meta-item">{moment(post.date_created).format('D MMMM, YYYY')}</div>                 
                </div>
            </div>

            <div className="post__featured">
                <Link hrefLang={"en"}  to="/">
                    <img src={post.blog_image_webp} alt={post.title} title={post.title} />
                </Link>
            </div>
            <div className={postClasses} dangerouslySetInnerHTML={{ __html: post.description }}></div>            
        </div>
    );
}

BlogPost.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
};

BlogPost.defaultProps = {
    layout: 'classic',
};
