// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';

class SitePagePrivacyPolicy extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            page: ''
        }
    }

    componentDidMount() {
        this.getCMSPage();
    }

    getCMSPage(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/get-cms-pages", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ type: 0})
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ page: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Privacy Policy', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page.meta_title}</title>
                    <meta name="title" content={this.state.page.meta_title} />
                    <meta name="keywords" content="" />
                    <meta name="description" content={this.state.page.meta_description} />
                    <meta name="twitter:title" content={this.state.page.meta_title} />
                    <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                    <meta name="twitter:description" content={this.state.page.meta_description} />
                    <meta property="og:title" content={this.state.page.meta_title} />
                    <meta property="og:description" content={this.state.page.meta_description} />
                    <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                    <meta property="og:url" content={ Config.DOMAIN + '/privacy-policy' } />
                    <link rel="canonical" href={Config.DOMAIN + '/privacy-policy'} />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Privacy Policy" breadcrumb={breadcrumb} />

                <div className="block faq">
                    <div className="container">
                        <div className="faq__section">
                            <div className="faq__section-body">
                                <div className="row">
                                    <div className="faq__section-column col-12 col-lg-12">
                                        <div className="typography" dangerouslySetInnerHTML={{ __html: this.state.page.description }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SitePagePrivacyPolicy;