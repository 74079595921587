// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import "react-datepicker/dist/react-datepicker.css";
import ReactPasswordStrength from 'react-password-strength';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPageChangeEmailAddress extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: {},
            pageHeading: 'Add Email Address',
            basicFormFields: {
                email_address: ''
            },
            basicFormFieldsError: {
                isEmptyEmail: false,
                isNotValidEmail: false,
            },
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ profile: response.data });

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    if(response.data.email !== ''){
                        this.setState({ pageHeading: "Change Email Address" });
                    }
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err getProfile: ", err);
            toast.error(err);
        });
    }

    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateUpdateProfileForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateUpdateProfileForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyEmail: false,
            isNotValidEmail: false,
        };

        var email_address = this.state.basicFormFields.email_address;
        if (email_address === '' || email_address === null) {
            basicFormFieldsError.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            basicFormFieldsError.isNotValidEmail = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    updateProfile(){
        let isValidated = this.validateUpdateProfileForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};

        fetch(Config.URL+"/update-email-address", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.setState({
                        basicFormFields: {
                            email_address : ''
                        }
                    });
                    this.props.history.push('/account/dashboard');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err updateProfile: ", err);
            toast.error(err);
        });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }


    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{`${this.state.pageHeading} | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>{this.state.pageHeading}</h5>
                    {this.state.profile.email !== '' &&
                        <span>Your current email address is: <b>{this.state.profile.email}</b></span>
                    }
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">

                        <div className="col-12 col-lg-6 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="profile-email-address">Email Address</label>
                                <input
                                    id="profile-email-address"
                                    name="email_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your email address"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.email_address}
                                />
                                {this.state.basicFormFieldsError.isEmptyEmail ?
                                <span className="errorInput">Please enter email address</span>
                                : null
                                }
                                {this.state.basicFormFieldsError.isNotValidEmail ?
                                <span className="errorInput">Please enter a valid email address</span>
                                : null
                                }
                            </div>

                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageChangeEmailAddress;