// react
import React from 'react';
import { Link } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';

// application
import {
    Fi24Hours48Svg,
    FiFreeDelivery48Svg,
    FiPaymentSecurity48Svg,
    FiTag48Svg,
} from '../../svg';

export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <div className="block-features__item">                        
                            <div className="block-features__icon">
                                <FiFreeDelivery48Svg />
                            </div>
                            <div className="block-features__content">
                                <Link hrefLang={"en"}  to="/shipping-information" style={{color: "#000"}}>
                                    <div className="block-features__title">Express Delivery</div>
                                    <div className="block-features__subtitle">Super Fast Delivery Available</div>
                                </Link>
                            </div>                        
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <Fi24Hours48Svg />
                        </div>
                        <div className="block-features__content">
                            <Link hrefLang={"en"}  to="/contact-us" style={{color: "#000"}}>   
                                <div className="block-features__title">Support 24/7</div>
                                <div className="block-features__subtitle">24/7 Customer Support Available</div>
                            </Link>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <FiPaymentSecurity48Svg />
                        </div>
                        <div className="block-features__content">
                            <Link hrefLang={"en"}  to={{ pathname: 'https://paygate.co.za' }} target="_blank" style={{color: "#000"}}>
                                <div className="block-features__title">Secure Payment</div>
                                <div className="block-features__subtitle">Pay Gate, Ozow, Pay Just Now, Pay Flex</div>
                            </Link>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <FiTag48Svg />
                        </div>
                        <div className="block-features__content">
                            <Link hrefLang={"en"}  to="/track-order" style={{color: "#000"}}>
                                <div className="block-features__title">Track Your Package</div>
                                <div className="block-features__subtitle">Track Your Order Now</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
