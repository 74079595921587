// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Map, Info } from 'react-store-locator';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';
import shopApi from '../../api/shop';

class SitePageStoreLocator extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            stores: [],
            metaTags: {
              meta_desc: "",
              meta_keywords: '',
              meta_title: "",
            }
        }
    }

    componentDidMount() {
        this.getStores();
        this.loadMetaTags();
    }

    loadMetaTags(){
      var request = shopApi.getMetaTagsBySlug('store-location');
      request.then((metaTags) => {
          this.setState({ metaTags : metaTags.data });
      });
    }

    getStores(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/store-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ stores: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Store Location', url: '' },
        ];

        let locations = [];
        if(this.state.stores.length > 0){
            this.state.stores.map(async (item) => {
                let storeJSON = {
                    id: item.id,
                    lat: item.latitude,
                    lng: item.longitude ,
                    show: item.name === 'Head Office' ? true : false,
                    name: item.name,
                    address: item.address,
                };
                await locations.push(storeJSON);
            });
        }

        const infoStyle = {
            width: '150px',
            height: '35px',
            backgroundColor: 'yellow',
        }

        const mapStyle = [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ebe3cd"
                }
              ]
            },
            {
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#523735"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f1e6"
                }
              ]
            },
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#c9b2a6"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#dcd2be"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ae9e90"
                }
              ]
            },
            {
              "featureType": "administrative.neighborhood",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "landscape.natural",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dfd2ae"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dfd2ae"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#93817c"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#a5b076"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#447530"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f1e6"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#fdfcf8"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f8c967"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#e9bc62"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.highway.controlled_access",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e98d58"
                }
              ]
            },
            {
              "featureType": "road.highway.controlled_access",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#db8555"
                }
              ]
            },
            {
              "featureType": "road.local",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#806b63"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dfd2ae"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#8f7d77"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#ebe3cd"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dfd2ae"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#b9d3c2"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#92998d"
                }
              ]
            }
        ];

        const metaTags = this.state.metaTags;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_desc} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                    <meta name="twitter:description" content={metaTags.meta_desc} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_desc} />
                    <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                    <meta property="og:url" content={ Config.DOMAIN + '/store-location' } />
                    <link rel="canonical" href={Config.DOMAIN + '/store-location'} />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Store Location" breadcrumb={breadcrumb} />

                <div className="block faq">
                    <div className="container">
                        <div className="faq__section">
                            {locations.length > 0 &&
                                <Map
                                    initialZoom={11}
                                    mapOptions={{styles: mapStyle}}
                                    locations={locations}
                                    googleApiKey={Config.googleAPIKey}
                                    infoStyle={infoStyle}
                                >
                                    {(location, closeLocation) => {
                                        return (
                                            <Info show={location.show}>
                                                <div style={{ background: 'black', padding: 10, boxShadow: "5px 5px 5px #FFF" }}>
                                                    <p style={{float: 'right', color: 'white', fontSize: 18, cursor: 'pointer'}} onClick={() => closeLocation(location.id)}>X</p>
                                                    <p style={{ color: 'white', fontSize: 18 }}>{location.name}</p>
                                                    <p style={{ color: 'white' }}>{location.address}</p>
                                                </div>
                                            </Info>
                                        )
                                    }}
                                </Map>
                            }
                        </div>


                        <div className="contact-us__container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="store_box">
                                        <h4 className="contact-us__header card-title">Head Office</h4>
                                        <div className="contact-us__address">
                                            <strong>Address:</strong><br/>
                                            <address>Radisson Hotel, Level 4, Laico Isle, Corner Rivonia Road and Daisy Street, Sandown, Sandton 2196</address>
                                            <strong>Tel:</strong>+27 11 784 5114<br/><br/>
                                            <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 17h00<br/>Fridays closed from 12h00 – 13h30 for prayers<br/><br/>

                                            <strong>Marketing Department:</strong><br/>
                                            Email : <a href="mailto:marketing@khaliques.co.za">marketing@khaliques.co.za</a><br/><br/>

                                            <strong>Accounts Department:</strong><br/>
                                            Name : Niaz Bhati<br/>
                                            Email : <a href="mailto:admin@khaliques.co.za">admin@khaliques.co.za</a><br/><br/>

                                            <strong>Customer Service Department:</strong><br/>
                                            Name : Abu Mohamed<br/>
                                            Email : <a href="mailto:abubakr@khaliques.co.za">abubakr@khaliques.co.za</a><br/><br/>

                                            Name : Saabir Moosa<br/>
                                            Email : <a href="mailto:saabir@khaliques.co.za">saabir@khaliques.co.za</a><br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="store_box">
                                        <h4 className="contact-us__header card-title">Sandton</h4>
                                        <div className="contact-us__address">
                                            <div>
                                                <strong>Address:</strong><br/>
                                                <address>126 Rivonia Road, Sandown, Sandton 2196</address><br/>
                                                <strong>Tel:</strong>+27 11 783 2468<br/><br/>
                                                <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 17h30<br/>Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 15h00<br/>Sunday closed<br/><br/>

                                                <strong>Manager:</strong><br/>
                                                Name : Rashid Davids/Mayur Ravjee<br/>
                                                Email : <a href="mailto:sandton@khaliques.co.za">sandton@khaliques.co.za</a><br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="store_box store_box_small">
                                        <h4 className="contact-us__header card-title">Oriental Plaza</h4>
                                        <div className="contact-us__address">
                                            <div>
                                            <strong>Address:</strong><br/>
                                                <address>Shop N47, Entrance 2, Oriental Plaza, Corner Lilian &amp; Lilian Ngoyi St, Fordsburg, Johannesburg, 2000</address>
                                                <strong>Tel:</strong>+27 11 836 4418<br/><br/>
                                                <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 17h00<br/>Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 15h00<br/>Sunday closed<br/><br/>

                                                <strong>Manager:</strong><br/>
                                                Name : Raihaan Nanabhay<br/>
                                                Email : <a href="mailto:plaza@khaliques.co.za">plaza@khaliques.co.za</a><br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="store_box store_box_small">
                                        <h4 className="contact-us__header card-title">Cedar Square</h4>
                                        <div className="contact-us__address">
                                            <div>
                                                    <strong>Address:</strong><br/>
                                                        <address>Shop G3.1, Cedar Rd, Fourways, 2068</address>
                                                        <strong>Tel:</strong>+27 11 465 1613<br/><br/>
                                                        <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 18h00<br/>Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 17h00<br/>Sunday 09h00 – 15h00<br/><br/>

                                                        <strong>Manager:</strong><br/>
                                                        Name : Himat Jivan<br/>
                                                        Email : <a href="mailto:cedar@khaliques.co.za">cedar@khaliques.co.za</a><br/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="store_box store_box_small">
                                        <h4 className="contact-us__header card-title">Mall of Africa</h4>
                                        <div className="contact-us__address">
                                            <div>
                                                <strong>Address:</strong><br/>
                                                    <address>Shop 2155,Upper Level, Mall of Africa, Midrand, 2196</address>
                                                    <strong>Tel:</strong>+27 82 083 0713<br/><br/>
                                                    <strong>Operating Hours:</strong><br/> Monday-Saturday 09h00 – 20h00<br/>Sunday 10h00 – 20h00<br/><br/>

                                                    <strong>Manager:</strong><br/>
                                                    Email : <a href="mailto:moa@khaliques.co.za">moa@khaliques.co.za</a><br/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SitePageStoreLocator;