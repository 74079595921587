// react
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'
import { Link } from 'react-router-dom';
// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';
import shopApi from '../../api/shop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '/about-us' },
        { title: 'Shipping Information', url: '/shipping-information' },
        { title: 'Privacy Policy', url: '/privacy-policy' },
        { title: 'Return Policy', url: '/return-policy' },
        { title: 'Terms & Conditions', url: '/terms-conditions' },
        { title: 'Contact Us', url: '/contact-us' },
        //{ title: 'Affiliate With Us', url: '/' },
    ];

    const accountLinks = [
        //{ title: 'Request For Return', url: '/request-for-return' },
        { title: 'Store Location', url: '/store-location' },
        { title: 'Track Order', url: '/track-order' },
        { title: 'FAQ', url: '/faq' },
        { title: 'Careers', url: '/careers' },
        { title: 'Suit Maintenance', url: '/suit-maintenance' },
        //{ title: 'Sitemap', url: '/sitemap' }
    ];

    const clickWhatsapp = () => {
        shopApi.g4SingleEventPush('whatsapp_click', window.location.href)
    }

    return (
        <div className="site-footer">
            <span onClick={() => clickWhatsapp()}>
                <FloatingWhatsApp phone="+27820786436" showPopup={false} showOnIE={true} zIndex={9000000} autoOpenTimeout={9000000000000000000} />
            </span>
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        &copy; Copyright {theme.year} <a href={theme.url} rel="noopener noreferrer" target="_blank">{theme.name}</a>. All rights reserved.
                    </div>
                    <div className="site-footer__payments footer-payment-icon-section" style={{float: 'right'}}>
                        <Link hrefLang={"en"}  to={{ pathname: 'https://paygate.co.za' }} target="_blank">
                            <img src="/images/payments.png" alt="Paygate" className="footer-payment-icon" style={{float: 'right'}} />
                        </Link>
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
