// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';
import { LogoSvg, LogoSmallSvg } from '../../svg';
// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import { Config } from '../../config';
import { url } from '../../services/utils';
import Currency from '../shared/Currency';
import shopApi from '../../api/shop';
import BlockLoader from '../blocks/BlockLoader';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockGiftCards extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            gift_cards: []
        };
    }

    async componentDidMount() {
       await this.getGiftCardList();
    }

    getGiftCardList(){
        this.setState({ isLoading: true });
        shopApi.getGiftCardList().then((response) => {
            if(response.status){
                this.setState({ gift_cards: response.data });
            }
            this.setState({ isLoading: false });
        });        
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {

        if(this.state.isLoading){
            return <BlockLoader/>;
        }
        
        const { title, layout, groups, onGroupClick } = this.props;

        const giftCardList = this.state.gift_cards.map((gift_card, index) => (
            <div onClick={() => { onGroupClick(gift_card.amount) }} key={index} className="block-brands__item">
                    <div style={{cursor: 'pointer', padding: 5, background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)', height: 100, borderRadius: 5, boxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)', WebkitBoxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)'}}>
                        <div style={{float: 'right', marginRight: 5}}><LogoSmallSvg /></div>
                        <span style={{float: 'right', color: '#f9c464', marginTop: 10, fontSize: 12, marginRight: 5, width: '100%', textAlign: 'right'}}>
                            {gift_card.amount === 'Custom' &&
                                <>
                                    <span style={{float: 'right', color: '#f9c464', fontSize: 12, marginRight: 5}}>{gift_card.amount}</span>
                                </>
                            }
                            {gift_card.amount !== 'Custom' &&
                                <>
                                    <Currency value={gift_card.amount} />
                                </>
                            }
                        </span>
                    </div>
            </div>
        ));        
        
        return (
            <>
                <div className={`block block-brands block-brands--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            groups={groups}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {giftCardList}                                
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

BlockGiftCards.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
};

BlockGiftCards.defaultProps = {
    layout: 'list-sm'    
};
