// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import BlogPost from './BlogPost';
import BlogSidebar from './BlogSidebar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

//export default function BlogPagePost(props) {
export default class BlogPagePost extends Component {


    constructor(props) {
        super(props);

        this.state = {
            post: [],
            isLoading: false,
            slug: this.props.match.params.slug
        };
    }

    async componentDidMount() {
        this.getBlogDetails();
    }

    // let content;

    // if (layout === 'classic') {
    //     const sidebar = <BlogSidebar position={sidebarPosition} />;

    //     let sidebarStart;
    //     let sidebarEnd;

    //     if (sidebarPosition === 'start') {
    //         sidebarStart = <div className="col-12 col-lg-4 order-1 order-lg-0">{sidebar}</div>;
    //     }
    //     if (sidebarPosition === 'end') {
    //         sidebarEnd = <div className="col-12 col-lg-4">{sidebar}</div>;
    //     }

    //     content = (
    //         <div className="row">
    //             {sidebarStart}
    //             <div className="col-12 col-lg-8">
    //                 <BlogPost layout={layout} />
    //             </div>
    //             {sidebarEnd}
    //         </div>
    //     );
    // } else if (layout === 'full') {
    //     content = (
    //         <div className="row justify-content-center">
    //             <div className="col-md-12 col-lg-9 col-xl-8">
    //                 <BlogPost layout={layout} />
    //             </div>
    //         </div>
    //     );
    // }

    getBlogDetails(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/get-blog-details", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({slug : this.state.slug})
        })
        .then(response => response.json())
        .then(async response => {
            this.setState({ isLoading: false });
            if(response.status){
               await this.setState({ post: response.data[0] });
            }
        })
        .catch(err => {
            //console.log("getBlogDetails err: ", err);
        });
    }

    render() {

        const { layout, sidebarPosition } = this.props;

        let content = (
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12 col-xl-12">
                    <BlogPost post={this.state.post} layout={layout} />
                </div>
            </div>
        );

        const breadcrumbs = [
            { title: 'Home', url: '/' },
            { title: 'Blog', url: '/blog' },
            { title: this.state.post.title, url: '' },
        ];

        const metaTags = this.state.post;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_description} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    <meta property="twitter:image" content={this.state.post.blog_image} />
                    <meta name="twitter:description" content={metaTags.meta_description} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_description} />
                    <meta property="og:image" content={this.state.post.blog_image} />
                    <meta property="og:url" content={ Config.DOMAIN + '/blog/'+this.state.post.slug } />
                    <link rel="canonical" href={ Config.DOMAIN + '/blog/'+this.state.post.slug } />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader breadcrumb={breadcrumbs} />

                <div className="container">{content}</div>
            </React.Fragment>
        );
    }
}

BlogPagePost.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

BlogPagePost.defaultProps = {
    layout: 'classic',
    sidebarPosition: 'start',
};
