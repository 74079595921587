// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Shake from 'react-reveal/Shake';
import { Modal } from 'reactstrap';
import "bootstrap/dist/css/bootstrap.css";

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities, cartRefresh } from '../../store/cart';
import { Cross12Svg, Cross20Svg, LogoSmallSvg, LogoSvg, LogoCardSvg } from '../../svg';
import { url } from '../../services/utils';
import BlockLoader from '../blocks/BlockLoader';
import { Config } from '../../config';

// data stubs
import theme from '../../data/theme';
import shopApi from '../../api/shop';
import BlockGiftCards from '../blocks/BlockGiftCards';

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            showModalView: false,
            isLoading: false,
            isEmptyCollaboratorCode: false,
            isEmptyGiftCardNumber: false,
            isEmptyOTP: false,
            isEmptyGiftCardAmount: false,
            showGiftCardForm: true,
            purchase_gift_card_amount: '',
            isEmptyPurchaseGiftCardAmount: false,
            showOTPForm: false,
            showAmountForm: false,
            OTPVerifyAuth: '',
            OTP: '',
            gift_card_amount: '',
            gift_card_number: '',
            collaborator_code: '',
            cardObj: '',
            isItemsOutOfStockModel: false,
            isItemsOutOfStockItems: [],
        };

        this.props.cartRefresh();
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount(){
        let { cart } = this.props;
        if(cart.items.data !== undefined){
            var cart_items = [];
            cart.items.data.map((itemObj, itemPrevIndex) => {
                if(itemObj.product_details !== ''){
                     if(itemObj.gift_card_id > 0){
                        let product_data = itemObj.product_details;
                        product_data.final_item_code = itemObj.product_details.id;
                        product_data.full_name = itemObj.product_details.card_no;
                        product_data.category_name = '';
                        product_data.brand_name = '';
                        product_data.color_name = '';
                        product_data.selling_price = itemObj.product_details.balance;
                        product_data.discount_price = itemObj.product_details.discounted_amount;
                        product_data.size_name = '';
                        product_data.gift_card_id = itemObj.gift_card_id;
                        cart_items.push(product_data);
                    } else {
                        cart_items.push(itemObj.product_details);
                    }
                    if(itemPrevIndex === cart.items.data.length -1){
                        shopApi.g4EventPush("view_cart", cart_items, true);
                    }
                }
            });
        }
    }

    applyGiftCardNumber(){
        if(!this.state.gift_card_number || this.state.gift_card_number == ''){
            this.setState({ isEmptyGiftCardNumber: true });
        } else {
            shopApi.applyGiftCard(this.state.gift_card_number).then((response) => {
                //console.log("applyGiftCardNumber response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        this.setState({ showOTPForm: true, OTPVerifyAuth: response.OTP_token });
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    verifyGiftCardOTP(){
        if(!this.state.OTP || this.state.OTP == ''){
            this.setState({ isEmptyOTP: true });
        } else {
            shopApi.verifyGiftCardOTP(this.state.OTP, this.state.OTPVerifyAuth).then((response) => {
                //console.log("verifyGiftCardOTP response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        this.setState({ showAmountForm: true });
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    applyGiftCardAmount(){
        if(!this.state.gift_card_amount || this.state.gift_card_amount == ''){
            this.setState({ isEmptyGiftCardAmount: true });
        } else {
            if(this.state.gift_card_amount <= 0){
                this.setState({ isEmptyGiftCardAmount: true });
            } else {
                shopApi.applyGiftCardAmount(this.state.gift_card_number, this.state.gift_card_amount).then((response) => {
                    //console.log("applyGiftCardAmount response", response);
                    if(response.status){
                        if(response.status == 2){
                            localStorage.removeItem(Config.x_token);
                            localStorage.removeItem(Config.web_user);
                            toast.error(response.message);
                            this.props.history.push("/");
                        } else {
                            this.setState({ showOTPForm: false, showAmountForm: false, showGiftCardForm: false });
                            toast.success(response.message);
                            this.props.cartRefresh();
                        }
                    } else {
                        toast.error(response.message);
                    }
                });
            }
        }
    }

    removeGiftCard(gift_card_id){
        shopApi.removeGiftCardAmount(gift_card_id).then((response) => {
            //console.log("removeGiftCard response", response);
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.cartRefresh();
                }
            } else {
                toast.error(response.message);
            }
        });
    }

    applyCollaboratorCode(){
        if(!this.state.collaborator_code || this.state.collaborator_code == ''){
            this.setState({ isEmptyCollaboratorCode: true });
        } else {
            this.setState({ isEmptyCollaboratorCode: false });
            shopApi.applyCollaboratorCode(this.state.collaborator_code).then((response) => {
                //console.log("applyCollaboratorCode response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        toast.success(response.message);
                        this.props.cartRefresh();
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    removeCollaboratorCode(collaborator_code){
        shopApi.removeCollaboratorCode(collaborator_code).then((response) => {
            //console.log("removeCollaboratorCode response", response);
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.cartRefresh();
                }
            } else {
                toast.error(response.message);
            }
        });
    }

    handleChangeQuantity = (item, quantity) => {
        // this.setState((state) => {
        //     const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

        //     if (!stateQuantity) {
        //         state.quantities.push({ itemId: item.id, value: quantity });
        //     } else {
        //         stateQuantity.value = quantity;
        //     }

        //     return {
        //         quantities: state.quantities,
        //     };
        // });

        this.props.cartUpdateQuantities(item, quantity);
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return quantities.filter((x) => {
            const item = cart.items.data.find((item) => item.id === x.itemId);

            return item && item.quantity !== x.value && x.value !== '';
        }).length > 0;
    }

    showModal = (amount) => {
        shopApi.generateGiftCard().then((response) => {
            //console.log("generateGiftCard response", response);
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    if(amount === 'Custom'){
                        this.setState({ purchase_gift_card_amount: '' });
                    } else {
                        this.setState({ purchase_gift_card_amount: amount });
                    }
                    this.setState({ cardObj: response.data });
                    this.setState({ showModalView: true });
                }
            } else {
                toast.error('Please login to use Purchase Gift Card!');
            }
        });
    }

    addGiftCard(){
        if(this.state.purchase_gift_card_amount == '' || this.state.purchase_gift_card_amount == 0){
            this.setState({ isEmptyPurchaseGiftCardAmount: true });
        } else {
            shopApi.addGiftCard(this.state.cardObj.id, this.state.purchase_gift_card_amount).then((response) => {
                //console.log("addGiftCard response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        let gift_card_data = {};
                        gift_card_data.final_item_code = cardObj.id;
                        gift_card_data.full_name = cardObj.card_no;
                        gift_card_data.category_name = '';
                        gift_card_data.brand_name = '';
                        gift_card_data.color_name = '';
                        gift_card_data.selling_price = purchase_gift_card_amount;
                        gift_card_data.discount_price = 0;
                        gift_card_data.size_name = '';
                        gift_card_data.gift_card_id = cardObj.id;
                        shopApi.g4EventPush("add_to_cart", gift_card_data);

                        this.setState({ cardObj: '' });
                        toast.success(response.message);
                        this.setState({ showModalView: false });
                        this.props.cartRefresh();
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    hideModal(){
        this.setState({ showModalView: false });
    }

    hideModalOutOfStock(){
        this.setState({ isItemsOutOfStockModel: false });
    }

    checkOutOfStock(){
        const { cart } = this.props;
        if(cart.items.status !== undefined && cart.items.status){
            let isOutOfStock = [];
            cart.items.data.map((item, itemIndex) => {
                if(item.product_details !== ''){
                    if(item.gift_card_id  == 0){
                        if(item.product_details.qty == 0){
                            isOutOfStock.push(item.product_details);
                        }
                    }
                }
                if(cart.items.data.length -1 == itemIndex){
                    if(isOutOfStock.length > 0){
                        this.setState({ isItemsOutOfStockModel: true, isItemsOutOfStockItems: isOutOfStock });
                    } else {
                        this.setState({ isItemsOutOfStockModel: false, isItemsOutOfStockItems: isOutOfStock });
                        this.props.history.push("/checkout");
                    }
                }
            });
        }
    }

    continueWithProceedOrder(){
        this.props.history.push("/checkout");
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;
        if(cart.items.status !== undefined && cart.items.status){

            return cart.items.data.map((item, itemIndex) => {
                let image;
                let options;
                item.product = item.product_details;

                if(item.product_details !== ''){

                    if(item.gift_card_id > 0){

                        image = (
                            <div className="product-image">
                                <Link hrefLang={"en"}  to="/" className="product-image__body">
                                    <img className="product-image__img" src={item.product.image} alt="Gift Card" title="Gift Card" />
                                </Link>
                            </div>
                        );

                        const removeButton = (
                            <AsyncAction
                                action={() => cartRemoveItem(item.id)}
                                render={({ run, loading }) => {
                                    const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                                        'btn-loading': loading,
                                    });

                                    return (
                                        <button type="button" onClick={run} className={classes}>
                                            <Cross12Svg />
                                        </button>
                                    );
                                }}
                            />
                        );

                        return (
                            <tr key={item.id} className="cart-table__row">
                                <td className="cart-table__column cart-table__column--image">
                                    {image}
                                </td>
                                <td className="cart-table__column cart-table__column--product">
                                    <Link hrefLang={"en"}  to="/" className="cart-table__product-name">
                                        Gift Card
                                    </Link>
                                    <ul className="cart-table__options">
                                        <li>{item.product.card_no}</li>
                                    </ul>
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Price">
                                    <Currency value={item.product.balance} />
                                </td>
                                <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                    <span>1</span>
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Discount">
                                    <span>0</span>
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                    <Currency value={item.product.balance} />
                                </td>
                                <td className="cart-table__column cart-table__column--remove">
                                    {removeButton}
                                </td>
                            </tr>
                        );

                    } else {

                        if (item.product.product_main_image !== '') {
                            image = (
                                <div className="product-image">
                                    <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="product-image__body">
                                        <img className="product-image__img" src={item.product.product_main_image} alt={item.product.full_name} title={item.product.full_name} />
                                    </Link>
                                </div>
                            );
                        }

                        //if (item.options.length > 0) {
                            options = (
                                // <ul className="cart-table__options">
                                //     {item.options.map((option, index) => (
                                //         <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                                //     ))}
                                // </ul>
                                <ul className="cart-table__options">
                                    {item.product.brand_name && <li>Brand : {(item.product.brand_name).toLowerCase()}</li>}
                                    {item.product.color_name && <li>Color : {(item.product.color_name).toLowerCase()}</li>}
                                    {item.product.size_name && <li>Size : {(item.product.size_name).toLowerCase()}</li>}
                                    {/* {product.style_name && <li>Style : {product.style_name}</li>} */}
                                    {item.product.final_item_code && <li>SKU : {(item.product.final_item_code)}</li>}
                                    {/* {product.express_delivery_image !=='' && <img src={product.express_delivery_image} style={{width: 160, height: 'auto'}} />} */}
                                </ul>
                            );
                        //}

                        const removeButton = (
                            <AsyncAction
                                action={() => cartRemoveItem(item.id)}
                                render={({ run, loading }) => {
                                    const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                                        'btn-loading': loading,
                                    });

                                    return (
                                        <button type="button" onClick={run} className={classes}>
                                            <Cross12Svg />
                                        </button>
                                    );
                                }}
                            />
                        );

                        return (
                            <tr key={item.id} className="cart-table__row">
                                <td className="cart-table__column cart-table__column--image">
                                    {image}
                                </td>
                                <td className="cart-table__column cart-table__column--product">
                                    <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="cart-table__product-name">
                                        {(item.product.full_name).toLowerCase()}
                                    </Link>
                                    {options}
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Price">
                                    <Currency value={item.product.selling_price} />
                                </td>
                                <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                    {item.product.qty > 0 &&
                                        <InputNumber
                                            readOnly={true}
                                            onChange={(quantity) => this.handleChangeQuantity(item.product, quantity)}
                                            //value={this.getItemQuantity(item)}
                                            value={item.qty}
                                            min={1}
                                        />
                                    }
                                    {item.product.qty == 0 &&
                                        <>
                                            <span>{item.qty}</span>
                                            <span style={{fontSize: 12}} className="badge badge-danger">Out of Stock</span>
                                        </>
                                    }
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Discount">
                                    <Currency value={item.product.discounted_amount} />
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                        <Currency value={item.product.sub_total} />
                                </td>
                                <td className="cart-table__column cart-table__column--remove">
                                    {removeButton}
                                </td>
                            </tr>
                        );
                    }

                }
            });

        } else {
            return [];
        }
    }

    renderOutOfStockItems() {
        if(this.state.isItemsOutOfStockItems.length > 0){

            return this.state.isItemsOutOfStockItems.map((item, itemIndex) => {
                let image;
                let options;
                item.product = item;

                    if (item.product.product_main_image !== '') {
                        image = (
                            <div className="product-image">
                                <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="product-image__body">
                                    <img className="product-image__img" src={item.product.product_main_image} alt={item.product.full_name} title={item.product.full_name} />
                                </Link>
                            </div>
                        );
                    }

                    options = (
                        <ul className="cart-table__options">
                            {item.product.brand_name && <li>Brand : {(item.product.brand_name).toLowerCase()}</li>}
                            {item.product.color_name && <li>Color : {(item.product.color_name).toLowerCase()}</li>}
                            {item.product.size_name && <li>Size : {(item.product.size_name).toLowerCase()}</li>}
                            {item.product.final_item_code && <li>SKU : {(item.product.final_item_code)}</li>}
                        </ul>
                    );


                    return (
                        <tr key={item.id} className="cart-table__row">
                            <td className="cart-table__column cart-table__column--image">
                                {image}
                            </td>
                            <td className="cart-table__column cart-table__column--product">
                                <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="cart-table__product-name">
                                    {(item.product.full_name).toLowerCase()}
                                </Link>
                                {options}
                            </td>
                            <td className="cart-table__column cart-table__column--price" data-title="Price">
                                <Currency value={item.product.selling_price} />
                            </td>
                            <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                <span style={{fontSize: 12}} className="badge badge-danger">Out of Stock</span>
                            </td>
                            <td className="cart-table__column cart-table__column--total" data-title="Discount">
                                <Currency value={item.product.discounted_amount} />
                            </td>
                            <td className="cart-table__column cart-table__column--total" data-title="Total">
                                    <Currency value={item.product.sub_total} />
                            </td>
                        </tr>
                    );
            });

        } else {
            return [];
        }
    }

    renderTotals() {
        const { cart } = this.props;
        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            let calcShippingLink;
            let giftCard;
            let couponCode;

            if(extraLine.price > 0){
                // if (extraLine.type === 'shipping') {
                //     calcShippingLink = <div className="cart__calc-shipping"><Link hrefLang={"en"}  to="/">Calculate Shipping</Link></div>;
                // }
                // if (extraLine.type === "pay_by_gift_card" && extraLine.price > 0) {
                //     giftCard = <div className="cart__calc-shipping">{cart.items.amounts.cart_pay_by_gift_card_no} <span title="Remove Gift Card" onClick={() => { this.removeGiftCard(cart.items.amounts.cart_pay_by_gift_card_id) }} className="remove-gift-card">X</span></div>;
                // }
                // if (extraLine.type === "pay_by_coupon_code" && extraLine.price > 0) {
                //     couponCode = <div className="cart__calc-shipping">{cart.items.data[0].discount_code} <span title="Remove Collaborator Code" onClick={() => { this.removeCollaboratorCode(cart.items.data[0].discount_code) }} className="remove-gift-card">X</span></div>;
                // }

                return (
                    <tr key={index}>
                        <th>
                            {extraLine.title}
                            {giftCard}
                            {couponCode}
                        </th>
                        <td>
                            <Currency value={extraLine.price} />
                            {calcShippingLink}
                        </td>
                    </tr>
                );
            }
        });

        return (
            <React.Fragment>
                {/* <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={(cart.items.amounts.sub_amount)} /></td>
                    </tr>
                </thead> */}
                <tbody className="cart__totals-body">
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities } = this.state;

        // const updateCartButton = (
        //     <AsyncAction
        //         action={() => cartUpdateQuantities(quantities)}
        //         render={({ run, loading }) => {
        //             const classes = classNames('btn btn-primary cart__update-button', {
        //                 'btn-loading': loading,
        //             });

        //             return (
        //                 <button type="button" onClick={run} className={classes} disabled={!this.cartNeedUpdate()}>
        //                     Update Cart
        //                 </button>
        //             );
        //         }}
        //     />
        // );

        return (
            <div className="cart block">
                <div className="container">

                <BlockGiftCards
                    title="Gift Cards"
                    layout="list-sm"
                    onGroupClick={this.showModal}
                />

                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--price">Unit Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--quantity">Discount</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>

                    <div className="row justify-content-end pt-md-4 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">



                        {/* <form className="cart__coupon-form">
                            <input type="text" onChange={this.handleSetValue} className="form-control" id="gift_card_number" name="gift_card_number" placeholder="Enter Virtual Gift Card Number" />
                            <button type="button" disabled={this.state.gift_card_number == '' ? true : false} onClick={() => { this.applyGiftCardNumber() }} className="btn btn-primary">Apply</button>
                        </form>
                        <div>&nbsp;</div>
                        <form className="cart__coupon-form">
                            <input type="text" onChange={this.handleSetValue} className="form-control" id="gift_card_number" name="gift_card_number" placeholder="Enter Virtual Gift Card Number" />
                            <button type="button" disabled={this.state.gift_card_number == '' ? true : false} onClick={() => { this.applyGiftCardNumber() }} className="btn btn-primary">Apply</button>
                        </form> */}

                        <div>&nbsp;</div>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Your Cart Total</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        {/* <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td><Currency currency={{'symbol' : 'R '}} value={(cart.total !== undefined ? cart.total : 0)} /></td>
                                            </tr>
                                        </tfoot> */}
                                    </table>
                                    <button onClick={() => { this.checkOutOfStock() }} className="btn btn-primary btn-xl btn-block cart__checkout-button">
                                        Proceed To Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: 'Home', url: url.home() },
            { title: 'Shopping Cart', url: '' },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <BlockGiftCards
                            title="Gift Cards"
                            layout="list-sm"
                            onGroupClick={this.showModal}
                        />
                        <div className="block-empty__body">
                            <div className="block-empty__message">Your shopping cart is empty!</div>
                            <div className="block-empty__actions">
                                <Link hrefLang={"en"}  to="/" className="btn btn-primary btn-sm">Continue</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.isLoading) {
            return <BlockLoader />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`My Cart | ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

                {this.state.cardObj !=='' &&
                    <Modal isOpen={this.state.showModalView} centered size="l">
                        <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                            <button className="quickview__close" type="button" onClick={() => { this.hideModal() }}>
                                <Cross20Svg />
                            </button>

                            <div style={{ width: '100%', padding: 5, background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)', height: 200, borderRadius: 5, boxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)', WebkitBoxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)'}}>
                                <div style={{marginLeft: 10, marginTop: 10, width: '100%'}}><LogoCardSvg /></div>
                                <div style={{color: '#f9c464', marginLeft: 10, marginTop: 5, fontWeight: 'bold'}}>Virtual Card Number</div>
                                <div style={{color: '#f9c464', marginLeft: 10, marginTop: 0, fontSize: 14}}>{this.state.cardObj.card_no}</div>
                                <div style={{color: '#f9c464', marginTop: 40, fontSize: 16, marginRight: 20, width: '100%', textAlign:'right'}}>
                                    Amont: R <input name="purchase_gift_card_amount" id="purchase_gift_card_amount" style={{ background: 'transparent', boxShadow: 'none', border: 'none', borderBottom: (this.state.isEmptyPurchaseGiftCardAmount ? '1px solid #FF0000' : '1px solid #f9c464'), color: '#f9c464', width: 100, marginRight: 10}} type="text" onChange={this.handleSetValue} width="20" defaultValue={this.state.purchase_gift_card_amount} />
                                </div>
                            </div>

                            <div style={{float: 'right'}}>
                                <button style={{color: '#f9c464', background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)'}} onClick={() => { this.addGiftCard() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                    Add
                                </button>
                            </div>
                        </div>
                    </Modal>
                }

                {this.state.isItemsOutOfStockModel &&
                    <Modal isOpen={this.state.isItemsOutOfStockModel} centered size="xl">
                        <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                            <button className="quickview__close" type="button" onClick={() => { this.hideModalOutOfStock() }}>
                                <Cross20Svg />
                            </button>
                            <h5>Few Items Are Unavailable/Out Of Stock For Checkout Process!</h5>
                            <table className="mt-3 cart__table cart-table">
                                <thead className="cart-table__head">
                                    <tr className="cart-table__row">
                                        <th className="cart-table__column cart-table__column--image">Image</th>
                                        <th className="cart-table__column cart-table__column--product">Product</th>
                                        <th className="cart-table__column cart-table__column--price">Unit Price</th>
                                        <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                        <th className="cart-table__column cart-table__column--quantity">Discount</th>
                                        <th className="cart-table__column cart-table__column--total">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="cart-table__body">
                                    {this.renderOutOfStockItems()}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4"><p>Please continue with available items!</p></td>
                                        <td>
                                            <button onClick={() => { this.hideModalOutOfStock() }} className="mt-3 btn btn-primary btn-xl btn-block cart__checkout-button">
                                                Cancel
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => { this.continueWithProceedOrder() }} className="mt-3 btn btn-primary btn-xl btn-block cart__checkout-button">
                                                Continue
                                            </button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </Modal>
                }

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartRefresh,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
