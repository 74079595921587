// react
import React, { useEffect, useState } from 'react';

//third party
import moment from 'moment';
// application
import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';
import BlockLoader from '../blocks/BlockLoader';
import { Config } from '../../config';
import { toast } from 'react-toastify';

// data stubs
//import reviews from '../../data/shopProductReviews';

function ProductTabReviews(props) {

    const { product } = props;
    const [reviews, setReviews] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordPerPage, setRecordPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [total_pages, setTotalPages] = useState(0);
    const [rating, setRating] = useState(5);
    const [review, setReview] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    let currentPic = 'images/avatars/user_placeholder.jpeg'

    const handleSetValue = (event) => {
        if(event.target.name == 'rating'){
            setRating(event.target.value);
        }
        if(event.target.name == 'review'){
            setReview(event.target.value);
        }
    };

    const submitReview = () => {
        if(localStorage.getItem(Config.x_token) === null){
            toast.error("You must login to rate item");
        } else {
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
            
            setIsLoading(true);
            fetch(Config.URL+"/create-ratings", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 
                    product_id : product.id,
                    rating : rating,
                    review : review
                })
            })
            .then(response => response.json())
            .then(response => {
                if(response.status){
                    if(response.status === 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/account/login");
                    } else {
                        getRarings(page);
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
                setIsLoading(false);
            })
            .catch(err => {
                //console.log("Product err: ", err);
                return;
            }); 
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
        setTimeout(() => {
            getRarings(page);
        },500);
    };

    const getRarings = (page) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
        setIsLoading(true);
        fetch(Config.URL+"/get-ratings-by-product", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ 
                product_id : product.id,
                page : page,
                records_per_page : recordPerPage
             })
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                setReviews(response.data);
                
                setTotalRecords(response.totalRecords);
                setRecordPerPage(response.recordPerPage);
                setPage(response.page);
                setTotalPages(response.total_pages);
            }
            setIsLoading(false);
        })
        .catch(err => {
            //console.log("Product err: ", err);
            return;
        }); 
    }

    useEffect(() => {
        
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
        setIsLoading(true);
        fetch(Config.URL+"/get-ratings-by-product", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ 
                product_id : product.id,
                page : page,
                records_per_page : recordPerPage
             })
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                setReviews(response.data);
                
                setTotalRecords(response.totalRecords);
                setRecordPerPage(response.recordPerPage);
                setPage(response.page);
                setTotalPages(response.total_pages);
            }
            setIsLoading(false);
        })
        .catch(err => {
            //console.log("Product err: ", err);
            return;
        });        
    }, [setIsLoading]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const reviewsList = reviews.map((review, index) => (
        <li key={index} className="reviews-list__item">
            <div className="review">
                <div className="review__avatar"><img src={currentPic} alt="" /></div>
                <div className=" review__content">
                    <div className="review__author">{review.name}</div>
                    <div className="review__rating">
                        <Rating value={review.rating} />
                    </div>
                    <div className=" review__text">{review.review}</div>
                    <div className=" review__date">{moment(review.date_created).format("Do MMM YYYY, h:mm:ss A")}</div>
                </div>
            </div>
        </li>
    ));

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h3 className="reviews-view__header">Customer Reviews</h3>

                <div className="reviews-list">
                    <ol className="reviews-list__content">
                        {reviewsList}
                        {reviews.length == 0 &&
                            <li>No ratings found</li>
                        }
                    </ol>
                    {total_pages >= recordPerPage &&
                    <div className="reviews-list__pagination">
                        <Pagination current={page} siblings={2} total={total_pages} onPageChange={handlePageChange} />
                    </div>
                    }
                </div>
            </div>
            
            {localStorage.getItem(Config.x_token) !== null && 
            <form className="reviews-view__form">
                <h3 className="reviews-view__header">Write A Review</h3>
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">Select Rating</label>
                                <select name="rating" id="review-stars" className="form-control" onChange={handleSetValue}>
                                    <option value="5">5 Stars Rating</option>
                                    <option value="4">4 Stars Rating</option>
                                    <option value="3">3 Stars Rating</option>
                                    <option value="2">2 Stars Rating</option>
                                    <option value="1">1 Star Rating</option>
                                </select>
                            </div>
                            {/* <div className="form-group col-md-4">
                                <label htmlFor="review-author">Your Name</label>
                                <input type="text" className="form-control" id="review-author" placeholder="Your Name" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-email">Email Address</label>
                                <input type="text" className="form-control" id="review-email" placeholder="Email Address" />
                            </div> */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">Your Review</label>
                            <textarea className="form-control" onChange={handleSetValue} name="review" id="review-text" rows="6" />
                        </div>
                        <div className="form-group mb-0">
                            <button type="button" onClick={submitReview} className="btn btn-primary btn-lg">Submit Your Review</button>
                        </div>
                    </div>
                </div>
            </form>
            }
        </div>
    );
}

export default ProductTabReviews;
