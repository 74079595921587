// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';

function HomeProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;
    //product.rating = 3
    //product.reviews = 10
    let discount = product.discount;
    product.compareAtPrice = false;
    product.price = product.selling_price;
    product.discount_price = 0;
    if(discount > 0){
        let pp = parseFloat(product.selling_price) * parseInt(discount) / 100;   
        let fpp = parseFloat(product.selling_price) - parseFloat(pp);
        product.compareAtPrice = fpp;
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">{discount}% off</div>);
        product.price = fpp;
        product.discount_price = pp;
    }

    
    // if (product.badges.includes('sale')) {
    //     badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    // }
    // if (product.badges.includes('hot')) {
    //     badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    // }
    // if (product.badges.includes('new')) {
    //     badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    // }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.product_main_image && product.product_main_image !== '') {
        image = (
            <div className="product-card__image product-image">
                <Link hrefLang={"en"}  to={url.product_detail(product)} className="product-image__body">
                    {/* <LazyLoad height={200} height={200} offset={[-200, 0]} placeholder={<PlaceholderComponent />} debounce={500} > */}
                        <img className="product-image__img" src={product.product_main_image} alt={(product.full_name).toLowerCase()} title={(product.full_name).toLowerCase()}/>
                    {/* </LazyLoad> */}
                </Link>
            </div>
        );
    }

    if (product.compareAtPrice) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={product.compareAtPrice} /></span>
                {' '}
                <span className="product-card__old-price"><Currency value={product.selling_price} /></span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={product.selling_price} />
            </div>
        );
    }

    // if (product.attributes && product.attributes.length) {
    //     features = (
    //         <ul className="product-card__features-list">
    //             {product.attributes.filter((x) => x.featured).map((attribute, index) => (
    //                 <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
    //             ))}
    //         </ul>
    //     );
    // }

    features = (
        <ul className="product-card__features-list">
            <li>{product.brand_name} {product.color_name}</li>                
        </ul>
    );

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(product.final_item_code)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link hrefLang={"en"}  to={url.product_detail(product)}>{product.full_name}</Link>
                </div>                
                <div className="product-card__rating">
                    <Rating value={(product.rating != null ? product.rating : 4)} />
                    <div className=" product-card__rating-legend">{`${product.reviews !== undefined ? product.reviews : 10} Reviews`}</div>
                </div>                
                {features}
            </div>
            <div className="product-card__actions">

                <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div>
                {price}
                <div className="product-card__buttons">
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                            </React.Fragment>
                        )}
                    />
                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                    'btn-light': !product.is_wish_listed,
                                    'btn-primary': product.is_wish_listed
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    {/* <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    /> */}
                </div>
            </div>
        </div>
    );
}

HomeProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeProductCard);
