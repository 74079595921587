export default [
    {
        title: 'R South Africa',
        currency: {
            code: 'RAND',
            symbol: 'R',
            name: 'South Africa Rand',
        },
    }
];
