// react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import Autocomplete from "react-google-autocomplete";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPageProfile extends Component {
//export default function AccountPageProfile() {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: '',
            countries: [],
            countryCode: 0,
            basicFormFields: {
                first_name: '',
                last_name: '',
                country: '',
                gender: '',
                city: '',
                address: '',
                date_of_birth: '',
                anniversary_date: '',
            },
            basicFormFieldsError: {
                isEmptyFirstName: false,
                isEmptyLastName: false,
                isEmptyCountry: false,
                isEmptyGender: false,
                isEmptyCity: false,
                isEmptyAddress: false,
                isEmptyDateOfBitrh: false,
                isEmptyAnniversaryDate: false
            },
        }
    }

    componentDidMount() {
        this.getAllCountries();
        this.getProfile();
    }

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getAllCountries response: ", response);
            if(response.status){
                let countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: item.name, value: item.id });
                });
                this.setState({ countries });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    response.data.date_of_birth = (response.data.date_of_birth !== '0000-00-00' ? new Date(response.data.date_of_birth) : new Date());
                    response.data.anniversary_date = (response.data.anniversary_date !== '0000-00-00' ? new Date(response.data.anniversary_date) : new Date());
                    this.setState({ profile: response.data, basicFormFields: response.data });

                    let $this = this;
                    setTimeout(() => {
                        $this.setState(prevState => ({
                            basicFormFields: { ...prevState.basicFormFields, 'country': response.data.country }
                        }));
                    }, 1000);

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateUpdateProfileForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateUpdateProfileForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyFirstName: false,
            isEmptyLastName: false,
            isEmptyCountry: false,
            isEmptyGender: false,
            isEmptyCity: false,
            isEmptyAddress: false,
            isEmptyDateOfBitrh: false,
            isEmptyAnniversaryDate: false
        };

        var first_name = this.state.basicFormFields.first_name;
        var last_name = this.state.basicFormFields.last_name;
        var country = this.state.basicFormFields.country;
        var gender = this.state.basicFormFields.gender;
        var city = this.state.basicFormFields.city;
        var address = this.state.basicFormFields.address;
        var date_of_birth = this.state.basicFormFields.date_of_birth;
        var anniversary_date = this.state.basicFormFields.anniversary_date;

        if (first_name === '' || first_name === null) {
            basicFormFieldsError.isEmptyFirstName = true;
            isReturn = false;
        }
        if (last_name === '' || last_name === null) {
            basicFormFieldsError.isEmptyLastName = true;
            isReturn = false;
        }
        if (country === '' || country === null || country === 0) {
            basicFormFieldsError.isEmptyCountry = true;
            isReturn = false;
        }
        if (gender === '' || gender === null) {
            basicFormFieldsError.isEmptyGender = true;
            isReturn = false;
        }
        if (city === '' || city === null) {
            basicFormFieldsError.isEmptyCity = true;
            isReturn = false;
        }
        if (address === '' || address === null) {
            basicFormFieldsError.isEmptyAddress = true;
            isReturn = false;
        }
        if (date_of_birth === '' || date_of_birth === null || date_of_birth === '0000-00-00') {
            basicFormFieldsError.isEmptyDateOfBitrh = true;
            isReturn = false;
        }
        if (anniversary_date === '' || anniversary_date === null || anniversary_date === '0000-00-00' ) {
            basicFormFieldsError.isEmptyAnniversaryDate = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    updateProfile(){
        let isValidated = this.validateUpdateProfileForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};
        updateProfileJSON.date_of_birth = moment(this.state.basicFormFields.date_of_birth).format("YYYY-MM-DD");
        updateProfileJSON.anniversary_date = moment(this.state.basicFormFields.anniversary_date).format("YYYY-MM-DD");

        fetch(Config.URL+"/update-profile", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.history.push('/account/dashboard');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    setCountry(country){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'country': country }
        }))
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }

    setBirthDate(date){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'date_of_birth': date }
        }));
    }

    setAnniversaryDate(date){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'anniversary_date': date }
        }));
    }

    sendMobileNumberVerification(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let postJSONData = {
            mobile_number : this.state.profile.contact
        }

        fetch(Config.URL+"/send-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.props.history.push({
                    pathname: '/account/change-mobilenumber',
                    itemObj: {
                        mobile_number: this.state.profile.contact,
                        countryCode: this.state.profile.countryCode,
                        verificationAuth: response.OTP_token,
                        pageHeading: "Verify OTP",
                        startTimerLogin: true
                    }
                })
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    sendEmailVerification(){
        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', this.state.profile.email_verify_token);

        let postJSONData = {
            email : this.state.profile.email
        }

        fetch(Config.URL+"/email-verification", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    handleSetAddress(place){
        var address = "", city = "", state = "", zip = "", country = "";
        for (var i = 0; i < place.address_components.length; i++) {
            var addr = place.address_components[i];
            // check if this entry in address_components has a type of country
            if (addr.types[0] == 'country')
                country = addr.long_name;
            else if (addr.types[0] == 'street_address') // address 1
                address = address + addr.long_name;
            else if (addr.types[0] == 'establishment')
                address = address + addr.long_name;
            else if (addr.types[0] == 'route')  // address 2
                address = address + addr.long_name;
            else if (addr.types[0] == 'sublocality_level_1')  // address 2
                address = (address !== '' ? address + ", "+addr.long_name : address + addr.long_name );
            else if (addr.types[0] == 'postal_code')       // Zip
                zip = addr.short_name;
            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                state = addr.long_name;
            else if (addr.types[0] == ['locality'])       // City
                city = addr.long_name;
        }

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'address': place.formatted_address }
        }));

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'city': city }
        }));
    }

    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{`My Profile | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>Edit Profile</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="profile-first-name">First Name</label>
                                <input
                                    id="profile-first-name"
                                    name="first_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your first Name"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.first_name}
                                />
                                {this.state.basicFormFieldsError.isEmptyFirstName ?
                                <span className="errorInput">Please enter first name</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-last-name">Last Name</label>
                                <input
                                    id="profile-last-name"
                                    name="last_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your last Name"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.last_name}
                                />
                                {this.state.basicFormFieldsError.isEmptyLastName ?
                                <span className="errorInput">Please enter last name</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-email">Email Address</label>
                                <input
                                    id="profile-email"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    defaultValue={this.state.basicFormFields.email}
                                />
                                <span className="changeButton"><Link hrefLang={"en"}  to="/account/change-emailaddress">{this.state.profile.email === '' ? "Add" : "Change"}</Link></span>
                                {this.state.profile.is_email_verify == 1 &&
                                <span className="verifyButton">
                                    Verified
                                </span>
                                }
                                {this.state.profile.is_email_verify == 0 &&
                                <span className="unVerifyButton">
                                    Unverified
                                </span>
                                }
                                {this.state.profile.is_email_verify == 0 &&
                                    <span className="verifyMobileNumber">Your email is not verified. <strong><span onClick={() => { this.sendEmailVerification() }}>Click here</span></strong> to send verification.</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-mobile-number">Mobile Number</label>
                                <input
                                    id="profile-mobile-number"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    defaultValue={this.state.basicFormFields.contact}
                                />
                                <span className="changeButton"><Link hrefLang={"en"}  to="/account/change-mobilenumber">{this.state.profile.contact === '' ? "Add" : "Change"}</Link></span>
                                {this.state.profile.is_verify == 1 &&
                                <span className="verifyButton">
                                    Verified
                                </span>
                                }
                                {this.state.profile.is_verify == 0 &&
                                <span className="unVerifyButton">
                                    Unverified
                                </span>
                                }
                                {this.state.profile.is_verify == 0 &&
                                    <span className="verifyMobileNumber">Your mobile number is not verified. <strong><span onClick={() => { this.sendMobileNumberVerification() }}>Click here</span></strong> to verify now.</span>
                                }
                            </div>
                            <div className="form-group">
                            <label htmlFor="profile-email">Country</label>
                                <SelectSearch
                                    options={this.state.countries}
                                    name="country"
                                    search
                                    value={[this.state.basicFormFields.country]}
                                    filterOptions={this.fuzzySearch}
                                    onChange={(event) => { this.setCountry(event) }}
                                    emptyMessage="No matched country found in search criteria"
                                />
                                {this.state.basicFormFieldsError.isEmptyCountry ?
                                <span className="errorInput">Please select country</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-gender">Gender</label>
                            </div>
                            {this.state.basicFormFieldsError.isEmptyGender ?
                                <span className="errorInput">Please select gender</span>
                                : null
                                }
                            <div className="form-group">
                                <span className="filter-list__input input-radio">
                                    <span className="input-radio__body">
                                        <input
                                            id="profile-gender"
                                            className="input-radio__input"
                                            name="gender"
                                            type="radio"
                                            value="male"
                                            onChange={this.handleSetValue}
                                            checked={(this.state.basicFormFields.gender === 'male' ? true : false)}
                                        />
                                        <span className="input-radio__circle" />
                                    </span>
                                </span>
                                <span className="filter-list__title">Male</span> &nbsp;&nbsp;&nbsp;

                                <span className="filter-list__input input-radio">
                                    <span className="input-radio__body">
                                        <input
                                            id="profile-gender"
                                            className="input-radio__input"
                                            name="gender"
                                            type="radio"
                                            value="female"
                                            onChange={this.handleSetValue}
                                            checked={(this.state.basicFormFields.gender === 'female' ? true : false)}
                                        />
                                        <span className="input-radio__circle" />
                                    </span>
                                </span>
                                <span className="filter-list__title">Female</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-address">Address</label>
                                <Autocomplete
                                    onChange={this.handleSetValue}
                                    className="form-control"
                                    apiKey={Config.googleAPIKey}
                                    id="address"
                                    name="address"
                                    onPlaceSelected={(place, inputRef, autocomplete) => {
                                        this.handleSetAddress(place);
                                    }}
                                    placeholder="Please enter your address"
                                    defaultValue={this.state.basicFormFields.address}
                                    options={{
                                        types: ["geocode"],
                                        //componentRestrictions: { country: "in" },
                                    }}
                                />
                                {/* <textarea
                                    rows="5"
                                    id="profile-address"
                                    name="address"
                                    className="form-control"
                                    placeholder="Please enter your address"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.address}
                                /> */}
                                {this.state.basicFormFieldsError.isEmptyAddress ?
                                <span className="errorInput">Please enter address</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-city">City</label>
                                <input
                                    id="profile-city"
                                    name="city"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your city"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.city}
                                />
                                {this.state.basicFormFieldsError.isEmptyCity ?
                                <span className="errorInput">Please enter city</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-address">Birth Date</label>
                                <DatePicker
                                    className="form-control"
                                    maxDate={new Date()}
                                    placeholderText={(this.state.basicFormFields.date_of_birth === '0000-00-00' ? 'Please select your birth date' : '')}
                                    selected={(this.state.basicFormFields.date_of_birth === '0000-00-00' ? '' : this.state.basicFormFields.date_of_birth)}
                                    onChange={(date) => this.setBirthDate(date)}
                                />
                                {this.state.basicFormFieldsError.isEmptyDateOfBitrh ?
                                <span className="errorInput">Please select birth date</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-address">Anniversary Date</label>
                                <DatePicker
                                    className="form-control"
                                    maxDate={new Date()}
                                    placeholderText={(this.state.basicFormFields.anniversary_date === '0000-00-00' ? 'Please select your anniversary date' : '')}
                                    selected={(this.state.basicFormFields.anniversary_date === '0000-00-00' ? '' : this.state.basicFormFields.anniversary_date)}
                                    onChange={(date) => this.setAnniversaryDate(date)}
                                />
                                {this.state.basicFormFieldsError.isEmptyAnniversaryDate ?
                                <span className="errorInput">Please select anniversary date</span>
                                : null
                                }
                            </div>
                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageProfile;