// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { toast } from 'react-toastify';
import Shake from 'react-reveal/Shake';
import { Modal } from 'reactstrap';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg, Cross12Svg, Cross20Svg, LogoSmallSvg, LogoSvg, LogoCardSvg } from '../../svg';
import BlockLoader from '../blocks/BlockLoader';

// data stubs
//import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';
import { checkOutItems } from '../../store/cart';
import { Config } from '../../config';

class ShopPageCheckout extends Component {
    //payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            payment: '',
            isLoading: false,
            isBlockLoading: false,
            isGiftCardLoading: false,
            isGiftCardModel: false,
            stores: [],
            address: [],
            addresses: [],
            gift_cards: [],
            shipping_id: '',
            collection_date: '',
            selected_collection_date: '',
            is_collection: false,
            collection_store: '',
            is_gift: false,
            gift_card_number: '',
            receiving_person_full_name: '',
            message_for_receiving_person: '',
            isEmptyStore: false,
            isEmptyCollectionDate: false,
            isEmptyReceivingPerson: false,
            isEmptyReceivingPersonMessage: false,
            isEmptyShippingAddress: false,
            isEmptyPaymentMethod: false,
            amountPayable: 0,
            termsAndConditionsAgreed: false,

            isEmptyCollaboratorCode: false,
            isEmptyGiftCardNumber: false,
            isEmptyOTP: false,
            isEmptyGiftCardAmount: false,
            showGiftCardForm: true,
            purchase_gift_card_amount: '',
            isEmptyPurchaseGiftCardAmount: false,
            showOTPForm: false,
            showAmountForm: false,
            OTPVerifyAuth: '',
            OTP: '',
            showShippingAddressModel: false,
            paymentMethods: []
        };
    }

    async componentDidMount() {
        this.props.checkOutItems(false, this.state.is_collection);
        this.getShippingAddresses();
        this.getStores();
        this.getAllPaymentMethods();


        let { cart } = this.props;
        if(cart.checkout_items.status !== undefined && cart.checkout_items.status){
            var checkout_items = [];
            cart.checkout_items.data.map((itemObj, itemCheckoutIndex) => {
                if(itemObj.product_details !== ''){
                    if(itemObj.gift_card_id > 0){
                    let product_data = itemObj.product_details;
                    product_data.final_item_code = itemObj.product_details.id;
                    product_data.full_name = itemObj.product_details.card_no;
                    product_data.category_name = '';
                    product_data.brand_name = '';
                    product_data.color_name = '';
                    product_data.selling_price = itemObj.product_details.balance;
                    product_data.discount_price = itemObj.product_details.discounted_amount;
                    product_data.size_name = '';
                    product_data.gift_card_id = itemObj.gift_card_id;
                    checkout_items.push(product_data);
                } else {
                    itemObj.product_details.gift_card_id = 0;
                    checkout_items.push(itemObj.product_details);
                }
                if(itemCheckoutIndex === cart.checkout_items.data.length -1){
                    shopApi.g4CheckoutEventPush("begin_checkout", checkout_items, cart.checkout_items.amounts);
                }
            }
            });
        }

    }

    getAllPaymentMethods(){
        this.setState({ isBlockLoading: true });
        shopApi.getPaymentMethods().then((response) => {
            //console.log("response", response);
            if(response.status){
                if(response.status === 2){
                    this.setState({ isBlockLoading: false, paymentMethods: [] });
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ paymentMethods: response.data, isBlockLoading: false, payment: response.data[0].alias });
                }
            } else {
                this.setState({ isBlockLoading: false, paymentMethods: [] });
            }
        });
    }

    getShippingAddresses(){
        this.setState({ isBlockLoading: true });
        shopApi.getAddresses(1).then((response) => {
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    response.data.forEach((address, key) => {
                        if(address.in_default){
                            var selectedAddress = [];
                            selectedAddress.push(address);
                            this.setState({ address: selectedAddress, shipping_id: address.id });
                            this.props.checkOutItems(address.id, this.state.is_collection);
                        }
                        if(response.data.length -1 == key){
                            if(this.state.address.length == 0){
                                var selectedAddress = [];
                                selectedAddress.push(address);
                                this.setState({ address: selectedAddress, shipping_id: address.id });
                                this.props.checkOutItems(address.id, this.state.is_collection);
                            }
                            this.setState({ isBlockLoading: false });
                        }
                    });
                    if(response.data.length == 0){
                        this.setState({ isBlockLoading: false });
                    }
                    this.setState({ addresses: response.data });
                }
            } else {
                this.setState({ isBlockLoading: false });
            }
        });
    }

    getMyGiftCards(){
        this.setState({ isGiftCardLoading: true });
        shopApi.getGiftCards(50, 'verify', 1).then((response) => {
            this.setState({ isGiftCardLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ gift_cards: response.data });
                }
            }
        });
    }

    getStores(){
        shopApi.getStores().then((response) => {
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ stores: response.data });
                }
            }
        });
    }

    handleSetIsCollection = (event) => {
        if (event.target.checked) {
            this.setState({ is_collection: true });
            this.props.checkOutItems(this.state.shipping_id, true);
        } else {
            this.setState({ is_collection: false });
            this.props.checkOutItems(this.state.shipping_id, false);
        }
    };

    handleSetIsGift = (event) => {
        if (event.target.checked) {
            this.setState({ is_gift: true });
        } else {
            this.setState({ is_gift: false });
        }
    };

    handleSetTermsAgreed = (event) => {
        if (event.target.checked) {
            this.setState({ termsAndConditionsAgreed: true });
        } else {
            this.setState({ termsAndConditionsAgreed: false });
        }
    };

    handleSetValue = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    setCollectionDate(date){
        this.setState({ collection_date: date });
        this.setState({ selected_collection_date: moment(date).format("YYYY-MM-DD") });
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value, isEmptyPaymentMethod: false });
        }
    };

    handleAddressChange = (event, address) => {
        if (event.target.checked) {
            this.setState({ shipping_id: event.target.value });
            var selectedAddress = [];
            selectedAddress.push(address);
            this.setState({ address: selectedAddress, showShippingAddressModel: false });
            this.props.checkOutItems(event.target.value, this.state.is_collection);
        }
    };

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        if (this.state.isBlockLoading) {
            return <BlockLoader />;
        }

        // const extraLines = cart.extraLines.map((extraLine, index) => (
        //     <tr key={index}>
        //         <th>{extraLine.title}</th>
        //         <td><Currency value={extraLine.price} /></td>
        //     </tr>
        // ));
        const extraLines = cart.extraLines.map((extraLine, index) => {
            let calcShippingLink;
            let giftCard;
            let couponCode;

            if(extraLine.price > 0){
                if (extraLine.type === 'shipping') {
                    calcShippingLink = <div className="cart__calc-shipping"><Link hrefLang={"en"}  to="/">Calculate Shipping</Link></div>;
                }
                // if (extraLine.type === "pay_by_gift_card" && extraLine.price > 0) {
                //     giftCard = <div className="cart__calc-shipping">{cart.checkout_items.amounts.cart_pay_by_gift_card_no} <span title="Remove Gift Card" onClick={() => { this.removeGiftCard(cart.checkout_items.amounts.cart_pay_by_gift_card_id) }} className="remove-gift-card">X</span></div>;
                // }
                // if (extraLine.type === "pay_by_coupon_code" && extraLine.price > 0) {
                //     couponCode = <div className="cart__calc-shipping">{cart.checkout_items.data[0].discount_code} <span title="Remove Collaborator Code" onClick={() => { this.removeCollaboratorCode(cart.checkout_items.data[0].discount_code) }} className="remove-gift-card">X</span></div>;
                // }

                return (
                    <tr key={index}>
                        <th>
                            {extraLine.title}
                            {giftCard}
                            {couponCode}
                        </th>
                        <td>
                            <Currency value={extraLine.price} />
                            {calcShippingLink}
                        </td>
                    </tr>
                );
            }
        });

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    {/* <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr> */}
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;

        if (cart.checkout_items.status == undefined) {
            return <BlockLoader />;
        }

        if(cart.checkout_items.status !== undefined && cart.checkout_items.status){
            const items = cart.checkout_items.data.map((item) => {
                let image;
                let options;
                item.product = item.product_details;

                if(item.product_details !== ''){

                    if(item.gift_card_id > 0){

                        image = (
                            <div key={item.id} className="product-image">
                                <span className="product-image__body">
                                    <img className="product-image__img" src={item.product.image} alt="Gift Card" title="Gift Card" />
                                </span>
                            </div>
                        );

                        return (
                            <tr key={item.id} className="cart-table__row">
                                <td className="cart-table__column cart-table__column--image">
                                    {image}
                                </td>
                                <td className="cart-table__column cart-table__column--product">
                                    <span className="cart-table__product-name">
                                        Gift Card
                                    </span>
                                    <ul className="cart-table__options">
                                        <li>{item.product.card_no}</li>
                                    </ul>
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Price">
                                    <Currency value={item.product.balance} />
                                </td>
                                <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                    <span style={{fontSize: 12}} className="badge badge-success">{item.qty}</span>
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Discount">
                                    <span>0</span>
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                    <Currency value={item.product.balance} />
                                </td>
                            </tr>
                        );

                    } else {

                        if (item.product.product_main_image !== '') {
                            image = (
                                <div key={item.id} className="product-image">
                                    <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="product-image__body">
                                        <img className="product-image__img" src={item.product.product_main_image} alt={item.product.full_name} title={item.product.full_name} />
                                    </Link>
                                </div>
                            );
                        }

                        options = (
                            <ul key={item.id} className="cart-table__options">
                                {item.product.brand_name && <li>Brand : {(item.product.brand_name).toLowerCase()}</li>}
                                {item.product.color_name && <li>Color : {(item.product.color_name).toLowerCase()}</li>}
                                {item.product.size_name && <li>Size : {(item.product.size_name).toLowerCase()}</li>}
                                {item.product.final_item_code && <li>SKU : {(item.product.final_item_code)}</li>}
                            </ul>
                        );

                        return (
                            <tr key={item.id} className="cart-table__row">
                                <td className="cart-table__column cart-table__column--image">
                                    {image}
                                </td>
                                <td className="cart-table__column cart-table__column--product">
                                    <Link hrefLang={"en"}  to={url.product_detail(item.product)} className="cart-table__product-name">
                                        {(item.product.full_name).toLowerCase()}
                                    </Link>
                                    {options}
                                </td>
                                <td className="cart-table__column cart-table__column--price" data-title="Price">
                                    <Currency value={item.product.selling_price} />
                                </td>
                                <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                    <span style={{fontSize: 12}} className="badge badge-success">{item.qty}</span>
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Discount">
                                    <Currency value={item.product.discounted_amount} />
                                </td>
                                <td className="cart-table__column cart-table__column--total" data-title="Total">
                                        <Currency value={item.product.sub_total} />
                                </td>
                            </tr>
                        );
                    }

                }
            });

            return (
                <table className="cart__table cart-table">
                    <thead className="cart-table__head">
                        <tr className="cart-table__row">
                            <th className="cart-table__column cart-table__column--image">Image</th>
                            <th className="cart-table__column cart-table__column--product">Product</th>
                            <th className="cart-table__column cart-table__column--price">Unit Price</th>
                            <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                            <th className="cart-table__column cart-table__column--quantity">Discount</th>
                            <th className="cart-table__column cart-table__column--total">Total</th>
                        </tr>
                    </thead>
                    <tbody className="cart-table__body">
                        {items}
                    </tbody>
                </table>
            );
        }
    }

    renderCartTotals() {
        const { cart } = this.props;

        if(cart.checkout_items.status !== undefined && cart.checkout_items.status){
            return (
                <table className="checkout__totals">
                    {this.renderTotals()}
                </table>
            );
        }
    }

    renderPaymentsList() {
        if (this.state.isBlockLoading) {
            return <BlockLoader />;
        }

        const { payment: currentPayment } = this.state;

        const { cart } = this.props;

        var amountPayable = 0;
        cart.extraLines.map((extraLine, index) => {
            if(extraLine.type == 'amount_payable'){
                amountPayable  = extraLine.price;
            }
        });

        const payments = this.state.paymentMethods.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li key={payment.key} className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.alias}
                                    checked={currentPayment === payment.alias}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.name}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.notes}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.alias}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        if(amountPayable == 0){
            return null
        }

        return (
            <div className="payment-methods">
                <h3 className="card-title">Select payment method</h3>
                {this.state.isEmptyPaymentMethod ?
                    <span className="errorInput">Please select payment method to complete your purchase</span>
                    : null
                }
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    renderShippingAddress(){
        let address = 'No shipping address found! Please add/select your shipping address.';
        if(this.state.address.length > 0){
            address = this.state.address.map((address) => (
                    <React.Fragment key={address.id}>
                        <div className="addresses-list__item card address-card" style={{maxWidth: '100%'}}>
                            {address.in_default === 1 && <div className="address-card__badge">Default</div>}

                            <div className="address-card__body">

                                {/* <span className="payment-methods__item-radio input-radio">
                                    <span className="input-radio__body">
                                        <input
                                            type="radio"
                                            className="input-radio__input"
                                            name="shipping_id"
                                            id={'shipping_id_'+address.id}
                                            value={address.id}
                                            checked={(this.state.shipping_id == address.id ? true : false)}
                                            onChange={this.handleAddressChange}
                                        />
                                        <span className="input-radio__circle" />
                                    </span>
                                </span> */}

                                <span htmlFor={'shipping_id_'+address.id} className="typeOfAddress">{address.type_of_address}</span>
                                <div htmlFor={'shipping_id_'+address.id} className="address-card__name">{`${address.first_name} ${address.last_name}`}</div>
                                <div className="address-card__row">
                                    {address.country_name}
                                    <br />
                                    {address.postcode}
                                    ,&nbsp;
                                    {address.city}
                                    <br />
                                    {address.address}
                                    <br />
                                    {address.address_2}
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    <div className="address-card__row-content">{address.contact}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">{address.email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="addresses-list__divider" />
                    </React.Fragment>
            ));
        }

        return address;
    }

    renderAllShippingAddresses(){
        let addresses = (<Link hrefLang={"en"}  to="/account/address/add">Add New Shipping Address</Link>);
        if(this.state.addresses.length > 0){
            addresses = this.state.addresses.map((address) => (
                    <React.Fragment key={address.id}>
                        <div className="addresses-list__item card address-card" style={{maxWidth: '100%'}}>
                            {address.in_default === 1 && <div className="address-card__badge">Default</div>}

                            <div className="address-card__body">

                                <span className="payment-methods__item-radio input-radio">
                                    <span className="input-radio__body">
                                        <input
                                            type="radio"
                                            className="input-radio__input"
                                            name="shipping_id"
                                            id={'shipping_id_'+address.id}
                                            value={address.id}
                                            checked={(this.state.shipping_id == address.id ? true : false)}
                                            onChange={(event) => { this.handleAddressChange(event, address) }}
                                        />
                                        <span className="input-radio__circle" />
                                    </span>
                                </span>

                                <span htmlFor={'shipping_id_'+address.id} className="typeOfAddress">{address.type_of_address}</span>
                                <div htmlFor={'shipping_id_'+address.id} className="address-card__name">{`${address.first_name} ${address.last_name}`}</div>
                                <div className="address-card__row">
                                    {address.country_name}
                                    <br />
                                    {address.postcode}
                                    ,&nbsp;
                                    {address.city}
                                    <br />
                                    {address.address}
                                    <br />
                                    {address.address_2}
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    <div className="address-card__row-content">{address.contact}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">{address.email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="addresses-list__divider" />
                    </React.Fragment>
            ));
        }

        return addresses;
    }

    renderGiftCards(){
        if (this.state.isGiftCardLoading) {
            return (
                <tr className="cart-table__row">
                    <td colSpan="3">
                        <BlockLoader />
                    </td>
                </tr>
            )
        }
        let gift_cards = '';
        if(this.state.gift_cards.length > 0){
            gift_cards = this.state.gift_cards.map((gift_card) => (
                <React.Fragment key={gift_card.id}>
                    <tr className="cart-table__row">
                        <td style={{width: '100%', textAlign: 'left', fontSize: 14, paddingTop: 10}}><span style={{marginLeft: 10}}>{gift_card.card_no}</span></td>
                        <td style={{textAlign: 'right', fontSize: 14, paddingTop: 10}}><Currency value={gift_card.balance} /></td>
                        <td style={{float: 'right', padding: 10, paddingRight: 0}}>
                            <span onClick={() => { this.useGiftCard(gift_card.card_no) }} style={{paddingRight: 15, paddingLeft: 15, paddingTop: 5, paddingBottom: 5, fontSize: 16, borderRadius: 5}} className="btn-danger">Use</span>
                        </td>
                    </tr>
                </React.Fragment>
            ));
        } else {
            return (
                <tr className="cart-table__row">
                    <td colSpan="3">
                        <span style={{width: '100%', float: 'left', marginTop: 10}} className="text-center">Your gift card list is empty!</span>
                    </td>
                </tr>
            )
        }
        return gift_cards;
    }

    validatePlaceOrder(amountPayable){
        var isValid = true;
        this.setState({ isEmptyStore: false });
        this.setState({ isEmptyCollectionDate: false });
        this.setState({ isEmptyReceivingPerson: false });
        this.setState({ isEmptyReceivingPersonMessage: false });
        this.setState({ isEmptyShippingAddress: false });
        this.setState({ isEmptyPaymentMethod: false });
        if(this.state.is_collection){
            if(this.state.collection_store == ''){
                this.setState({ isEmptyStore: true });
                isValid = false;
            }
            if(this.state.collection_date == ''){
                this.setState({ isEmptyCollectionDate: true });
                isValid = false;
            }
        }
        if(this.state.is_gift){
            if(this.state.receiving_person_full_name == ''){
                this.setState({ isEmptyReceivingPerson: true });
                isValid = false;
            }
            if(this.state.message_for_receiving_person == ''){
                this.setState({ isEmptyReceivingPersonMessage: true });
                isValid = false;
            }
        }
        if(this.state.shipping_id == ''){
            this.setState({ isEmptyShippingAddress: true });
            isValid = false;
        }

        if(amountPayable > 0){
            if(this.state.payment == ''){
                this.setState({ isEmptyPaymentMethod: true });
                isValid = false;
            }
        }

        return isValid;
    }

    placeOrder(){
        const { cart } = this.props;

        const Msg = (props) => {
        return (
            <>
            {props.message}
            <br />
            <Link hrefLang={"en"}  style={{color: "#fff", fontWeight: 'bold', fontSize: 16}} to="/account/profile">Verify Now!</Link>
            </>
        );
        };

        var amountPayable = 0;
        cart.extraLines.map((extraLine, index) => {
            if(extraLine.type == 'amount_payable'){
                amountPayable  = extraLine.price;
            }
        });

        var validatePlaceOrder = this.validatePlaceOrder(amountPayable);
        if(!validatePlaceOrder){
            return;
        }

        // this.props.history.push({ pathname: "/order-process", payment_params: '', payment_method: this.state.payment});
        // return;
        this.setState({ isLoading: true });
        let placeOrderJSON = {
            "shipping_id": this.state.shipping_id,
            "payment_method": (amountPayable > 0 ? this.state.payment : '') ,
            "is_collection": (this.state.is_collection ? 1 : 0),
            "collection_store": (this.state.collection_store ? this.state.collection_store : 0),
            "collection_date": this.state.selected_collection_date,
            "is_gift": (this.state.is_gift ? 1 : 0),
            "receiving_person_full_name": this.state.receiving_person_full_name,
            "message_for_receiving_person": this.state.message_for_receiving_person
        }
        shopApi.placeOrder(placeOrderJSON).then((response) => {
            //console.log("placeOrder response: ", response)
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    if(response.status == 4){
                        toast.error(<Msg message={response.message} />);
                    } else {
                        if(response.status == 3){
                            toast.success(response.message);
                            this.props.history.push({ pathname: "/order-status/"+response.data });
                        } else {
                            toast.success(response.message);
                            this.props.history.push({ pathname: "/order-process", payment_params: response.data, payment_method: this.state.payment});
                        }
                    }
                }
            } else {
                toast.error(response.message);
            }
        });

    }

    applyGiftCardNumber(){
        if(!this.state.gift_card_number || this.state.gift_card_number == ''){
            this.setState({ isEmptyGiftCardNumber: true });
        } else {
            this.setState({ isBlockLoading: true });
            shopApi.applyGiftCard(this.state.gift_card_number).then((response) => {
                //console.log("applyGiftCardNumber response", response);
                this.setState({ isBlockLoading: false });
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        this.setState({ showOTPForm: true, OTPVerifyAuth: response.OTP_token });
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    verifyGiftCardOTP(){
        if(!this.state.OTP || this.state.OTP == ''){
            this.setState({ isEmptyOTP: true });
        } else {
            this.setState({ isBlockLoading: true });
            shopApi.verifyGiftCardOTP(this.state.OTP, this.state.OTPVerifyAuth).then((response) => {
                this.setState({ isBlockLoading: false });
                //console.log("verifyGiftCardOTP response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        this.setState({ showAmountForm: true });
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    applyGiftCardAmount(){
        if(!this.state.gift_card_amount || this.state.gift_card_amount == ''){
            this.setState({ isEmptyGiftCardAmount: true });
        } else {
            if(this.state.gift_card_amount <= 0){
                this.setState({ isEmptyGiftCardAmount: true });
            } else {
                this.setState({ isBlockLoading: true });
                shopApi.applyGiftCardAmount(this.state.gift_card_number, this.state.gift_card_amount).then(async (response) => {
                    this.setState({ isBlockLoading: false });
                    //console.log("applyGiftCardAmount response", response);
                    if(response.status){
                        if(response.status == 2){
                            localStorage.removeItem(Config.x_token);
                            localStorage.removeItem(Config.web_user);
                            toast.error(response.message);
                            this.props.history.push("/");
                        } else {
                            this.setState({ showOTPForm: false, showAmountForm: false, showGiftCardForm: false });
                            toast.success(response.message);
                            //this.props.cartRefresh();
                            this.props.checkOutItems(this.state.shipping_id, this.state.is_collection);
                        }
                    } else {
                        toast.error(response.message);
                    }
                });
            }
        }
    }

    removeGiftCard(gift_card_id){
        this.setState({ isBlockLoading: true });
        shopApi.removeGiftCardAmount(gift_card_id).then(async (response) => {
            this.setState({ isBlockLoading: false });
            //console.log("removeGiftCard response", response);
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.setState({ showGiftCardForm: true });
                    //this.props.cartRefresh();
                    this.props.checkOutItems(this.state.shipping_id, this.state.is_collection);
                }
            } else {
                toast.error(response.message);
            }
        });
    }

    applyCollaboratorCode(){
        if(!this.state.collaborator_code || this.state.collaborator_code == ''){
            this.setState({ isEmptyCollaboratorCode: true });
        } else {
            this.setState({ isEmptyCollaboratorCode: false, isBlockLoading: true });
            shopApi.applyCollaboratorCode(this.state.collaborator_code).then((response) => {
                this.setState({ isBlockLoading: false });
                //console.log("applyCollaboratorCode response", response);
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        toast.success(response.message);
                        //this.props.cartRefresh();
                        this.props.checkOutItems(this.state.shipping_id, this.state.is_collection);
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    removeCollaboratorCode(collaborator_code){
        this.setState({ isBlockLoading: true });
        shopApi.removeCollaboratorCode(collaborator_code).then((response) => {
            this.setState({ isBlockLoading: false });
            //console.log("removeCollaboratorCode response", response);
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    //this.props.cartRefresh();
                    this.props.checkOutItems(this.state.shipping_id, this.state.is_collection);
                }
            } else {
                toast.error(response.message);
            }
        });
    }

    selectShippingAddress(){
        this.setState({ showShippingAddressModel: true })
    }

    hideShippingAddressModel(){
        this.setState({ showShippingAddressModel: false });
    }

    hideGiftCardModal(){
        this.setState({ isGiftCardModel: false });
    }

    showGiftCardModal(){
        this.setState({ isGiftCardModel: true });
        this.getMyGiftCards();
    }

    useGiftCard(gift_card_number){
        this.setState({ gift_card_number });
        this.hideGiftCardModal();
    }

    render() {
        const { cart } = this.props;

        if(cart.quantity < 1){
            return <Redirect to="/" />;
        }

        if(cart.checkout_items.status !== undefined && cart.checkout_items.status){
            if(cart.checkout_items.status === 0){
                return <Redirect to="/" />;
            }
            if(cart.checkout_items.status === 2){
                return <Redirect to="/" />;
            }
        }

        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shopping Cart', url: '/cart' },
            { title: 'Checkout', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout | ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {this.state.isGiftCardModel &&
                    <Modal isOpen={this.state.isGiftCardModel} centered size="l">
                        <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                            <button className="quickview__close" type="button" onClick={() => { this.hideGiftCardModal() }}>
                                <Cross20Svg />
                            </button>
                            <h5>Please Use Your Gift Card!</h5>
                            <table className="mt-3 cart__table cart-table">
                                <thead className="cart-table__head">
                                    <tr className="cart-table__row">
                                        <th className="cart-table__column cart-table__column--product">Virtual Card Number</th>
                                        <th className="cart-table__column cart-table__column--price">Balance</th>
                                        <th className="cart-table__column cart-table__column--quantity">#</th>
                                    </tr>
                                </thead>
                                <tbody className="cart-table__body">
                                    {this.renderGiftCards()}
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                }

                {this.state.showShippingAddressModel &&
                    <Modal isOpen={this.state.showShippingAddressModel} centered size="l">
                        <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                            <button className="quickview__close" type="button" onClick={() => { this.hideShippingAddressModel() }}>
                                <Cross20Svg />
                            </button>

                            <h5>Please select your shipping address!</h5>
                            {this.renderAllShippingAddresses()}
                        </div>
                    </Modal>
                }

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Returning customer?
                                    {' '}
                                    <Link hrefLang={"en"}  to="/account/login">Click here to login</Link>
                                </div>
                            </div> */}
                            <div className="col-12 col-lg-12 col-xl-12">
                                {this.renderCart()}
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-6">
                                <div className="card mb-lg-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Select Your Shipping Address</h3>

                                        <div className="shipping-address-render">
                                            <p style={{float: 'right', width: '100%', textAlign: 'right'}}><Link hrefLang={"en"}  to="/account/address/add">Add A New Shipping Address?</Link></p>
                                            <p onClick={() => { this.selectShippingAddress() }} style={{float: 'left', width: '100%', textAlign: 'left'}}><span style={{color: '#1a66ff', cursor: 'pointer'}}>Change Shipping Address?</span></p>
                                            {this.renderShippingAddress()}
                                        </div>

                                        {/* <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="First Name"
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Last Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="checkout-company-name">
                                                Company Name
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-company-name"
                                                placeholder="Company Name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-country">Country</label>
                                            <select id="checkout-country" className="form-control">
                                                <option>Select a country...</option>
                                                <option>United States</option>
                                                <option>Russia</option>
                                                <option>Italy</option>
                                                <option>France</option>
                                                <option>Ukraine</option>
                                                <option>Germany</option>
                                                <option>Australia</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-address">
                                                Apartment, suite, unit etc.
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <input type="text" className="form-control" id="checkout-address" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" id="checkout-city" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-state">State / County</label>
                                            <input type="text" className="form-control" id="checkout-state" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                            <input type="text" className="form-control" id="checkout-postcode" />
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-email">Email address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="checkout-email"
                                                    placeholder="Email address"
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text" className="form-control" id="checkout-phone" placeholder="Phone" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-create-account" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-create-account">
                                                    Create an account?
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="card-divider" />
                                    <div className="card-body">
                                        <h3 className="card-title">Other Details</h3>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input onChange={this.handleSetIsCollection} className="input-check__input" type="checkbox" id="checkout-collection" name="is_collection" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-collection">
                                                    Collection
                                                </label>
                                            </div>
                                        </div>
                                        {this.state.is_collection &&
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <select onChange={this.handleSetValue} id="suit-maintennance-store-id" name="collection_store" className="form-control form-control-select2">
                                                    <option value="">Select Store</option>
                                                    {this.state.stores.length > 0 ? (
                                                        this.state.stores.map((store) => {
                                                            return(
                                                                <option value={store.id}>{store.name}</option>
                                                            )
                                                        })
                                                    ) : (
                                                        <option value="">Store list is empty</option>
                                                    )}
                                                </select>
                                                {this.state.isEmptyStore ?
                                                    <span className="errorInput">Please select store</span>
                                                    : null
                                                }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <DatePicker
                                                    className="form-control"
                                                    id="suit-maintennance-purchase-date"
                                                    minDate={new Date(moment().add(4, 'days'))}
                                                    placeholderText="Select Collection Date"
                                                    selected={this.state.collection_date}
                                                    onChange={(date) => this.setCollectionDate(date)}
                                                />
                                                {this.state.isEmptyCollectionDate ?
                                                    <span className="errorInput">Please choose collection date</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        }

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input onChange={this.handleSetIsGift} className="input-check__input" type="checkbox" id="checkout-is_gift" name="is_gift" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-is_gift">
                                                    This is a Gift
                                                </label>
                                            </div>
                                        </div>
                                        {this.state.is_gift &&
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-_full_name">Receiving Person Fullname</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-_full_name"
                                                    name="receiving_person_full_name"
                                                    onChange={this.handleSetValue}
                                                />
                                                {this.state.isEmptyReceivingPerson ?
                                                    <span className="errorInput">Please enter receiving person full name</span>
                                                    : null
                                                }
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-message_for_receiving_person">Add Message for Receiving Person</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-message_for_receiving_person"
                                                    name="message_for_receiving_person"
                                                    onChange={this.handleSetValue}
                                                />
                                                {this.state.isEmptyReceivingPersonMessage ?
                                                    <span className="errorInput">Please enter message for receiving person</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        }

                                        {/* <div className="form-group">
                                            <label htmlFor="checkout-comment">
                                                Order notes
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <textarea id="checkout-comment" className="form-control" rows="4" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-6 mt-6 mt-lg-0">
                                <div className="card mb-0">

                                {(cart.checkout_items.status !== undefined && cart.checkout_items.status) &&
                                    <>
                                        <div className="card-body" style={{paddingBottom: 0}}>
                                        <h3 className="card-title">Gift Card & Collaborator Code</h3>
                                        {this.state.shipping_id == '' &&
                                            <p>In order to use/manage <b style={{color: '#FF0000'}}>Gift Card</b> or <b style={{color: '#FF0000'}}>Collaborator Code</b> please select your shipping address first.</p>
                                        }

                                        {(cart.checkout_items.amounts.cart_pay_by_gift_card_id != 0 && cart.checkout_items.amounts.cart_pay_by_gift_card_id != null) &&
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-xl-12 mb-2 mt-lg-0">
                                                        <div>Gift Card</div>
                                                        <div style={{backgroundColor: '#d3d3d3', width: 'auto', float: 'left', borderWidth: 2, borderColor: '#ff0000', borderStyle: 'dashed', paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2, borderRadius: 5}}>
                                                            <div className="cart__calc-shipping" style={{color: '#000'}}>
                                                                {cart.checkout_items.amounts.cart_pay_by_gift_card_no} <span title="Remove Gift Card" onClick={() => { this.removeGiftCard(cart.checkout_items.amounts.cart_pay_by_gift_card_id) }} className="remove-gift-card">X</span>
                                                            </div>
                                                        </div>
                                                        <div>&nbsp;</div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {(cart.checkout_items.data[0].discount_code != null && cart.checkout_items.data[0].discount_code != '') &&
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-xl-12 mt-5 mb-3 mt-lg-0">
                                                        <div>Collaborator Code</div>
                                                        <div style={{backgroundColor: '#d3d3d3', width: 'auto', float: 'left', borderWidth: 2, borderColor: '#ff0000', borderStyle: 'dashed', paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2, borderRadius: 5}}>
                                                            <div className="cart__calc-shipping" style={{color: '#000'}}>
                                                                {cart.checkout_items.data[0].discount_code} <span title="Remove Collaborator Code" onClick={() => { this.removeCollaboratorCode(cart.checkout_items.data[0].discount_code) }} className="remove-gift-card">X</span>
                                                            </div>
                                                        </div>
                                                        <div>&nbsp;</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {((cart.checkout_items.amounts.cart_pay_by_gift_card_id == 0 || cart.checkout_items.amounts.cart_pay_by_gift_card_id == null) && this.state.shipping_id !== '') &&
                                            <>
                                            {this.state.showGiftCardForm &&
                                                <>
                                                    <form className="cart__coupon-form">
                                                        {this.state.isEmptyGiftCardNumber &&
                                                            <Shake>
                                                                <input type="text" value={this.state.gift_card_number} onChange={this.handleSetValue} className="form-control is-invalid" id="gift_card_number" name="gift_card_number" placeholder="Enter Virtual Gift Card Number" />
                                                            </Shake>
                                                        }
                                                        {!this.state.isEmptyGiftCardNumber &&
                                                            <input type="text" value={this.state.gift_card_number} disabled={this.state.showOTPForm ? true : false} onChange={this.handleSetValue} className="form-control" id="gift_card_number" name="gift_card_number" placeholder="Enter Virtual Gift Card Number" />
                                                        }
                                                        <button type="button" disabled={this.state.showOTPForm ? true : false} onClick={() => { this.applyGiftCardNumber() }} className="btn btn-primary">Apply</button>
                                                        <button type="button" onClick={() => { this.showGiftCardModal() }} className="btn btn-primary"> <i className="fa fa-gift"/>&nbsp;View</button>
                                                    </form>
                                                    <span className="text-success" style={{fontSize: 12}}>Enter gift card number or use your purchased gift cards by clicking <b>View</b> button!</span>
                                                    <div>&nbsp;</div>
                                                </>
                                            }
                                            {this.state.showOTPForm &&
                                                <>
                                                    <form className="cart__coupon-form">
                                                        {this.state.isEmptyOTP &&
                                                            <Shake>
                                                                <input type="text" onChange={this.handleSetValue} className="form-control is-invalid" id="OTP" name="OTP" placeholder="Enter OTP" />
                                                            </Shake>
                                                        }
                                                        {!this.state.isEmptyOTP &&
                                                            <input type="text" disabled={this.state.showAmountForm ? true : false} onChange={this.handleSetValue} className="form-control" id="OTP" name="OTP" placeholder="Enter OTP" />
                                                        }
                                                        <button type="button" disabled={this.state.showAmountForm ? true : false} onClick={() => { this.verifyGiftCardOTP() }} className="btn btn-primary">Apply</button>
                                                    </form>
                                                    <div>&nbsp;</div>
                                                </>
                                            }
                                            {this.state.showAmountForm &&
                                                <>
                                                    <form className="cart__coupon-form">
                                                        {this.state.isEmptyGiftCardAmount &&
                                                            <Shake>
                                                                <input type="text" onChange={this.handleSetValue} className="form-control is-invalid" id="gift_card_amount" name="gift_card_amount" placeholder="Enter Amount" />
                                                            </Shake>
                                                        }
                                                        {!this.state.isEmptyGiftCardAmount &&
                                                            <input type="text" onChange={this.handleSetValue} className="form-control" id="gift_card_amount" name="gift_card_amount" placeholder="Enter Amount" />
                                                        }
                                                        <button type="button" onClick={() => { this.applyGiftCardAmount() }} className="btn btn-primary">Apply</button>
                                                    </form>
                                                    <div>&nbsp;</div>
                                                </>
                                            }
                                            </>
                                        }
                                        {((cart.checkout_items.data[0].discount_value == 0 || cart.checkout_items.data[0].discount_value == null) && this.state.shipping_id !== '') &&
                                            <>
                                                <form className="cart__coupon-form">
                                                    {this.state.isEmptyCollaboratorCode &&
                                                        <Shake>
                                                            <input type="text" onChange={this.handleSetValue} className="form-control is-invalid" id="collaborator_code" name="collaborator_code" placeholder="Collaborator Code" />
                                                        </Shake>
                                                    }
                                                    {!this.state.isEmptyCollaboratorCode &&
                                                        <input type="text" onChange={this.handleSetValue} className="form-control" id="collaborator_code" name="collaborator_code" placeholder="Collaborator Code" />
                                                    }
                                                    <button type="button" onClick={() => { this.applyCollaboratorCode() }} className="btn btn-primary">Apply</button>
                                                </form>
                                                <div>&nbsp;</div>
                                            </>
                                        }
                                        </div>
                                    </>
                                }




                                    <div className="card-body" style={{paddingTop: 10}}>
                                        <h3 className="card-title">Your Order Total</h3>

                                        {this.renderCartTotals()}

                                        {this.renderPaymentsList()}

                                        {!this.state.isBlockLoading &&
                                            <>
                                                <div className="checkout__agree form-group">
                                                    <div className="form-check">
                                                        <span className="form-check-input input-check">
                                                            <span className="input-check__body">
                                                                <input onChange={this.handleSetTermsAgreed} checked={this.state.termsAndConditionsAgreed} className="input-check__input" type="checkbox" id="checkout-terms" name="checkout_terms" />
                                                                <span className="input-check__box" />
                                                                <Check9x7Svg className="input-check__icon" />
                                                            </span>
                                                        </span>
                                                        <label className="form-check-label" htmlFor="checkout-terms">
                                                            I have read and agree to the website&nbsp;
                                                            <Link hrefLang={"en"}  to="/terms-conditions">Terms &amp; Conditions</Link>
                                                        </label>
                                                    </div>
                                                </div>

                                                {this.state.isEmptyShippingAddress ?
                                                    <>
                                                        <span className="errorInput">Please select your shipping address first in order to continue.</span><div>&nbsp;</div>
                                                    </>
                                                    : null
                                                }

                                                <button type="button" disabled={(!this.state.termsAndConditionsAgreed ? true : false)} onClick={() => { this.placeOrder() }} className="btn btn-primary btn-xl btn-block">Place Order</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = { checkOutItems };

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
