// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Pagination from '../shared/Pagination';

// data stubs
import theme from '../../data/theme';
import Currency from '../shared/Currency';
import { Config } from '../../config';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';

export default class AccountPageMyGiftCards extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            gift_cards: [],
            page: 1,
            total_pages: 0,
            total_records: 0,
        };
    }

    componentDidMount() {
        this.getMyGiftCards();
    }

    handlePageChange = (page) => {
        this.setState({ page });
        var $this = this;
        setTimeout(() => {
            $this.getMyGiftCards();
        },500);
    };

    getMyGiftCards(){
        this.setState({ isLoading: true });
        shopApi.getGiftCards(5, 'all', this.state.page).then((response) => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({
                        gift_cards: response.data,
                        total_pages: response.total_pages,
                        total_records: response.total_records
                     });
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/account/dashboard");
            }
        });

    }

    copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            toast.success("Gift card number copied successfully!")
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            toast.success("Gift card number copied successfully!");
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }

    }

    render() {

        let gift_cards = this.state.gift_cards;

        let giftCards = '';
        if(gift_cards.length > 0){
            giftCards = gift_cards.map((gift_card) => {
                return (
                    <tr key={gift_card.id}>
                        <td className="td_left_align">
                            <div style={{marginTop: 10, marginBottom: 0}}>{gift_card.card_no}</div>
                            {(gift_card.is_approve && gift_card.balance > 0) &&
                                <span style={{padding: 4, fontSize: 12, borderRadius: 5}} onClick={() => { this.copyToClipboard(gift_card.card_no) }} className="btn-danger">Copy Code</span>
                            }
                        </td>
                        <td><Currency value={gift_card.balance} /></td>
                        <td>{(gift_card.is_used ? 'Yes' : 'No')}</td>
                        <td>{(gift_card.is_approve ? 'Yes' : 'No')}</td>
                        <td>{(gift_card.is_verify ? 'Yes' : 'No')}</td>
                        <td>{moment(gift_card.created_date).format("YYYY-MM-DD")}</td>
                    </tr>
                )
            });
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`My Gift Cards | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card">
                <div className="card-header">
                    <h5>My Gift Cards</h5>
                </div>
                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Virtual Card Number</th>
                                        <th>Balance</th>
                                        <th>Used</th>
                                        <th>Approved</th>
                                        <th>Verified</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody className="card-table__body card-table__body--merge-rows">
                                    {giftCards}
                                </tbody>
                            </table>
                        </div>
                        <div className="card-divider" />
                        <div className="card-footer">
                            <Pagination current={this.state.page} total={this.state.total_pages} onPageChange={this.handlePageChange} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
