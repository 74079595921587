// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

// data stubs
import theme from '../../data/theme';
import Currency from '../shared/Currency';
import { Config } from '../../config';
import { url } from '../../services/utils';

export default class AccountPageOrderDetails extends Component {
//export default function AccountPageOrderDetails() {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            id: this.props.location.id,
            order: [],
            order_totals: [],
            order_status: [],
        };
    }

    componentDidMount() {
        if(!this.state.id || this.state.id === undefined){
            toast.error("Invalid order details");
            this.props.history.push('/account/orders');
        } else {
            this.getOrderDetails();
        }
    }

    getOrderDetails(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let postJSONData = {
            order_id : this.state.id
        }

        fetch(Config.URL+"/order-details", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postJSONData)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ order: response.data });
                    this.setState({ order_totals: response.amounts });
                    this.setState({ order_status: response.order_status });
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/account/orders");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {

        let order = this.state.order;
        let order_totals = this.state.order_totals;

        let orderStatus = 'Pending';
        if(order.web_order_status === 1){
            orderStatus = 'Approved';
        }
        if(order.web_order_status === 2){
            orderStatus = 'Rejected';
        }
        if(order.web_order_status === 3){
            orderStatus = 'In Preparing';
        }
        if(order.web_order_status === 4){
            orderStatus = 'In Transit';
        }
        if(order.web_order_status === 5){
            orderStatus = 'Delivered';
        }
        if(order.web_order_status === 6){
            orderStatus = 'Canceled';
        }
        if(order.is_return === 1){
            orderStatus = 'Partially Return';
        }
        if(order.is_return === 2){
            orderStatus = 'Full Return';
        }

        let orderItems = '';
        if(order.order_items !== undefined){
            orderItems = order.order_items.map((orderItem) => {
                if(orderItem.item_details !== ''){
                    if(orderItem.is_gift_card){
                        return (
                            <tr key={orderItem.id}>
                                <td>
                                    <img width="80" src={orderItem.item_details.gift_card_image} />
                                </td>
                                <td className="td_left_align">
                                    Gift Card
                                    <span className="item_code">Item Code: {orderItem.item_details.card_no}</span>
                                </td>
                                <td>-</td>
                                <td>{orderItem.return_qty}</td>
                                <td>{orderItem.qty}</td>
                                <td><Currency value={orderItem.item_details.balance} /></td>
                                <td>-</td>
                                <td><Currency value={orderItem.row_total} /></td>
                            </tr>
                        )
                    } else {
                        return (
                            <tr key={orderItem.id}>
                                <td><img width="80" src={orderItem.item_details.product_main_image !== '' ? orderItem.item_details.product_main_image : 'images/avatars/user_placeholder.jpeg' } /></td>
                                <td className="td_left_align">
                                    <Link hrefLang={"en"}  to={url.product_detail(orderItem.item_details)}>{(orderItem.item_details.full_name).toLowerCase()}</Link>
                                    <span className="item_code">Item Code: {orderItem.item_code}</span>
                                </td>
                                <td>{(orderItem.return_qty > 0 ? (orderItem.return_qty === orderItem.qty ? 'Full' : 'Partially') : '-')}</td>
                                <td>{orderItem.return_qty}</td>
                                <td>{orderItem.qty}</td>
                                <td><Currency value={orderItem.price} /></td>
                                <td><Currency value={orderItem.discount_amount} /></td>
                                <td><Currency value={orderItem.row_total} /></td>
                            </tr>
                        )
                    }
                }
            });
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Order Details | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card">
                    <div className="order-header">
                        <div className="order-header__actions">
                            <Link hrefLang={"en"}  to="/account/orders" className="btn btn-xs btn-secondary">Back to list</Link>
                        </div>
                        <h5 className="order-header__title">#{order.order_no}</h5>
                        <div className="order-header__subtitle">
                            Was placed on
                            {' '}
                            <mark className="order-header__date">{moment(order.date_created).format("Do MMM YYYY, h:mm:ss A")}</mark>
                            {' '}
                            and is currently is
                            {' '}
                            <mark className="order-header__status">{orderStatus}.</mark>
                        </div>
                        <div className="order-header__subtitle">
                            {order.delivery_way_bill_no &&
                            <span className="order-header__date"><b>Way Bill No</b>: {order.delivery_way_bill_no}<br/></span>
                            }
                            {order.delivery_tracking_info &&
                            <span className="order-header__date"><b>Tracking Info</b>: {order.delivery_tracking_info}<br/></span>
                            }
                            {order.delivery_order_note &&
                            <span className="order-header__date"><b>Note(For Order Track)</b>: {order.delivery_order_note}<br/></span>
                            }
                        </div>
                    </div>
                    <div className="card-divider" />

                    {this.state.order_status !== undefined && this.state.order_status !== null && this.state.order_status.length > 0 &&
                        <>
                        <h4 className="text-center mt-3">Order Tracking</h4>
                        <div className="track">
                            {this.state.order_status.length > 0 ? (

                                this.state.order_status.map((item, key) => {
                                    let trackStatus = 'Order Created';
                                    if(item.status === 0){
                                        trackStatus = 'Order Created';
                                    }
                                    if(item.status === 1){
                                        trackStatus = 'Approved';
                                    }
                                    if(item.status === 2){
                                        trackStatus = 'Rejected';
                                    }
                                    if(item.status === 3){
                                        trackStatus = 'In Preparing';
                                    }
                                    if(item.status === 4){
                                        trackStatus = 'In Transit';
                                    }
                                    if(item.status === 5){
                                        trackStatus = 'Delivered';
                                    }
                                    if(item.status === 7){
                                        trackStatus = 'Return';
                                    }
                                    return(
                                        <div className={item.current_status ? "step active" : "step"}>
                                            <span className="icon">
                                                <i className="fa fa-check"></i>
                                            </span>
                                            <span className="text">{trackStatus}</span>
                                            {item.current_status &&
                                                <>
                                                    <span className="date">{moment(item.date_updated).format("Do MMM YYYY, h:mm:ss A")}</span>
                                                    {item.comment !== '' &&
                                                        <span className="comment">{item.comment}</span>
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })

                            ) : (
                                null
                            )}
                        </div>
                        </>
                    }

                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm order_details">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product</th>
                                        <th>Return</th>
                                        <th>Return Qty</th>
                                        <th>Qty</th>
                                        <th width="15%">Unit Price</th>
                                        <th width="15%">Discount</th>
                                        <th width="15%">Sub Total</th>
                                    </tr>
                                </thead>
                                <tbody className="card-table__body card-table__body--merge-rows">
                                    {orderItems}
                                    <tr>
                                        <td colSpan="9"><div style={{marginTop: 10, marginBottom: 10}} className="card-divider" /></td>
                                    </tr>
                                    {order_totals.total_amount > 0 &&
                                    <tr>
                                        <th colSpan="7">Sub Amount</th>
                                        <td><Currency value={order_totals.total_amount} /></td>
                                    </tr>
                                    }
                                    {order_totals.discount_amount > 0 &&
                                        <tr>
                                            <th colSpan="7">Discount Amount (-)</th>
                                            <td><Currency value={order_totals.discount_amount} /></td>
                                        </tr>
                                    }
                                    {order_totals.total_amount_exc_vat > 0 &&
                                    <tr>
                                        <th colSpan="7">Total Amount Ex. Vat(-)</th>
                                        <td><Currency value={order_totals.total_amount_exc_vat} /></td>
                                    </tr>
                                    }
                                    {order_totals.total_vat_amt > 0 &&
                                    <tr>
                                        <th colSpan="7">Total Vat Amount</th>
                                        <td><Currency value={order_totals.total_vat_amt} /></td>
                                    </tr>
                                    }
                                    {order_totals.sub_amount > 0 &&
                                    <tr>
                                        <th colSpan="7">Total Amount</th>
                                        <td><Currency value={order_totals.sub_amount} /></td>
                                    </tr>
                                    }
                                    {order_totals.shipping_charge > 0 &&
                                        <tr>
                                            <th colSpan="7">Delivery Charge (+)</th>
                                            <td><Currency value={order_totals.shipping_charge} /></td>
                                        </tr>
                                    }
                                    {order_totals.purchase_gift_card > 0 &&
                                        <tr>
                                            <th colSpan="7">Purchase Gift Card (+)</th>
                                            <td><Currency value={order_totals.purchase_gift_card} /></td>
                                        </tr>
                                    }
                                    {order_totals.pay_by_gift_card > 0 &&
                                        <tr>
                                            <th colSpan="7">Pay By Using Gift Card (-)</th>
                                            <td><Currency value={order_totals.pay_by_gift_card} /></td>
                                        </tr>
                                    }
                                    {/* <tr>
                                        <th colSpan="7">Pay By Using Coupon Code (-)</th>
                                        <td><Currency value={order_totals.pay_by_coupon_code} /></td>
                                    </tr> */}
                                </tbody>
                                <tfoot className="card-table__body--merge-rows card-table__body order_details_final_totals">
                                    <tr>
                                        <th colSpan="7"><b style={{fontSize: 20}}>Amount Paid</b></th>
                                        <td width="20%"><Currency value={order_totals.amount_payable} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 no-gutters mx-n2">
                    <div className="col-sm-12 col-12 px-2">
                        <div className="card address-card address-card--featured">
                            <div className="address-card__body">
                                <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                                <div className="address-card__name">{order.sa_first_name} {order.sa_last_name}</div>
                                <div className="address-card__row">
                                    {order.country_name}
                                    <br />
                                    {order.sa_zipcode}
                                    ,&nbsp;
                                    {order.sa_city}
                                    <br />
                                    {order.sa_address_1}
                                    <br />
                                    {order.sa_address_2}
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    <div className="address-card__row-content">{order.sa_contact}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">{order.sa_email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                        <div className="card address-card address-card--featured">
                            <div className="address-card__body">
                                <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                                <div className="address-card__name">Helena Garcia</div>
                                <div className="address-card__row">
                                    Random Federation
                                    <br />
                                    115302, Moscow
                                    <br />
                                    ul. Varshavskaya, 15-2-178
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    <div className="address-card__row-content">38 972 588-42-36</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">stroyka@example.com</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}
