// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// data stubs
import theme from '../../data/theme';
import shopApi from '../../api/shop';

function SocialLinks(props) {
    const { shape, className } = props;

    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });

    const socialClick = (item) => {
        shopApi.g4SocialEventPush('social_click', item.domain, window.location.href)
    }

    const items = [
        { type: 'facebook', url: 'https://www.facebook.com/khaliquesSA', icon: 'fab fa-facebook-f', domain: 'facebook.com' },
        { type: 'twitter', url: 'https://twitter.com/khaliques_man', icon: 'fab fa-twitter', domain: 'twitter.com' },
        { type: 'linkedin', url: 'https://www.linkedin.com/company/khaliques/', icon: 'fab fa-linkedin', domain: 'linkedin.com' },
        { type: 'youtube', url: 'https://www.youtube.com/channel/UCbX0ujj3mEKzwJF-ktQd7zQ', icon: 'fab fa-youtube', domain: 'youtube.com' },
        { type: 'instagram', url: 'https://www.instagram.com/khaliques/', icon: 'fab fa-instagram', domain: 'instagram.com' },
        { type: 'pinterest', url: 'https://in.pinterest.com/khaliques/', icon: 'fab fa-pinterest', domain: 'pinterest.com' }
    ].map((item) => (
        <li key={item.type} className="social-links__item">
            <a
                className={`social-links__link social-links__link--type--${item.type}`}
                href={item.url}
                onClick={() => socialClick(item)}
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className={item.icon} />
            </a>
        </li>
    ));

    return (
        <div className={classes}>
            <ul className="social-links__list">
                {items}
            </ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(['circle', 'rounded']),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
