// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import Autocomplete from "react-google-autocomplete";

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';

class SitePageRequestForReturn extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            countries: [],
            collection_countries: [],
            disableOrderInput: false,
            showAddressFields: false,
            selectedFiles: [],
            basicFormFields: {
                first_name: "",
                last_name: "",
                email: "",
                contact: "",
                order_no: (props.location.order_no !== undefined && props.location.order_no !== null && props.location.order_no !== '' ? props.location.order_no : ''),
                topic: "Return",
                size: "",
                weight: "",
                return_method: "",
                refund_method: "Cash",
                message: "",
                country: 202,
                address_1: '',
                address_2: '',
                collection_country: 202,
                city: '',
                postcode: ''
            },
            basicFormFieldsError: {
                isEmptyFirstName: false,
                isEmptyLastName: false,
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyCountry: false,
                isEmptyContact: false,
                isEmptyOrderNo: false,
                isEmptyTopic: false,
                isEmptySize: false,
                isEmptyWeight: false,
                isEmptyReturnMethod: false,
                isEmptyRefundMethod: false,
                isEmptyHowCanWeHelpYou: false,
                isEmptyCollectionCountry: false,
                isEmptyAddress1: false,
                isEmptyAddress2: false,
                isEmptyCollectionCountry: false,
                isEmptyCity: false,
                isEmptyPostCode: false,
            },
        }
    }

    componentDidMount() {
        if(this.state.basicFormFields.order_no === ''){
            toast.error("Invalid order for return request");
            this.props.history.push('/');
        } else {
            this.getAllCountries();
            let $this = this;
            setTimeout(() => {
                $this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'country': 202 }
                }));
                $this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'collection_country': 202 }
                }));
                $this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'order_no': this.props.location.order_no }
                }));
                $this.setState({ disableOrderInput: true });
            },1000);
        }
    }

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                let countries = [];
                let collection_countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: "+" + item.country_code + "    " + item.name, value: item.id });
                    collection_countries.push({ name: item.name, value: item.id });
                    if(response.data.length -1 === key){
                        this.setState({ countries });
                        this.setState({ collection_countries });
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyFirstName: false,
            isEmptyLastName: false,
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyCountry: false,
            isEmptyContact: false,
            isEmptyOrderNo: false,
            isEmptyTopic: false,
            isEmptySize: false,
            isEmptyWeight: false,
            isEmptyReturnMethod: false,
            isEmptyRefundMethod: false,
            isEmptyHowCanWeHelpYou: false,
            isEmptyAddress1: false,
            isEmptyAddress2: false,
            isEmptyCollectionCountry: false,
            isEmptyCity: false,
            isEmptyPostCode: false,
        };

        var first_name = this.state.basicFormFields.first_name;
        var last_name = this.state.basicFormFields.last_name;
        var email_address = this.state.basicFormFields.email;
        var contact = this.state.basicFormFields.contact;
        var country = this.state.basicFormFields.country;
        var order_no = this.state.basicFormFields.order_no;
        var topic = this.state.basicFormFields.topic;
        var size = this.state.basicFormFields.size;
        var weight = this.state.basicFormFields.weight;
        var return_method = this.state.basicFormFields.return_method;
        var refund_method = this.state.basicFormFields.refund_method;
        var how_can_we_help = this.state.basicFormFields.message;
        var address_1 = this.state.basicFormFields.address_1;
        var address_2 = this.state.basicFormFields.address_2;
        var collection_country = this.state.basicFormFields.collection_country;
        var city = this.state.basicFormFields.city;
        var postcode = this.state.basicFormFields.postcode;

        if (first_name === '' || first_name === null) {
            basicFormFieldsError.isEmptyFirstName = true;
            isReturn = false;
        }
        if (last_name === '' || last_name === null) {
            basicFormFieldsError.isEmptyLastName = true;
            isReturn = false;
        }
        if (email_address === '' || email_address === null) {
            basicFormFieldsError.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            basicFormFieldsError.isNotValidEmail = true;
            isReturn = false;
        }
        if (contact === '' || contact === null) {
            basicFormFieldsError.isEmptyContact = true;
            isReturn = false;
        }
        if (country === '' || country === null) {
            basicFormFieldsError.isEmptyCountry = true;
            isReturn = false;
        }
        if (order_no === '' || order_no === null) {
            basicFormFieldsError.isEmptyOrderNo = true;
            isReturn = false;
        }
        if (topic === '' || topic === null) {
            basicFormFieldsError.isEmptyTopic = true;
            isReturn = false;
        }
        if (size === '' || size === null) {
            basicFormFieldsError.isEmptySize = true;
            isReturn = false;
        }
        if (weight === '' || weight === null) {
            basicFormFieldsError.isEmptyWeight = true;
            isReturn = false;
        }
        if (return_method === '' || return_method === null) {
            basicFormFieldsError.isEmptyReturnMethod = true;
            isReturn = false;
        }
        if (refund_method === '' || refund_method === null) {
            basicFormFieldsError.isEmptyRefundMethod = true;
            isReturn = false;
        }
        if (how_can_we_help === '' || how_can_we_help === null) {
            basicFormFieldsError.isEmptyHowCanWeHelpYou = true;
            isReturn = false;
        }
        if(return_method !== '' && return_method === 'Collect'){
            if (address_1 === '' || address_1 === null) {
                basicFormFieldsError.isEmptyAddress1 = true;
                isReturn = false;
            }
            // if (address_2 === '' || address_2 === null) {
            //     basicFormFieldsError.isEmptyAddress2 = true;
            //     isReturn = false;
            // }
            if (collection_country === '' || collection_country === null) {
                basicFormFieldsError.isEmptyCollectionCountry = true;
                isReturn = false;
            }
            if (city === '' || city === null) {
                basicFormFieldsError.isEmptyCity = true;
                isReturn = false;
            }
            if (postcode === '' || postcode === null) {
                basicFormFieldsError.isEmptyPostCode = true;
                isReturn = false;
            }
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }));
        if(e.target.name === 'return_method'){
            if(e.target.value === 'Collect'){
                this.setState({ showAddressFields: true });
            } else {
                this.setState({ showAddressFields: false });
            }
        }
    }

    handleSetAddress(place){
        var address = "", city = "", state = "", zip = "", country = "";
        for (var i = 0; i < place.address_components.length; i++) {
            var addr = place.address_components[i];
            // check if this entry in address_components has a type of country
            if (addr.types[0] == 'country')
                country = addr.long_name;
            else if (addr.types[0] == 'street_address') // address 1
                address = address + addr.long_name;
            else if (addr.types[0] == 'establishment')
                address = address + addr.long_name;
            else if (addr.types[0] == 'route')  // address 2
                address = address + addr.long_name;
            else if (addr.types[0] == 'sublocality_level_1')  // address 2
                address = (address !== '' ? address + ", "+addr.long_name : address + addr.long_name );
            else if (addr.types[0] == 'postal_code')       // Zip
                zip = addr.short_name;
            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                state = addr.long_name;
            else if (addr.types[0] == ['locality'])       // City
                city = addr.long_name;
        }
        if(country !== ''){
            var countryObject = this.state.collection_countries.find(x => x.name === country);
            if(countryObject !== ''){
                this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'collection_country': countryObject.value }
                }));
            }
        }

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'address_1': place.formatted_address }
        }));

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'city': city }
        }));
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'postcode': zip }
        }));
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    setCountry(country){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'country': country }
        }));
    }

    setCollectionCountry(country){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'collection_country': country }
        }));
    }

    /**
     * [onFileChange set file object to global variable and preview selected image]
     * @param  {[type]} event [Object of selected image]
     * @return {}
     */
     onFileChange(event) {
        this.setState({ selectedFiles: event.target.files });
    }

    submitRequest(){
        let isValidated = this.validateForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let submitRequestJSON = {... this.state.basicFormFields};
        var data = new FormData();
        Object.keys(submitRequestJSON).map((key) => {
            data.append(key, this.state.basicFormFields[key]);
        });
        var submitForm = true;
        if(this.state.selectedFiles.length > 0){
            if(this.state.selectedFiles.length > 5){
                submitForm = false;
            } else {
                Object.values(this.state.selectedFiles).map((item) => {
                    data.append("attachment[]", item, item.name);
                });
            }
        }

        if(!submitForm){
            toast.error("You can upload maximum 5 files");
        } else {
            fetch(Config.URL+"/request-for-return", {
                method: "POST",
                headers: headers,
                body: data
            })
            .then(response => response.json())
            .then(response => {
                this.setState({ isLoading: false });
                if(response.status){
                    if(response.status === 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        toast.success(response.message);
                        this.props.history.push('/account/orders');
                    }
                } else {
                    toast.error(response.message);
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                //console.log("err: ", err);
                toast.error(err);
            });
        }

    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Request For Return', url: '' },
        ];

        return (
            <div className="card">
                <Helmet>
                    <title>{`Request For Return | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Request For Return" breadcrumb={breadcrumb} />

                <div className="block faq">
                    <div className="container">
                        <div className="faq__section">
                            <div className="faq__section-body">
                                <div className="row">
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-lg-12 col-xl-12">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-first-name">First Name</label>
                                                        <input
                                                            type="text"
                                                            name="first_name"
                                                            className="form-control"
                                                            id="request-first-name"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyFirstName ?
                                                        <span className="errorInput">Please enter first name</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-last-name">Last Name</label>
                                                        <input
                                                            type="text"
                                                            name="last_name"
                                                            className="form-control"
                                                            id="request-last-name"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyLastName ?
                                                        <span className="errorInput">Please enter last name</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-email">Email</label>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            className="form-control"
                                                            id="request-email"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyEmail ?
                                                        <span className="errorInput">Please enter email address</span>
                                                        : null
                                                        }
                                                        {this.state.basicFormFieldsError.isNotValidEmail ?
                                                        <span className="errorInput">Please enter valid email address</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-contact">Contact Number</label>
                                                        <div className="mobileContainer">
                                                            <div className="countrySection">
                                                            <SelectSearch
                                                                options={this.state.countries}
                                                                name="country"
                                                                search
                                                                value={[this.state.basicFormFields.country]}
                                                                filterOptions={this.fuzzySearch}
                                                                onChange={(event) => { this.setCountry(event) }}
                                                                emptyMessage="No matched country found in search criteria"
                                                            />
                                                            </div>
                                                            <div className="mobileNumberSection">
                                                                <input
                                                                    type="number"
                                                                    value={this.state.basicFormFields.contact}
                                                                    id="contact"
                                                                    onChange={this.handleSetValue}
                                                                    name="contact"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            {this.state.basicFormFieldsError.isEmptyContact ?
                                                            <span className="errorInput">Please enter contact number</span>
                                                            : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-order-no">#Order No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="request-order-no"
                                                            name="order_no"
                                                            disabled={this.state.disableOrderInput}
                                                            defaultValue={this.state.basicFormFields.order_no}
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyOrderNo ?
                                                        <span className="errorInput">Please enter order number</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-topic">Topic</label>
                                                        <select onChange={this.handleSetValue} id="request-topic" name="topic" className="form-control form-control-select2">
                                                            <option value="Return">Return</option>
                                                            <option value="Exchange">Exchange</option>
                                                            <option value="More Product Information">More Product Information</option>
                                                        </select>
                                                        {this.state.basicFormFieldsError.isEmptyTopic ?
                                                        <span className="errorInput">Please select topic</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-size">Size</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="request-size"
                                                            name="size"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptySize ?
                                                        <span className="errorInput">Please enter size</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-weight">Weight</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="request-weight"
                                                            name="weight"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyWeight ?
                                                        <span className="errorInput">Please enter weight</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-return-method">Return Method</label>
                                                        <select onChange={this.handleSetValue} id="request-return-method" name="return_method" className="form-control form-control-select2">
                                                            <option value="">Select</option>
                                                            <option value="Collect">Collect</option>
															<option value="Drop Off">Drop Off</option>
                                                        </select>
                                                        {this.state.basicFormFieldsError.isEmptyReturnMethod ?
                                                        <span className="errorInput">Please select return method</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="request-refund-method">Refund Method</label>
                                                        <select onChange={this.handleSetValue} id="request-refund-method" name="refund_method" className="form-control form-control-select2">
                                                            <option value="Cash">Cash</option>
															<option value="Into Bank Account">Into Bank Account</option>
                                                        </select>
                                                        {this.state.basicFormFieldsError.isEmptyRefundMethod ?
                                                        <span className="errorInput">Please select refund method</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                {this.state.showAddressFields &&
                                                <>
                                                    <div className="form-group">
                                                    <label htmlFor="request-address">Street Address</label>
                                                        <Autocomplete
                                                            onChange={this.handleSetValue}
                                                            className="form-control"
                                                            apiKey={Config.googleAPIKey}
                                                            id="request-address"
                                                            name="address_1"
                                                            onPlaceSelected={(place, inputRef, autocomplete) => {
                                                                this.handleSetAddress(place);
                                                            }}
                                                            defaultValue={this.state.basicFormFields.address_1}
                                                            options={{
                                                                types: ["geocode"]
                                                            }}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyAddress1 ?
                                                        <span className="errorInput">Please enter street address</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="request-address_2">
                                                            Apartment, suite, unit, Landmark etc.
                                                            {' '}
                                                            <span className="text-muted">(Optional)</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="request-address_2"
                                                            name="address_2"
                                                            onChange={this.handleSetValue}
                                                        />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="request-contact">Country</label>
                                                            <SelectSearch
                                                                options={this.state.collection_countries}
                                                                name="collection_country"
                                                                search
                                                                value={[this.state.basicFormFields.collection_country]}
                                                                filterOptions={this.fuzzySearch}
                                                                onChange={(event) => { this.setCollectionCountry(event) }}
                                                                emptyMessage="No matched country found in search criteria"
                                                            />
                                                            {this.state.basicFormFieldsError.isEmptyCollectionCountry ?
                                                            <span className="errorInput">Please select country</span>
                                                            : null
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="address-city">Town / City</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="address-city"
                                                                name="city"
                                                                defaultValue={this.state.basicFormFields.city}
                                                                onChange={this.handleSetValue}
                                                            />
                                                            {this.state.basicFormFieldsError.isEmptyCity ?
                                                            <span className="errorInput">Please enter town / city</span>
                                                            : null
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="request-postcode">Postcode / ZIP</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="request-postcode"
                                                                name="postcode"
                                                                defaultValue={this.state.basicFormFields.postcode}
                                                                onChange={this.handleSetValue}
                                                            />
                                                            {this.state.basicFormFieldsError.isEmptyPostCode ?
                                                            <span className="errorInput">Please enter postcode / zip</span>
                                                            : null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="request-how-can-we-help-you">How can we help you?</label>
                                                    <textarea
                                                        onChange={this.handleSetValue}
                                                        id="request-how-can-we-help-you"
                                                        name="message"
                                                        className="form-control"
                                                        rows="4"
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyHowCanWeHelpYou ?
                                                    <span className="errorInput">Please enter message</span>
                                                    : null
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="request-attachment">Attach Your Images <small>(Maximum 5 images allowed)</small></label>
                                                    <input type="file" name="attachment[]" onChange={(event) => { this.onFileChange(event) }} className="form-control" id="request-attachment" multiple={true} />
                                                </div>

                                                <div className="form-group mt-3 mb-0">
                                                    <button onClick={() => { this.submitRequest() }} className="btn btn-primary" type="button">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default SitePageRequestForReturn;