// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import shopApi from '../../api/shop';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';

class SitePageFaq extends Component {
//function SitePageFaq() {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            faqs: [],
            metaTags: {
                meta_desc: "",
                meta_keywords: '',
                meta_title: "",
            }
        }
    }

    componentDidMount() {
        this.getFaqs();
        this.loadMetaTags();
    }

    loadMetaTags(){
        var request = shopApi.getMetaTagsBySlug('faq');
        request.then((metaTags) => {
            this.setState({ metaTags : metaTags.data });
        });
    }

    getFaqs(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/get-faq-listing", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ faqs: response.data });
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Frequently Asked Questions', url: '' },
        ];

        const metaTags = this.state.metaTags;

        let faqs = '';
        if(this.state.faqs.length > 0){
            faqs = this.state.faqs.map((faq) => (
            <React.Fragment key={faq.id}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                           {faq.question}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                    </AccordionItemPanel>
                </AccordionItem>
            </React.Fragment>
            ));
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_desc} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                    <meta name="twitter:description" content={metaTags.meta_desc} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_desc} />
                    <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                    <meta property="og:url" content={ Config.DOMAIN + '/faq' } />
                    <link rel="canonical" href={Config.DOMAIN + '/faq'} />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Frequently Asked Questions" breadcrumb={breadcrumb} />

                <div className="block faq">
                    <div className="container">
                        <div className="faq__section">
                            <div className="faq__section-body">
                                <div className="row">
                                    <div className="faq__section-column col-12 col-lg-12">
                                        {/* <div className="typography" dangerouslySetInnerHTML={{ __html: this.state.page.description }}></div> */}
                                        <Accordion>
                                            {faqs}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SitePageFaq;
