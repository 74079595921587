// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';

import PasswordStrengthBar from 'react-password-strength-bar';
import ReactPasswordStrength from 'react-password-strength';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OtpInput from 'react-otp-input';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Forgot Password', url: '' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AccountPageForgotPassword extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isLoggedIn: false,
            email: '',
            password: '',
            forgotpassword_type: 'password',
            forgotpassword_type_c: 'password',
            formFields: {
                email: '',
                n_password: '',
                c_password: ''
            },
            validationFormErrors: {
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyMobileNumber: false,
                isEmptyNPassword: false,
                isEmptyCPassword: false,
                isEmptyOTP: false,
                isNotValidLenghtPassword: false,
                isNotValidLenghtCPassword: false,
                isNotValidPassword: false,
                isNotValidCPassword: false,
                isNotSameBothPassword: false,
            },
            pageSubHeading: 'Forgot Password',
            flag: '',
            openVerificationDialog: false,
            active: 'by_email',
            countries: [],
            countryCode: 0,
            countryCodeRemember: '27-202',
            otp: '',
            verificationAuth: '',
            forgotPasswordToken: '',
            isResetPasswordShown: false,
            timerDuration: 60,
            startTimerForgotPassword: false,
            showResendOTPButton: false,
            passwordScore: 0,
            cPasswordScore: 0,
        }
    }

    componentDidMount() {
        var isAlreadyLoggedin = (localStorage.getItem(Config.x_token) !== null ? true : false);
        if (isAlreadyLoggedin) {
            this.props.history.push("/");
        }
        this.getAllCountries();
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    handleSetOTP = (otp) => this.setState({ otp });

    setCountryCode(event){
        this.setState({ countryCode: event.split("-")[0], countryCodeRemember: event });
    }

    changeLoginType(type){
        this.setState({ active : type, otp: '' });
    }

    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyEmail: false,
            isEmptyMobileNumber: false,
            isEmptyNPassword: false,
            isEmptyCPassword: false,
            isNotValidEmail: false
        };

        const email = this.state.formFields.email;
        if(this.state.active == 'by_email'){
            if (email === '' || email === null) {
                validationFormErrors.isEmptyEmail = true;
                isReturn = false;
            }
            if (email !== '' && !parseInt(email) && !this.isValidEmailAddress(email)) {
                validationFormErrors.isNotValidEmail = true;
                isReturn = false;
            }
        } else {
            if (email === '' || email === null) {
                validationFormErrors.isEmptyMobileNumber = true;
                isReturn = false;
            }
        }

        this.setState({ validationFormErrors });
        return isReturn;
    }

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getAllCountries response: ", response);
            if(response.status){
                let countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: "+" + item.country_code + "    " + item.name, value: item.country_code +"-"+item.id });
                });
                this.setState({ countries });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    forgotPassword(){
        let isValidated = this.validateForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);

        let postCredentials = {...this.state.formFields};
        postCredentials.email = this.state.formFields.email;
        delete postCredentials.n_password;
        delete postCredentials.c_password;

        if(this.state.active === 'by_mobile'){
            if(this.state.countryCode == 0){
                postCredentials.email = "27" + this.state.formFields.email;
            } else {
                postCredentials.email = this.state.countryCode + "" + this.state.formFields.email;
            }
        }

        fetch(Config.URL+"/forgot-password", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("forgotPassword response: ", response);
            if(this.state.active === 'by_email'){
                if(response.status){
                    toast.success(response.message);
                    this.setState({ verificationEmailAuth: '', openVerificationDialog: false, otp: ''});
                } else {
                    this.setState({ openVerificationDialog: false, otp: '' });
                    toast.error(response.message);
                }
            } else {
                if(response.status){
                    toast.success(response.message);
                    this.setState({ forgotPasswordToken: response.forgot_password_token, verificationAuth: response.OTP_token, openVerificationDialog: false, pageSubHeading: 'Verify OTP', otp: '', showResendOTPButton: false, startTimerForgotPassword: true});
                } else {
                    this.setState({ openVerificationDialog: false, otp: '' });
                    toast.error(response.message);
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, verificationEmailAuth: '', otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    resendOTP(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);

        let postCredentials = {...this.state.formFields};
        if(this.state.countryCode == 0){
            postCredentials.email = "27" + this.state.formFields.email;
        } else {
            postCredentials.email = this.state.countryCode + "" + this.state.formFields.email;
        }
        delete postCredentials.n_password;
        delete postCredentials.c_password;

        fetch(Config.URL+"/forgot-password", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("resendOTP response: ", response);
            if(response.status){
                toast.success(response.message);
                this.setState({ forgotPasswordToken: response.forgot_password_token, verificationAuth: response.OTP_token, openVerificationDialog: false, pageSubHeading: 'Verify OTP', otp: '', showResendOTPButton: false});
            } else {
                this.setState({ openVerificationDialog: false, otp: '' });
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, verificationEmailAuth: '', otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateResetPasswordForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateResetPasswordForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyEmail: false,
            isEmptyMobileNumber: false,
            isEmptyNPassword: false,
            isEmptyCPassword: false,
            isNotValidLenghtPassword: false,
            isNotValidLenghtCPassword: false,
            isNotSameBothPassword: false,
            isNotValidPassword: false,
            isNotValidCPassword: false,
            isNotValidEmail: false
        };

        var n_password = this.state.formFields.n_password;
        var c_password = this.state.formFields.c_password;
        var passwordScore = this.state.passwordScore;
        var cPasswordScore = this.state.cPasswordScore;
        if (n_password === '' || n_password === null) {
            validationFormErrors.isEmptyNPassword = true;
            isReturn = false;
        }
        if (c_password === '' || c_password === null) {
            validationFormErrors.isEmptyCPassword = true;
            isReturn = false;
        }
        if(n_password !== '' && passwordScore < 4){
            validationFormErrors.isNotValidPassword = true;
            isReturn = false;
        }
        if(c_password !== '' && cPasswordScore < 4){
            validationFormErrors.isNotValidCPassword = true;
            isReturn = false;
        }
        if (n_password !== '' && n_password.length < 8) {
            validationFormErrors.isNotValidPassword = true;
            isReturn = false;
        }
        if (c_password !== '' && c_password.length < 8) {
            validationFormErrors.isNotValidCPassword = true;
            isReturn = false;
        }
        if (n_password !== '' && c_password !== '' && n_password !== c_password) {
            validationFormErrors.isNotSameBothPassword = true;
            isReturn = false;
        }

        this.setState({ validationFormErrors });
        return isReturn;
    }

    updatePassword(){
        let isValidated = this.validateResetPasswordForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let postCredentials = {...this.state.formFields};
        postCredentials._token = this.state.forgotPasswordToken;
        delete postCredentials.email;

        fetch(Config.URL+"/reset-password-update", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("updatePassword response: ", response);
            if(response.status){
                toast.success(response.message);
                this.setState({ isResetPasswordShown: true, verificationAuth: '', openVerificationDialog: false, pageSubHeading: 'Forgot Password', otp: ''});
                this.props.history.push("/account/login");
            } else {
                this.setState({isResetPasswordShown: true, verificationAuth: '', openVerificationDialog: false, pageSubHeading: 'Forgot Password', otp: ''});
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ isResetPasswordShown: false, forgotPasswordToken: '', verificationAuth: '', openVerificationDialog: false, pageSubHeading: 'Forgot Password', otp: ''});
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateOTPForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateOTPForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyOTP: false
        };

        const otp = this.state.otp;

        if (otp === '' || otp === null) {
            validationFormErrors.isEmptyOTP = true;
            isReturn = false;
        }
        this.setState({ validationFormErrors });
        return isReturn;
    }

    verifyOTP(){
        let isValidated = this.validateOTPForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', this.state.verificationAuth);

        let postCredentials = {...this.state.formFields};
        if(this.state.countryCode == 0){
            postCredentials.mobile_number = "27" + this.state.formFields.email;
        } else {
            postCredentials.mobile_number = this.state.countryCode + "" + this.state.formFields.email;
        }
        //postCredentials.mobile_number = this.state.countryCode + "" + this.state.formFields.email;
        postCredentials.otp = this.state.otp;
        delete postCredentials.email;

        fetch(Config.URL+"/verify-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ pageSubHeading: 'Forgot Password', otp: '', isResetPasswordShown: true });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '', isResetPasswordShown: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    closeVerificationDialog(){
        this.setState({ openVerificationDialog : false });
    }

    setForgotPasswordEnable(){
        this.setState({ pageSubHeading : 'Forgot Password', showResendOTPButton: false, startTimerForgotPassword: false, otp: '', verificationAuth: '', verificationEmailAuth: '' });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, [e.target.name]: e.target.value }
        }))
    }

    /**
     * [handleSubmitForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    handleSubmitForm(event) {
        event.preventDefault();
    }

    handleClose = () => {
        this.setState({ isLoading: false });
    };

    /**
     * [handleSetValuePassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValuePassword = e => {
        this.setState({ passwordScore: e.score });
        //console.log(e.score);
        // this.setState(prevState => ({
        //     formFields: { ...prevState.formFields, ['n_password']: e.password }
        // }));
        if(e.score === 4 && e.isValid){
            this.setState(prevState => ({
                formFields: { ...prevState.formFields, ['n_password']: e.password }
            }));
        }
    }

    /**
     * [handleSetValueCPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValueCPassword = e => {
        this.setState({ cPasswordScore: e.score });
        //console.log(e.score);
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, ['c_password']: e.password }
        }));
        if(e.score === 4 && e.isValid){
           this.setState(prevState => ({
            formFields: { ...prevState.formFields, ['c_password']: e.password }
           }))
        }
    }

    changeRegisterPasswordType(forgotpassword_type){
        this.setState({ forgotpassword_type });
    }

    changeRegisterCPasswordType(forgotpassword_type_c){
        this.setState({ forgotpassword_type_c });
    }

    render() {

        const renderTimeForgotPassword = ({ remainingTime }) => {
            //console.log(remainingTime);
            if (remainingTime === 0) {
                this.setState({ startTimerForgotPassword: false, showResendOTPButton: true });
                return;
            }

            return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
            );
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Forgot Password | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Forgot Password" breadcrumb={breadcrumb} />

                {this.state.flag !== '' &&
                    <Dialog
                        open={this.state.openVerificationDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        //onClose={() => { this.closeVerificationDialog() }}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Your account is not verified."}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.errorVerificationMessage}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={() => { this.closeVerificationDialog() }} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.sendVerification() }} color="primary">
                            Verify Now
                        </Button>
                        </DialogActions>
                    </Dialog>
                }

                <div className="block">
                    <div className="container">
                        <div className="row">
                            {!this.state.isResetPasswordShown &&
                                <div className="col-md-6 d-flex">

                                    {this.state.pageSubHeading == 'Forgot Password' &&
                                        <div className="card flex-grow-1 mb-md-0">
                                            <div className="card-body">
                                                <h3 className="card-title">{this.state.pageSubHeading}</h3>

                                                    <div className="prsntg_btns">
                                                        <span onClick={()=> { this.changeLoginType('by_email') }} className={(this.state.active === 'by_email' ? 'button active' : 'button')}>By Email</span>
                                                        <span onClick={()=> { this.changeLoginType('by_mobile') }} className={(this.state.active === 'by_mobile' ? 'button active' : 'button')}>By Mobile</span>
                                                    </div>

                                                    <form onSubmit={this.handleSubmitForm}>
                                                    {this.state.active === "by_email" ?
                                                    <div className="form-group">
                                                        <label htmlFor="login-email">Email address</label>
                                                        <input
                                                            type="text"
                                                            value={this.state.formFields.email}
                                                            id="email"
                                                            onChange={this.handleSetValue}
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Please enter email address"
                                                        />
                                                        {this.state.validationFormErrors.isEmptyEmail ?
                                                        <span className="errorInput">Please enter email address</span>
                                                        : null
                                                        }
                                                        {this.state.validationFormErrors.isNotValidEmail ?
                                                        <span className="errorInput">Please enter a valid email address</span>
                                                        : null
                                                        }
                                                    </div>
                                                    :
                                                    <div className="form-group">
                                                        <label htmlFor="login-email">Mobile number</label>
                                                        <div className="mobileContainer">
                                                            <div className="countrySection">
                                                            <SelectSearch
                                                                options={this.state.countries}
                                                                name="country"
                                                                search
                                                                value={[this.state.countryCodeRemember]}
                                                                filterOptions={this.fuzzySearch}
                                                                onChange={(event) => { this.setCountryCode(event) }}
                                                                emptyMessage="No matched country found in search criteria"
                                                            />
                                                            </div>
                                                            <div className="mobileNumberSection">
                                                                <input
                                                                    type="number"
                                                                    value={this.state.formFields.email}
                                                                    id="mobile_number"
                                                                    onChange={this.handleSetValue}
                                                                    name="email"
                                                                    className="form-control"
                                                                    placeholder="Please enter mobile number"
                                                                />
                                                            </div>
                                                            {this.state.validationFormErrors.isEmptyMobileNumber ?
                                                            <span className="errorInput">Please enter mobile number</span>
                                                            : null
                                                            }
                                                        </div>
                                                    </div>
                                                    }
                                                    <small className="form-text text-muted">
                                                            <Link hrefLang={"en"}  to="/account/login">Already a member? Please login here</Link>
                                                        </small>
                                                    <button onClick={() => { this.forgotPassword() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Submit
                                                    </button>
                                                </form>

                                            </div>
                                        </div>
                                    }

                                    {this.state.pageSubHeading == 'Verify OTP' &&
                                        <div className="card flex-grow-1 mb-md-0">
                                            <div className="card-body">
                                                <h3 className="card-title">{this.state.pageSubHeading}</h3>
                                                <p>We have sent an OTP to: <b>{this.state.formFields.email}</b></p>
                                                <form onSubmit={this.handleSubmitForm}>
                                                    <div className="form-group">
                                                        <label htmlFor="otp">Enter verification code</label>
                                                        <OtpInput
                                                            id="otp"
                                                            value={this.state.otp}
                                                            onChange={this.handleSetOTP}
                                                            numInputs={6}
                                                            inputStyle={{width: 50, height: 50, borderRadius: 10}}
                                                            separator={<span>&nbsp;-&nbsp;</span>}
                                                        />
                                                        {this.state.validationFormErrors.isEmptyOTP ?
                                                            <span className="errorInput">Please enter OTP</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <button onClick={() => { this.verifyOTP() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Verify Now
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => { this.setForgotPasswordEnable() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Cancel
                                                    </button>&nbsp;&nbsp;&nbsp;
                                                    {this.state.showResendOTPButton &&
                                                        <button tton onClick={() => { this.resendOTP() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                            Resend OTP
                                                        </button>
                                                    }
                                                    {this.state.startTimerForgotPassword &&
                                                    <div className="timer-wrapper">
                                                        <CountdownCircleTimer
                                                        size={60}
                                                        strokeWidth={5}
                                                        duration={this.state.timerDuration}
                                                        isPlaying={this.state.startTimerForgotPassword}
                                                        colors={[["#000", 0.33], ["#000", 0.33], ["#FF0000"]]}
                                                        //onComplete={() => [false, 1000]}
                                                        >
                                                        {renderTimeForgotPassword}
                                                        </CountdownCircleTimer>
                                                    </div>
                                                    }
                                                </form>

                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {this.state.isResetPasswordShown &&
                                <div className="col-md-6 d-flex mt-4 mt-md-0">
                                    <div className="card flex-grow-1 mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Update Your Password</h3>
                                            <form onSubmit={this.handleSubmitForm}>
                                                <div className="form-group">
                                                    <label htmlFor="n_password">New Password</label>
                                                    {/* <input
                                                        id="n_password"
                                                        type="password"
                                                        name="n_password"
                                                        onChange={this.handleSetValue}
                                                        className="form-control"
                                                        placeholder="New Password"
                                                    /> */}
                                                    <ReactPasswordStrength
                                                    className=""
                                                    minLength={8}
                                                    minScore={2}
                                                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                                    changeCallback={this.handleSetValuePassword}
                                                    inputProps={{ type: this.state.forgotpassword_type, name: "n_password", autoComplete: "off", className: "form-control", placeholder: "Please enter new password" }}
                                                    />
                                                    <div className="pass-wrapper pass-wrapper-signup-password">
                                                        {this.state.forgotpassword_type == 'password' ?
                                                            <i onClick={() => { this.changeRegisterPasswordType('text') }} className="fa fa-eye"></i>
                                                        :
                                                            <i onClick={() => { this.changeRegisterPasswordType('password') }} className="fa fa-eye-slash"></i>
                                                        }
                                                    </div>
                                                    {this.state.validationFormErrors.isEmptyNPassword ?
                                                        <span className="errorInput">Please enter new password</span>
                                                    : null
                                                    }
                                                    {this.state.validationFormErrors.isNotValidPassword ?
                                                    <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required<br></br></span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="c_password">Confirm Password</label>
                                                    {/* <input
                                                        id="c_password"
                                                        type="password"
                                                        name="c_password"
                                                        onChange={this.handleSetValue}
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                    /> */}
                                                    <ReactPasswordStrength
                                                    className=""
                                                    minLength={8}
                                                    minScore={2}
                                                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                                    changeCallback={this.handleSetValueCPassword}
                                                    inputProps={{ type: this.state.forgotpassword_type_c, name: "c_password", autoComplete: "off", className: "form-control", placeholder: "Please enter confirm password" }}
                                                    />
                                                    <div className="pass-wrapper pass-wrapper-signup-password">
                                                        {this.state.forgotpassword_type_c == 'password' ?
                                                            <i onClick={() => { this.changeRegisterCPasswordType('text') }} className="fa fa-eye"></i>
                                                        :
                                                            <i onClick={() => { this.changeRegisterCPasswordType('password') }} className="fa fa-eye-slash"></i>
                                                        }
                                                    </div>
                                                    {this.state.validationFormErrors.isEmptyCPassword ?
                                                        <span className="errorInput">Please enter confirm password</span>
                                                    : null
                                                    }
                                                    {this.state.validationFormErrors.isNotSameBothPassword ?
                                                    <span className="errorInput">Password and confirm password must be same <br></br></span>
                                                    : null
                                                    }
                                                    {this.state.validationFormErrors.isNotValidCPassword ?
                                                    <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required<br></br></span>
                                                    : null
                                                    }
                                                </div>
                                                <button type="submit" onClick={() => { this.updatePassword() }} className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                    Update
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

export default AccountPageForgotPassword;