// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Pagination from '../shared/Pagination';
import PostCard from '../shared/PostCard';
import BlogSidebar from './BlogSidebar';
import { Config } from '../../config';

// data stubs
//import posts from '../../data/blogPosts';
import theme from '../../data/theme';
import shopApi from '../../api/shop';

export default class BlogPageCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            posts: [],
            metaTags: {
                meta_desc: "",
                meta_keywords: '',
                meta_title: "",
            },
        };
    }

    async componentDidMount() {
        this.getBlogs();
        this.loadMetaTags();
    }

    loadMetaTags(){
        var request = shopApi.getMetaTagsBySlug('blog');
        request.then((metaTags) => {
            this.setState({ metaTags : metaTags.data });
        });
      }

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };

    getBlogs(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/get-blog-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(async response => {
            if(response.status){
               await this.setState({ posts: response.data });
            }
        })
        .catch(err => {
            //console.log("getBlogs err: ", err);
        });
    }


    render() {
        const { layout, sidebarPosition } = this.props;
        const { page } = this.state;

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Blog', url: '' }
        ];

        let sidebarStart;
        let sidebarEnd;

        //const sidebar = <BlogSidebar position={sidebarPosition} />;

        // if (sidebarPosition === 'start') {
        //     sidebarStart = <div className="col-12 col-lg-4 order-1 order-lg-0">{sidebar}</div>;
        // } else if (sidebarPosition === 'end') {
        //     sidebarEnd = <div className="col-12 col-lg-4">{sidebar}</div>;
        // }

        const postsList = this.state.posts.map((post) => {
            const postLayout = {
                classic: 'grid-lg',
                grid: 'grid-nl',
                list: 'list-nl',
            }[layout];

            return (
                <div key={post.id} className="col-12 col-lg-4">
                    <PostCard post={post} layout={postLayout} />
                </div>
            );
        });

        const metaTags = this.state.metaTags;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_desc} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    {this.state.posts.length > 0 &&
                        <meta property="twitter:image" content={this.state.posts[0].blog_image} />
                    }
                    <meta name="twitter:description" content={metaTags.meta_desc} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_desc} />
                    {this.state.posts.length > 0 &&
                        <meta property="og:image" content={this.state.posts[0].blog_image} />
                    }
                    <meta property="og:url" content={ Config.DOMAIN + '/blog' } />
                    <link rel="canonical" href={ Config.DOMAIN + '/blog' } />
                </Helmet>

                <PageHeader header="Latest Blog" breadcrumb={breadcrumb} />

                <div className="container">
                    <div className="row">
                        {sidebarStart}
                        <div className="col-12 col-lg-12">
                            <div className="block">
                                <div className="posts-view">
                                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                        <div className="posts-list__body row">
                                            {postsList}
                                        </div>
                                    </div>
                                    {/* <div className="posts-view__pagination">
                                        <Pagination
                                            current={page}
                                            siblings={2}
                                            total={this.state.posts.length}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {sidebarEnd}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

BlogPageCategory.propTypes = {
    /**
     * blog layout
     * one of ['classic', 'grid', 'list'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

BlogPageCategory.defaultProps = {
    layout: 'classic',
    sidebarPosition: 'start',
};
