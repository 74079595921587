// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ShareSocial } from 'react-share-social'


// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import { Config } from '../../config';
import { toast } from 'react-toastify';
import { url } from '../../services/utils';
import BlockLoader from '../blocks/BlockLoader';
import shopApi from '../../api/shop';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            product: props.product,
            isLoading: false
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
        // let headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');

        // this.setState({ isLoading: true });
        // fetch(Config.URL+"/get-product-details", {
        //     method: "POST",
        //     headers: headers,
        //     body: JSON.stringify({ final_item_code : this.state.product.final_item_code })
        // })
        // .then(response => response.json())
        // .then(response => {
        //     this.setState({ isLoading: false });
        //     if(response.status){
        //         if(response.data.qty >= quantity){
        //             this.setState({ quantity });
        //         } else {
        //             toast.error("Sorry we have only " + (response.data.qty) + " quantity available for this item");
        //         }
        //     } else {
        //         toast.error("Invalid item found for update quantity");
        //     }
        // })
        // .catch(err => {
        //     this.setState({ isLoading: false });
        //     console.log("Product err: ", err);
        //     return;
        // });
    };

    notifyMe = (id) => {
        if(shopApi.getAuth() == null){
            toast.error("Please login to use notify feature!");
        } else {
            this.setState({ isLoading: true });
            shopApi.notifyMe(id).then((response) => {
                this.setState({ isLoading: false });
                if(response.status){
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            });
        }
    };

    handleSetProduct = (final_item_code) => {
        this.setState({ isLoading: true });
        shopApi.getProductByFinalItemCode(final_item_code).then((response) => {
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ product: response.data });
            }
        });
    };

    render() {
        const {
            //product,
            layout,
            wishlistAddItem,
            compareAddItem,
            cartAddItem
        } = this.props;
        const { quantity, isLoading } = this.state;
        let prices;

        let product = this.state.product;
        //this.props.product = this.state.product;

        let discount = product.discount !== undefined ? product.discount : 0;
        product.compareAtPrice = false;
        product.price = product.selling_price;
        product.discount_price = 0;
        if(discount > 0){
            let pp = parseFloat(product.selling_price) * parseInt(discount) / 100;
            let fpp = parseFloat(product.selling_price) - parseFloat(pp);
            product.compareAtPrice = fpp;
            product.price = fpp;
            product.discount_price = pp;
        }

        if (isLoading) {
            return <BlockLoader />;
        }

        const brand = {
            name : product.brand_name
        }

        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price"><Currency value={product.compareAtPrice} /></span>
                    {' '}
                    <span className="product__old-price"><Currency value={product.selling_price} /></span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.selling_price} />;
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    {product.product_images && product.product_images.length > 0  &&
                        <ProductGallery layout={layout} images={product.product_images} />
                    }

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-svg-icon', {
                                            'btn-loading': loading,
                                            'btn-light': !product.is_wish_listed,
                                            'btn-primary': product.is_wish_listed
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            {/* <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            /> */}
                        </div>
                        <h1 className="product__name">{product.full_name}</h1>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={(product.product_avg_rating !== null && product.product_avg_rating !== 0 ? product.product_avg_rating : 4)} />
                            </div>
                            <div className="product__rating-legend">
                                {`${product.product_total_reviews !== undefined && product.product_total_reviews !== 0 ? product.product_total_reviews : 10} Reviews`}
                                <span>/</span>
                                <Link hrefLang={"en"}  to="/">Write A Review</Link>
                            </div>
                        </div>
                        <div className="product__description">
                            {(product.description).toLowerCase()}
                            {product.category_description !=='' &&
                                <span className="text-danger">
                                    <br />
                                    <span dangerouslySetInnerHTML={{ __html: (product.category_description).toLowerCase() }}></span>
                                </span>
                            }
                            <br />
                            {product.express_delivery_image !=='' && <img src={product.express_delivery_image} style={{width: 100, height: 'auto'}} />}
                        </div>
                        {/* <ul className="product__features">
                            <li>&nbsp;</li>
                        </ul> */}
                        <ul className="product__meta">
                            {product.qty > 0 &&
                            <li className="product__meta-availability">
                                Availability:
                                {' '}
                                <span className="text-success">In Stock</span>
                            </li>
                            }
                            {product.qty <= 0 &&
                            <li className="product__meta-availability">
                                Availability:
                                {' '}
                                <span className="text-danger product__new-price" style={{fontWeight: 'bold', fontFamily:'arial'}}>Out of stock</span>
                            </li>
                            }
                            <li>
                                Brand:&nbsp;
                                <Link hrefLang={"en"}  to={url.brand(brand)}>
                                    <span className="text-success">{(product.brand_name).toLowerCase()}</span>
                                </Link>
                            </li>
                            <li>SKU: {product.final_item_code}</li>
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        {/* <div className="product__availability">
                            Availability:
                            {' '}
                            <span className="text-success">In Stock</span>
                        </div> */}

                        <div className="product__prices">
                            {prices}
                        </div>

                        <form className="product__options">
                            {/* <div className="form-group product__option">
                                <div className="product__option-label">Color</div>
                                <div className="input-radio-color">
                                    <div className="input-radio-color__list">
                                        {product.colors.length > 0 ? (
                                            product.colors.map((color) => {
                                                return(
                                                    <label
                                                        className="input-radio-color__item"
                                                        style={{ color: color.name }}
                                                        data-toggle="tooltip"
                                                        title={color.name}
                                                    >
                                                        <input type="radio" checked name="color" value={color.id} />
                                                        <span />
                                                    </label>
                                                )
                                            })
                                        ) : (
                                            null
                                        )}
                                        <label
                                            className="input-radio-color__item input-radio-color__item--white"
                                            style={{ color: product.color_name }}
                                            data-toggle="tooltip"
                                            title={product.color_name}
                                        >
                                            <input type="radio" checked name="color" value={product.color_id} />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ffd333' }}
                                            data-toggle="tooltip"
                                            title="Yellow"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ff4040' }}
                                            data-toggle="tooltip"
                                            title="Red"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item input-radio-color__item--disabled"
                                            style={{ color: '#4080ff' }}
                                            data-toggle="tooltip"
                                            title="Blue"
                                        >
                                            <input type="radio" name="color" disabled />
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div> */}

                            {product.colors.length > 0 ? (
                            <div className="form-group product__option">
                                <div className="product__option-label">Color</div>
                                <div className="input-radio-color">
                                    <div className="input-radio-color__list">
                                        {product.colors.map((color, key) => {
                                                return(
                                                    <React.Fragment key={"color_"+key}>
                                                    <label
                                                        //className="input-radio-color__item input-radio-color__item--white"
                                                        className={((color.name).split(" ")[0]).toLowerCase() == 'white' ? (color.qty <= 0 ? "input-radio-color__item input-radio-color__item--disabled" : "input-radio-color__item input-radio-color__item--white") : (color.qty <= 0 ? "input-radio-color__item input-radio-color__item--disabled" : "input-radio-color__item") }
                                                        style={{ color: (color.name).split(" ")[0] }}
                                                        data-toggle="tooltip"
                                                        title={color.name}
                                                    >
                                                        <input
                                                            onChange={() => { this.handleSetProduct(color.final_item_code) }}
                                                            type="radio"
                                                            checked={(product.color_id == color.id ? true : false)}
                                                            name="color"
                                                            //disabled={color.qty <= 0 ? true : false}
                                                            value={color.id}
                                                        />
                                                        <span />
                                                    </label>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            ) : (
                                null
                            )}

                            {product.sizes.length > 0 ? (
                            <div className="form-group product__option">
                                <div className="product__option-label">Size</div>

                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                    {product.sizes.map((size, key) => {
                                            return(
                                                <React.Fragment key={"sizes_"+key}>
                                                <label>
                                                    <input
                                                        onChange={() => { this.handleSetProduct(size.final_item_code) }}
                                                        checked={(product.size_id == size.id ? true : false)}
                                                        type="radio"
                                                        name="sizes"
                                                        //disabled={size.qty <= 0 ? true : false}
                                                        value={size.id}
                                                    />
                                                    <span className={(size.qty <= 0 ? "disable_attributes" : '')}>{size.name}</span>
                                                </label>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                            ) : (
                                null
                            )}

                            {/* {product.styles.length > 0 ? (
                            <div className="form-group product__option">
                                <div className="product__option-label">Style</div>

                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                    {product.styles.map((style) => {
                                            return(
                                                <label>
                                                    <input
                                                        //onChange={() => { this.handleSetProduct(style.final_item_code) }}
                                                        //checked={(product.style_id == style.id ? true : false)}
                                                        type="radio"
                                                        name="styles"
                                                        disabled={style.qty <= 0 ? true : false}
                                                        value={style.id}
                                                    />
                                                    <span>{(style.name).toLowerCase()}</span>
                                                </label>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                            ) : (
                                null
                            )} */}

                            {/* {product.suit_types.length > 0 ? (
                            <div className="form-group product__option">
                                <div className="product__option-label">Suit Types</div>

                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                    {product.suit_types.map((suit_type) => {
                                            return(
                                                <label>
                                                    <input
                                                        //onChange={() => { this.handleSetProduct(suit_type.final_item_code) }}
                                                        //checked={(product.suite_type_id == suit_type.id ? true : false)}
                                                        type="radio"
                                                        name="suit_types"
                                                        disabled={suit_type.qty <= 0 ? true : false}
                                                        value={suit_type.id}
                                                    />
                                                    <span>{(suit_type.name).toLowerCase()}</span>
                                                </label>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                            ) : (
                                null
                            )} */}

                            {/* {product.season.length > 0 ? (
                            <div className="form-group product__option">
                                <div className="product__option-label">Season</div>

                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                    {product.season.map((seasonObj) => {
                                            return(
                                                <label>
                                                    <input
                                                        //onChange={() => { this.handleSetProduct(seasonObj.final_item_code) }}
                                                        //checked={(product.season_id == seasonObj.id ? true : false)}
                                                        type="radio"
                                                        name="season"
                                                        disabled={seasonObj.qty <= 0 ? true : false}
                                                        value={seasonObj.id}
                                                    />
                                                    <span>{(seasonObj.name).toLowerCase()}</span>
                                                </label>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                            ) : (
                                null
                            )} */}

                            {/* <div className="form-group product__option">
                                <div className="product__option-label">Material</div>
                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Metal</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Wood</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" disabled />
                                            <span>Plastic</span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="form-group product__option">
                                {product.qty > 0 &&
                                    <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                                }
                                <div className="product__actions">
                                    {product.qty > 0 ?
                                    <>
                                        <div className="product__actions-item">
                                            <InputNumber
                                                id="product-quantity"
                                                aria-label="Quantity"
                                                className="product__quantity"
                                                size="lg"
                                                min={1}
                                                value={quantity}
                                                onChange={this.handleChangeQuantity}
                                            />
                                        </div>
                                        <div className="product__actions-item product__actions-item--addtocart">
                                            <AsyncAction
                                                action={() => cartAddItem(product, [], quantity)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        disabled={!quantity || quantity > product.qty}
                                                        className={classNames('btn btn-primary btn-lg', {
                                                            'btn-loading': loading,
                                                        })}
                                                    >
                                                        Add to cart
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <button onClick={() => { this.notifyMe(product.id) }} type="button" className="btn btn-danger btn-lg">Notify Me</button>
                                    </div>
                                    }

                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                        'btn-secondary': !product.is_wish_listed,
                                                        'btn-primary': product.is_wish_listed
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    {/* <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        {/* <div className="product__tags tags">
                            <div className="tags__list">
                                <Link hrefLang={"en"}  to="/">Mounts</Link>
                                <Link hrefLang={"en"}  to="/">Electrodes</Link>
                                <Link hrefLang={"en"}  to="/">Chainsaws</Link>
                            </div>
                        </div> */}

                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <ShareSocial
                                    onSocialButtonClicked={
                                        data => shopApi.g4SocialEventPush('social_click', (data == 'facebook clicked.' ? 'facebook.com' : 'twitter.com'), window.location.href)
                                    }
                                    style={style}
                                    url={Config.DOMAIN + location.pathname}
                                    socialTypes={['facebook','twitter']}
                                />
                                {/* <li className="share-links__item share-links__item--type--like"><Link hrefLang={"en"}  to="/">Like</Link></li>
                                <li className="share-links__item share-links__item--type--tweet"><Link hrefLang={"en"}  to="/">Tweet</Link></li> */}
                                {/* <li className="share-links__item share-links__item--type--pin"><Link hrefLang={"en"}  to="/">Pin It</Link></li>
                                <li className="share-links__item share-links__item--type--counter"><Link hrefLang={"en"}  to="/">4K</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const style = {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // borderRadius: 3,
    // border: 0,
    // color: 'white',
    // padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(Product);
