// react
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';

// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';
import { Config } from '../../config';
import shopApi from '../../api/shop';
import { url } from '../../services/utils';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockCategories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: []
        };
    }

    async componentDidMount() {
        await this.getCategories();
    }

    getCategories(){

        shopApi.getCategories().then((response) => {
            if(response.status){
                this.setState({ categories: response.data });
            }
        });        
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    redirect(group){
        //alert(group.url);
        //this.props.history.push("/home");
        return <Redirect to='/profile' />
    }
    
    render() {

        const { title, layout, groups, onGroupClick } = this.props;

        const categoriesList = this.state.categories.map((category, index) => {
            const classes = `block-categories__item category-card category-card--layout--${layout}`;

            const subcategories = category.brands.map((sub, subIndex) => {
                if(subIndex > 3){
                    return (
                        null
                    )
                } else {                    
                    return (
                        <React.Fragment key={"subcategories_"+subIndex}>
                            <li>
                                {/* <Link hrefLang={"en"}  to={url.brand(sub)}> */}
                                <Link hrefLang={"en"}  to={{ pathname : url.category_brand(category, sub) }}>
                                    {(sub.name).toLowerCase()}
                                </Link>
                            </li>
                        </React.Fragment>
                    )
                }
            });

            return (   
                <React.Fragment key={"categories_"+index}>
                    <div className={classes}>
                    <div className="category-card__body">
                        <div className="category-card__image">
                            {/* <Link hrefLang={"en"}  to={url.category(category)}> */}
                            <Link hrefLang={"en"}  to={{ pathname : url.category(category)}}>
                                <LazyLoad height={200} height={200} offset={[-200, 0]} placeholder={<PlaceholderComponent />} debounce={500} >
                                    <img src={category.image} alt={category.name} title={category.name} />
                                </LazyLoad>
                            </Link>
                        </div>
                        <div className=" category-card__content">
                            <div className=" category-card__name">
                                <Link hrefLang={"en"}  to={{ pathname : url.category(category)}}>
                                    {(category.name).toLowerCase()}
                                </Link>
                            </div>
                            <ul className="category-card__links">
                                {subcategories}
                            </ul>
                            <div className="category-card__all">
                                <Link hrefLang={"en"}  to={{ pathname : url.category(category)}}>
                                    Show All
                                </Link>
                            </div>
                            {/* <div className="category-card__products">
                                {`${category.products} Products`}
                            </div> */}
                        </div>
                    </div>
                </div>                
                </React.Fragment>
            );
        });        

        return (
            // <div className={`block block--highlighted block-categories block-categories--layout--${layout}`}>
            <>
            {this.state.categories.length > 0 &&
                <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            groups={groups}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                            onGroupClick={onGroupClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {categoriesList}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

BlockCategories.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['classic', 'compact', 'list-sm']),
};

BlockCategories.defaultProps = {
    layout: 'list-sm',
};
