// react
import React, { useState } from 'react';
import { toast } from 'react-toastify';

// application
import SocialLinks from '../shared/SocialLinks';
import shopApi from '../../api/shop';

export default function FooterNewsletter() {

    const [email_address, setEmailAddress] = useState('');
    const [isEmptyEmail, setEmptyEmailAddress] = useState(false);
    const [isNotValidEmail, setValidEmailAddress] = useState(false);

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    const handleSetValue = e => {
        e.persist();
        setEmailAddress(e.target.value);
    }

    /**
     * [handleSubmitForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    const handleSubmitForm = e => {
        e.preventDefault();
    }

    /**
     * [submitNewsLetter submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    const submitNewsLetter = e => {
        setEmptyEmailAddress(false);
        setValidEmailAddress(false);
        if(email_address == ''){
            setEmptyEmailAddress(true);
        }
        if(email_address !=='' && !isValidEmailAddress(email_address)){
            setValidEmailAddress(true);
        } else {
            shopApi.subscribeToNewsLetter(email_address).then((response) => {
                if(response.status){
                    shopApi.g4NewsLetterEventPush('newsletter_inquiry_form', email_address)
                    toast.success(response.message);
                    setEmailAddress('');
                }
            });
        }
    }

    const isValidEmailAddress = (emailAddress) => {
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
                Be the first to receive news on exclusive special offers.
            </div>

            <form action="#" onSubmit={handleSubmitForm} className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                <input
                    type="email"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                    onChange={handleSetValue}
                    value={email_address}
                />
                <button type="button" onClick={submitNewsLetter} className="footer-newsletter__form-button btn btn-primary">Subscribe</button>
            </form>
            {isEmptyEmail ?
                <span className="errorInput">Please enter email address</span>
            : null
            }
            {isNotValidEmail ?
                <span className="errorInput">Please enter a valid email address</span>
            : null
            }

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Follow us on social networks
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
