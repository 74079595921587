// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import PromotionCard from '../shared/PromotionCard';
import { sidebarOpen } from '../../store/sidebar';


function PromotionsView(props) {
    const {
        isLoading,
        promotionList,
        layout,
        grid,        
    } = props;    

    let [promotionListItems, setPromotions] = useState(promotionList.data);

    const promotionsListItems = promotionListItems.map((promotion) => (
        <div key={promotion.id} className="products-list__item categories">
            <PromotionCard promotion={promotion} />
        </div>
    ));

    const rootClasses = classNames('products-view', {
        'products-view--loading': isLoading,
    });

    const filiterItems = (event) => {
        let value = event.target.value.toLowerCase();
        let results = [];
        results = promotionList.data.filter((data) => {
            return ((data.name).toLowerCase()).search(value) != -1 || ((data.short_desc).toLowerCase()).search(value) != -1;
        });      
        setPromotions(results);
    }
    
    let content;

    if (promotionListItems.length > 0) {
        content = (            
            <div className="products-view__content">
                {promotionListItems.length > 1 &&
                    <span style={{float: 'right', width: '100%', textAlign: 'right'}}>
                        <input onChange={(e) => filiterItems(e)} style={{float: 'right', width: '24%', textAlign: 'left'}} className="form-control" name="search" placeholder={'Search over '+promotionList.data.length+' promotions'} type="search" autoComplete="off" />
                    </span>
                }
                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">
                        {promotionsListItems}
                    </div>
                </div>                  
            </div>
        );
    } else {
        content = (
            <div className="products-view__content">
                <span style={{float: 'right', width: '100%', textAlign: 'right', marginBottom: 20}}>
                        <input onChange={(e) => filiterItems(e)} style={{float: 'right', width: '24%', textAlign: 'left'}} className="form-control" name="search" placeholder={'Search over '+promotionList.data.length+' promotions'} type="search" autoComplete="off" />
                </span>
                <div className="products-view__empty" style={{marginTop: 20}}>                
                    <div className="products-view__empty-title">No matching items</div>                
                </div>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
        </div>
    );
}

PromotionsView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
     promotionList: PropTypes.object,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

PromotionsView.defaultProps = {
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(PromotionsView);
