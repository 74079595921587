// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg, FiPaymentSecurity48Svg } from '../../svg';
import { toast } from 'react-toastify';
import lottie from "lottie-web";
import Lottie from 'react-lottie';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';
import { Config } from '../../config';
import animationOrderSuccess from '../../lotties/order_success';
import animationOrderFail from '../../lotties/order_fail';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import { cartRefresh, checkOutItems } from '../../store/cart';

class ShopPageOrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Please wait...!',
            message: 'Do not click the refresh or back button or this transaction may be interrupted or terminated.',
            order_number: props.match.params.order_number,
            showSuccess: false,
            showFail: false,
            wishlistedItems: []
        };
    }

    async componentDidMount(){
        this.props.cartRefresh();
        this.props.checkOutItems(false, false);
        this.getOrderStatus();
        this.getWishlistedItems();
    }

    getOrderStatus(){
        shopApi.getOrderStatus(this.state.order_number).then((response) => {
            //console.log("getOrderStatus response", response);
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ title: response.message.title, message: response.message.message });
                }
                if(response.data !== null){
                    if(response.data === 1){

                        shopApi.getCustomerOrderDetails({ order_number : this.state.order_number }).then((responseOrder) => {
                            if(responseOrder.status){
                                var order_items = [];
                                responseOrder.order_details.data.order_items.map((itemObj, itemPrevIndex) => {
                                    if(itemObj.item_details !== ''){
                                         if(itemObj.is_gift_card){
                                            let product_data = itemObj.item_details;
                                            product_data.final_item_code = itemObj.item_details.id;
                                            product_data.full_name = itemObj.item_details.card_no;
                                            product_data.category_name = '';
                                            product_data.brand_name = '';
                                            product_data.color_name = '';
                                            product_data.selling_price = itemObj.item_details.balance;
                                            product_data.discount_price = itemObj.item_details.discounted_amount;
                                            product_data.size_name = '';
                                            product_data.gift_card_id = itemObj.item_details.id;
                                            order_items.push(product_data);
                                        } else {
                                            itemObj.item_details.gift_card_id = 0;
                                            order_items.push(itemObj.item_details);
                                        }
                                        if(itemPrevIndex === responseOrder.order_details.data.order_items.length -1){
                                            shopApi.g4PurchaseEventPush("purchase", order_items, responseOrder.order_details.amounts, 'success', responseOrder.order_details.data.payment_method);
                                        }
                                    }
                                });
                            }
                        });

                        //shopApi.triggerPurchaseGTag(this.state.order_number);
                        this.setState({ showSuccess: true, showFail: false });
                    } else {

                        shopApi.getCustomerOrderDetails({ order_number : this.state.order_number }).then((responseOrder) => {
                            if(responseOrder.status){
                                var order_items = [];
                                responseOrder.order_details.data.order_items.map((itemObj, itemPrevIndex) => {
                                    if(itemObj.item_details !== ''){
                                        if(itemObj.is_gift_card){
                                            let product_data = itemObj.item_details;
                                            product_data.final_item_code = itemObj.item_details.id;
                                            product_data.full_name = itemObj.item_details.card_no;
                                            product_data.category_name = '';
                                            product_data.brand_name = '';
                                            product_data.color_name = '';
                                            product_data.selling_price = itemObj.item_details.balance;
                                            product_data.discount_price = itemObj.item_details.discounted_amount;
                                            product_data.size_name = '';
                                            product_data.gift_card_id = itemObj.item_details.id;
                                            order_items.push(product_data);
                                        } else {
                                            itemObj.item_details.gift_card_id = 0;
                                            order_items.push(itemObj.item_details);
                                        }
                                        if(itemPrevIndex === responseOrder.order_details.data.order_items.length -1){
                                            shopApi.g4PurchaseEventPush("purchase", order_items, responseOrder.order_details.amounts, 'fail', responseOrder.order_details.data.payment_method);
                                        }
                                    }
                                });
                            }
                        });

                        this.setState({showSuccess: false, showFail: true });
                    }
                }
             } else {
                toast.error(response.message);
                this.props.history.push("/404");
             }
        });
    }

    getWishlistedItems(){
        shopApi.getWishListItems().then((response) => {
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ wishlistedItems: response.data });
                }
             }
        });
    }

    render() {

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Order ${this.state.showSuccess ? 'Success' : 'Fail'} — ${theme.name}`}</title>
                </Helmet>

                <div className="container">
                    <div className="order-success__body">

                        <div className="order-success__header">
                            {this.state.showSuccess &&
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: this.state.showSuccess,
                                    animationData: animationOrderSuccess,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={300}
                                width={300}
                            />
                            }
                            {this.state.showFail &&
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: this.state.showFail,
                                    animationData: animationOrderFail,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={300}
                                width={300}
                            />
                            }
                            <h1 className="order-success__title">{this.state.title}</h1>
                            <div className="order-success__subtitle">{this.state.message}</div>
                            <div className="order-success__actions">
                                <Link hrefLang={"en"}  to="/" className="btn btn-xs btn-secondary">Continue Shopping</Link>
                            </div>
                        </div>

                    </div>
                    {this.state.wishlistedItems.length > 0 && (
                            <BlockProductsCarousel
                                title="Your Wishlist"
                                layout="grid-5"
                                products={this.state.wishlistedItems}
                            />
                    )}
                </div>
            </div>
        );

    }
}


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = { cartRefresh, checkOutItems };

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderStatus);