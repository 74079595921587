// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import PageHeader from '../shared/PageHeader';
import BrandsView from './BrandsView';
import shopApi from '../../api/shop';
import { sidebarClose } from '../../store/sidebar';
import { url } from '../../services/utils';
import { Config } from '../../config';

// data stubs
import theme from '../../data/theme';
const initialState = {
    init: false,
    /**
     * Indicates that the brand list is loading.
     */
    brandListIsLoading: true,
    /**
     * Brands list.
     */
    brandList: null
};

function reducer(state, action) {
    switch (action.type) {
    case 'FETCH_BRANDS_LIST':
        return { ...state, brandListIsLoading: true };
    case 'FETCH_BRANDS_SUCCESS':
        return {
            ...state,
            init: true,
            brandListIsLoading: false,
            brandList: action.brandList,
        };
    default:
        throw new Error();
    }
}

function init(state) {
    return { ...state };
}

function ShopPageBrands(props) {
    const {
        columns,
        viewMode
    } = props;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [metaTags, setMetaTags] = useState({
        data: {
            content_1: '',
            content_2: '',
            h1_title: '',
            h2_title: '',
            id: '',
            meta_desc: '',
            meta_keywords: '',
            meta_title: '',
            page_name: '',
        }
    });

    // Load brands.
    useEffect(() => {
        let request;
        let canceled = false;

        dispatch({ type: 'FETCH_BRANDS_LIST' });

        request = shopApi.getBrands();

        request.then((brandList) => {
            if (canceled) {
                return;
            }

            request = shopApi.getMetaTagsBySlug('brands');
            request.then((metaTags) => {
                if (canceled) {
                    return;
                }
                setMetaTags(metaTags);
            });
            dispatch({ type: 'FETCH_BRANDS_SUCCESS', brandList });
        });

        return () => {
            canceled = true;
        };
    }, [dispatch, '']);

    // // Load products.
    // useEffect(() => {
    //     let canceled = false;

    //     dispatch({ type: 'FETCH_PRODUCTS_LIST' });

    //     shopApi.getProductsList(
    //         state.options,
    //         { ...state.filters, category: categorySlug },
    //     ).then((productsList) => {
    //         if (canceled) {
    //             return;
    //         }

    //         dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
    //     });

    //     return () => {
    //         canceled = true;
    //     };
    // }, [dispatch, categorySlug, state.options, state.filters]);

    // // Load latest products.
    // useEffect(() => {
    //     let canceled = false;

    //     if (offcanvas === 'always') {
    //         setLatestProducts([]);
    //     } else {
    //         shopApi.getLatestProducts({ limit: 5 }).then((result) => {
    //             if (canceled) {
    //                 return;
    //             }

    //             setLatestProducts(result);
    //         });
    //     }

    //     return () => {
    //         canceled = true;
    //     };
    // }, [offcanvas]);

    if (state.brandListIsLoading && !state.brandList) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Brands', url: url.brands() },
    ];
    let pageTitle = 'Brands';
    let content;

    const brandsView = (
        <BrandsView
            isLoading={state.brandListIsLoading}
            brandList={state.brandList}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
        />
    );

    content = (
        <div className="container">
            <div className="block">{brandsView}</div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.data.meta_title}</title>
                <meta name="title" content={metaTags.data.meta_title} />
                <meta name="keywords" content={metaTags.data.meta_keywords} />
                <meta name="description" content={metaTags.data.meta_desc} />
                <meta name="twitter:title" content={metaTags.data.meta_title} />
                {state.brandList &&
                    <meta property="twitter:image" content={state.brandList.data[0].image} />
                }
                <meta name="twitter:description" content={metaTags.data.meta_desc} />
                <meta property="og:title" content={metaTags.data.meta_title} />
                <meta property="og:description" content={metaTags.data.meta_desc} />
                {state.brandList &&
                    <meta property="og:image" content={state.brandList.data[0].image} />
                }
                <meta property="og:url" content={ Config.DOMAIN + url.brand_all() } />
                <link rel="canonical" href={ Config.DOMAIN + url.brand_all() } />
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageBrands.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageBrands.defaultProps = {
    columns: 4,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageBrands);