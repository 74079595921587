// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// application
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { url } from '../../services/utils';

function MobileLinks(props) {
    let { links, category, level, onItemClick, cat_id } = props;

    let handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    let linksList = links.map((link, index) => {
        let item;
        // if (link.type === 'link' || link.type === 'button') {
         if (link.name !== '') {
            item = (
                <Collapse
                    toggleClass="mobile-links__item--open"
                    render={({ toggle, setItemRef, setContentRef }) => {
                        let arrow;
                        let subLinks;
                        let linkOrButton;

                        if (link.brands && link.brands.length > 0) {
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    <MobileLinks
                                        category={link}
                                        links={link.brands}
                                        cat_id={link.id}
                                        level={level + 1}
                                        onItemClick={onItemClick}
                                    />
                                </div>
                            );
                        }

                        // linkOrButton = (
                        //     <button
                        //         type="button"
                        //         className="mobile-links__item-link"
                        //         onClick={() => handleItemClick(link)}
                        //     >
                        //         {link.name}
                        //     </button>
                        // );
                        if(!link.brands){
                            linkOrButton = (
                                <AppLink
                                    to={{ pathname : url.category_brand(category, link) }}
                                    //to={{ pathname : url.brand(link) }}
                                    onClick={() => onItemClick(link, 'brand')}
                                    className="mobile-links__item-link"
                                >
                                    {(link.name).toLowerCase()}
                                </AppLink>
                            );
                        } else {
                            linkOrButton = (
                                <button
                                    type="button"
                                    className="mobile-links__item-link"
                                    onClick={() => onItemClick(item, 'category')}
                                >
                                    <Link hrefLang={"en"}  style={{color: '#333'}} to={url.category(link)}>
                                        {(link.name).toLowerCase()}
                                    </Link>
                                </button>
                            );
                        }

                        // if (link.type === 'link') {
                        //     linkOrButton = (
                        //         <AppLink
                        //             to={link.url}
                        //             className="mobile-links__item-link"
                        //             onClick={() => handleItemClick(link)}
                        //         >
                        //             {link.name}
                        //         </AppLink>
                        //     );
                        // } else {
                        //     linkOrButton = (
                        //         <button
                        //             type="button"
                        //             className="mobile-links__item-link"
                        //             onClick={() => handleItemClick(link)}
                        //         >
                        //             {link.brands}
                        //         </button>
                        //     );
                        // }

                        return (
                            <div className="mobile-links__item" ref={setItemRef}>
                                <div className="mobile-links__item-title">
                                    {linkOrButton}
                                    {arrow}
                                </div>
                                {subLinks}
                            </div>
                        );
                    }}
                />
            );
        }
        // } else if (link.type === 'divider') {
        //     item = <div className="mobile-links__divider" />;
        // }

        return (
            <li key={index}>{item}</li>
        );
    });

    return (
        <ul className={`mobile-links mobile-links--level--${level}`}>
            {linksList}
        </ul>
    );
}

MobileLinks.propTypes = {
    category: PropTypes.object,
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    category: {},
    links: [],
    level: 0,
};

export default MobileLinks;
