// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';

// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import shopApi from '../../api/shop';

import { url } from '../../services/utils';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockVideos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            videos: []
        };
    }

    async componentDidMount() {
        await this.getVideos();
    }

    getVideos(){
        shopApi.getVideos().then((response) => {
            if(response.status){                
                this.setState({ videos: response.data });
            }
        });        
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };
    
    fetchThumb(typedUrl){
        let tempVar = typedUrl;
        var videoID = tempVar.split("?v=")[1];
        if(videoID.split("&")[0] !== undefined && videoID.split("&")[0] !== null && videoID.split("&")[0] !== ''){
            videoID = videoID.split("&")[0];
        }        
        return "https://img.youtube.com/vi/"+videoID+"/0.jpg";
     }

    render() {
        
        const { title, layout, groups, onGroupClick } = this.props;

        const videoList = this.state.videos.map((video, index) => (
            <div key={index} className="block-brands__item video-list">
                <Link hrefLang={"en"}  to={{ pathname : video.video_url }} target={'_blank'}>
                    {/* <LazyLoad height={200} height={200} offset={[-200, 0]} placeholder={<PlaceholderComponent />} debounce={500} > */}
                        <img alt={video.video_title} title={video.video_title} src={this.fetchThumb(video.video_url)} />
                    {/* </LazyLoad> */}
                </Link>
                <span>{video.video_title}</span>
            </div>
        ));
        
        return (
            <>
            {this.state.videos.length > 0 && 
                <div className={`block block-brands block-brands--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            groups={groups}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                            onGroupClick={onGroupClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {videoList}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

BlockVideos.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
};

BlockVideos.defaultProps = {
    layout: 'list-sm'    
};
