// react
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// third-party
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// application
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';

// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';
//import ReactGA from 'react-ga';

//ReactGA.initialize('UA-130893356-1');
//ReactGA.initialize('G-CK9ES47D2R');

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function(registration) {
        //console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        //console.log("Service worker registration failed, error:", err);
      });
  }


// var rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<React.Fragment>
//     <Provider store={store}>
//         <Root />
//     </Provider>
//   </React.Fragment>, rootElement);
// } else {
//   ReactDOM.render(<React.Fragment>
//     <Provider store={store}>
//         <Root />
//     </Provider>
//   </React.Fragment>, rootElement);
// }


ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lc-9jsmAAAAAFbySp2RiUQTuj-OE-6EkFEFycwd"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <React.Fragment>
        <Provider store={store}>
            <Root />
        </Provider>
      </React.Fragment>
    </GoogleReCaptchaProvider>
), document.getElementById('root'));

// ReactDOM.render((
//     // eslint-disable-next-line react/jsx-filename-extension
//     <React.Fragment>
//       <Provider store={store}>
//           <Root />
//       </Provider>
//     </React.Fragment>
// ), document.getElementById('root'));

// const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
// renderMethod(
//   <React.StrictMode>
//       <Provider store={store}>
//           <Root />
//       </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
