// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import PageHeader from '../shared/PageHeader';
import CategoriesView from './CategoriesView';
import shopApi from '../../api/shop';
import { sidebarClose } from '../../store/sidebar';
import { url } from '../../services/utils';
import { Config } from '../../config';

// data stubs
import theme from '../../data/theme';
const initialState = {
    init: false,
    /**
     * Indicates that the category list is loading.
     */
    categoryListIsLoading: true,
    /**
     * Brands list.
     */
    brandList: null
};

function reducer(state, action) {
    switch (action.type) {
    case 'FETCH_CATEGORIES_LIST':
        return { ...state, categoryListIsLoading: true };
    case 'FETCH_CATEGORIES_SUCCESS':
        return {
            ...state,
            init: true,
            categoryListIsLoading: false,
            categoryList: action.categoryList,
        };
    default:
        throw new Error();
    }
}

function init(state) {
    return { ...state };
}

function ShopPageCategories(props) {
    const {
        columns,
        viewMode
    } = props;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [metaTags, setMetaTags] = useState({
        data: {
            content_1: '',
            content_2: '',
            h1_title: '',
            h2_title: '',
            id: '',
            meta_desc: '',
            meta_keywords: '',
            meta_title: '',
            page_name: '',
        }
    });

    // Load categories.
    useEffect(() => {
        let request;
        let canceled = false;

        dispatch({ type: 'FETCH_CATEGORIES_LIST' });

        request = shopApi.getAllCategories();

        request.then((categoryList) => {
            if (canceled) {
                return;
            }

            request = shopApi.getMetaTagsBySlug('categories');
            request.then((metaTags) => {
                if (canceled) {
                    return;
                }
                setMetaTags(metaTags);
                dispatch({ type: 'FETCH_CATEGORIES_SUCCESS', categoryList });
            });

        });

        return () => {
            canceled = true;
        };
    }, [dispatch, '']);

    if (state.categoryListIsLoading && !state.categoryList) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Categories', url: url.categories() },
    ];
    let pageTitle = 'Categories';
    let content;


    const categoriesView = (
        <CategoriesView
            isLoading={state.categoryListIsLoading}
            categoryList={state.categoryList}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
        />
    );

    content = (
        <div className="container">
            <div className="block">{categoriesView}</div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.data.meta_title}</title>
                <meta name="title" content={metaTags.data.meta_title} />
                <meta name="keywords" content={metaTags.data.meta_keywords} />
                <meta name="description" content={metaTags.data.meta_desc} />
                <meta name="twitter:title" content={metaTags.data.meta_title} />
                {state.categoryList &&
                    <meta property="twitter:image" content={state.categoryList.data[0].image} />
                }
                <meta name="twitter:description" content={metaTags.data.meta_desc} />
                <meta property="og:title" content={metaTags.data.meta_title} />
                <meta property="og:description" content={metaTags.data.meta_desc} />
                {state.categoryList &&
                    <meta property="og:image" content={state.categoryList.data[0].image} />
                }
                <meta property="og:url" content={ Config.DOMAIN + url.category_all() } />
                <link rel="canonical" href={ Config.DOMAIN + url.category_all() } />
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageCategories.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageCategories.defaultProps = {
    columns: 4,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategories);