// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import StroykaSlick from '../shared/StroykaSlick';
import Rating from '../shared/Rating';

// data stubs
import brands from '../../data/shopBrands';
import { Config } from '../../config';
import { url } from '../../services/utils';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockRateYourProducts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nonRatedProducts: []
        };
    }

    async componentDidMount() {
        await this.getNonRatedProducts();
    }

    getNonRatedProducts(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        
        fetch(Config.URL+"/rate-your-products", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                if(response.status === 1){
                    this.setState({ nonRatedProducts: response.data });
                }
            }
        })
        .catch(err => {
            //console.log("getNonRatedProducts err: ", err);
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        
        const { title, layout } = this.props;

        const nonRatedProductsList = this.state.nonRatedProducts.map((nonRatedProduct, index) => (
            <div key={index} className="block-brands__item block-rate_your_product">
                <div className="rateProductMain">
                    <div className="image">
                        <img alt={nonRatedProduct.full_name} title={nonRatedProduct.full_name} src={nonRatedProduct.product_main_image} />
                    </div>
                    <div className="textSection">
                        <div className="productTitle">
                            {(nonRatedProduct.full_name).toLowerCase()}
                        </div>
                        <div className="productRatings">
                            <Rating value={0} />
                        </div>
                    </div>
                    <Link hrefLang={"en"}  title={nonRatedProduct.full_name} to={url.product_detail(nonRatedProduct)}>
                        <button type="button" className="btn btn-primary product-card__addtocart rateButton">Rate Now</button>
                    </Link>
                </div>
            </div>
        ));
        
        return (
            <>
            {this.state.nonRatedProducts.length > 0 &&                
                <div className={`block block-brands block-brands--layout--${layout}`} data-layout={layout}>
                    <div className="container">
                        <BlockHeader
                            arrows
                            title={title}
                            onNext={this.handleNextClick}
                            onPrev={this.handlePrevClick}
                        />
                        <div className="block-posts__slider">
                            <StroykaSlick 
                            {...slickSettings}
                            ref={this.setSlickRef}
                                {...slickSettings[layout]}
                            >
                                {nonRatedProductsList}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>            
            }
            </>
        );
    }
}

BlockRateYourProducts.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
};

BlockRateYourProducts.defaultProps = {
    layout: 'list-sm'    
};
