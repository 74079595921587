// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import PlaceholderComponent from '../../components/PlaceHolder';


// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

import { toast } from 'react-toastify';

import { Config } from '../../config';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}       
     */
     constructor(props) {
        super(props)
        this.state = {
            slides: []
        }
    }

    media = window.matchMedia('(min-width: 992px)');

    // slides = [
    //     {
    //         title: 'Big choice of<br>Plumbing products',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-1-ltr.jpg',
    //             rtl: 'images/slides/slide-1-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-1-full-ltr.jpg',
    //             rtl: 'images/slides/slide-1-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-1-mobile.jpg',
    //             rtl: 'images/slides/slide-1-mobile.jpg',
    //         },
    //     },
    //     {
    //         title: 'Screwdrivers<br>Professional Tools',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-2-ltr.jpg',
    //             rtl: 'images/slides/slide-2-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-2-full-ltr.jpg',
    //             rtl: 'images/slides/slide-2-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-2-mobile.jpg',
    //             rtl: 'images/slides/slide-2-mobile.jpg',
    //         },
    //     },
    //     {
    //         title: 'One more<br>Unique header',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-3-ltr.jpg',
    //             rtl: 'images/slides/slide-3-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-3-full-ltr.jpg',
    //             rtl: 'images/slides/slide-3-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-3-mobile.jpg',
    //             rtl: 'images/slides/slide-3-mobile.jpg',
    //         },
    //     },
    // ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
        this.getBanners();
    }

    getBanners(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
        fetch(Config.URL+"/get-banners", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                this.setState({ slides: response.data });
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slides = this.state.slides.map((slide, index) => {
            //const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];
            const image = slide.banner_image;
            
            let redirectLink = (slide.redirect_link ? slide.redirect_link : '/');
            let target = '_self';
            if(slide.link_type == 'category'){
                if(slide.brand_ids != null && slide.brand_ids !== ''){
                    redirectLink = slide.redirect_link+'?filter_brands='+slide.brand_ids;
                }
            }
            if(slide.link_type == 'external'){
                target = '_blank';                
            }

            return (
                <React.Fragment key={index} >
                    <Link hrefLang={"en"}  to={{ pathname : redirectLink }} target={target}>
                        <div className="block-slideshow__slide">
                            {/* <LazyLoad height={200} once={false} placeholder={<PlaceholderComponent />} debounce={500} > */}
                            <div
                                className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}
                            />
                            <div
                                className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                                style={{
                                    //backgroundImage: `url(${slide.image_mobile[direction]})`,
                                    backgroundImage: `url(${image})`,
                                }}
                            />  
                            {/* </LazyLoad> */}
                            <div className="block-slideshow__slide-content">
                                <div
                                    className="block-slideshow__slide-title"
                                    dangerouslySetInnerHTML={{ __html: slide.heading }}
                                />
                                <div
                                    className="block-slideshow__slide-text"
                                    dangerouslySetInnerHTML={{ __html: slide.description }}
                                />
                                {/* <div className="block-slideshow__slide-button">
                                    <Link hrefLang={"en"}  to="/" className="btn btn-primary btn-lg">Shop Now</Link>
                                </div> */}
                            </div>
                        </div>
                    </Link>
                </React.Fragment>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {slides}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
