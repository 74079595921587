import firebase from "firebase/app";
import "firebase/messaging";

let messaging = '';
const config = {
  apiKey: "AIzaSyADzRkqmO9u-KNCFA47AaZJkZuKeR73fho",
  authDomain: "khaliques-sa.firebaseapp.com",
  projectId: "khaliques-sa",
  storageBucket: "khaliques-sa.appspot.com",
  messagingSenderId: "83711456098",
  appId: "1:83711456098:web:9e65ae626b49199c99723b",
  measurementId: "G-CK9ES47D2R"
};

//console.log("firebase.messaging.isSupported()", firebase.messaging.isSupported());
if(firebase.messaging.isSupported()){
  const initializedFirebaseApp = firebase.initializeApp(config);
  messaging = initializedFirebaseApp.messaging();  

  messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
    "BCm7Fmzki6b_KnUGTVabPVjN9N7Trc0lT_jqe7dNf2OHOCvT8aesN7GmjBznOGDTjVoe1NgyfTxXva_Jbqc82e0"
  );
}

export { messaging };