// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';
import { Check12x9Svg } from '../../svg';

import { Config } from '../../config';

class AccountPageEmailPreferences extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: {},
            basicFormFields: {
                sale_newsletter: '',
                monthly_newsletter: '',
                product_newsletter: ''
            }
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({
                        profile: response.data,
                        basicFormFields: {
                            sale_newsletter: response.data.in_sale_newsletter,
                            monthly_newsletter: response.data.in_monthly_newsletter,
                            product_newsletter: response.data.in_product_news
                        }
                    });

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err getProfile: ", err);
            toast.error(err);
        });
    }


    updateProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};
        updateProfileJSON.product_newsletter = updateProfileJSON.product_newsletter.toString();

        fetch(Config.URL+"/update-email-preference", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.history.push('/account/dashboard');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: (e.target.checked ? 1 : 0) }
        }))
    }

    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{`Email Preferences | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>Email Preferences</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-12 col-xl-12">
                            <div className="col-12 col-lg-12 col-xl-12" style={{float: 'left', paddingLeft: 0, marginBottom: 0}}>
                                <div className="col-3 col-lg-3 col-xl-3" style={{float: 'left', paddingLeft: 0}}>
                                    <label htmlFor="profile-product-newsletter">Product News</label>
                                </div>
                                <div className="col-9 col-lg-9 col-xl-9">
                                    <label className="input-check-color__body">
                                        <input
                                            className="input-check-color__input"
                                            type="checkbox"
                                            name="product_newsletter"
                                            checked={this.state.basicFormFields.product_newsletter == "1" ? true : false}
                                            id="profile-product-newsletter"
                                            onChange={this.handleSetValue}
                                        />
                                        <span className="input-check-color__box" />
                                        <Check12x9Svg className="input-check-color__icon" />
                                        <span className="input-check-color__stick" />
                                    </label>
                                </div>
                            </div>
                            <p style={{fontSize: 12, marginBottom: 50}}>Getting started the latest product updates on KHALIQUES</p>

                            <div className="col-12 col-lg-12 col-xl-12" style={{float: 'left', paddingLeft: 0, marginBottom: 0}}>
                                <div className="col-3 col-lg-3 col-xl-3" style={{float: 'left', paddingLeft: 0}}>
                                    <label htmlFor="profile-monthly-newsletter">Monthly Newsletter</label>
                                </div>
                                <div className="col-9 col-lg-9 col-xl-9">
                                    <label className="input-check-color__body">
                                        <input
                                            className="input-check-color__input"
                                            type="checkbox"
                                            name="monthly_newsletter"
                                            checked={this.state.basicFormFields.monthly_newsletter == '1' ? true : false}
                                            id="profile-monthly-newsletter"
                                            onChange={this.handleSetValue}
                                        />
                                        <span className="input-check-color__box" />
                                        <Check12x9Svg className="input-check-color__icon" />
                                        <span className="input-check-color__stick" />
                                    </label>
                                </div>
                            </div>
                            <p style={{fontSize: 12, marginBottom: 50}}>You will get monthly updates from KHALIQUES</p>

                            <div className="col-12 col-lg-12 col-xl-12" style={{float: 'left', paddingLeft: 0, marginBottom: 0}}>
                                <div className="col-3 col-lg-3 col-xl-3" style={{float: 'left', paddingLeft: 0}}>
                                    <label htmlFor="profile-sale-newsletter">Offers &amp; Sale</label>
                                </div>
                                <div className="col-9 col-lg-9 col-xl-9">
                                    <label className="input-check-color__body">
                                        <input
                                            className="input-check-color__input"
                                            type="checkbox"
                                            name="sale_newsletter"
                                            checked={this.state.basicFormFields.sale_newsletter == '1' ? true : false}
                                            id="profile-sale-newsletter"
                                            onChange={this.handleSetValue}
                                        />
                                        <span className="input-check-color__box" />
                                        <Check12x9Svg className="input-check-color__icon" />
                                        <span className="input-check-color__stick" />
                                    </label>
                                </div>
                            </div>
                            <p style={{fontSize: 12, marginBottom: 20}}>Get update on new existing offers and sale on KHALIQUES</p>

                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageEmailPreferences;