// react
import React from 'react';
import moment from 'moment';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function PostCard(props) {    
    const { post, layout } = props;
    const regex = /(<([^>]+)>)/ig;
    const description = post.description.replace(regex, '').substring(0, 200);

    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    // const categories = post.categories.map((category, index) => (
    //     <Link hrefLang={"en"}  key={index} to="/">{category}</Link>
    // ));

    return (
        <div className={cardClasses}>
            <div className="post-card__image">
                <Link hrefLang={"en"}  to={'/blog/'+post.slug}>
                    <img src={post.blog_image_webp} alt={post.title} title={post.title} />
                </Link>
            </div>
            <div className="post-card__info">
                {/* <div className="post-card__category">
                    {categories}
                </div> */}
                <div className="post-card__name">
                    <Link hrefLang={"en"}  to={'/blog/'+post.slug} dangerouslySetInnerHTML={{ __html: post.title }}></Link>
                </div>
                <div className="post-card__date">{moment(post.date_created).format('D MMMM, YYYY')}  <div className="postAuthor">{post.author}</div> </div>
                <div className="post-card__content" dangerouslySetInnerHTML={{ __html: description }}>
                </div>
                {/* <div className="post-card__read-more">
                    <Link hrefLang={"en"}  to={'/blog/'+post.slug} className="btn btn-secondary btn-sm">Read More</Link>
                </div> */}
            </div>
        </div>
    );
}

PostCard.propTypes = {
    /**
     * post data object
     */
    post: PropTypes.object,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default PostCard;
