// react
import React from 'react';
import { Link } from 'react-router-dom';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import { ArrowRoundedRight6x9Svg } from '../../svg';

import { url } from '../../services/utils';

function MenuLeft(props) {
    const {
        layout,
        withIcons,
        items,
        onClick,
        categoryObj,
    } = props;

    // const renderLink = (item, content) => {
    //     let link;

    //     if (item.url) {
    //         link = (
    //             <AppLink
    //                 {...item.props}
    //                 to={item.url}
    //                 onClick={() => onClick(item)}
    //             >
    //                 {content}
    //             </AppLink>
    //         );
    //     } else {
    //         // link = <button type="button" onClick={() => onClick(item)}>{content}</button>;
    //         link = (
    //             <AppLink
    //                 {...item.props}
    //                 to={"/"}
    //                 onClick={() => onClick(item)}
    //             >
    //                 {content}
    //             </AppLink>
    //         );
    //     }

    //     return link;
    // };

    const itemsList = items.map((item, index) => {
        let arrow;
        let submenu;
        let icon;

        if (item.submenu && item.submenu.length) {
            arrow = <ArrowRoundedRight6x9Svg className="menu__arrow" />;
        }

        // if (item.submenu && item.submenu.length) {
        //     submenu = (
        //         <div className="menu__submenu">
        //             <Menu items={item.submenu} />
        //         </div>
        //     );
        // }

        // if (categoryObj !== undefined && categoryObj.image !== '' && categoryObj.image !== null) {
        //     icon = (
        //         <div className="menu__icon">
        //             <img src={categoryObj.image} srcSet={categoryObj.image} alt={categoryObj.name} />
        //         </div>
        //     );
        // }

        const menuStyle = {
            backgroundImage: categoryObj !== undefined && categoryObj.image !== '' && categoryObj.image !== null ? `url('${categoryObj.image}')` : '',
        };           

        return (
            <li className="megamenu__item" key={'left_menu_brands_'+index + 1}> 
            {/* {renderLink(item, ( */}
                <React.Fragment>
                    {icon}
                    <Link hrefLang={"en"}  to={{ pathname : url.category_brand(categoryObj, item)}}>
                        {(item.name).toLowerCase()}
                    </Link>
                    {arrow}
                </React.Fragment>
            {/* ))} */}
            {submenu}
            </li>                     
        );
    });

    const classes = classNames(`menu menu--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return (
        // <ul className={classes}>
        // <ul className={(categoryObj !== undefined && categoryObj.image !== '' && categoryObj.image !== null ? 'megamenu__links megamenu__links--level--1' : classes)}>
        <div className={'col-4'}>
            <ul className={'megamenu__links megamenu__links--level--1'}>
                {itemsList}
            </ul>
        </div>        
    );
}

MenuLeft.propTypes = {
    /** one of ['classic', 'topbar'] (default: 'classic') */
    layout: PropTypes.oneOf(['classic', 'topbar']),
    /** default: false */
    withIcons: PropTypes.bool,
    /** array of menu items */
    items: PropTypes.array,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

MenuLeft.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],
    onClick: () => {},
};

export default MenuLeft;
