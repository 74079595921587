// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import shopApi from '../../api/shop';


// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class SitePageContactUs extends Component {
//function SitePageContactUs() {
    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            basicFormFields: {
                name: "",
                email: "",
                contact: "",
                subject: "",
                message: "",
                captcha_verification: ""
            },
            basicFormFieldsError: {
                isEmptyName: false,
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyContact: false,
                isEmptySubject: false,
                isEmptyMessage: false,
                isEmptyRecaptcha: false,
            },
            metaTags: {
                meta_desc: "",
                meta_keywords: '',
                meta_title: "",
            }
        }
    }

    async componentDidMount() {
        loadReCaptcha(Config.GOOGLE_CAPTCHA_KEY, function(){ });
        this.loadMetaTags();
    }

    loadMetaTags(){
        var request = shopApi.getMetaTagsBySlug('contact-us');
        request.then((metaTags) => {
            this.setState({ metaTags : metaTags.data });
        });
    }

    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyName: false,
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyContact: false,
            isEmptySubject: false,
            isEmptyMessage: false,
            isEmptyRecaptcha: false,
        };

        var name = this.state.basicFormFields.name;
        var email_address = this.state.basicFormFields.email;
        var contact = this.state.basicFormFields.contact;
        var subject = this.state.basicFormFields.subject;
        var message = this.state.basicFormFields.message;
        var recaptchaToken = this.state.basicFormFields.captcha_verification;

        if (name === '' || name === null) {
            basicFormFieldsError.isEmptyName = true;
            isReturn = false;
        }
        if (email_address === '' || email_address === null) {
            basicFormFieldsError.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            basicFormFieldsError.isNotValidEmail = true;
            isReturn = false;
        }
        if (contact === '' || contact === null) {
            basicFormFieldsError.isEmptyContact = true;
            isReturn = false;
        }
        if (subject === '' || subject === null) {
            basicFormFieldsError.isEmptySubject = true;
            isReturn = false;
        }
        if (message === '' || message === null) {
            basicFormFieldsError.isEmptyMessage = true;
            isReturn = false;
        }
        if (recaptchaToken === '' || recaptchaToken === null) {
            basicFormFieldsError.isEmptyRecaptcha = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }

    contactUs(){
        let isValidated = this.validateForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let contactJSON = { ...this.state.basicFormFields };

        fetch(Config.URL+"/contact-us", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(contactJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){

                shopApi.g4SingleEventPush('contact_us',{
                    name: contactJSON.name,
                    email: contactJSON.email,
                    contact_number: contactJSON.contact,
                    subject: contactJSON.subject,
                    message: contactJSON.message
                });

                //shopApi.triggerContactUsGTag();

                toast.success(response.message);
                this.setState({
                    basicFormFields: {
                        name: "",
                        email: "",
                        contact: "",
                        subject: "",
                        message: "",
                        captcha_verification: ""
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    verifyCallback = (recaptchaToken) => {
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, ['captcha_verification']: recaptchaToken }
        }))
    }

    updateToken = () => {
        this.recaptcha.execute();
    }


    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Contact Us', url: '' }
        ];

        const metaTags = this.state.metaTags;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_desc} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                    <meta name="twitter:description" content={metaTags.meta_desc} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_desc} />
                    <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                    <meta property="og:url" content={ Config.DOMAIN + '/contact-us' } />
                    <link rel="canonical" href={Config.DOMAIN + '/contact-us'} />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <BlockMap />

                <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

                <div className="block">
                    <div className="container">
                        <div className="card mb-0">
                            <div className="card-body contact-us">
                                <div className="contact-us__container">

                                    <div className="row">
                                        <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                            <h4 className="contact-us__header card-title">Head Office</h4>

                                            <div className="contact-us__address">
                                                <p>
                                                    Radisson Hotel, Level 4, Laico Isle, Corner Rivonia Road and Daisy Street, Sandown, Sandton 2196
                                                    <br/>
                                                    Phone Number: +27 11 784 5114
                                                </p>

                                                <p>
                                                    <strong>Opening Hours:</strong>
                                                    <br />
                                                    Monday - Friday 09h00 – 17h00
                                                    <br />
                                                    Fridays closed from 12h00 – 13h30 for prayers
                                                </p>

                                                <p>
                                                    <strong>Marketing Department:</strong>
                                                    <br />
                                                    Email : marketing@khaliques.co.za
                                                </p>
                                                <p>
                                                    <strong>Accounts Department:</strong>
                                                    <br />
                                                    Name : Niaz Bhati<br />
                                                    Email : admin@khaliques.co.za
                                                </p>
                                                <p>
                                                    <strong>Customer Service Department:</strong>
                                                    <br />
                                                    Name : Abu Mohamed<br/>
                                                    Email : abubakr@khaliques.co.za<br/><br/>
                                                    Name : Kefilwe Mkwanazi<br/>
                                                    Email : Kefi@khaliques.co.za
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="contact-name">Your Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        id="contact-name"
                                                        onChange={this.handleSetValue}
                                                        defaultValue={this.state.basicFormFields.name}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyName ?
                                                    <span className="errorInput">Please enter name</span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="contact-email">Email</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        id="contact-email"
                                                        onChange={this.handleSetValue}
                                                        defaultValue={this.state.basicFormFields.email}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyEmail ?
                                                    <span className="errorInput">Please enter email address</span>
                                                    : null
                                                    }
                                                    {this.state.basicFormFieldsError.isNotValidEmail ?
                                                    <span className="errorInput">Please enter valid email address</span>
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact-contact">Contact Number</label>
                                                    <input
                                                        type="text"
                                                        name="contact"
                                                        className="form-control"
                                                        id="contact-contact"
                                                        onChange={this.handleSetValue}
                                                        defaultValue={this.state.basicFormFields.contact}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyContact ?
                                                    <span className="errorInput">Please enter contact number</span>
                                                    : null
                                                    }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact-subject">Subject</label>
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        className="form-control"
                                                        id="contact-subject"
                                                        onChange={this.handleSetValue}
                                                        defaultValue={this.state.basicFormFields.subject}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptySubject ?
                                                    <span className="errorInput">Please enter subject</span>
                                                    : null
                                                    }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact-message">Message</label>
                                                <textarea
                                                    onChange={this.handleSetValue}
                                                    id="contact-message"
                                                    name="message"
                                                    className="form-control"
                                                    rows="4"
                                                    defaultValue={this.state.basicFormFields.message}
                                                />
                                                {this.state.basicFormFieldsError.isEmptyMessage ?
                                                <span className="errorInput">Please enter message</span>
                                                : null
                                                }
                                                {this.state.basicFormFieldsError.isEmptyRecaptcha ?
                                                <span className="errorInput">Captcha is missing or expired, please reload the page.</span>
                                                : null
                                                }
                                            </div>
                                            <ReCaptcha
                                                ref={ref => this.recaptcha = ref}
                                                sitekey={Config.GOOGLE_CAPTCHA_KEY}
                                                action='submit'
                                                verifyCallback={this.verifyCallback}
                                            />
                                            <button onClick={() => { this.contactUs() }}  type="button" className="btn btn-primary">Send Message</button>

                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="store_box">
                                                <h4 className="contact-us__header card-title">Oriental Plaza</h4>
                                                <div className="contact-us__address">
                                                    <strong>Address:</strong><br/>
                                                    <address>Shop N47, Entrance 2, Oriental Plaza, Corner Lilian &amp; Lilian Ngoyi St, Fordsburg, Johannesburg, 2000</address>
                                                    <strong>Tel:</strong>+27 11 836 4418<br/><br/>
                                                    <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 17h00<br/>Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 15h00<br/>Sunday closed<br/><br/>

                                                    <strong>Manager:</strong><br/>
                                                    Name : Raihaan Nanabhay<br/>
                                                    Email : <a href="mailto:plaza@khaliques.co.za">plaza@khaliques.co.za</a><br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="store_box">
                                                <h4 className="contact-us__header card-title">Cedar Square</h4>
                                                <div className="contact-us__address">
                                                    <strong>Address:</strong><br/>
                                                    <address>Shop G3.1, Cedar Rd, Fourways, 2068</address>
                                                    <strong>Tel:</strong>+27 11 465 1613<br/><br/>
                                                    <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 18h00<br/>Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 17h00<br/>Sunday 09h00 – 15h00<br/><br/>

                                                    <strong>Manager:</strong><br/>
                                                    Name : Himat Jivan<br/>
                                                    Email : <a href="mailto:cedar@khaliques.co.za">cedar@khaliques.co.za</a><br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="store_box">
                                                <h4 className="contact-us__header card-title">Mall of Africa</h4>
                                                <div className="contact-us__address">
                                                    <strong>Address:</strong><br/>
                                                    <address>Shop 2155,Upper Level, Mall of Africa, Midrand, 2196</address>
                                                    <strong>Tel:</strong>+27 82 083 0713<br/><br/>
                                                    <strong>Operating Hours:</strong><br/> Monday-Saturday 09h00 – 20h00<br/>Sunday 10h00 – 20h00<br/><br/>

                                                    <strong>Manager:</strong><br/>
                                                    Email : <a href="mailto:moa@khaliques.co.za">moa@khaliques.co.za</a><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="store_box">
                                                <h4 className="contact-us__header card-title">Sandton</h4>
                                                <div className="contact-us__address">
                                                    <div>
                                                        <strong>Address:</strong><br />
                                                        <address>126 Rivonia Road, Sandown, Sandton 2196</address>
                                                        <strong>Tel:</strong>+27 11 783 2468<br/><br/>
                                                        <strong>Operating Hours:</strong><br/> Monday - Friday 09h00 – 17h30<br />Fridays closed from 12h00 – 13h30 for prayers<br/>Saturday 09h00 – 15h00<br/>Sunday closed<br/><br/>
                                                        <strong>Manager:</strong><br/>
                                                        Name : Rashid Davids/Mayur Ravjee<br/>
                                                        Email : <a href="mailto:sandton@khaliques.co.za">sandton@khaliques.co.za</a><br/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SitePageContactUs;
