// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import Autocomplete from "react-google-autocomplete";

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';

class AccountPageEditAddress extends Component {
//export default function AccountPageEditAddress() {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            countries: [],
            selectedCountry: 202,
            basicFormFields: {
                address_id: (this.props.location.address !== undefined ? this.props.location.address.id : ''),
                first_name: "",
                last_name: "",
                email: "",
                contact: "",
                address: "",
                address_2: "",
                city: "",
                country: 202,
                postcode: "",
                type_of_address: "home",
                type: 1
            },
            basicFormFieldsError: {
                isEmptyFirstName: false,
                isEmptyLastName: false,
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyContact: false,
                isEmptyAddress: false,
                isEmptyCity: false,
                isEmptyCountry: false,
                isEmptyPostCode: false
            },
        }
    }

    componentDidMount() {
        if(!this.state.basicFormFields.address_id || this.state.basicFormFields.address_id === ''){
            toast.error("Invalid address update");
            this.props.history.push('/account/addresses');
        } else {
            this.setState({
                basicFormFields: {
                    address_id: (this.props.location.address.id !== undefined ? this.props.location.address.id : ''),
                    first_name: this.props.location.address.first_name,
                    last_name: this.props.location.address.last_name,
                    email: this.props.location.address.email,
                    contact: this.props.location.address.contact,
                    address: this.props.location.address.address,
                    address_2: this.props.location.address.address_2,
                    city: this.props.location.address.city,
                    country: this.props.location.address.country,
                    postcode: this.props.location.address.postcode,
                    type_of_address: this.props.location.address.type_of_address,
                    type: 1
                }
            });
            let $this = this;
            setTimeout(() => {
                $this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'country': this.props.location.address.country }
                }));
            },1000);
            this.getAllCountries();
        }
    }

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                let countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: item.name, value: item.id});
                    if(response.data.length -1 === key){
                        this.setState({ countries });
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateAddressForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateAddressForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyFirstName: false,
            isEmptyLastName: false,
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyContact: false,
            isEmptyAddress: false,
            isEmptyCity: false,
            isEmptyCountry: false,
            isEmptyPostCode: false
        };

        var first_name = this.state.basicFormFields.first_name;
        var last_name = this.state.basicFormFields.last_name;
        var email_address = this.state.basicFormFields.email;
        var contact = this.state.basicFormFields.contact;
        var address = this.state.basicFormFields.address;
        var city = this.state.basicFormFields.city;
        var country = this.state.basicFormFields.country;
        var postcode = this.state.basicFormFields.postcode;

        if (first_name === '' || first_name === null) {
            basicFormFieldsError.isEmptyFirstName = true;
            isReturn = false;
        }
        if (last_name === '' || last_name === null) {
            basicFormFieldsError.isEmptyLastName = true;
            isReturn = false;
        }
        if (email_address === '' || email_address === null) {
            basicFormFieldsError.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            basicFormFieldsError.isNotValidEmail = true;
            isReturn = false;
        }
        if (contact === '' || contact === null) {
            basicFormFieldsError.isEmptyContact = true;
            isReturn = false;
        }
        if (country === '' || country === null || country === 0) {
            basicFormFieldsError.isEmptyCountry = true;
            isReturn = false;
        }
        if (city === '' || city === null) {
            basicFormFieldsError.isEmptyCity = true;
            isReturn = false;
        }
        if (address === '' || address === null) {
            basicFormFieldsError.isEmptyAddress = true;
            isReturn = false;
        }
        if (postcode === '' || postcode === null) {
            basicFormFieldsError.isEmptyPostCode = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    handleSetAddress(place){
        //console.log(place);
        var address = "", city = "", state = "", zip = "", country = "";
        for (var i = 0; i < place.address_components.length; i++) {
            var addr = place.address_components[i];
            // check if this entry in address_components has a type of country
            if (addr.types[0] == 'country')
                country = addr.long_name;
            else if (addr.types[0] == 'street_address') // address 1
                address = address + addr.long_name;
            else if (addr.types[0] == 'establishment')
                address = address + addr.long_name;
            else if (addr.types[0] == 'route')  // address 2
                address = address + addr.long_name;
            else if (addr.types[0] == 'sublocality_level_1')  // address 2
                address = (address !== '' ? address + ", "+addr.long_name : address + addr.long_name );
            else if (addr.types[0] == 'postal_code')       // Zip
                zip = addr.short_name;
            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                state = addr.long_name;
            else if (addr.types[0] == ['locality'])       // City
                city = addr.long_name;
        }
        if(country !== ''){
            var countryObject = this.state.countries.find(x => x.name === country);
            if(countryObject !== ''){
                this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'country': countryObject.value }
                }));
            }
        }
        // var $this = this;
        // setTimeout(() => {
        //     $this.setState(prevState => ({
        //         basicFormFields: { ...prevState.basicFormFields, 'address': "---" }
        //     }));
        // },1000);

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'address': place.formatted_address }
        }));
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'city': city }
        }));
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'postcode': zip }
        }));
    }

    setCountry(country){
        // var country_id = country.split("-")[0];
        // var country_name = country.split("-")[1];
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'country': country }
        }));
        // this.setState(prevState => ({
        //     basicFormFields: { ...prevState.basicFormFields, 'country_name': country_name }
        // }));
    }

    updatedAddress(){
        let isValidated = this.validateAddressForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateAddressJSON = {... this.state.basicFormFields};

        fetch(Config.URL+"/modify-address", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateAddressJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.history.push('/account/addresses');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });

    }

    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{`Edit Address | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>Edit Address</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-first-name">First Name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        className="form-control"
                                        id="address-first-name"
                                        defaultValue={this.state.basicFormFields.first_name}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyFirstName ?
                                    <span className="errorInput">Please enter first name</span>
                                    : null
                                    }
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-last-name">Last Name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        className="form-control"
                                        id="address-last-name"
                                        defaultValue={this.state.basicFormFields.last_name}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyLastName ?
                                    <span className="errorInput">Please enter last name</span>
                                    : null
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                {/* <label htmlFor="checkout-company-name">Company Name{' '}<span className="text-muted">(Optional)</span></label> */}
                                <label htmlFor="address-email">Email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        id="address-email"
                                        defaultValue={this.state.basicFormFields.email}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyEmail ?
                                    <span className="errorInput">Please enter email address</span>
                                    : null
                                    }
                                    {this.state.basicFormFieldsError.isNotValidEmail ?
                                    <span className="errorInput">Please enter valid email address</span>
                                    : null
                                    }
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-country">Country</label>
                                {/* <select id="checkout-country" className="form-control form-control-select2">
                                    <option>Select a country...</option>
                                    <option>United States</option>
                                    <option>Russia</option>
                                    <option>Italy</option>
                                    <option>France</option>
                                    <option>Ukraine</option>
                                    <option>Germany</option>
                                    <option>Australia</option>
                                </select> */}
                                <SelectSearch
                                    options={this.state.countries}
                                    name="country"
                                    search
                                    value={[this.state.basicFormFields.country]}
                                    filterOptions={this.fuzzySearch}
                                    onChange={(event) => { this.setCountry(event) }}
                                    emptyMessage="No matched country found in search criteria"
                                />
                                {this.state.basicFormFieldsError.isEmptyCountry ?
                                <span className="errorInput">Please select country</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="address-address">Street Address</label>
                                <Autocomplete
                                    onChange={this.handleSetValue}
                                    className="form-control"
                                    apiKey={Config.googleAPIKey}
                                    id="address-address"
                                    name="address"
                                    onPlaceSelected={(place, inputRef, autocomplete) => {
                                        this.handleSetAddress(place);
                                    }}
                                    defaultValue={this.state.basicFormFields.address}
                                    options={{
                                        types: ["geocode"],
                                        //componentRestrictions: { country: "in" },
                                    }}
                                />
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    id="address-address"
                                    name="address"
                                    defaultValue={this.state.basicFormFields.address}
                                    onChange={this.handleSetValue}
                                /> */}
                                {this.state.basicFormFieldsError.isEmptyAddress ?
                                <span className="errorInput">Please enter street address</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="address-address_2">
                                    Apartment, suite, unit, Landmark etc.
                                    {' '}
                                    <span className="text-muted">(Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address-address_2"
                                    name="address_2"
                                    defaultValue={this.state.basicFormFields.address_2}
                                    onChange={this.handleSetValue}
                                />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-city">Town / City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address-city"
                                        name="city"
                                        defaultValue={this.state.basicFormFields.city}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyCity ?
                                    <span className="errorInput">Please enter town / city</span>
                                    : null
                                    }
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-postcode">Postcode / ZIP</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address-postcode"
                                        name="postcode"
                                        defaultValue={this.state.basicFormFields.postcode}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyPostCode ?
                                    <span className="errorInput">Please enter postcode / zip</span>
                                    : null
                                    }
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-contact">Mobile Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="address-contact"
                                        name="contact"
                                        defaultValue={this.state.basicFormFields.contact}
                                        onChange={this.handleSetValue}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyContact ?
                                    <span className="errorInput">Please enter mobile number</span>
                                    : null
                                    }
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="address-contact">Type Of Address</label>
                                    <select value={this.state.basicFormFields.type_of_address} onChange={this.handleSetValue} id="address-type-of-address" name="type_of_address" className="form-control form-control-select2">
                                        <option value="home">Home</option>
                                        <option value="work">Work</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updatedAddress() }} className="btn btn-primary" type="button">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AccountPageEditAddress;