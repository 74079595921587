// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OtpInput from 'react-otp-input';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPageChangeMobileNumber extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)

        const { itemObj } = this.props.location;

        this.state = {
            isLoading: false,
            profile: {},
            pageHeading: (itemObj !== undefined ? itemObj.pageHeading : "Add Mobile Number"),
            countries: [],
            countryCode: (itemObj !== undefined ? itemObj.countryCode : 0),
            countryCodeRemember: '27-202',
            otp: '',
            verificationAuth: (itemObj !== undefined ? itemObj.verificationAuth : ''),
            timerDuration: 60,
            startTimerLogin: (itemObj !== undefined ? itemObj.startTimerLogin : false),
            showResendOTPButton: false,
            basicFormFields: {
                mobile_number: (itemObj !== undefined ? itemObj.mobile_number : '')
            },
            basicFormFieldsError: {
                isEmptyMobileNumber: false,
                isEmptyOTP: false
            },
            location: itemObj
        }
    }

    componentDidMount() {
        this.getProfile();
        this.getAllCountries();
        let $this = this;
        setTimeout(() => {
            $this.setState({ countryCodeRemember: '27-202' });
        }, 1000);
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ profile: response.data });

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    if(response.data.contact !== ''){
                        if(this.state.location === undefined){
                            this.setState({ pageHeading: "Change Mobile Number" });
                        }
                    }
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err getProfile: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateUpdateProfileForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateUpdateProfileForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyMobileNumber: false
        };

        var mobile_number = this.state.basicFormFields.mobile_number;
        if (mobile_number === '' || mobile_number === null) {
            basicFormFieldsError.isEmptyMobileNumber = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    updateProfile(){
        let isValidated = this.validateUpdateProfileForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};
        updateProfileJSON.mobile_number = this.state.countryCode + "" + this.state.basicFormFields.mobile_number;

        fetch(Config.URL+"/update-mobile-number", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.setState({
                        verificationAuth: response.OTP_token,
                        pageHeading: "Verify OTP",
                        startTimerLogin: true
                    });
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err updateProfile: ", err);
            toast.error(err);
        });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }

    handleSetOTP = (otp) => this.setState({ otp });

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getAllCountries response: ", response);
            if(response.status){
                let countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: "+" + item.country_code + "    " + item.name, value: item.country_code +"-"+item.id });
                });
                this.setState({ countries });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    setCountryCode(event){
        this.setState({ countryCode: event.split("-")[0], countryCodeRemember: event });
    }

    /**
     * [validateOTPForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateOTPForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyOTP: false
        };

        const otp = this.state.otp;

        if (otp === '' || otp === null) {
            basicFormFieldsError.isEmptyOTP = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    verifyOTP(){
        let isValidated = this.validateOTPForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.verificationAuth);

        let postCredentials = {...this.state.basicFormFields};
        if(this.state.location === undefined){
            postCredentials.mobile_number = this.state.countryCode + "" + this.state.basicFormFields.mobile_number;
        } else {
            postCredentials.mobile_number = this.state.basicFormFields.mobile_number;
        }
        postCredentials.otp = this.state.otp;

        fetch(Config.URL+"/verify-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.props.history.push("/account/profile");
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    resendOTP(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let postCredentials = {...this.state.basicFormFields};
        if(this.state.location === undefined){
            postCredentials.mobile_number = this.state.countryCode + "" + this.state.basicFormFields.mobile_number;
        } else {
            postCredentials.mobile_number = this.state.basicFormFields.mobile_number;
        }

        fetch(Config.URL+"/send-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({
                    verificationAuth: response.OTP_token,
                    pageHeading: "Verify OTP",
                    startTimerLogin: false,
                    showResendOTPButton: false
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {

        const renderTimeLogin = ({ remainingTime }) => {
            if (remainingTime === 0) {
                this.setState({ startTimerLogin: false, showResendOTPButton: true });
                return;
            }

            return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
            );
        };

        return (
            <div className="card">
                <Helmet>
                    <title>{`${this.state.pageHeading} | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header changeMobileNumberHeading">
                    <h5>{this.state.pageHeading}</h5>
                    {(this.state.profile && this.state.profile.contact !== '') &&
                        <span>Your current mobile number is: <b>{this.state.profile.contact}</b></span>
                    }
                    {this.state.pageHeading == "Verify OTP" &&
                        <span>We have sent an OTP to: <b>{this.state.countryCode}{this.state.basicFormFields.mobile_number}</b></span>
                    }
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">

                        <div className="col-12 col-lg-6 col-xl-6">


                            {this.state.pageHeading !== 'Verify OTP' &&
                            <>
                            <div className="form-group changeMobileNumber">
                                <label htmlFor="profile-mobile-number">Mobile Number</label>
                                <div className="mobileContainer">
                                    <div className="countrySection">
                                        <SelectSearch
                                            options={this.state.countries}
                                            name="country"
                                            search
                                            value={[this.state.countryCodeRemember]}
                                            filterOptions={this.fuzzySearch}
                                            onChange={(event) => { this.setCountryCode(event) }}
                                            emptyMessage="No matched country found in search criteria"
                                        />
                                    </div>
                                    <div className="mobileNumberSection">
                                        <input
                                            type="number"
                                            value={this.state.basicFormFields.mobile_number}
                                            id="profile-mobile-number"
                                            onChange={this.handleSetValue}
                                            name="mobile_number"
                                            className="form-control"
                                            placeholder="Please enter mobile number"
                                        />
                                    </div>
                                    {this.state.basicFormFieldsError.isEmptyMobileNumber ?
                                    <span className="errorInput">Please enter mobile number</span>
                                    : null
                                    }
                                </div>
                            </div>
                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                            </>
                            }


                            {this.state.pageHeading === 'Verify OTP' &&
                            <>
                            <div className="form-group changeMobileNumber">
                                <div className="form-group">
                                    <label htmlFor="otp">Enter verification code</label>
                                    <OtpInput
                                        id="otp"
                                        value={this.state.otp}
                                        onChange={this.handleSetOTP}
                                        numInputs={6}
                                        inputStyle={{width: 50, height: 50, borderRadius: 10}}
                                        separator={<span>&nbsp;-&nbsp;</span>}
                                    />
                                    {this.state.basicFormFieldsError.isEmptyOTP ?
                                        <span className="errorInput">Please enter OTP</span>
                                    : null
                                    }
                                </div>
                                <button onClick={() => { this.verifyOTP() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                    Verify Now
                                </button> &nbsp;&nbsp;&nbsp;
                                <button onClick={() => { this.props.history.push("/account/profile") }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                    Cancel
                                </button> &nbsp;&nbsp;&nbsp;

                                {this.state.showResendOTPButton &&
                                    <button onClick={() => { this.resendOTP() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                        Resend OTP
                                    </button>
                                }
                                {this.state.startTimerLogin &&
                                <div className="timer-wrapper">
                                    <CountdownCircleTimer
                                    size={60}
                                    strokeWidth={5}
                                    duration={this.state.timerDuration}
                                    isPlaying={this.state.startTimerLogin}
                                    colors={[["#000", 0.33], ["#000", 0.33], ["#FF0000"]]}
                                    //onComplete={() => [false, 1000]}
                                    >
                                    {renderTimeLogin}
                                    </CountdownCircleTimer>
                                </div>
                                }
                            </div>
                            </>
                            }



                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageChangeMobileNumber;