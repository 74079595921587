// react
import React, { Component, useState } from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';

import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageAddAddress from './AccountPageAddAddress';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPageMyGiftCards from './AccountPageMyGiftCards';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';
import AccountPageMeasurements from './AccountPageMeasurements';
import AccountPageEmailPreferences from './AccountPageEmailPreferences';
import AccountPageChangeEmailAddress from './AccountPageChangeEmailAddress';
import AccountPageChangeMobileNumber from './AccountPageChangeMobileNumber';

import { Config } from '../../config';

export default function AccountLayout(props) {
    const { match, location } = props;

    let user = (localStorage.getItem(Config.web_user) !== null ? Config.getData(localStorage.getItem(Config.web_user)) : null);
    let [userObj, setUser] = useState(user);

    let profilePercentage = [{"sign_up":0,"basic_details":0,"email_verify":0,"measurements":0,"email_preference":0}];
    if(userObj !== null){
        let userData = JSON.parse(userObj);
        profilePercentage[0].sign_up = userData.profile_percentage !== '' ? JSON.parse(userData.profile_percentage)[0].sign_up : 0;
        profilePercentage[0].basic_details = userData.profile_percentage !== '' ? JSON.parse(userData.profile_percentage)[0].basic_details : 0;
        profilePercentage[0].email_verify = userData.profile_percentage !== '' ? JSON.parse(userData.profile_percentage)[0].email_verify : 0;
        profilePercentage[0].measurements = userData.profile_percentage !== '' ? JSON.parse(userData.profile_percentage)[0].measurements : 0;
        profilePercentage[0].email_preference = userData.profile_percentage !== '' ? JSON.parse(userData.profile_percentage)[0].email_preference : 0;
    }    

    /**
     * [RequireAuth Create secure route]
     * @param {@View} ComposedComponent [Component name to be pass here]
     */
    function RequireAuth(ComposedComponent) {
        class RequireAuth extends Component {  
            state = {
                isAuthenticated: (localStorage.getItem(Config.x_token) === null ? false : true)
            }

            // Push to login route if not authenticated on mount
            async componentDidMount() {
                if(await !this.state.isAuthenticated) {
                    this.props.history.push("/account/login");
                    return false;
                }
            }            
        
            // Push to login route if not authenticated on update
            async componentDidUpdate(nextProps) {
                if(await !this.state.isAuthenticated) {
                    this.props.history.push("/account/login");
                    return false;
                }        
            }
        
            // Otherwise render the original component
            render() {
                if(this.state.isAuthenticated) {
                    return <ComposedComponent {...this.props}/>
                } else {
                    return null
                }
            }  
        }
        return RequireAuth;  
    }

    function logoutUser(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
        
        fetch(Config.URL+"/logout", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                localStorage.removeItem(Config.x_token);
                localStorage.removeItem(Config.web_user);
                localStorage.removeItem('state');
                props.history.push("/");
            } else {
                toast.error(response.message);                
            }
        })
        .catch(err => {
            toast.error(err);
        });        
    }

    function setData(){
        let user = (localStorage.getItem(Config.web_user) !== null ? Config.getData(localStorage.getItem(Config.web_user)) : null);
        setUser(user);
    }

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'My Account', url: '' },
    ];

    const links = [
        { title: 'Dashboard', url: 'dashboard' },
        { title: 'My Profile ('+ (profilePercentage[0].sign_up + profilePercentage[0].basic_details + profilePercentage[0].email_verify)  +'/70)', url: 'profile' },
        { title: 'My Measurements ('+ profilePercentage[0].measurements +'/20)', url: 'measurements' },
        { title: 'My Email Preferences ('+ profilePercentage[0].email_preference +'/10)', url: 'email-preferences' },
        { title: 'Change My Mobile Number', url: 'change-mobilenumber' },
        { title: 'Change My Email Address', url: 'change-emailaddress' },        
        { title: 'My Orders', url: 'orders' },
        { title: 'My Gift Cards', url: 'gift-cards' },
        { title: 'My Addresses', url: 'addresses' },
        { title: 'Update My Password', url: 'password' },
        { title: 'Logout', url: 'login' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                {link.url === 'login' ? 
                <span onClick={() => { logoutUser() }} ><a>{link.title}</a></span>
                :
                <Link hrefLang={"en"}  onClick={() => { setData() }} to={url}>{link.title}</Link>
                }                
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navigation</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={RequireAuth(AccountPageDashboard)} />
                                <Route exact path={`${match.path}/profile`} component={RequireAuth(AccountPageProfile)} />
                                <Route exact path={`${match.path}/measurements`} component={RequireAuth(AccountPageMeasurements)} />
                                <Route exact path={`${match.path}/email-preferences`} component={RequireAuth(AccountPageEmailPreferences)} />
                                <Route exact path={`${match.path}/change-mobilenumber`} component={RequireAuth(AccountPageChangeMobileNumber)} />
                                <Route exact path={`${match.path}/change-emailaddress`} component={RequireAuth(AccountPageChangeEmailAddress)} />                                
                                <Route exact path={`${match.path}/orders`} component={RequireAuth(AccountPageOrders)} />
                                <Route exact path={`${match.path}/gift-cards`} component={RequireAuth(AccountPageMyGiftCards)} />
                                <Route exact path={`${match.path}/order/view`} component={RequireAuth(AccountPageOrderDetails)} />
                                <Route exact path={`${match.path}/addresses`} component={RequireAuth(AccountPageAddresses)} />
                                <Route exact path={`${match.path}/address/add`} component={RequireAuth(AccountPageAddAddress)} />
                                <Route exact path={`${match.path}/address/edit`} component={RequireAuth(AccountPageEditAddress)} />                                
                                <Route exact path={`${match.path}/password`} component={RequireAuth(AccountPagePassword)} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
