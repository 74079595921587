import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import shopApi from '../../api/shop';
import { Config } from '../../config';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_LOGIN_REQUIRED, WISHLIST_REFRESH } from './wishlistActionTypes';

const Msg = (props) => {
    return (
      <>
        {props.message}
        <br />
        <Link hrefLang={"en"}  style={{color: "#fff", fontWeight: 'bold', fontSize: 16}} to="/account/login">Login here!</Link>
      </>
    );
  };


export function wishListLoginRequired(response) {
    return {
        type: WISHLIST_LOGIN_REQUIRED,
        response: response
    };
}

export function wishListRefreshSuccess(response) {
    return {
        type: WISHLIST_REFRESH,
        response: response
    };
}

export function wishlistAddItemSuccess(response) {
    return {
        type: WISHLIST_ADD_ITEM,
        response: response
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                shopApi.addWishListItem(product.id).then(async (response) => {
                    if(response.status){
                        if(response.status == 2){
                            toast.error(response.message);
                        } else {
                            toast.success(product.full_name + " " + response.message);

                            shopApi.g4EventPush("add_to_wishlist", product);

                            shopApi.getWishListItems().then(async (responseWishlistItems) => {
                                dispatch(wishlistAddItemSuccess(responseWishlistItems));
                            });
                        }
                    } else {
                        if(localStorage.getItem(Config.x_token) === null){
                            toast.error(<Msg message="You must have to login to use add to wishlist!" />);
                            dispatch(wishListLoginRequired([]));
                        } else {
                            toast.error(response.message);
                            dispatch(wishListLoginRequired([]));
                        }
                    }
                });
                resolve();
            }, 500);
        })
    );
}

export function wishlistRemoveItem(product) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            // setTimeout(() => {
            //     dispatch(wishlistRemoveItemSuccess(productId));
            //     resolve();
            // }, 500);
            setTimeout(() => {
                shopApi.addWishListItem(product.p_id).then(async (response) => {
                    if(response.status){
                        if(response.status == 2){
                            toast.error(response.message);
                        } else {

                            shopApi.g4EventPush("remove_from_wishlist", product);

                            toast.success(`Product "${product.full_name}" removed from wish list!`);
                            shopApi.getWishListItems().then(async (responseWishlistItems) => {
                                dispatch(wishlistAddItemSuccess(responseWishlistItems));
                            });
                        }
                    } else {
                        if(localStorage.getItem(Config.x_token) === null){
                            toast.error(<Msg message="You must have to login to use remove from wishlist!" />);
                            dispatch(wishListLoginRequired([]));
                        } else {
                            toast.error(response.message);
                            dispatch(wishListLoginRequired([]));
                        }
                    }
                    resolve();
                });
            }, 500);
        })
    );
}

export function wishlistRefesh() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                shopApi.getWishListItems().then(async (responseWishlistItems) => {
                    dispatch(wishListRefreshSuccess(responseWishlistItems));
                });
                resolve();
            }, 500);
        })
    );
}
