// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;
    let opacity = 0.4;

    let discount = product.discount !== undefined ? product.discount : 0;
    product.compareAtPrice = false;
    product.price = product.selling_price;
    product.discount_price = 0;
    if(discount > 0){
        let pp = parseFloat(product.selling_price) * parseInt(discount) / 100;
        let fpp = parseFloat(product.selling_price) - parseFloat(pp);
        product.compareAtPrice = fpp;
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">{discount}% off</div>);
        product.price = fpp;
        product.discount_price = pp;
    }

    if(product.qty <= 0){
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale"><b>Out Of Stock</b></div>);
    }

    // if (product.badges.includes('sale')) {
    //     badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    // }
    // if (product.badges.includes('hot')) {
    //     badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    // }
    // if (product.badges.includes('new')) {
    //     badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    // }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.product_main_image && product.product_main_image !== '') {
        image = (
            <div className="product-card__image product-image" style={{opacity: (product.qty <= 0 ? opacity : 1)}}>
                <Link hrefLang={"en"}  to={url.product_detail(product)} className="product-image__body">
                    <img className="product-image__img" src={product.product_main_image} alt="" />
                </Link>
            </div>
        );
    }

    if (product.compareAtPrice) {
        price = (
            <div className="product-card__prices" style={{opacity: (product.qty <= 0 ? opacity : 1)}}>
                <span className="product-card__new-price"><Currency value={product.compareAtPrice} /></span>
                {' '}
                <span className="product-card__old-price"><Currency value={product.selling_price} /></span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices" style={{opacity: (product.qty <= 0 ? opacity : 1)}}>
                <Currency value={product.selling_price} />
            </div>
        );
    }

        features = (
            <>
            <ul className="product-card__features-list">
                {product.brand_name && <li>Brand : {product.brand_name}</li>}
                {product.color_name && <li>Color : {product.color_name}</li>}
                {product.size_name && <li>Size : {product.size_name}</li>}
                {product.style_name && <li>Style : {product.style_name}</li>}
                {product.final_item_code && <li>SKU : {product.final_item_code}</li>}
                {product.express_delivery_image !=='' && <img src={product.express_delivery_image} style={{width: 160, height: 'auto'}} />}
            </ul>
            </>
        );

    // if (product.attributes && product.attributes.length) {
    //     features = (
    //         <ul className="product-card__features-list">
    //             {product.attributes.filter((x) => x.featured).map((attribute, index) => (
    //                 <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
    //             ))}
    //         </ul>
    //     );
    // }

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(product.final_item_code)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info common-product-listings" style={{opacity: (product.qty <= 0 ? opacity : 1)}}>
                <div className="product-card__name">
                    <Link hrefLang={"en"}  to={url.product_detail(product)}>{product.full_name}</Link>
                </div>
                <div className="product-card__rating">
                    <Rating value={(product.product_avg_rating !== null && product.product_avg_rating !== 0 ? product.product_avg_rating : 4)} />
                    <div className=" product-card__rating-legend">{`${product.product_total_reviews !== undefined && product.product_total_reviews !== 0 ? product.product_total_reviews : 10} Reviews`}</div>
                </div>
                {features}
            </div>
            <div className="product-card__actions">
                {product.qty > 0 &&
                <div className="product-card__availability">
                    Availability:
                    <span className="text-success"> In Stock</span>
                </div>
                }
                {product.qty == 0 &&
                <div className="product-card__availability">
                    Availability:
                    <span className="product-card__new-price" style={{fontWeight: 'bold', fontFamily:'arial'}}>Out of Stock</span>
                </div>
                }
                {price}
                <div className="product-card__buttons">
                    {product.qty > 0 &&
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                            </React.Fragment>
                        )}
                    />
                    }

                    {/* {product.qty <= 0 &&
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Notify Me
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Notify Me
                                </button>
                            </React.Fragment>
                        )}
                    />
                    } */}

                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                    'btn-light': !product.is_wish_listed,
                                    'btn-primary': product.is_wish_listed
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    {/* <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    /> */}
                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
