import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_LOGIN_REQUIRED, CART_REFRESH, CHECKOUT_ITEMS } from './cartActionTypes';
import shopApi from '../../api/shop';
/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcDiscount(items) {
    return items.reduce((discount_price, item) => discount_price + item.discount_price, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) =>  (item.product_details !=='' ? quantity + item.qty : quantity ) , 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

function addItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;
        
    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.price,
            discount_price: product.discount_price,
            total: product.price * quantity,
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const total_discount = calcDiscount(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        lastItemId,
        subtotal,
        total_discount,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function loginRequired(state) {
    return {
        ...state
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'shipping',
*   title: 'Shipping',
*   price: 25
* }
*/

let initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    checkout_items: [],
    subtotal: 0,
    total_discount: 0,
    extraLines:[],
    // extraLines: [ // shipping, taxes, fees, .etc
    //     {
    //         type: 'shipping',
    //         title: 'Shipping',
    //         price: 100,
    //     },
    //     {
    //         type: 'tax',
    //         title: 'Tax',
    //         price: 0,
    //     },
    // ],
    total: 0,
};

async function loadCart(){
    if(shopApi.getAuth() !== null){
        await shopApi.getCartItems().then(async (response) => {
            if(response.status && response.status == 1){
                initialState.items = await response;
                let otherDetails = [
                    {
                        type: 'sub_amount',
                        title: 'Sub Amount',
                        price: parseFloat(response.amounts.total_amount),
                    },
                    {
                        type: 'discount_amtount',
                        title: 'Discount Amount (-)',
                        price: parseFloat(response.amounts.discount_amount),
                    },
                    {
                        type: 'total_amount_exc_vat',
                        title: 'Total Amount Ex. Vat(-)',
                        price: parseFloat(response.amounts.total_amount_exc_vat),
                    },
                    {
                        type: 'total_amount_vat',
                        title: 'Total Vat Amount',
                        price: parseFloat(response.amounts.total_vat_amt),
                    },                
                    {
                        type: 'total_amount',
                        title: 'Total Amount',
                        price: parseFloat(response.amounts.sub_amount),
                    },
                    {
                        type: 'shipping_charge',
                        title: 'Delivery Charge (+)',
                        price: parseFloat(response.amounts.shipping_charge),
                    },
                    {
                        type: 'pay_by_gift_card',
                        title: 'Pay By Using Gift Card (-)',
                        price: parseFloat(response.amounts.pay_by_gift_card),
                    },
                    // {
                    //     type: 'pay_by_coupon_code',
                    //     title: 'Pay By Using Coupon Code (-)',
                    //     price: parseFloat(response.amounts.pay_by_coupon_code),
                    // },
                    {
                        type: 'purchase_gift_card',
                        title: 'Purchase Gift Card (+)',
                        price: parseFloat(response.amounts.purchase_gift_card),
                    },
                    {
                        type: 'amount_payable',
                        title: 'Amount Payable',
                        price: parseFloat(response.amounts.amount_payable),
                    }
                ]
                initialState.extraLines = otherDetails;
                initialState.quantity = calcQuantity(response.data);
            } else {
                initialState.items = await response;
                initialState.extraLines = [];
                initialState.quantity = 0;
            }        
        });
    }
}

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
    case CART_ADD_ITEM:
        //return addItem(state, action.product, action.options, action.quantity);
        //loadCart();
        // console.log("Add", abc);
        //console.log("abc", abc);
        //console.log("initialStateAdd", initialState);
        //console.log("action.response", action.response);
        //return initialState;
        // let lastItemId = state.lastItemId + 1;
        // let subtotal = 0;
        // let total_discount = 0;
        // let total = 0;

        // return {
        //     ...state,
        //     lastItemId,
        //     subtotal,
        //     total_discount,
        //     total,
        //     items: initialState.items,
        //     quantity: initialState.quantity,
        //     extraLines: initialState.extraLines
        // }
        // (async () => {
        //     await loadCart();
        //     return { ...state, items: initialState.items, extraLines: initialState.extraLines, quantity: initialState.quantity, lastItemId: state.lastItemId + 1 };
        // })()
        // setTimeout(() => {
        //     return { ...state, items: initialState.items, extraLines: initialState.extraLines, quantity: initialState.quantity, lastItemId: state.lastItemId + 1 };    
        // },1500);
        var response = action.response;        
        if(response.status && response.status == 1){
            let otherDetails = [
                {
                    type: 'sub_amount',
                    title: 'Sub Amount',
                    price: parseFloat(response.amounts.total_amount),
                },
                {
                    type: 'discount_amtount',
                    title: 'Discount Amount (-)',
                    price: parseFloat(response.amounts.discount_amount),
                },
                {
                    type: 'total_amount_exc_vat',
                    title: 'Total Amount Ex. Vat(-)',
                    price: parseFloat(response.amounts.total_amount_exc_vat),
                },
                {
                    type: 'total_amount_vat',
                    title: 'Total Vat Amount',
                    price: parseFloat(response.amounts.total_vat_amt),
                },                
                {
                    type: 'total_amount',
                    title: 'Total Amount',
                    price: parseFloat(response.amounts.sub_amount),
                },
                {
                    type: 'shipping_charge',
                    title: 'Delivery Charge (+)',
                    price: parseFloat(response.amounts.shipping_charge),
                },
                {
                    type: 'pay_by_gift_card',
                    title: 'Pay By Using Gift Card (-)',
                    price: parseFloat(response.amounts.pay_by_gift_card),
                },
                // {
                //     type: 'pay_by_coupon_code',
                //     title: 'Pay By Using Coupon Code (-)',
                //     price: parseFloat(response.amounts.pay_by_coupon_code),
                // },
                {
                    type: 'purchase_gift_card',
                    title: 'Purchase Gift Card (+)',
                    price: parseFloat(response.amounts.purchase_gift_card),
                },
                {
                    type: 'amount_payable',
                    title: 'Amount Payable',
                    price: parseFloat(response.amounts.amount_payable),
                }
            ]        
            return { ...state, items: response, checkout_items: [], extraLines: otherDetails, quantity: calcQuantity(response.data), lastItemId: 1 };    
        } else {
            return { ...initialState, items: response, checkout_items: [], extraLines: [], quantity: 0, lastItemId: 0 };
        }
        
    case CART_REMOVE_ITEM:        
        var response = action.response;
        if(response.status && response.status == 1){
            let otherDetails = [
                {
                    type: 'sub_amount',
                    title: 'Sub Amount',
                    price: parseFloat(response.amounts.total_amount),
                },
                {
                    type: 'discount_amtount',
                    title: 'Discount Amount (-)',
                    price: parseFloat(response.amounts.discount_amount),
                },
                {
                    type: 'total_amount_exc_vat',
                    title: 'Total Amount Ex. Vat(-)',
                    price: parseFloat(response.amounts.total_amount_exc_vat),
                },
                {
                    type: 'total_amount_vat',
                    title: 'Total Vat Amount',
                    price: parseFloat(response.amounts.total_vat_amt),
                },                
                {
                    type: 'total_amount',
                    title: 'Total Amount',
                    price: parseFloat(response.amounts.sub_amount),
                },
                {
                    type: 'shipping_charge',
                    title: 'Delivery Charge (+)',
                    price: parseFloat(response.amounts.shipping_charge),
                },
                {
                    type: 'pay_by_gift_card',
                    title: 'Pay By Using Gift Card (-)',
                    price: parseFloat(response.amounts.pay_by_gift_card),
                },
                // {
                //     type: 'pay_by_coupon_code',
                //     title: 'Pay By Using Coupon Code (-)',
                //     price: parseFloat(response.amounts.pay_by_coupon_code),
                // },
                {
                    type: 'purchase_gift_card',
                    title: 'Purchase Gift Card (+)',
                    price: parseFloat(response.amounts.purchase_gift_card),
                },
                {
                    type: 'amount_payable',
                    title: 'Amount Payable',
                    price: parseFloat(response.amounts.amount_payable),
                }
            ]
            return { ...state, items: response, checkout_items: [], extraLines: otherDetails, quantity: calcQuantity(response.data), lastItemId: 1 };        
        } else {
            return { ...initialState, items: response, checkout_items: [], extraLines: [], quantity: 0, lastItemId: 0 };
        }

    case CART_UPDATE_QUANTITIES:
        //return updateQuantities(state, action.quantities);
        var response = action.response;
        if(response.status && response.status == 1){
            let otherDetails = [
                {
                    type: 'sub_amount',
                    title: 'Sub Amount',
                    price: parseFloat(response.amounts.total_amount),
                },
                {
                    type: 'discount_amtount',
                    title: 'Discount Amount (-)',
                    price: parseFloat(response.amounts.discount_amount),
                },
                {
                    type: 'total_amount_exc_vat',
                    title: 'Total Amount Ex. Vat(-)',
                    price: parseFloat(response.amounts.total_amount_exc_vat),
                },
                {
                    type: 'total_amount_vat',
                    title: 'Total Vat Amount',
                    price: parseFloat(response.amounts.total_vat_amt),
                },                
                {
                    type: 'total_amount',
                    title: 'Total Amount',
                    price: parseFloat(response.amounts.sub_amount),
                },
                {
                    type: 'shipping_charge',
                    title: 'Delivery Charge (+)',
                    price: parseFloat(response.amounts.shipping_charge),
                },
                {
                    type: 'pay_by_gift_card',
                    title: 'Pay By Using Gift Card (-)',
                    price: parseFloat(response.amounts.pay_by_gift_card),
                },
                // {
                //     type: 'pay_by_coupon_code',
                //     title: 'Pay By Using Coupon Code (-)',
                //     price: parseFloat(response.amounts.pay_by_coupon_code),
                // },
                {
                    type: 'purchase_gift_card',
                    title: 'Purchase Gift Card (+)',
                    price: parseFloat(response.amounts.purchase_gift_card),
                },
                {
                    type: 'amount_payable',
                    title: 'Amount Payable',
                    price: parseFloat(response.amounts.amount_payable),
                }
            ]
            return { ...state, items: response, checkout_items: [], extraLines: otherDetails, quantity: calcQuantity(response.data), lastItemId: 1 };        
        } else {
            return { ...initialState, items: response, checkout_items: [], extraLines: [], quantity: 0, lastItemId: 0 };
        }
    
    case CART_LOGIN_REQUIRED:
        return loginRequired(state);
    
    case CART_REFRESH:
        var response = action.response;
        if(response.status && response.status == 1){
            let otherDetails = [
                {
                    type: 'sub_amount',
                    title: 'Sub Amount',
                    price: parseFloat(response.amounts.total_amount),
                },
                {
                    type: 'discount_amtount',
                    title: 'Discount Amount (-)',
                    price: parseFloat(response.amounts.discount_amount),
                },
                {
                    type: 'total_amount_exc_vat',
                    title: 'Total Amount Ex. Vat(-)',
                    price: parseFloat(response.amounts.total_amount_exc_vat),
                },
                {
                    type: 'total_amount_vat',
                    title: 'Total Vat Amount',
                    price: parseFloat(response.amounts.total_vat_amt),
                },                
                {
                    type: 'total_amount',
                    title: 'Total Amount',
                    price: parseFloat(response.amounts.sub_amount),
                },
                {
                    type: 'shipping_charge',
                    title: 'Delivery Charge (+)',
                    price: parseFloat(response.amounts.shipping_charge),
                },
                {
                    type: 'pay_by_gift_card',
                    title: 'Pay By Using Gift Card (-)',
                    price: parseFloat(response.amounts.pay_by_gift_card),
                },
                // {
                //     type: 'pay_by_coupon_code',
                //     title: 'Pay By Using Coupon Code (-)',
                //     price: parseFloat(response.amounts.pay_by_coupon_code),
                // },
                {
                    type: 'purchase_gift_card',
                    title: 'Purchase Gift Card (+)',
                    price: parseFloat(response.amounts.purchase_gift_card),
                },
                {
                    type: 'amount_payable',
                    title: 'Amount Payable',
                    price: parseFloat(response.amounts.amount_payable),
                }
            ]
            return { ...state, items: response, checkout_items: [], extraLines: otherDetails, quantity: calcQuantity(response.data), lastItemId: 1 };        
        } else {
            return { ...initialState, items: response, checkout_items: [], extraLines: [], quantity: 0, lastItemId: 0 };
        }
    
    case CHECKOUT_ITEMS:
        var response = action.response;
        if(response.status && response.status == 1){
            let otherDetails = [
                {
                    type: 'sub_amount',
                    title: 'Sub Amount',
                    price: parseFloat(response.amounts.total_amount),
                },
                {
                    type: 'discount_amtount',
                    title: 'Discount Amount (-)',
                    price: parseFloat(response.amounts.discount_amount),
                },
                {
                    type: 'total_amount_exc_vat',
                    title: 'Total Amount Ex. Vat(-)',
                    price: parseFloat(response.amounts.total_amount_exc_vat),
                },
                {
                    type: 'total_amount_vat',
                    title: 'Total Vat Amount',
                    price: parseFloat(response.amounts.total_vat_amt),
                },                
                {
                    type: 'total_amount',
                    title: 'Total Amount',
                    price: parseFloat(response.amounts.sub_amount),
                },
                {
                    type: 'shipping_charge',
                    title: 'Delivery Charge (+)',
                    price: parseFloat(response.amounts.shipping_charge),
                },
                {
                    type: 'pay_by_gift_card',
                    title: 'Pay By Using Gift Card (-)',
                    price: parseFloat(response.amounts.pay_by_gift_card),
                },
                // {
                //     type: 'pay_by_coupon_code',
                //     title: 'Pay By Using Coupon Code (-)',
                //     price: parseFloat(response.amounts.pay_by_coupon_code),
                // },
                {
                    type: 'purchase_gift_card',
                    title: 'Purchase Gift Card (+)',
                    price: parseFloat(response.amounts.purchase_gift_card),
                },
                {
                    type: 'amount_payable',
                    title: 'Amount Payable',
                    price: parseFloat(response.amounts.amount_payable),
                }
            ]
            return { ...state, checkout_items: response, extraLines: otherDetails, quantity: calcQuantity(response.data), lastItemId: 1 };        
        } else {
            return { ...initialState, items: response, checkout_items: [], extraLines: [], quantity: 0, lastItemId: 0 };
        }

    default:
        loadCart();
        return initialState;
    }
}
