// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

// application
import PageHeader from '../shared/PageHeader';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';
// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';
import Currency from '../shared/Currency';

function ShopPageTrackOrder() {
    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Track Order', url: '' },
    ];

    const [order, setOrder] = useState([]);
    const [orderItems, setOrderItems] = useState(null);
    const [order_totals, setOrderTotals] = useState({
        amount_payable: 0,
        cart_pay_by_gift_card_id: 0,
        cart_pay_by_gift_card_no: "0",
        discount_amount: 0,
        pay_by_coupon_code: 0,
        pay_by_gift_card: 0,
        purchase_gift_card: 0,
        shipping_charge: 0,
        sub_amount: 0,
        total_amount: 0,
        total_amount_exc_vat: 0,
        total_discount: 0,
        total_vat_amt: 0
    });

    const [isLoading, setIsLoading] = useState(false);
    const [order_number, setOrderNumber] = useState('');
    const [isEmptyOrderNumber, setIsEmptyOrderNumber] = useState(false);
    const [trackings, setTrackings] = useState([]);
    const [metaTags, setMetaTags] = useState({
        meta_desc: "",
        meta_keywords: '',
        meta_title: "",
    });

    useEffect(() => {
        var request = shopApi.getMetaTagsBySlug('track-order');
        request.then((metaTags) => {
            setMetaTags(metaTags.data);
        });
    }, []);

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     const handleSetValue = e => {
        e.persist();
        setOrderNumber(e.target.value);
    }

    const trackOrder = () => {
        setIsEmptyOrderNumber(false);
        if(shopApi.getAuth() == null){
            toast.error("Please login to use track order.");
        } else {
            if(order_number == ''){
                setIsEmptyOrderNumber(true);
            } else {
                setIsEmptyOrderNumber(false);
                setIsLoading(true);
                shopApi.getTrackOrder(order_number).then((response) => {
                    setIsLoading(false);
                    if(response.status){
                        if(response.status == 2){
                            localStorage.removeItem(Config.x_token);
                            localStorage.removeItem(Config.web_user);
                            toast.error(response.message);
                            setTrackings([]);
                            this.props.history.push("/");
                        } else {
                            if(response.order_details.data.transaction_status !== 1){
                                toast.error(response.message);
                                setTrackings([]);
                                setOrder([]);
                            } else {
                                setTrackings(response.data);
                                setOrder(response.order_details.data);
                                setOrderTotals(response.order_details.amounts);

                                if(response.order_details.data.order_items !== undefined){
                                    var orderItemsData = response.order_details.data.order_items.map((orderItem) => {
                                        if(orderItem.item_details !== ''){
                                            if(orderItem.is_gift_card){
                                                return (
                                                    <tr key={orderItem.id}>
                                                        <td>
                                                            <img width="80" src={orderItem.item_details.gift_card_image} />
                                                        </td>
                                                        <td className="td_left_align">
                                                            Gift Card
                                                            <span className="item_code">Item Code: {orderItem.item_details.card_no}</span>
                                                        </td>
                                                        <td>-</td>
                                                        <td>{orderItem.return_qty}</td>
                                                        <td>{orderItem.qty}</td>
                                                        <td><Currency value={orderItem.item_details.balance} /></td>
                                                        <td>-</td>
                                                        <td><Currency value={orderItem.row_total} /></td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={orderItem.id}>
                                                        <td><img width="80" src={orderItem.item_details.product_main_image !== '' ? orderItem.item_details.product_main_image : 'images/avatars/user_placeholder.jpeg' } /></td>
                                                        <td className="td_left_align">
                                                            <Link hrefLang={"en"}  to={url.product_detail(orderItem.item_details)}>{(orderItem.item_details.full_name).toLowerCase()}</Link>
                                                            <span className="item_code">Item Code: {orderItem.item_code}</span>
                                                        </td>
                                                        <td>{(orderItem.return_qty > 0 ? (orderItem.return_qty === orderItem.qty ? 'Full' : 'Partially') : '-')}</td>
                                                        <td>{orderItem.return_qty}</td>
                                                        <td>{orderItem.qty}</td>
                                                        <td><Currency value={orderItem.price} /></td>
                                                        <td><Currency value={orderItem.discount_amount} /></td>
                                                        <td><Currency value={orderItem.row_total} /></td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    });

                                    setOrderItems(orderItemsData);
                                }
                            }
                        }
                    } else {
                        setTrackings([]);
                        toast.error(response.message);
                    }
                });
            }
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.meta_title}</title>
                <meta name="title" content={metaTags.meta_title} />
                <meta name="keywords" content={metaTags.meta_keywords} />
                <meta name="description" content={metaTags.meta_desc} />
                <meta name="twitter:title" content={metaTags.meta_title} />
                <meta name="twitter:description" content={metaTags.meta_desc} />
                <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                <meta property="og:title" content={metaTags.meta_title} />
                <meta property="og:description" content={metaTags.meta_desc} />
                <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                <meta property="og:url" content={ Config.DOMAIN + '/track-order' } />
                <link rel="canonical" href={ Config.DOMAIN + '/track-order' } />
            </Helmet>

            <Backdrop className="backdrop" open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row justify-content-center track-order">
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="card flex-grow-1 mb-0 mt-lg-4 mt-md-3 mt-2">
                                <div className="card-body">
                                    <div className="card-title text-center"><h1 className="pt-lg-0 pt-2">Track Order</h1></div>
                                    {shopApi.getAuth() == null &&
                                        <p className="mb-4 pt-2">
                                            You must have to <Link hrefLang={"en"}  to="/account/login">login</Link> to track your order.
                                        </p>
                                    }
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="order_number">Invoice Number</label>
                                            <input onChange={handleSetValue} id="order_number" name="order_number" type="text" className="form-control" />
                                            {isEmptyOrderNumber ?
                                            <span className="errorInput">Please enter Invoice Number</span>
                                            : null
                                            }
                                        </div>
                                        <div className="pt-3">
                                            <button onClick={trackOrder} type="button" className="btn btn-primary btn-lg btn-block">Track</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        {trackings.length > 0 ? (
                            <>
                                <div className="col-xl-10 col-lg-10 col-md-10 card mt-5">
                                    <h4 className="text-center mt-4 mb-3">Tracking Status</h4>
                                        <div className="track">
                                        {trackings.map((item, key) => {
                                            let trackStatus = 'Order Created';
                                            if(item.status === 0){
                                                trackStatus = 'Order Created';
                                            }
                                            if(item.status === 1){
                                                trackStatus = 'Approved';
                                            }
                                            if(item.status === 2){
                                                trackStatus = 'Rejected';
                                            }
                                            if(item.status === 3){
                                                trackStatus = 'In Preparing';
                                            }
                                            if(item.status === 4){
                                                trackStatus = 'In Transit';
                                            }
                                            if(item.status === 5){
                                                trackStatus = 'Delivered';
                                            }
                                            if(item.status === 7){
                                                trackStatus = 'Return';
                                            }
                                            return(
                                                <div key={`status_`+item.id} className={item.current_status ? "step active" : "step"}>
                                                    <span className="icon">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                    <span className="text">{trackStatus}</span>
                                                    {item.current_status &&
                                                        <>
                                                            <span className="date">{moment(item.date_updated).format("Do MMM YYYY, h:mm:ss A")}</span>
                                                            {item.comment !== '' &&
                                                                <span className="comment">{item.comment}</span>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            )
                                            })
                                        }
                                    </div>



                                </div>


                                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                    <div className="card">
                                        <div className="order-header">
                                            <h5 className="order-header__title">#{order.order_no}</h5>
                                            <div className="order-header__subtitle">
                                                Was placed on
                                                {' '}
                                                <mark className="order-header__date">{moment(order.date_created).format("Do MMM YYYY, h:mm:ss A")}</mark>
                                            </div>
                                            <div className="order-header__subtitle">
                                                {order.delivery_way_bill_no &&
                                                <span className="order-header__date"><b>Way Bill No</b>: {order.delivery_way_bill_no}<br/></span>
                                                }
                                                {order.delivery_tracking_info &&
                                                <span className="order-header__date"><b>Tracking Info</b>: {order.delivery_tracking_info}<br/></span>
                                                }
                                                {order.delivery_order_note &&
                                                <span className="order-header__date"><b>Note(For Order Track)</b>: {order.delivery_order_note}<br/></span>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-divider" />

                                        <div className="card-table">
                                            <div className="table-responsive-sm order_details">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Product</th>
                                                            <th>Return</th>
                                                            <th>Return Qty</th>
                                                            <th>Qty</th>
                                                            <th width="15%">Unit Price</th>
                                                            <th width="15%">Discount</th>
                                                            <th width="15%">Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="card-table__body card-table__body--merge-rows">
                                                        {orderItems}
                                                        <tr>
                                                            <td colSpan="9"><div style={{marginTop: 10, marginBottom: 10}} className="card-divider" /></td>
                                                        </tr>
                                                        {order_totals.total_amount > 0 &&
                                                        <tr>
                                                            <th colSpan="7">Sub Amount</th>
                                                            <td><Currency value={order_totals.total_amount} /></td>
                                                        </tr>
                                                        }
                                                        {order_totals.discount_amount > 0 &&
                                                            <tr>
                                                                <th colSpan="7">Discount Amount (-)</th>
                                                                <td><Currency value={order_totals.discount_amount} /></td>
                                                            </tr>
                                                        }
                                                        {order_totals.total_amount_exc_vat > 0 &&
                                                        <tr>
                                                            <th colSpan="7">Total Amount Ex. Vat(-)</th>
                                                            <td><Currency value={order_totals.total_amount_exc_vat} /></td>
                                                        </tr>
                                                        }
                                                        {order_totals.total_vat_amt > 0 &&
                                                        <tr>
                                                            <th colSpan="7">Total Vat Amount</th>
                                                            <td><Currency value={order_totals.total_vat_amt} /></td>
                                                        </tr>
                                                        }
                                                        {order_totals.sub_amount > 0 &&
                                                        <tr>
                                                            <th colSpan="7">Total Amount</th>
                                                            <td><Currency value={order_totals.sub_amount} /></td>
                                                        </tr>
                                                        }
                                                        {order_totals.shipping_charge > 0 &&
                                                            <tr>
                                                                <th colSpan="7">Delivery Charge (+)</th>
                                                                <td><Currency value={order_totals.shipping_charge} /></td>
                                                            </tr>
                                                        }
                                                        {order_totals.purchase_gift_card > 0 &&
                                                            <tr>
                                                                <th colSpan="7">Purchase Gift Card (+)</th>
                                                                <td><Currency value={order_totals.purchase_gift_card} /></td>
                                                            </tr>
                                                        }
                                                        {order_totals.pay_by_gift_card > 0 &&
                                                            <tr>
                                                                <th colSpan="7">Pay By Using Gift Card (-)</th>
                                                                <td><Currency value={order_totals.pay_by_gift_card} /></td>
                                                            </tr>
                                                        }
                                                        {/* <tr>
                                                            <th colSpan="7">Pay By Using Coupon Code (-)</th>
                                                            <td><Currency value={order_totals.pay_by_coupon_code} /></td>
                                                        </tr> */}
                                                    </tbody>
                                                    <tfoot className="card-table__body--merge-rows card-table__body order_details_final_totals">
                                                        <tr>
                                                            <th colSpan="7"><b style={{fontSize: 20}}>Amount Paid</b></th>
                                                            <td width="20%"><Currency value={order_totals.amount_payable} /></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                            ) : (
                                <div className="col-xl-10 col-lg-10 col-md-10 card mt-5">
                                    <h4 className="text-center mt-4 mb-3">Tracking Status</h4>
                                    <div className="text-center mb-3">No tracking found!</div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopPageTrackOrder;
