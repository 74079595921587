import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_LOGIN_REQUIRED, WISHLIST_REFRESH } from './wishlistActionTypes';
import shopApi from '../../api/shop';

function loginRequired() {
    return initialState = {
        status: 0,
        data: [],
        message: ''
    };
}

function refreshWishList(state, response) {
    // return {
    //     ...state, 
    //     response
    // };
    return response;
}

function addItem(state, response) {
    // const itemIndex = state.findIndex((x) => x.id === product.id);

    // if (itemIndex === -1) {
    //     return [
    //         ...state,
    //         JSON.parse(JSON.stringify(product)),
    //     ];
    // }
    return response;
    return state;
}

var initialState = {
    status: 0,
    data: [],
    message: ''
};

async function loadWishList(){
    if(shopApi.getAuth() !== null){
        await shopApi.getWishListItems().then(async (response) => {
            if(response.status && response.status == 1){
                initialState.status = await response.status;
                initialState.data = await response.data;
                initialState.message = await response.message;
            } else {
                initialState.status = 0;
                initialState.data = [];
                initialState.message = '';
            }
        });
    }
}

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
    case WISHLIST_ADD_ITEM:
        return addItem(state, action.response);

    case WISHLIST_REMOVE_ITEM:
        //return state.filter((x) => x.id !== action.productId);
    
    case WISHLIST_REFRESH:
        var response = action.response;
        if(response.status && response.status == 1){
            return refreshWishList(state, action.response);
        } else {
            return refreshWishList(initialState, action.response);
        }
    
    case WISHLIST_LOGIN_REQUIRED:
        return loginRequired();

    default:
        loadWishList();
        return initialState;
    }
}
