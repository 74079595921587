export const url = {
    home: () => '/',

    all: () => '/category/all',
    
    category_all: () => '/category',
   
    brand_all: () => '/brand',
    
    catalog: () => '/catalog',
    
    brands: () => '/brands',

    promotions: (category) => '/promotions',
    
    promotion_detail: (promotion) => '/category/all?filter_discount='+promotion.id,
    
    categories: (category) => '/categories',
    
    category: (category) => `/category/${((category.name).toLowerCase()).replace(/ /g, "-")}`,

    category_brand: (category, brand) => `/category/${((category.name).toLowerCase()).replace(/ /g, "-")}` + '?filter_brands='+brand.id,

    product: (product) => `/products/`,
   
    //product_detail: (product) => `/${((product.category_name).toLowerCase()).replace(/ /g, "-")}/${((product.brand_name).toLowerCase()).replace(/ /g, "-")}/${((product.product_full_name).toLowerCase()).replace(/ /g, "-")}/${product.final_item_code}`,
    product_detail: (product) => `/category/${((product.category_name).toLowerCase()).replace(/ /g, "-")}/${(((product.full_name).toLowerCase()).replace(/  /g, " ").replace(/ /g, "-"))}_${product.final_item_code}`,
    
    brand: (brand) => `/brand/${((brand.name).toLowerCase()).replace(/ /g, "-")}`,

    search_products: (value) => `/category/all?search=${value}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
