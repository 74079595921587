    // react
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
// third-party
import { Helmet } from 'react-helmet-async';
import { useHistory } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// application
import shopApi from '../../api/shop';

// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockVideos from '../blocks/BlockVideos';
import BlockRateYourProducts from '../blocks/BlockRateYourProducts';
import BlockSpecialOffers from '../blocks/BlockSpecialOffers';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockPosts from '../blocks/BlockPosts';
import BlockHomeProductsCarousel from '../blocks/BlockHomeProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

import BlockGiftCards from '../blocks/BlockGiftCards';
import { Modal } from 'reactstrap';
import "bootstrap/dist/css/bootstrap.css";

// data stubs
import posts from '../../data/blogPosts';
import theme from '../../data/theme';
import { Cross20Svg, LogoCardSvg } from '../../svg';
import { cartRefresh } from '../../store/cart';
import { Config } from '../../config';
import moment from 'moment';

function HomePage(props) {

    const { cartRefresh } = props;
    const [showModalView, setShowModalView] = useState(false);
    const [showSubscribeModalView, setSubscribeShowModalView] = useState(false);
    const [cardObj, setCardObj] = useState('');
    const [purchase_gift_card_amount, setPurchaseGiftCardAmount] = useState(0);
    const [isEmptyPurchaseGiftCardAmount, setIsEmptyPurchaseGiftCardAmount] = useState(false);
    const [metaTags, setMetaTags] = useState({
        h1_title: "",
        content_1: "",
        h2_title: "",
        content_2: "",
        meta_desc: "",
        meta_keywords: '',
        meta_title: "",
    });

    let history = useHistory();
    const redirect = group => {
        history.push(group.url);
    }

    {useMemo(() => {
       var request = shopApi.getMetaTagsBySlug('home');
        request.then((metaTags) => {
            setMetaTags(metaTags.data);
        });
    }, [])};

    const cookiePrivacy = (<Link hrefLang={"en"}  style={{color: "#FFF", fontWeight: 'bold', textDecoration: 'underline'}} to="/privacy-policy">Privacy Policy!</Link>);
    const cookieTerms = (<Link hrefLang={"en"}  style={{color: "#FFF", fontWeight: 'bold', textDecoration: 'underline'}} to="/terms-conditions">Terms &amp; Conditions!</Link>);

    const showModal = (amount) => {
        shopApi.generateGiftCard().then((response) => {
            if(response.status){
                if(response.status == 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                } else {
                    if(amount === 'Custom'){
                        setPurchaseGiftCardAmount(0);
                    } else {
                        setPurchaseGiftCardAmount(amount);
                    }
                    setCardObj(response.data);
                    setShowModalView(true);
                    viewPopup(response.data);
                }
            } else {
                toast.error('Please login to use Purchase Gift Card!');
            }
        });
    }

    const addGiftCard = () => {
        if(purchase_gift_card_amount == '' || purchase_gift_card_amount == 0){
            setIsEmptyPurchaseGiftCardAmount(true);
        } else {
            shopApi.addGiftCard(cardObj.id, purchase_gift_card_amount).then((response) => {
                if(response.status){
                    if(response.status == 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                    } else {
                        setCardObj('');

                        let gift_card_data = {};
                        gift_card_data.final_item_code = cardObj.id;
                        gift_card_data.full_name = cardObj.card_no;
                        gift_card_data.category_name = '';
                        gift_card_data.brand_name = '';
                        gift_card_data.color_name = '';
                        gift_card_data.selling_price = purchase_gift_card_amount;
                        gift_card_data.discount_price = 0;
                        gift_card_data.size_name = '';
                        gift_card_data.gift_card_id = cardObj.id;
                        shopApi.g4EventPush("add_to_cart", gift_card_data);

                        toast.success(response.message);
                        setShowModalView(false);
                        viewPopup('');
                        cartRefresh();
                    }
                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    const hideModal = () => {
        setShowModalView(false);
    }

    const hideSubscribeModal = () => {
        setSubscribeShowModalView(false);
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    const handleSetValue = e => {
        e.persist();
        setPurchaseGiftCardAmount(e.target.value);
    }

    const viewPopup = (cardObj) => {
        if(cardObj!== ''){
            return(
                <Modal isOpen={showModalView} centered size="l">
                    <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                        <button className="quickview__close" type="button" onClick={() => { hideModal() }}>
                            <Cross20Svg />
                        </button>

                        <div style={{ width: '100%', padding: 5, background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)', height: 200, borderRadius: 5, boxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)', WebkitBoxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)'}}>
                            <div style={{marginLeft: 10, marginTop: 10, width: '100%'}}><LogoCardSvg /></div>
                            <div style={{color: '#f9c464', marginLeft: 10, marginTop: 5, fontWeight: 'bold'}}>Virtual Card Number</div>
                            <div style={{color: '#f9c464', marginLeft: 10, marginTop: 0, fontSize: 14}}>{cardObj.card_no}</div>
                            <div style={{color: '#f9c464', marginTop: 40, fontSize: 16, marginRight: 20, width: '100%', textAlign:'right'}}>
                                Amont: R<input name="purchase_gift_card_amount" id="purchase_gift_card_amount" style={{ background: 'transparent', boxShadow: 'none', border: 'none', borderBottom: (isEmptyPurchaseGiftCardAmount ? '1px solid #FF0000' : '1px solid #f9c464'), color: '#f9c464', width: 100, marginRight: 10}} type="text" onChange={handleSetValue} width="20" defaultValue={purchase_gift_card_amount} />
                            </div>
                        </div>

                        <div style={{float: 'right'}}>
                            <button style={{color: '#f9c464', background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)'}} onClick={() => { addGiftCard() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                Add
                            </button>
                        </div>
                    </div>
                </Modal>
            )
        }
    }

    const viewSubscribePopup = () => {
        return(
            <Modal isOpen={showSubscribeModalView} centered size="l">
                <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                    <button className="quickview__close" type="button" onClick={() => hideSubscribeModal()}>
                        <Cross20Svg />
                    </button>

                    <h5 className="footer-newsletter__title">Subscribe to our newsletter!</h5>
                    <div className="footer-newsletter__text">
                        Be the first to receive news on exclusive special offers and free coupon for first time signup verified user!
                    </div>

                    <form action="#" onSubmit={(e) => { handleSubmitSubscribeForm(e) }} className="footer-newsletter__form">
                        <label className="sr-only" htmlFor="newsletter-address">Email Address</label>
                        <input
                            type="email"
                            className="footer-newsletter__form-input form-control"
                            id="newsletter-address"
                            placeholder="Email Address..."
                            onChange={(e) => { handleSetInputValue(e) }}
                            value={email_address || ''}
                        />
                        <button type="button" onClick={(e) => { submitNewsLetter(e) }} className="footer-newsletter__form-button btn btn-primary">Subscribe</button>
                    </form>
                    {isEmptyEmail ?
                        <span className="errorInput">Please enter email address</span>
                    : null
                    }
                    {isNotValidEmail ?
                        <span className="errorInput">Please enter a valid email address</span>
                    : null
                    }
                </div>
            </Modal>
        )
    }

    const [email_address, setEmailAddress] = useState('');
    const [isEmptyEmail, setEmptyEmailAddress] = useState(false);
    const [isNotValidEmail, setValidEmailAddress] = useState(false);

    /**
     * [handleSetInputValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    const handleSetInputValue = e => {
        e.persist();
        setEmailAddress(e.target.value);
    }

    /**
     * [handleSubmitSubscribeForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    const handleSubmitSubscribeForm = e => {
        e.preventDefault();
    }

    /**
     * [submitNewsLetter submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    const submitNewsLetter = e => {
        setEmptyEmailAddress(false);
        setValidEmailAddress(false);
        if(email_address == ''){
            setEmptyEmailAddress(true);
        }
        if(email_address !=='' && !isValidEmailAddress(email_address)){
            setValidEmailAddress(true);
        } else {
            shopApi.subscribeToNewsLetter(email_address).then((response) => {
                if(response.status){
                    toast.success(response.message);
                    setEmailAddress('');
                    localStorage.setItem("isSubscribed", "yes");
                    setSubscribeShowModalView(false);
                }
            });
        }
    }

    const isValidEmailAddress = (emailAddress) => {
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.meta_title}</title>
                <meta name="title" content={metaTags.meta_title} />
                <meta name="keywords" content={metaTags.meta_keywords} />
                <meta name="description" content={metaTags.meta_desc} />
                <meta name="twitter:title" content={metaTags.meta_title} />
                <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                <meta name="twitter:description" content={metaTags.meta_desc} />
                <meta property="og:title" content={metaTags.meta_title} />
                <meta property="og:description" content={metaTags.meta_desc} />
                <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                <meta property="og:url" content={ Config.DOMAIN } />
                <link rel="canonical" href={ Config.DOMAIN } />
            </Helmet>

            {useMemo(() => <BlockSlideShow withDepartments />, [])}

            {useMemo(() => <BlockFeatures />, [])}

            {useMemo(() =>
                    <BlockGiftCards
                        title="Gift Cards"
                        layout="list-sm"
                        onGroupClick={showModal}
            />, [])}

            {useMemo(() => (
                <BlockHomeProductsCarousel
                    title="New Arrivals"
                    URL="/get-new-arrivals"
                    layout="grid-4"
                />
            ), [])}

            {useMemo(() => <BlockSpecialOffers title="Special Offers" groups={[{ id: 1, name: 'View All', url: '/promotions' }]} onGroupClick={redirect} layout="list-sm" />, [])}

            {useMemo(() => (
                <BlockHomeProductsCarousel
                    title="Best sellers"
                    URL="/get-best-seller"
                    layout="grid-4"
                />
            ), [])}

            {useMemo(() => (
                <BlockCategories
                    title="Top Categories"
                    layout="list-sm"
                    groups={[{ id: 1, name: 'View All', url: '/category' }]}
                    onGroupClick={redirect}
                />
            ), [])}

            {useMemo(() => (
                <BlockBrands
                    title="Top Brands"
                    layout="list-sm"
                    groups={[{ id: 1, name: 'View All', url: '/brand' }]}
                    onGroupClick={redirect}
                />
            ), [])}

            {viewPopup(cardObj)}

            {viewSubscribePopup()}

            {useEffect(() => {
                setTimeout(() => {
                    if(localStorage.getItem("isSubscribed") === null){
                        var sesstionTime = localStorage.getItem('pst');
                        if(sesstionTime === null){
                            setSubscribeShowModalView(true);
                            localStorage.setItem('pst', (moment().add(10, 'minutes').format('x')).toString());
                        } else {
                            sesstionTime = parseInt(sesstionTime);
                            var currentTime = moment.now();
                            if(currentTime > sesstionTime){
                                setSubscribeShowModalView(true);
                                localStorage.setItem('pst', (moment().add(10, 'minutes').format('x')).toString());
                            }
                        }
                    }
                }, 3000);
            }, [])}

            {shopApi.getAuth() !== null &&
                <>
                {useMemo(() => (
                    <BlockRateYourProducts
                        title="Rate Your Purchase"
                        layout="list-sm"
                    />
                ), [])}
                </>
            }

            {useMemo(() =>
                {cardObj !=='' &&
                    <Modal isOpen={showModalView} centered size="l">
                        <div className="quickview" style={{paddingTop: 50, paddingRight: 30, paddingBottom: 20, paddingLeft: 30}}>
                            <button className="quickview__close" type="button" onClick={() => { hideModal() }}>
                                <Cross20Svg />
                            </button>

                            <div style={{ width: '100%', padding: 5, background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)', height: 200, borderRadius: 5, boxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)', WebkitBoxShadow: '2px 3px 20px -15px rgb(0 0 0 / 70%)'}}>
                                <div style={{marginLeft: 10, marginTop: 10, width: '100%'}}><LogoCardSvg /></div>
                                <div style={{color: '#f9c464', marginLeft: 10, marginTop: 5, fontWeight: 'bold'}}>Virtual Card Number</div>
                                <div style={{color: '#f9c464', marginLeft: 10, marginTop: 0, fontSize: 14}}>{cardObj.card_no}</div>
                                <div style={{color: '#f9c464', marginTop: 40, fontSize: 16, marginRight: 20, width: '100%', textAlign:'right'}}>
                                    Amont: R <input name="purchase_gift_card_amount" id="purchase_gift_card_amount" style={{ background: 'transparent', boxShadow: 'none', border: 'none', borderBottom: (isEmptyPurchaseGiftCardAmount ? '1px solid #FF0000' : '1px solid #f9c464'), color: '#f9c464', width: 100, marginRight: 10}} type="text" onChange={handleSetValue} width="20" defaultValue={purchase_gift_card_amount} />
                                </div>
                            </div>

                            <div style={{float: 'right'}}>
                                <button style={{color: '#f9c464', background: 'linear-gradient(90deg, rgba(52,62,72,1) 0%, rgba(42,50,61,1) 35%, rgba(29,35,49,1) 100%)'}} onClick={() => { addGiftCard() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                    Add
                                </button>
                            </div>
                        </div>
                    </Modal>
            }, [])}

            {/* {useMemo(() => <BlockSpecialOffers title="Special Offers" layout="list-sm" />, [])} */}

            {/* {useMemo(() => (
                <BlockCategories
                    title="Top Categories"
                    layout="list-sm"
                    groups={[{ id: 1, name: 'View All', url: '/category' }]}
                    onGroupClick={redirect}
                />
            ), [])}

            {useMemo(() => (
                <BlockBrands
                    title="Top Brands"
                    layout="list-sm"
                    groups={[{ id: 1, name: 'View All', url: '/brand' }]}
                    onGroupClick={redirect}
                />
            ), [])} */}

            {/* {useMemo(() => (
                <BlockHomeProductsCarousel
                    title="Bestsellers"
                    URL="/get-best-seller"
                    layout="grid-4"
                />
            ), [])} */}

            {/* {useMemo(() => (
                <BlockHomeProductsCarousel
                    title="New Arrivals"
                    URL="/get-new-arrivals"
                    layout="grid-4"
                />
            ), [])} */}

            {shopApi.getAuth() !== null &&
                <>
                {useMemo(() => (
                    <BlockHomeProductsCarousel
                        title="Recently Viewed Items"
                        URL="/get-recent-view"
                        layout="grid-4"
                    />
                ), [])}
                </>
            }

            <div className={`block block-features`}>
                <div className="container">
                    <div className="block-features__item" style={{padding: 0}}>
                        <h2>{metaTags.h2_title}</h2>
                    </div>
                    <div className="block-features__item" style={{padding: 0, paddingTop: 20}}>
                        <p dangerouslySetInnerHTML={{ __html: metaTags.content_2 }}></p>
                    </div>
                </div>
            </div>

            {useMemo(() => <BlockPosts title="Latest Blog" layout="list-sm" posts={posts} />, [])}

            {useMemo(() => (
                <BlockVideos
                    title="Our Videos"
                    layout="list-sm"
                    //groups={[]}
                    //onGroupClick={}
                />
            ), [])}

            <div className={`block block-features`}>
                <div className="container">
                    <div className="block-features__item" style={{padding: 0}}>
                        <h1>{metaTags.h1_title}</h1>
                    </div>
                    <div className="block-features__item" style={{padding: 0, paddingTop: 20}}>
                        <p dangerouslySetInnerHTML={{ __html: metaTags.content_1 }}></p>
                    </div>
                </div>
            </div>

            <CookieConsent
                location="bottom"
                buttonText="Accept Cookies!"
                cookieName="khaliquesCookies"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                <span>Notice: Khaliques.co.za uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our {cookiePrivacy} and {cookieTerms} </span>
            </CookieConsent>
        </React.Fragment>
    );
}

//export default HomePage;


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRefresh
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
