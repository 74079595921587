// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import Autocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import { Config } from '../../config';
import shopApi from '../../api/shop';

class SitePageSuitMaintenance extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            countries: [],
            collection_countries: [],
            stores: [],
            metaTags: {
                meta_desc: "",
                meta_keywords: '',
                meta_title: "",
            },
            basicFormFields: {
                first_name: "",
                last_name: "",
                email: "",
                contact: "",
                country: 202,
                address: '',
                address_2: '',
                collection_country: 202,
                city: '',
                invoice_no: '',
                purchase_date : '',
                store_id : 0
            },
            basicFormFieldsError: {
                isEmptyFirstName: false,
                isEmptyLastName: false,
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyCountry: false,
                isEmptyContact: false,
                isEmptyCollectionCountry: false,
                isEmptyAddress1: false,
                isEmptyAddress2: false,
                isEmptyCollectionCountry: false,
                isEmptyCity: false,
                isEmptyInvoiceNumber: false,
                isEmptyPurchaseDate: false,
                isEmptyStoreID: false,
            },
        }
    }

    componentDidMount() {
        this.getAllCountries();
        this.getStores();
        let $this = this;
        setTimeout(() => {
            $this.setState(prevState => ({
                basicFormFields: { ...prevState.basicFormFields, 'country': 202 }
            }));
            $this.setState(prevState => ({
                basicFormFields: { ...prevState.basicFormFields, 'collection_country': 202 }
            }));
        }, 1000);
        this.loadMetaTags();
    }

    loadMetaTags(){
        var request = shopApi.getMetaTagsBySlug('suite-maintenance');
        request.then((metaTags) => {
            this.setState({ metaTags : metaTags.data });
        });
      }

    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            if(response.status){
                let countries = [];
                let collection_countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: "+" + item.country_code + "    " + item.name, value: item.id });
                    collection_countries.push({ name: item.name, value: item.id });
                    if(response.data.length -1 === key){
                        this.setState({ countries });
                        this.setState({ collection_countries });
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    getStores(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(Config.URL+"/store-list", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ stores: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }


    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     * [validateForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyFirstName: false,
            isEmptyLastName: false,
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyCountry: false,
            isEmptyContact: false,
            isEmptyAddress1: false,
            isEmptyAddress2: false,
            isEmptyCollectionCountry: false,
            isEmptyCity: false,
            isEmptyInvoiceNumber: false,
            isEmptyPurchaseDate: false,
            isEmptyStoreID: false,
        };

        var first_name = this.state.basicFormFields.first_name;
        var last_name = this.state.basicFormFields.last_name;
        var email_address = this.state.basicFormFields.email;
        var contact = this.state.basicFormFields.contact;
        var country = this.state.basicFormFields.country;
        var address = this.state.basicFormFields.address;
        var collection_country = this.state.basicFormFields.collection_country;
        var city = this.state.basicFormFields.city;
        var invoice_no = this.state.basicFormFields.invoice_no;
        var purchase_date = this.state.basicFormFields.purchase_date;
        var store_id = this.state.basicFormFields.store_id;

        if (first_name === '' || first_name === null) {
            basicFormFieldsError.isEmptyFirstName = true;
            isReturn = false;
        }
        if (last_name === '' || last_name === null) {
            basicFormFieldsError.isEmptyLastName = true;
            isReturn = false;
        }
        if (email_address === '' || email_address === null) {
            basicFormFieldsError.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            basicFormFieldsError.isNotValidEmail = true;
            isReturn = false;
        }
        if (contact === '' || contact === null) {
            basicFormFieldsError.isEmptyContact = true;
            isReturn = false;
        }
        if (country === '' || country === null) {
            basicFormFieldsError.isEmptyCountry = true;
            isReturn = false;
        }
        if (address === '' || address === null) {
            basicFormFieldsError.isEmptyAddress1 = true;
            isReturn = false;
        }
        if (collection_country === '' || collection_country === null) {
            basicFormFieldsError.isEmptyCollectionCountry = true;
            isReturn = false;
        }
        if (city === '' || city === null) {
            basicFormFieldsError.isEmptyCity = true;
            isReturn = false;
        }
        if (invoice_no === '' || invoice_no === null) {
            basicFormFieldsError.isEmptyInvoiceNumber = true;
            isReturn = false;
        }
        if (purchase_date === '' || purchase_date === null) {
            basicFormFieldsError.isEmptyPurchaseDate = true;
            isReturn = false;
        }
        if (store_id === '' || store_id === 0) {
            basicFormFieldsError.isEmptyStoreID = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }));
        if(e.target.name === 'return_method'){
            if(e.target.value === 'Collect'){
                this.setState({ showAddressFields: true });
            } else {
                this.setState({ showAddressFields: false });
            }
        }
    }

    handleSetAddress(place){
        var address = "", city = "", state = "", zip = "", country = "";
        for (var i = 0; i < place.address_components.length; i++) {
            var addr = place.address_components[i];
            // check if this entry in address_components has a type of country
            if (addr.types[0] == 'country')
                country = addr.long_name;
            else if (addr.types[0] == 'street_address') // address 1
                address = address + addr.long_name;
            else if (addr.types[0] == 'establishment')
                address = address + addr.long_name;
            else if (addr.types[0] == 'route')  // address 2
                address = address + addr.long_name;
            else if (addr.types[0] == 'sublocality_level_1')  // address 2
                address = (address !== '' ? address + ", "+addr.long_name : address + addr.long_name );
            else if (addr.types[0] == 'postal_code')       // Zip
                zip = addr.short_name;
            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                state = addr.long_name;
            else if (addr.types[0] == ['locality'])       // City
                city = addr.long_name;
        }
        if(country !== ''){
            var countryObject = this.state.collection_countries.find(x => x.name === country);
            if(countryObject !== ''){
                this.setState(prevState => ({
                    basicFormFields: { ...prevState.basicFormFields, 'collection_country': countryObject.value }
                }));
            }
        }

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'address': place.formatted_address }
        }));

        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'city': city }
        }));
    }

    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    setCountry(country){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'country': country }
        }));
    }

    setCollectionCountry(country){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'collection_country': country }
        }));
    }

    setPurchaseDate(date){
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, 'purchase_date': date }
        }));
    }

    submitSuitRequest(){
        let isValidated = this.validateForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let submitSuitRequestJSON = {... this.state.basicFormFields};
        submitSuitRequestJSON.purchase_date = moment(submitSuitRequestJSON.purchase_date).format('YYYY-MM-DD');
        submitSuitRequestJSON.country = submitSuitRequestJSON.collection_country;

        fetch(Config.URL+"/suit-maintenance", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(submitSuitRequestJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.history.push('/');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Suit Maintenance', url: '' },
        ];

        const metaTags = this.state.metaTags;

        return (
            <div className="card">
                <Helmet>
                    <title>{metaTags.meta_title}</title>
                    <meta name="title" content={metaTags.meta_title} />
                    <meta name="keywords" content={metaTags.meta_keywords} />
                    <meta name="description" content={metaTags.meta_desc} />
                    <meta name="twitter:title" content={metaTags.meta_title} />
                    <meta property="twitter:image" content='https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp' />
                    <meta name="twitter:description" content={metaTags.meta_desc} />
                    <meta property="og:title" content={metaTags.meta_title} />
                    <meta property="og:description" content={metaTags.meta_desc} />
                    <meta property="og:image" content="https://khaliques.s3.us-east-2.amazonaws.com/filesources/banner/1625040884.webp" />
                    <meta property="og:url" content={ Config.DOMAIN + '/suit-maintenance' } />
                    <link rel="canonical" href={Config.DOMAIN + '/suit-maintenance'} />
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Suit Maintenance" breadcrumb={breadcrumb} />

                <div className="block faq">
                    <div className="container">
                        <div className="faq__section">
                            <div className="faq__section-body">
                                <div className="row">
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-lg-12 col-xl-12">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="suit-maintennance-first-name">First Name</label>
                                                        <input
                                                            type="text"
                                                            name="first_name"
                                                            className="form-control"
                                                            id="suit-maintennance-first-name"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyFirstName ?
                                                        <span className="errorInput">Please enter first name</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                    <label htmlFor="suit-maintennance-last-name">Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        className="form-control"
                                                        id="suit-maintennance-last-name"
                                                        onChange={this.handleSetValue}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyLastName ?
                                                    <span className="errorInput">Please enter last name</span>
                                                    : null
                                                    }
                                                </div>
                                                </div>

                                                <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="suit-maintennance-email">Email</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        id="suit-maintennance-email"
                                                        onChange={this.handleSetValue}
                                                    />
                                                    {this.state.basicFormFieldsError.isEmptyEmail ?
                                                    <span className="errorInput">Please enter email address</span>
                                                    : null
                                                    }
                                                    {this.state.basicFormFieldsError.isNotValidEmail ?
                                                    <span className="errorInput">Please enter valid email address</span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="suit-maintennance-contact">Contact Number</label>
                                                    <div className="mobileContainer">
                                                        <div className="countrySection">
                                                        <SelectSearch
                                                            options={this.state.countries}
                                                            name="country"
                                                            search
                                                            value={[this.state.basicFormFields.country]}
                                                            filterOptions={this.fuzzySearch}
                                                            onChange={(event) => { this.setCountry(event) }}
                                                            emptyMessage="No matched country found in search criteria"
                                                        />
                                                        </div>
                                                        <div className="mobileNumberSection">
                                                            <input
                                                                type="number"
                                                                value={this.state.basicFormFields.contact}
                                                                id="suit-maintennance-contact"
                                                                onChange={this.handleSetValue}
                                                                name="contact"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.basicFormFieldsError.isEmptyContact ?
                                                        <span className="errorInput">Please enter contact number</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="suit-maintennance-address">Street Address</label>
                                                <Autocomplete
                                                    onChange={this.handleSetValue}
                                                    className="form-control"
                                                    apiKey={Config.googleAPIKey}
                                                    id="suit-maintennance-address"
                                                    name="address_1"
                                                    onPlaceSelected={(place, inputRef, autocomplete) => {
                                                        this.handleSetAddress(place);
                                                    }}
                                                    defaultValue={this.state.basicFormFields.address_1}
                                                    options={{
                                                        types: ["geocode"]
                                                    }}
                                                />
                                                {this.state.basicFormFieldsError.isEmptyAddress1 ?
                                                <span className="errorInput">Please enter street address</span>
                                                : null
                                                }
                                            </div>
                                                <div className="form-group">
                                                <label htmlFor="suit-maintennance-address_2">
                                                    Apartment, suite, unit, Landmark etc.
                                                    {' '}
                                                    <span className="text-muted">(Optional)</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="suit-maintennance-address_2"
                                                    name="address_2"
                                                    onChange={this.handleSetValue}
                                                />
                                            </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="suit-maintennance-country">Country</label>
                                                        <SelectSearch
                                                            options={this.state.collection_countries}
                                                            name="collection_country"
                                                            id="suit-maintennance-country"
                                                            search
                                                            value={[this.state.basicFormFields.collection_country]}
                                                            filterOptions={this.fuzzySearch}
                                                            onChange={(event) => { this.setCollectionCountry(event) }}
                                                            emptyMessage="No matched country found in search criteria"
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyCollectionCountry ?
                                                        <span className="errorInput">Please select country</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="suit-maintennance-city">Town / City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="suit-maintennance-city"
                                                            name="city"
                                                            defaultValue={this.state.basicFormFields.city}
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyCity ?
                                                        <span className="errorInput">Please enter town / city</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="suit-maintennance-invoice-number">Invoice Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="suit-maintennance-invoice-number"
                                                            name="invoice_no"
                                                            onChange={this.handleSetValue}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyInvoiceNumber ?
                                                        <span className="errorInput">Please enter invoice number</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="suit-maintennance-purchase-date">Date of Purchase</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            id="suit-maintennance-purchase-date"
                                                            maxDate={new Date()}
                                                            selected={(this.state.basicFormFields.purchase_date === '0000-00-00' ? '' : this.state.basicFormFields.purchase_date)}
                                                            onChange={(date) => this.setPurchaseDate(date)}
                                                        />
                                                        {this.state.basicFormFieldsError.isEmptyPurchaseDate ?
                                                        <span className="errorInput">Please select purchase date</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="suit-maintennance-store-id">Which Store Did You Purchase From?</label>
                                                        <select onChange={this.handleSetValue} id="suit-maintennance-store-id" name="store_id" className="form-control form-control-select2">
                                                            <option value="">Select Store</option>
                                                            {this.state.stores.length > 0 ? (
                                                                this.state.stores.map((store) => {
                                                                    return(
                                                                        <option value={store.id}>{store.name}</option>
                                                                    )
                                                                })
                                                            ) : (
                                                                <option value="">Store list is empty</option>
                                                            )}
                                                        </select>
                                                        {this.state.basicFormFieldsError.isEmptyStoreID ?
                                                        <span className="errorInput">Please select store</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group mt-3 mb-0">
                                                    <button onClick={() => { this.submitSuitRequest() }} className="btn btn-primary" type="button">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default SitePageSuitMaintenance;