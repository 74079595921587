// react
import React, { Component } from 'react';
import { messaging } from '../firebase';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';
import Lottie from 'react-lottie';
import lottie from "lottie-web";
import animationData from '../lotties/lf30_editor_cnimtlmb';

import { Config } from '../config';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';
import shopApi from '../api/shop';

// pages
import Layout from './Layout';
import HomePage from './home/HomePage';
import { toast } from 'react-toastify';

class Root extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            leftMenuItems: []
        };
    }

    async componentDidMount() {

        // lottie.loadAnimation({
        //     container: document.querySelector("#lottie_loader"),
        //     animationData: animationData,
        //     renderer: "SVG", // "SVG", "canvas", "html"
        //     loop: true, // boolean
        //     autoplay: true, // boolean
        // });
        
        if(messaging !== ''){
           messaging.requestPermission()
            .then(async function() {
                const token = await messaging.getToken();                
                //console.log("Token NS: ", token);
                localStorage.setItem("device_token", token);
            })
            .catch(function(err) {
                //console.log("Unable to get permission to notify.");
            });
            navigator.serviceWorker.addEventListener("message", (message) => toast.success(message));            
        }
        

        // preloader
        // setTimeout(() => {            
        //     const preloader = document.querySelector('.site-preloader');

        //     preloader.addEventListener('transitionend', (event) => {
        //         if (event.propertyName === 'opacity') {
        //             preloader.parentNode.removeChild(preloader);
        //         }
        //     });
        //     preloader.classList.add('site-preloader__fade');
        // }, 1000);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');

        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }

        await this.getLeftMenuCategories();
    }

    async getLeftMenuCategories(){
        shopApi.getLeftMenu().then(async (response) => {
            if(response.status){                
                await this.setState({ leftMenuItems: response.data });
             }
        });       
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];
      
        return (
            <>            
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>                            
                            <Switch>
                                {/* <Route
                                    path="/home-two"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="compact" homeComponent={HomePageOne} />
                                    )}
                                /> */}
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <Layout leftMenuItems={this.state.leftMenuItems} {...props} headerLayout="default" homeComponent={HomePage} />
                                    )}
                                />
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
            </>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
