import React from 'react';

export default function Placeholder() {
  return (
    <>      
        <div className="react-loading-skeleton" style={{height: 200, width: '100%'}}>
          {/* <img src="/ms-icon-310x310.png" /> */}
          {/* <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div> */}
          {/* <span className="react-loading-skeleton" style="height: 200px;">&zwnj;</span> */}
        </div>      
    </>
  );
}