// react
import React, { Component } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

// data stubs
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import Currency from '../shared/Currency';

import { Config } from '../../config';

class AccountPageDashboard extends Component {
//export default function AccountPageDashboard() {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: '',
            selectedFile: '',
            defaultAddress: '',
            orders: [],
            currentPic: 'images/avatars/user_placeholder.jpeg'
        }
    }

    componentDidMount() {
        this.getProfile();
        this.getDefaultAddress();
        this.getOrders();
    }

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ profile: response.data });

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    if(response.data.profile_image !== ''){
                        let currentPic = '';
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                        this.setState({ currentPic });
                    }
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    getDefaultAddress(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-default-address", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ defaultAddress: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    getOrders(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/order-list", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({records_per_page: 5})
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ orders: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    /**
     * [onFileChange set file object to global variable and preview selected image]
     * @param  {[type]} event [Object of selected image]
     * @return {}
     */
    onFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        this.setState({ currentPic: objectUrl });
        setTimeout(() => {
            this.changeProfilePicture();
        }, 1000);
    }

  /**
   * [changeProfilePicture change/update profile picture of user]
   * @return {}
   */
  changeProfilePicture() {
    if (this.state.selectedFile !== '') {
        this.setState({ isLoading: true });

        let headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        var data = new FormData();
        data.append("profile_image", this.state.selectedFile, this.state.selectedFile.name);

        fetch(Config.URL+"/update-profile-image", {
            method: "POST",
            headers: headers,
            body: data
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }
  }

    handleSetValue(event, order){
        if(event.target.value === 'view_details'){
            this.props.history.push({ pathname : '/account/order/view', id: order.id});
        }
        if(event.target.value === 'request_for_return'){
            this.props.history.push({ pathname : '/request-for-return', order_no: order.order_no});
        }
        if(event.target.value === 'download_invoice'){
            this.setState({ isLoading: true });
            setTimeout(() => {
                document.getElementById("invoice_download_"+order.order_no).submit();
                this.setState({ isLoading: false });
            }, 1000);
        }
    }

    render() {

        const orders = this.state.orders.map((order) => {
            let orderStatus = 'Pending';
            if(order.web_order_status === 1){
                orderStatus = 'Approved';
            }
            if(order.web_order_status === 2){
                orderStatus = 'Rejected';
            }
            if(order.web_order_status === 3){
                orderStatus = 'In Preparing';
            }
            if(order.web_order_status === 4){
                orderStatus = 'In Transit';
            }
            if(order.web_order_status === 5){
                orderStatus = 'Delivered';
            }
            if(order.web_order_status === 6){
                orderStatus = 'Canceled';
            }
            if(order.is_return === 1){
                orderStatus = 'Partially Return';
            }
            if(order.is_return === 2){
                orderStatus = 'Full Return';
            }
            let isProductFound = false;

            return (
            <tr key={order.id}>
                <td width="40%"><Link hrefLang={"en"}  to={{ pathname: "/account/order/view", id: order.id}}>#{order.order_no}</Link>
                    <br/>
                    <form name={'invoice_download_'+order.order_no} id={'invoice_download_'+order.order_no} method="POST" action={Config.POS+'/download-invoice'} style={{display: 'none'}}>
                        <input type="hidden" name="id" value={order.order_no} />
                        <input type="hidden" name="random_id" value={order.customer_id} />
                    </form>
                    <span style={{fontSize: 12, color: '#000'}}>Order Items:</span><br/>
                    <ul style={{marginLeft: -20}}>
                    {order.order_items.map((order_item, index) => {
                        if(order_item.item_details !== undefined){
                            if(order_item.item_details.is_gift_card !== undefined && order_item.item_details.is_gift_card){
                                return(
                                    <React.Fragment key={index}>
                                        <li style={{width: '100%', float: 'left'}}>
                                        <span style={{fontSize: 10}}>Gift Card</span><br/>
                                        <span style={{fontSize: 9}}>Item Code: {order_item.item_details.card_no}</span><br/>
                                        </li>
                                    </React.Fragment>
                                )
                            } else {
                                isProductFound = true;
                                return(
                                    <React.Fragment key={index}>
                                        <li style={{width: '100%', float: 'left'}}>
                                        <span style={{fontSize: 10}}>{order_item.item_details.full_name}</span><br/>
                                        <span style={{fontSize: 9}}>Item Code: {order_item.item_details.item_code}</span><br/>
                                        </li>
                                    </React.Fragment>
                                )
                            }
                        }
                    })
                    }
                    </ul>
                </td>
                <td width="15%">{moment(order.date_created).format("YYYY/MM/DD")}</td>
                <td width="15%">{orderStatus}</td>
                <td width="15%"><Currency value={order.total_amount} /></td>
                <td width="20%">
                <select onChange={(event) => { this.handleSetValue(event, order) }} id="order-actions" name="actions" className="form-control form-control-select2">
                    <option value="">Actions</option>
                    <option value="view_details">View Details</option>
                    {(order.web_order_status > 0 && order.web_order_status !== 2 && order.web_order_status !== 6) &&
                        <option value="download_invoice">Download Invoice</option>
                    }
                    {(order.web_order_status === 5 || order.web_order_status === 6) &&
                        <>
                            {isProductFound &&
                            <option value="request_for_return">Request For Return</option>
                            }
                        </>
                    }
                </select>
                </td>
            </tr>
        )
        });

        return (
            <div className="dashboard">
                <Helmet>
                    <title>{`Dashboard | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="dashboard__profile card profile-card">
                    <div className="card-body profile-card__body">
                        <div className="profile-card__avatar">
                            <img onClick={() => { this.upload.click() }} src={this.state.currentPic} title={(this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : '')} alt={(this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : '')} />
                            <input style={{ display: 'none' }} type="file" ref={(ref) => this.upload = ref} onChange={(event) => { this.onFileChange(event) }} />
                        </div>
                        <div className="profile-card__name">{(this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : '')}</div>
                        <div className="profile-card__email">{(this.state.profile ? this.state.profile.email : '')}</div>
                        <div className="profile-card__edit">
                            <Link hrefLang={"en"}  to="profile" className="btn btn-secondary btn-sm">Edit Profile</Link>
                        </div>
                    </div>
                </div>

                <div className="dashboard__address card address-card address-card--featured">
                    {this.state.defaultAddress !== '' &&
                    <>
                        <div className="address-card__badge">Default Address</div>
                        <div className="address-card__body">
                            <span className="typeOfAddress">{this.state.defaultAddress.type_of_address}</span>
                            <div className="address-card__name">{`${this.state.defaultAddress.first_name} ${this.state.defaultAddress.last_name}`} </div>
                            <div className="address-card__row">
                                {this.state.defaultAddress.country_name}
                                <br />
                                {this.state.defaultAddress.postcode}
                                ,&nbsp;
                                {this.state.defaultAddress.city}
                                <br />
                                {this.state.defaultAddress.address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{this.state.defaultAddress.contact}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{this.state.defaultAddress.email}</div>
                            </div>
                            <div className="address-card__footer">
                                <Link hrefLang={"en"}  to={{ pathname: "/account/address/edit", address: this.state.defaultAddress}}>Edit</Link>
                            </div>
                        </div>
                    </>
                    }
                    {this.state.defaultAddress === '' &&
                        <div className="address-card__badge">You have no any default address</div>
                    }
                </div>
                <div className="dashboard__orders card">
                    <div className="card-header">
                        <h5>My Recent Orders</h5>
                    </div>
                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#Order</th>
                                        <th>Order Date</th>
                                        <th>Status</th>
                                        <th>Order Total</th>
                                        <th width="20%">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageDashboard;