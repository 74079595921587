// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

function WidgetTags() {
    return (
        <div className="widget-tags widget">
            <h4 className="widget__title">Tags Cloud</h4>
            <div className="tags tags--lg">
                <div className="tags__list">
                    <Link hrefLang={"en"}  to="/">Promotion</Link>
                    <Link hrefLang={"en"}  to="/">Power Tool</Link>
                    <Link hrefLang={"en"}  to="/">New Arrivals</Link>
                    <Link hrefLang={"en"}  to="/">Screwdriver</Link>
                    <Link hrefLang={"en"}  to="/">Wrench</Link>
                    <Link hrefLang={"en"}  to="/">Mounts</Link>
                    <Link hrefLang={"en"}  to="/">Electrodes</Link>
                    <Link hrefLang={"en"}  to="/">Chainsaws</Link>
                    <Link hrefLang={"en"}  to="/">Manometers</Link>
                    <Link hrefLang={"en"}  to="/">Nails</Link>
                    <Link hrefLang={"en"}  to="/">Air Guns</Link>
                    <Link hrefLang={"en"}  to="/">Cutting Discs</Link>
                </div>
            </div>
        </div>
    );
}

export default WidgetTags;
