import shopApi from '../../api/shop';
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from './quickviewActionTypes';
import { Config } from '../../config';

let cancelPreviousRequest = () => {};

export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(final_item_code) {
    return (dispatch) => {
        cancelPreviousRequest();

        return new Promise((resolve) => {
            let canceled = false;
            // sending request to server, timeout is used as a stub
            const timer = setTimeout(() => {
                
                // if (product) {
                //     dispatch(quickviewOpenSuccess(product));
                // }
                // resolve();

                let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('device_type', Config.device_type);
                if(localStorage.getItem(Config.x_token) !== null){
                    headers.append('device_token', Config.device_token);
                    headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));
                }
                
                fetch(Config.URL+"/get-product-details", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({ final_item_code : final_item_code })
                })
                .then(response => response.json())
                .then(response => {
                    if (canceled) {
                        return;
                    }
                    if(response.status){
                        dispatch(quickviewOpenSuccess(response.data));
                        resolve();
                    } else {
                        return;
                    }
                })
                .catch(err => {
                    //console.log("Product err: ", err);
                    return;
                });

                // shopApi.getProductBySlug(productSlug).then((product) => {
                //     if (canceled) {
                //         return;
                //     }

                //     if (product) {
                //         dispatch(quickviewOpenSuccess(product));
                //     }

                //     resolve();
                // });
            }, 350);

            cancelPreviousRequest = () => {
                canceled = true;
                clearTimeout(timer);
                resolve();
            };
        });
    };
}
