export default {
    name: 'Khaliques',
    year: 2021,
    fullName: '',
    url: 'https://www.khaliques.co.za',
    author: {
        name: 'Kaushik Sureja',
        profile_url: 'https://www.hkinfoway.com',
    },
    contacts: {
        address: 'Address Radisson Hotel, Level 4,Corner Linden Road & Daisy Street, Sandton',
        email: 'info@khaliques.co.za',
        phone: '+27 11 784 5114',
    },
};
