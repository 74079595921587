// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';

import { toast } from 'react-toastify';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPageMeasurements extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            profile: {},
            jacketSizes: [],
            shirtSizes: [],
            shoeSizes: [],
            basicFormFields: {
                jacket_size: '',
                shirt_size: '',
                shoe_size: '',
                trouser_length: '',
                trouser_waist: '',
                old_code: ''
            },
            basicFormFieldsError: {
                isEmptyJacketSize: false,
                isEmptyShirtSize: false,
                isEmptyShoesSize: false,
                isEmptyTrouserLength: false,
                isEmptyTrouserWaist: false,
                isEmptyOldCode: false
            },
        }
    }

    componentDidMount() {
        this.getMeasurements();
        this.getProfile();
    }

    getMeasurements() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-measurements", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getMeasurements response: ", response);
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({
                        jacketSizes: response.data.jacket_sizes,
                        shirtSizes: response.data.shirt_sizes,
                        shoeSizes: response.data.shoe_sizes,
                    });
                }
            } else {
                this.props.history.push("/");
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err getMeasurements: ", err);
            toast.error(err);
        });
    }

    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    getProfile(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/get-profile", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({
                        profile: response.data,
                        basicFormFields: response.data
                    });
                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                }
            } else {
                toast.error(response.message);
                this.props.history.push("/");
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err getProfile: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateUpdateProfileForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateUpdateProfileForm() {
        var isReturn = true;
        let basicFormFieldsError = {
            isEmptyJacketSize: false,
            isEmptyShirtSize: false,
            isEmptyShoesSize: false,
            isEmptyTrouserLength: false,
            isEmptyTrouserWaist: false,
            isEmptyOldCode: false
        };

        var shirt_size = this.state.basicFormFields.shirt_size;
        var jacket_size = this.state.basicFormFields.jacket_size;
        var shoe_size = this.state.basicFormFields.shoe_size;
        var trouser_length = this.state.basicFormFields.trouser_length;
        var trouser_waist = this.state.basicFormFields.trouser_waist;
        var old_code = this.state.basicFormFields.old_code;

        if (shirt_size === '' || shirt_size === 0 || shirt_size === null) {
            basicFormFieldsError.isEmptyShirtSize = true;
            isReturn = false;
        }
        if (jacket_size === '' || jacket_size === 0 || jacket_size === null) {
            basicFormFieldsError.isEmptyJacketSize = true;
            isReturn = false;
        }
        if (shoe_size === '' || shoe_size === 0 || shoe_size === null) {
            basicFormFieldsError.isEmptyShoesSize = true;
            isReturn = false;
        }
        if (trouser_length === '' || trouser_length === null) {
            basicFormFieldsError.isEmptyTrouserLength = true;
            isReturn = false;
        }
        if (trouser_waist === '' || trouser_waist === null) {
            basicFormFieldsError.isEmptyTrouserWaist = true;
            isReturn = false;
        }
        if (old_code === '' || old_code === null) {
            basicFormFieldsError.isEmptyOldCode = true;
            isReturn = false;
        }
        this.setState({ basicFormFieldsError });
        return isReturn;
    }

    updateProfile(){
        let isValidated = this.validateUpdateProfileForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        let updateProfileJSON = {... this.state.basicFormFields};

        fetch(Config.URL+"/update-measurements", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(updateProfileJSON)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.props.history.push('/account/dashboard');
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            basicFormFields: { ...prevState.basicFormFields, [e.target.name]: e.target.value }
        }))
    }

    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{`Measurements | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="card-header">
                    <h5>Edit Measurements</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="profile-shirt-size">Select Shirt Size</label>
                                <select onChange={this.handleSetValue} className="form-control" name="shirt_size" id="profile-shirt-size">
                                    <option value=''>Select Shirt Size</option>
                                    {this.state.shirtSizes.length > 0 &&
                                        this.state.shirtSizes.map((item) => {
                                            return(
                                                <>
                                                {this.state.profile.shirt_size == item.id &&
                                                    <option key={'shirt_size_'+item.id} selected value={item.id}>{item.name}</option>
                                                }
                                                {this.state.profile.shirt_size != item.id &&
                                                    <option key={'shirt_size_'+item.id} value={item.id}>{item.name}</option>
                                                }
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {this.state.basicFormFieldsError.isEmptyShirtSize ?
                                <span className="errorInput">Please select shirt size</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-jacket-size">Select Jacket Size</label>
                                <select onChange={this.handleSetValue} className="form-control" name="jacket_size" id="profile-jacket-size">
                                    <option value=''>Select Jacket Size</option>
                                    {this.state.jacketSizes.length > 0 &&
                                        this.state.jacketSizes.map((item) => {
                                            return(
                                                <>
                                                {this.state.profile.jacket_size == item.id &&
                                                    <option key={'jacket_size_'+item.id} selected value={item.id}>{item.name}</option>
                                                }
                                                {this.state.profile.jacket_size != item.id &&
                                                    <option key={'jacket_size_'+item.id} value={item.id}>{item.name}</option>
                                                }
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {this.state.basicFormFieldsError.isEmptyJacketSize ?
                                <span className="errorInput">Please select jacket size</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-trouser-length">Trouser Length (CM)</label>
                                <input
                                    id="profile-trouser-length"
                                    name="trouser_length"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your trouser length"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.basicFormFields.trouser_length}
                                />
                                {this.state.basicFormFieldsError.isEmptyTrouserLength ?
                                <span className="errorInput">Please enter trouser length</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-trouser-waist">Trouser Waist (CM)</label>
                                <input
                                    id="profile-trouser-waist"
                                    name="trouser_waist"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your trouser waist"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.profile.trouser_waist}
                                />
                                {this.state.basicFormFieldsError.isEmptyTrouserWaist ?
                                <span className="errorInput">Please enter trouser waist</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-shoes-size">Select Shoes Size</label>
                                <select onChange={this.handleSetValue} className="form-control" name="shoe_size" id="profile-shoes-size">
                                <option value=''>Select Shoes Size</option>
                                    {this.state.shoeSizes.length > 0 &&
                                        this.state.shoeSizes.map((item) => {
                                            return(
                                                <>
                                                {this.state.profile.shoe_size == item.id &&
                                                    <option key={'shoe_size_'+item.id} selected value={item.id}>{item.name}</option>
                                                }
                                                {this.state.profile.shoe_size != item.id &&
                                                    <option key={'shoe_size_'+item.id} value={item.id}>{item.name}</option>
                                                }
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {this.state.basicFormFieldsError.isEmptyShoesSize ?
                                <span className="errorInput">Please select shoes size</span>
                                : null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-old-code">Old Code</label>
                                <input
                                    id="profile-old-code"
                                    name="old_code"
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your old code"
                                    onChange={this.handleSetValue}
                                    defaultValue={this.state.profile.old_code}
                                />
                                {this.state.basicFormFieldsError.isEmptyOldCode ?
                                <span className="errorInput">Please enter old code</span>
                                : null
                                }
                            </div>

                            <div className="form-group mt-3 mb-0">
                                <button onClick={() => { this.updateProfile() }} type="button" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountPageMeasurements;