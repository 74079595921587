// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import lottie from "lottie-web";
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// application
import { FiPaymentSecurity48Svg } from '../../svg';
import animationPaymentInProcess from '../../lotties/payment-in-process';
// data stubs
import theme from '../../data/theme';
import { url } from '../../services/utils';
import shopApi from '../../api/shop';

export default class ShopPageOrderProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Processing, Please Wait...!',
            message: 'Please do not click the refresh or back button or this transaction may be interrupted or terminated.',
            payment_params: ((props.location.payment_params === undefined || props.location.payment_params === null || props.location.payment_params === '') ? '' : props.location.payment_params),
            payment_method: ((props.location.payment_method === undefined || props.location.payment_method === null || props.location.payment_method === '') ? '' : props.location.payment_method),
            intervalFunction: '',
        };
    }

    componentDidMount(){
        if(this.state.payment_params == undefined || this.state.payment_params == '' || this.state.payment_method == undefined || this.state.payment_method == ''){
            this.props.history.push("/404");
        } else {
            if(this.state.payment_params !== '' && (this.state.payment_method == 'paygate' || this.state.payment_method == 'ozow')){
                setTimeout(() => {
                    document.getElementById("payment_form").submit();
                }, 2000);
            }
            if(this.state.payment_params !== '' && this.state.payment_method == 'payjustnow'){
                this.setState({ message: "Khaliques redirecting you to the new tab for payment process. Please do not click the refresh or back button or this transaction may be interrupted or terminated." })

                setTimeout(() => {
                    window.open(this.state.payment_params.redirect_to, '_blank');
                }, 2000);

                var $this = this;
                this.state.intervalFunction = setInterval(() => {
                    $this.getOrderStatus();
                }, 5000);

            }
            if(this.state.payment_params !== '' && this.state.payment_method == 'payflex'){
                this.setState({ message: "Khaliques redirecting you to the new tab for payment process. Please do not click the refresh or back button or this transaction may be interrupted or terminated." })

                setTimeout(() => {
                    window.open(this.state.payment_params.redirect_to, '_blank');
                }, 2000);

                var $this = this;
                this.state.intervalFunction = setInterval(() => {
                    $this.getOrderStatus();
                }, 5000);

            }
        }
    }

    reOpenPaymentPage(){
        setTimeout(() => {
            window.open(this.state.payment_params.redirect_to, '_blank');
        }, 1000);

        var $this = this;
        if(this.state.intervalFunction == ''){
            //console.log("Started again!");
            this.state.intervalFunction = setInterval(() => {
                $this.getOrderStatus();
            }, 5000);
        }
    }

    getOrderStatus(){
        shopApi.getOrderStatus(this.state.payment_params.order_number).then((response) => {
            //console.log("getOrderStatus response", response);
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    if(this.state.intervalFunction !== null){
                        //console.log("Cleared");
                        clearInterval(this.state.intervalFunction);
                    }
                    setTimeout(() => {
                        this.props.history.push("/");
                    }, 2000);
                } else {
                    //this.setState({ title: response.message.title, message: response.message.message });
                    if(response.data !== null){
                        if(response.data === 1){
                            if(this.state.intervalFunction !== null){
                                //console.log("Cleared");
                                clearInterval(this.state.intervalFunction);
                            }
                            //shopApi.triggerPurchaseGTag(this.state.payment_params.order_number);
                            setTimeout(() => {
                                this.props.history.push("/order-status/"+this.state.payment_params.order_number);
                            }, 2000);
                        }
                    }
                }
             } else {
                if(this.state.intervalFunction !== null){
                    //console.log("Cleared");
                    clearInterval(this.state.intervalFunction);
                }
                setTimeout(() => {
                    this.props.history.push("/order-cancel");
                }, 2000);
             }
        });
    }

    render() {

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Order Processing | ${theme.name}`}</title>
                </Helmet>

                <div className="container">
                    <div className="order-success__body">

                        <div className="order-success__header">
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: animationPaymentInProcess,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={300}
                                width={300}
                            />
                            <h1 className="order-success__title">{this.state.title}</h1>
                            <div className="order-success__subtitle">{this.state.message}</div>

                            {(this.state.payment_params !== '' && this.state.payment_params.redirect_to !== '' && (this.state.payment_method == 'payjustnow' || this.state.payment_method == 'payflex')) &&
                            <div className="order-success__actions">
                                <span onClick={() => { this.reOpenPaymentPage(); }} className="btn btn-xs btn-secondary">Click here if you not redirect to the payment page.</span>
                            </div>
                            }
                        </div>

                        {this.state.payment_params !== '' && this.state.payment_method == 'paygate' &&
                        <form name="payment_form" id="payment_form" method="POST" action={this.state.payment_params.RedirectUrl} style={{display: 'none'}}>
                            <input type="hidden" name="PAYGATE_ID" value={this.state.payment_params.PAYGATE_ID} />
                            <input type="hidden" name="REFERENCE" value={this.state.payment_params.REFERENCE} />
                            <input type="hidden" name="PAY_REQUEST_ID" value={this.state.payment_params.PAY_REQUEST_ID} />
                            <input type="hidden" name="CHECKSUM" value={this.state.payment_params.CHECKSUM} />
                        </form>
                        }

                        {this.state.payment_params !== '' && this.state.payment_method == 'ozow' &&
                        <form name="payment_form" id="payment_form" method="POST" action={this.state.payment_params.RedirectUrl}>
                            <input type="hidden" name="SiteCode" value={this.state.payment_params.SiteCode} />
                            <input type="hidden" name="CountryCode" value={this.state.payment_params.CountryCode} />
                            <input type="hidden" name="CurrencyCode" value={this.state.payment_params.CurrencyCode} />
                            <input type="hidden" name="Amount" value={this.state.payment_params.Amount} />
                            <input type="hidden" name="TransactionReference" value={this.state.payment_params.TransactionReference} />
                            <input type="hidden" name="BankReference" value={this.state.payment_params.BankReference} />
                            <input type="hidden" name="Optional1" value={this.state.payment_params.Optional1} />
                            <input type="hidden" name="Optional2" value={this.state.payment_params.Optional2} />
                            <input type="hidden" name="Optional3" value={this.state.payment_params.Optional3} />
                            <input type="hidden" name="Customer" value={this.state.payment_params.Customer} />
                            <input type="hidden" name="CancelUrl" value={this.state.payment_params.CancelUrl} />
                            <input type="hidden" name="ErrorUrl" value={this.state.payment_params.ErrorUrl} />
                            <input type="hidden" name="SuccessUrl" value={this.state.payment_params.SuccessUrl} />
                            <input type="hidden" name="NotifyUrl" value={this.state.payment_params.NotifyUrl} />
                            <input type="hidden" name="IsTest" value={this.state.payment_params.IsTest} />
                            <input type="hidden" name="HashCheck" value={this.state.payment_params.HashCheck} />
                        </form>
                        }

                    </div>
                </div>
            </div>
        );

    }
}
