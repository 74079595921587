// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
import DropdownLanguage from './DropdownLanguage';

import { Config } from "../../config";
import { toast } from 'react-toastify';
import { cartRefresh } from '../../store/cart';
import { wishlistRefesh } from '../../store/wishlist';

function Topbar(props) {

    const { cartRefresh, wishlistRefesh } = props;

    const links = [
        //{ title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: '/about-us' },
        //{ title: <FormattedMessage id="topbar.contacts" defaultMessage="Contacts" />, url: '/contact-us' },
        // { title: <FormattedMessage id="topbar.storeLocation" defaultMessage="Store Location" />, url: '/store-location' },
        // { title: <FormattedMessage id="topbar.trackOrder" defaultMessage="Track Order" />, url: '/track-order' },
        // { title: <FormattedMessage id="topbar.requestForReturn" defaultMessage="Request for Return" />, url: '/request-for-return' },
        //{ title: <FormattedMessage id="topbar.blog" defaultMessage="Blog" />, url: '/blog/category-classic' },
    ];

    const accountLinks = [
        { title: 'Dashboard', url: '/account/dashboard' },
        { title: 'Edit Profile', url: '/account/profile' },
        { title: 'Order History', url: '/account/orders' },
        { title: 'Addresses', url: '/account/addresses' },
        { title: 'Password', url: '/account/password' },
        { title: 'Logout', url: '/account/login' },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link hrefLang={"en"}  className="topbar-link" to={item.url}>{item.title}</Link>
        </div>
    ));

    function handleClick(item){
        if(item.title == 'Logout'){
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

            fetch(Config.URL+"/logout", {
                method: "POST",
                headers: headers,
                body: null
            })
            .then(response => response.json())
            .then(response => {
                cartRefresh();
                wishlistRefesh();
            })
            .catch(err => {
                //console.log("Logout err: ", err);
            });
            localStorage.removeItem(Config.x_token);
            localStorage.removeItem(Config.web_user);
        }
    }

    let user = (localStorage.getItem(Config.web_user) !== null ? Config.getData(localStorage.getItem(Config.web_user)) : null);

    return (
        <div className="site-header__topbar topbar" style={{height: 60}}>
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" style={{height: 39}}>
                        {/* <div className="slide-animation">
                            <div>Download</div>
                            <div>
                                <span>&nbsp;our free app today</span>
                            </div>
                        </div>
                        <img style={{width: 130, marginLeft: 10}} src="/images/google-play.png" alt="Google Play" /> */}
                        {/* <span className="linearAnimation" data-text="Download our free app today">Download our free app today</span> */}
                        <span style={{color: '#999', fontSize: 18}}>Download <span>our free app today</span>
                            <Link className="blink-text" hrefLang={"en"} target={"_blank"} rel="noopener noreferrer" to={{pathname: 'https://play.google.com/store/apps/details?id=com.khaliques'}}>
                                <img style={{width: 130, marginLeft: 10}} src="/images/google-play.png" alt="Google Play" />
                            </Link>
                            <Link className="blink-text" hrefLang={"en"} target={"_blank"} rel="noopener noreferrer" to={{pathname: 'https://apps.apple.com/us/app/khaliques-sa/id1570961415'}}>
                                <img style={{width: 110, marginLeft: 10}} src="/images/app-store.jpg" alt="App Store" />
                            </Link>
                        </span>
                    </div>
                    <div className="topbar__item">
                        {user &&
                            <span>Welcome: {JSON.parse(user).first_name} {JSON.parse(user).last_name}&nbsp;&nbsp;&nbsp;| </span>
                        }
                        {!user &&
                            <span><Link hrefLang={"en"}  className="noLink" to="/account/login">Login | SignUp</Link></span>
                        }
                        {user &&
                            <Dropdown
                                title={<FormattedMessage id="topbar.myAccount" defaultMessage="My Account" />}
                                items={accountLinks}
                                onClick={(item) => { handleClick(item) }}
                            />
                        }
                    </div>
                    {/* <div className="topbar__item">
                        <DropdownCurrency />
                    </div>
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

//export default Topbar;

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    cart: state.cart,
});

const mapDispatchToProps = { cartRefresh, wishlistRefesh };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Topbar);