// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import CategorySidebar from './CategorySidebar';
import CategorySidebarItem from './CategorySidebarItem';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import shopApi from '../../api/shop';
import WidgetFilters from '../widgets/WidgetFilters';
import WidgetProducts from '../widgets/WidgetProducts';
import { sidebarClose } from '../../store/sidebar';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// data stubs
import theme from '../../data/theme';
import { url, getCategoryParents } from '../../services/utils';
import { Config } from '../../config';

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === 'string') {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === 'string') {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === 'string') {
        optionValues.sort = query.sort;
    }
    if (typeof query.search === 'string') {
        optionValues.search = query.search;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [
        parseQueryOptions(location),
        parseQueryFilters(location),
    ];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== 'default') {
        params.sort = options.sort;
    }

    if (options.search != '') {
        params.search = options.search;
    }

    Object.keys(filters).filter((x) => x !== 'category' && !!filters[x]).forEach((filterSlug) => {
        params[`filter_${filterSlug}`] = filters[filterSlug];
    });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
     category_id: null,
     /**
      * Indicates that the products list is loading.
      */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     * options.search:  string - Search product.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
    case 'FETCH_CATEGORY_SUCCESS':
        return {
            ...state,
            init: true,
            categoryIsLoading: false,
            //category: action.category,
            //categoryObj: action.category,
            //categoryName: action.category.data[0].name,
            //category_id: action.category.data[0].id
        };
    case 'FETCH_PRODUCTS_LIST':
        return {
            ...state,
            productsListIsLoading: true
        };
    case 'FETCH_PRODUCTS_LIST_SUCCESS':
        return {
            ...state,
            init: true,
            productsListIsLoading: false,
            productsList: action.productsList,
            //category: action.category,
        };
    case 'SET_OPTION_VALUE':
        return {
            ...state,
            options: { ...state.options, page: 1, [action.option]: action.value },
        };
    case 'SET_FILTER_VALUE':
        return {
            ...state,
            options: { ...state.options, page: 1 },
            filters: { ...state.filters, [action.filter]: action.value },
        };
    case 'RESET_FILTERS':
        //return { ...state, options: { ...state.options, page: 1 }, filters: { category_id: state.filters.category_id } };
        //return state.init ? initialState : state;
        return {
            ...state,
            options: { page : 1},
            filters: {}
        };
    case 'RESET':
        return state.init ? initialState : state;
    default:
        throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageCategory(props) {
    const {
        categorySlug,
        brandSlug,
        columns,
        viewMode,
        sidebarPosition,
        location,
        search
    } = props;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const [state, dispatch] = useReducer(reducer, initialState, init);
    //const [latestProducts, setLatestProducts] = useState([]);

    const [metaTags, setMetaTags] = useState({
        meta_desc: '',
        meta_keywords: '',
        meta_title: '',
        image: '',
        name: ''
    });

    //console.log("search NS: ", search);
    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location_c = `${window.location.pathname}${query ? '?' : ''}${query}`;
        window.history.replaceState(null, '', location_c);
    }, [state.options, state.filters]);

    // Load category.
    // useEffect(() => {
    //     let request;
    //     let canceled = false;

    //     //let cat_slug = (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0];
    //     //categorySlug = cat_slug;
    //     dispatch({ type: 'RESET', categorySlug });
    //     if (categorySlug) {
    //         request = shopApi.getCategoryBySlug(categorySlug);
    //     } else {
    //         request = Promise.resolve(null);
    //     }

    //     request.then((category) => {
    //         if (canceled) {
    //             return;
    //         }

    //         dispatch({ type: 'FETCH_CATEGORY_SUCCESS', category });
    //     });

    //     return () => {
    //         canceled = true;
    //     };
    // }, [dispatch, categorySlug]);

    // useEffect(() => {
    //     // const { pathname } = location;
    //     // console.log('New path:', pathname);
    //     const { pathname } = location;
    //     const url = new URL(Config.URL+pathname);
    //     const params = new URLSearchParams(url.search);
    //     const filter_brands = params.get('filter_brands');
    // }, [location.pathname, state.options, {...state.filters, brands: filter_brands}]);


    // Load products.
    useEffect(() => {
        // console.log("state", state);
        //const { pathname } = location;
        const url = new URL(window.location);
        const params = new URLSearchParams(url.search);
        var filter_brands = params.get('filter_brands');
        //console.log("url", url);
        // console.log("filter_brands", filter_brands);
        if(filter_brands !== undefined && filter_brands !== null && filter_brands !== ''){
            filter_brands = filter_brands;
        } else {
            filter_brands = '';
        }
        let canceled = false;

        //dispatch({ type: 'RESET', categorySlug });
        // console.log("categorySlug: ", categorySlug);
        // console.log("state.filters: ", state.filters);
        // console.log("state.options: ", state.options);
        //   console.log("window.location.pathname: ", window.location.pathname);

        if(categorySlug !== undefined && categorySlug !== null && categorySlug !== '' && categorySlug !== 'all'){
            let request;
            request = shopApi.getCategoryBySlug(categorySlug);
            request.then((category) => {
                if (canceled) {
                    return;
                }
                //dispatch({ type: 'FETCH_CATEGORY_SUCCESS', category });

                //console.log("category", category);

                if(category.status){
                    setMetaTags({
                        meta_desc: category.data.meta_description,
                        meta_keywords: category.data.meta_keywords,
                        meta_title: category.data.meta_title,
                        image: category.data.image,
                        name: category.data.name
                    })
                    dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                    let categoryObj = category.data;
                    shopApi.getProductsList( state.options, { ...state.filters, brands: filter_brands, category: ((categoryObj.name).toLowerCase()).split("?")[0], category_id: categoryObj.id },
                    ).then((productsList) => {
                        if (canceled) {
                            return;
                        }

                        dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                        dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                    });

                } else {
                    dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                    shopApi.getProductsList( state.options, { ...state.filters, brands: filter_brands, category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
                    ).then((productsList) => {
                        if (canceled) {
                            return;
                        }

                        dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                        dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                    });
                }
            });
        } else if(brandSlug !== undefined && brandSlug !== null && brandSlug !== '') {
            let request;
            request = shopApi.getBrandBySlug(brandSlug);
            request.then((brand) => {
                if (canceled) {
                    return;
                }
                if(brand.status){
                    setMetaTags({
                        meta_desc: brand.data.meta_description,
                        meta_keywords: brand.data.meta_keywords,
                        meta_title: brand.data.meta_title,
                        image: brand.data.image,
                        name: brand.data.name
                    })
                    dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                    shopApi.getProductsList( state.options, { ...state.filters, brands: (brand.data.id).toString(), category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
                    ).then((productsList) => {
                        if (canceled) {
                            return;
                        }

                        dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                        dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                    });

                } else {

                    dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                    shopApi.getProductsList( state.options, { ...state.filters, brands: filter_brands, category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
                    ).then((productsList) => {
                        if (canceled) {
                            return;
                        }

                        dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                        dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                    });
                }

            })
        } else {
            if(categorySlug !== undefined && categorySlug !== null && categorySlug !== '' && categorySlug == 'all'){
                if(state.options.page === undefined){
                    dispatch({ type: 'RESET' });
                    dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                }
                shopApi.getProductsList( state.options, { ...state.filters, brands: '', category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
                ).then((productsList) => {
                    if (canceled) {
                        return;
                    }

                    dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                    dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                });

            } else {

                dispatch({ type: 'FETCH_PRODUCTS_LIST' });
                shopApi.getProductsList( state.options, { ...state.filters, brands: filter_brands, category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
                ).then((productsList) => {
                    if (canceled) {
                        return;
                    }

                    dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
                    dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
                });

            }
        }

        //if(state.categoryObj !== undefined && state.categoryObj !== null && state.categoryObj.data !== null){
            //shopApi.getProductsList( state.options, { ...state.filters, category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0], category_id: props.location.id },
            // shopApi.getProductsList( state.options, { ...state.filters, category: ((sate.categoryName).toLowerCase()).split("?")[0], category_id: state.category_id },
            // ).then((productsList) => {
            //     if (canceled) {
            //         return;
            //     }

            //     dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
            //     //dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
            // });
        // } else {
        //     shopApi.getProductsList( state.options, { ...state.filters, category: '' },
        //     ).then((productsList) => {
        //         if (canceled) {
        //             return;
        //         }

        //         dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
        //         //dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
        //     });
        // }

        // shopApi.getProductsList( state.options, { ...state.filters, category: ((location.pathname).split("/")[2]).toLowerCase(), category_id: props.location.id },
        // ).then((productsList) => {
        //     if (canceled) {
        //         return;
        //     }

        //     dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
        //     dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
        // });

        return () => {
            canceled = true;
        };
    }, [location.pathname, dispatch, (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0], state.options, state.filters]);

    // Load latest products.
    // useEffect(() => {
    //     let canceled = false;

    //     if (offcanvas === 'always') {
    //         setLatestProducts([]);
    //     } else {
    //         shopApi.getLatestProducts({ limit: 5 }).then((result) => {
    //             if (canceled) {
    //                 return;
    //             }

    //             setLatestProducts(result);
    //         });
    //     }

    //     return () => {
    //         canceled = true;
    //     };
    // }, [offcanvas]);

    if (state.categoryIsLoading || (state.productsListIsLoading && !state.productsList)) {
        //return <BlockLoader />;
        return (
            <>
                <div className="container">
                    <div className="page-header">
                        <div className="page-header__container container">
                            <div className="page-header__breadcrumb">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/"><Skeleton count={1} /></a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="page-header__title"><h1>&nbsp;</h1></div>
                        </div>
                    </div>
                    <div className="shop-layout shop-layout--sidebar--start">
                        <div className="shop-layout__sidebar">
                        <div className="block block-sidebar block-sidebar--offcanvas--mobile">
                            <div className="block-sidebar__backdrop"></div>
                            <div className="block-sidebar__body">
                                <div className="block-sidebar__header">
                                    <div className="block-sidebar__title"><Skeleton count={1} /></div>
                                    <button className="block-sidebar__close" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                        <path d="M17.71,17.71L17.71,17.71c-0.387,0.387-1.013,0.387-1.4,0L10,11.4l-6.31,6.31c-0.387,0.387-1.013,0.387-1.4,0l0,0 c-0.387-0.387-0.387-1.013,0-1.4L8.6,10L2.29,3.69c-0.387-0.387-0.387-1.013,0-1.4l0,0c0.387-0.387,1.013-0.387,1.4,0L10,8.6 l6.31-6.31c0.387-0.387,1.013-0.387,1.4,0l0,0c0.387,0.387,0.387,1.013,0,1.4L11.4,10l6.31,6.31 C18.097,16.697,18.097,17.323,17.71,17.71z"></path>
                                    </svg>
                                    </button>
                                </div>
                                <div className="block-sidebar__item">
                                    <div className="widget-filters widget widget-filters--offcanvas--mobile">
                                    <h4 className="widget-filters__title widget__title">Filters</h4>
                                    <div className="widget-filters__list">
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-filters__item">
                                            <div className="filter">
                                                <button type="button" className="filter__title">
                                                <Skeleton count={1} />
                                                </button>
                                                <div className="filter__body">
                                                <div className="filter__container">
                                                    <div className="filter-list">
                                                        <div className="filter-list__list">
                                                            <label className="filter-list__item">
                                                            <span className="filter-list__input input-check">
                                                                <span className="input-check__body">
                                                                </span>
                                                            </span>
                                                            <span className="filter-list__title">
                                                                    <Skeleton count={1} />
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="shop-layout__content">
                        <div className="block">
                            <div className="products-view">
                                <div className="products-view__loader"></div>
                                <div className="products-view__content">
                                    <div className="products-view__options">
                                    <div className="view-options view-options--offcanvas--mobile">
                                        <div className="view-options__filters-button">
                                            <button type="button" className="filters-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="filters-button__icon">
                                                <path d="M7,14v-2h9v2H7z M14,7h2v2h-2V7z M12.5,6C12.8,6,13,6.2,13,6.5v3c0,0.3-0.2,0.5-0.5,0.5h-2 C10.2,10,10,9.8,10,9.5v-3C10,6.2,10.2,6,10.5,6H12.5z M7,2h9v2H7V2z M5.5,5h-2C3.2,5,3,4.8,3,4.5v-3C3,1.2,3.2,1,3.5,1h2 C5.8,1,6,1.2,6,1.5v3C6,4.8,5.8,5,5.5,5z M0,2h2v2H0V2z M9,9H0V7h9V9z M2,14H0v-2h2V14z M3.5,11h2C5.8,11,6,11.2,6,11.5v3 C6,14.8,5.8,15,5.5,15h-2C3.2,15,3,14.8,3,14.5v-3C3,11.2,3.2,11,3.5,11z"></path>
                                                </svg>
                                                <span className="filters-button__title">Filters</span>
                                            </button>
                                        </div>
                                        <div className="view-options__layout">
                                            <div className="layout-switcher">
                                                <div className="layout-switcher__list">
                                                <button title="Grid" type="button" className="layout-switcher__button layout-switcher__button--active">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                        <path d="M15.2,16H9.8C9.4,16,9,15.6,9,15.2V9.8C9,9.4,9.4,9,9.8,9h5.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7 H9.8C9.4,7,9,6.6,9,6.2V0.8C9,0.4,9.4,0,9.8,0h5.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z M6.2,16H0.8 C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h5.4C6.6,9,7,9.4,7,9.8v5.4C7,15.6,6.6,16,6.2,16z M6.2,7H0.8C0.4,7,0,6.6,0,6.2V0.8 C0,0.4,0.4,0,0.8,0h5.4C6.6,0,7,0.4,7,0.8v5.4C7,6.6,6.6,7,6.2,7z"></path>
                                                    </svg>
                                                </button>
                                                <button title="Grid With Features" type="button" className="layout-switcher__button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                        <path d="M16,0.8v14.4c0,0.4-0.4,0.8-0.8,0.8H9.8C9.4,16,9,15.6,9,15.2V0.8C9,0.4,9.4,0,9.8,0l5.4,0C15.6,0,16,0.4,16,0.8z M7,0.8 v14.4C7,15.6,6.6,16,6.2,16H0.8C0.4,16,0,15.6,0,15.2L0,0.8C0,0.4,0.4,0,0.8,0l5.4,0C6.6,0,7,0.4,7,0.8z"></path>
                                                    </svg>
                                                </button>
                                                <button title="List" type="button" className="layout-switcher__button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                        <path d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h14.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7 H0.8C0.4,7,0,6.6,0,6.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z"></path>
                                                    </svg>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="products-view__list products-list" data-layout="grid-3-sidebar" data-with-features="false">
                                        <div className="products-list__body">
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="products-view__list products-list" data-layout="grid-3-sidebar" data-with-features="false">
                                        <div className="products-list__body">
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="products-view__list products-list" data-layout="grid-3-sidebar" data-with-features="false">
                                        <div className="products-list__body">
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="products-list__item">
                                                <div className="product-card">
                                                    <div className="product-card__image product-image">
                                                        <Skeleton count={1} height={200} />
                                                    </div>
                                                    <div className="product-card__info common-product-listings">
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    <div className="product-card__name">
                                                            <Skeleton count={1}  />
                                                    </div>
                                                    </div>
                                                    <div className="product-card__actions">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
        //return <BlockLoader />;
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: ((((location.pathname).split("/")[2]).toLowerCase()).split("?")[0]).replace(/-/g, " "), url: location.pathname },
    ];

    let pageTitle = capitalizeFirstLetter(((((location.pathname).split("/")[2]).toLowerCase()).split("?")[0]).replace(/-/g, " "));
    let content;

    // if(props.location !== undefined && props.location.search !== undefined && props.location.search !== null && props.location.search !== ''){
    //     var searchString = (props.location.search).split("=");
    //     console.log("Gloabal SearchString Main Page: ", searchString);
    //     if(searchString[0] !== undefined &&
    //         searchString[0]!== null &&
    //         searchString[0]!== '' &&
    //         searchString[0]=== '?search' &&
    //         searchString[1] !== undefined &&
    //         searchString[1]!== null &&
    //         searchString[1]!== ''){
    //                 //setTimeout(() => {
    //                     //setSearchText(options.search);
    //                     //console.log("NSNSNS");
    //                     //const search = options.search;
    //                     // dispatch({
    //                     //     type: 'SEARCH_PRODUCTS',
    //                     //     search: search
    //                     // });
    //                     // dispatch({ type: 'FETCH_PRODUCTS_LIST' });
    //                     // shopApi.getProductsList( options, { ...filters, category: (((location.pathname).split("/")[2]).toLowerCase()).split("?")[0] },
    //                     // ).then((productsList) => {
    //                     //     dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList });
    //                     //     dispatch({ type: 'FETCH_CATEGORY_SUCCESS' });
    //                     // });
    //                 //}, 1000);
    //             // setTimeout(() => {
    //                 // const search = searchString[1];
    //                 // dispatch({
    //                 //     type: 'SEARCH_PRODUCTS',
    //                 //     search: search
    //                 // });
    //             // }, 1000);
    //         // if(state.options.search_all !== undefined && state.options.search_all !== null && state.options.search_all !== ''){
    //         //     if(state.options.search !== searchString[1]){
    //                 //state.options.search = searchString[1];
    //                 //
    //                 // const optionType = 'search';
    //                 // dispatch({
    //                 //     type: 'SET_OPTION_VALUE',
    //                 //     optionType,
    //                 //     value: searchString[1],
    //                 // });
    //         //     }
    //         // }
    //     }
    // }
    //state.options.search = search;
    // console.log("options", state.options);
    // if (state.category) {
    //     getCategoryParents(state.category).forEach((parent) => {
    //         breadcrumb.push({ title: parent.name, url: url.category(parent) });
    //     });

    //     breadcrumb.push({ title: state.category.name, url: url.category(state.category) });
    //     pageTitle = state.category.name;
    // }

    //console.log("state.productsList", state.productsList);

    const productsView = (
        <ProductsView
            location={location}
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
            offcanvas={offcanvas}
        />
    );

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    offcanvas={offcanvas}
                    filters={state.productsList.data.filters}
                    //filters={[]}
                    values={state.filters}
                    dispatch={dispatch}
                />
            </CategorySidebarItem>
            {/* {offcanvas !== 'always' && (
                <CategorySidebarItem className="d-none d-lg-block">
                    <WidgetProducts title="Latest Products" products={latestProducts} />
                </CategorySidebarItem>
            )} */}
        </CategorySidebar>
    );

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar">
                {sidebarComponent}
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaTags.meta_title}</title>
                <meta name="title" content={metaTags.meta_title} />
                <meta name="keywords" content={metaTags.meta_keywords} />
                <meta name="description" content={metaTags.meta_desc} />
                <meta name="twitter:title" content={metaTags.meta_title} />
                <meta property="twitter:image" content={metaTags.image} />
                <meta name="twitter:description" content={metaTags.meta_desc} />
                <meta property="og:title" content={metaTags.meta_title} />
                <meta property="og:description" content={metaTags.meta_desc} />
                <meta property="og:image" content={metaTags.image} />
                <meta property="og:url" content={ Config.DOMAIN + '/category/'+(metaTags.name).toLowerCase() } />
                <link rel="canonical" href={ Config.DOMAIN + '/category/'+(metaTags.name).toLowerCase() } />
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
