// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

// application
import { url } from '../../services/utils';
import PlaceholderComponent from '../../components/PlaceHolder';

function PromotionCard(props) {
    const {
        promotion,
        layout
    } = props;
    const containerClasses = classNames('', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let image;

    if (promotion.promotion_image) {
        image = (
            <div className="product-card__image product-image">
                <Link hrefLang={"en"}  to={{ pathname : url.promotion_detail(promotion)}} className="product-image__body">
                    <img className="product-image__img" src={promotion.promotion_image} alt={promotion.name} title={promotion.name} />
                </Link>
            </div>
        );
    }    

    return (
        <div className={containerClasses}>
            <div key={promotion.id} className="block-brands__item block-special_offers">
                <div style={{backgroundColor: promotion.background_color, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                    <span className="special_offer_discount">{(promotion.discount).toFixed(0)}% OFF</span>
                    {(promotion.short_desc != null && promotion.short_desc != 'null') &&
                        <span className="special_offer_sub_title">{(promotion.short_desc).toLowerCase()}</span>
                    }
                    {(promotion.short_desc == null || promotion.short_desc == '') &&
                        <span className="special_offer_sub_title">Special Offer</span>
                    }
                    <Link hrefLang={"en"}  title={promotion.name} to={url.promotion_detail(promotion)}>
                        {promotion.image !== '' && 
                            <LazyLoad height={200} offset={[0, 0]} placeholder={<PlaceholderComponent />} debounce={500} >
                                <img alt={promotion.name} title={promotion.name} src={promotion.promotion_image} />
                            </LazyLoad>
                        }                    
                        <span className="special_offer_title" style={{backgroundColor: promotion.background_color, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                            {(promotion.name).toLowerCase()}
                        </span>
                    </Link>
                </div>
            </div>
            {/* <div className="product-card__info">
                <div className="product-card__name">
                    <Link hrefLang={"en"}  to={url.promotion_detail(promotion)}>{promotion.short_desc}</Link>
                </div>
            </div>
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link hrefLang={"en"}  to={url.promotion_detail(promotion)}>{promotion.name}</Link>
                    <div>&nbsp;</div>
                </div>
            </div> */}
        </div>
    );
}

PromotionCard.propTypes = {
    /**
     * product object
     */
    promotion: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PromotionCard);
