// react
import React from 'react';

// data stubs
import theme from '../../data/theme';
import shopApi from '../../api/shop';

export default function FooterContacts() {

    const clickPhone = () => {
        shopApi.g4SingleEventPush('phone_number_click', window.location.href)
    }

    const clickEmail = () => {
        shopApi.g4SingleEventPush('email_click', window.location.href)
    }

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            <div className="footer-contacts__text">
                With over 47 years' experience, Khaliques is South Africa's premium suit retailer and the destination for menswear For the businessman who knows what he wants.
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {theme.contacts.address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <a onClick={() => clickEmail()} href={'mailto:'+theme.contacts.email}>{theme.contacts.email}</a>
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    <a onClick={() => clickPhone()} href={'tel:'+theme.contacts.phone}>{theme.contacts.phone}</a>
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Mon-Sat 10:00AM - 7:00PM
                </li>
            </ul>
        </div>
    );
}
