// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';

import { Config } from '../../config';

class AccountPageAddresses extends Component {
//export default function AccountPageAddresses() {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
     constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            addresses: [],
            confirmDelete: false,
            addressObj: '',
        }
    }

    componentDidMount() {
        this.getAddresses();
    }

    getAddresses(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/address-list", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ type: 1})
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    this.setState({ addresses: response.data });
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [confirmDelete confirm delete content]
     * @param  {String} status [confirm delete status to hide/show dialog]
     * @return {}
     */
    confirmDelete(status) {
        if (!status) {
        this.setState({ confirmDelete: false });
        } else {

        this.setState({
            isLoading: true,
            confirmDelete: false
        });

        const address = this.state.addressObj;

        this.setState({ isLoading: true });
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);
            headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

            fetch(Config.URL+"/remove-address", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ address_id: address.id})
            })
            .then(response => response.json())
            .then(response => {
                this.setState({ isLoading: false });
                if(response.status){
                    if(response.status === 2){
                        localStorage.removeItem(Config.x_token);
                        localStorage.removeItem(Config.web_user);
                        toast.error(response.message);
                        this.props.history.push("/");
                    } else {
                        toast.success(response.message);
                        this.getAddresses();
                    }
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                //console.log("err: ", err);
                toast.error(err);
            });
        }
    }

    /**
     * [showConfirmDialog show confirm remove dialog]
     * @param  {Object} addressObj [object of selected address]
     * @return {}
     */
    showConfirmDialog(addressObj) {
        this.setState({
            confirmDelete: true,
            addressObj: addressObj
        });
    }

    makeDefaultAddress(address){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.getData(localStorage.getItem(Config.x_token)));

        fetch(Config.URL+"/set-default-address", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ address_id: address.id, type: 1})
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                if(response.status === 2){
                    localStorage.removeItem(Config.x_token);
                    localStorage.removeItem(Config.web_user);
                    toast.error(response.message);
                    this.props.history.push("/");
                } else {
                    toast.success(response.message);
                    this.getAddresses();
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            toast.error(err);
        });
    }

    render() {

        let addresses = '';
        if(this.state.addresses.length > 0){
            addresses = this.state.addresses.map((address) => (
                <React.Fragment key={address.id}>
                    <div className="addresses-list__item card address-card">
                        {address.in_default === 1 && <div className="address-card__badge">Default</div>}

                        <div className="address-card__body">
                            <span className="typeOfAddress">{address.type_of_address}</span>
                            <div className="address-card__name">{`${address.first_name} ${address.last_name}`}</div>
                            <div className="address-card__row">
                                {address.country_name}
                                <br />
                                {address.postcode}
                                ,&nbsp;
                                {address.city}
                                <br />
                                {address.address}
                                <br />
                                {address.address_2}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{address.contact}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{address.email}</div>
                            </div>
                            <div className="address-card__footer">
                                <Link hrefLang={"en"}  to={{ pathname: "/account/address/edit", address: address}}>Edit</Link>
                                &nbsp;&nbsp;
                                <span className="removeLink" onClick={() => { this.showConfirmDialog(address) }}>Remove</span>
                            </div>
                        </div>
                        {address.in_default === 0 && <div className="btn btn-secondary btn-sm default_button" onClick={() => { this.makeDefaultAddress(address) }}>Make Default</div>}
                    </div>
                    <div className="addresses-list__divider" />
                </React.Fragment>
            ));
        }

        return (
            <>
            <div className="addresses-list">
                <Helmet>
                    <title>{`My Addresses | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog
                open={this.state.confirmDelete}
                onClose={() => { this.confirmDelete(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove address?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.confirmDelete(false) }} color="primary">No</Button>
                        <Button onClick={() => { this.confirmDelete(true) }} color="primary" autoFocus>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Link hrefLang={"en"}  to="/account/address/add" className="addresses-list__item addresses-list__item--new">
                    <div className="addresses-list__plus" />
                    <div className="btn btn-secondary btn-sm">Add New</div>
                </Link>
                <div className="addresses-list__divider" />

            </div>
            <div className="addresses-list addresses-list-custom">
                {addresses}
            </div>
            </>
        );
    }
}

export default AccountPageAddresses;