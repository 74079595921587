// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';

function BrandCard(props) {
    const {
        brand,
        layout
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let image;

    if (brand.image) {
        image = (
            <div className="product-card__image product-image">
                <Link hrefLang={"en"}  to={{ pathname : url.brand(brand) + "?filter_brands="+brand.id, id: brand.category_id}} className="product-image__body">
                    <img className="product-image__img" src={brand.image} alt={brand.name} title={brand.name} />
                </Link>
            </div>
        );
    }    

    return (
        <div className={containerClasses}>            
            {image}
            <div className="product-card__info">
                {/* <div className="product-card__name">
                    <Link hrefLang={"en"}  to={url.brand(brand)}>{brand.name}</Link>
                </div> */}
                {/* <div className="product-card__rating">
                    &nbsp;
                </div> */}
                {/* <Link hrefLang={"en"}  to={url.brand(brand)}>
                <button
                    type="button"
                    className={classNames('btn btn-primary product-card__addtocart', {
                        'btn-loading': false,
                    })}
                >
                    View
                </button>
                </Link> */}
                {/* <div className="product-card__rating">
                    &nbsp;
                </div> */}
            </div>
        </div>
    );
}

BrandCard.propTypes = {
    /**
     * product object
     */
    brand: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrandCard);
