var CryptoJS = require("crypto-js");
//import env from "react-dotenv";
//require('dotenv').config(process.env.PUBLIC_URL+ '/.env')
// require('dotenv').config();
// var env = process.env;

let build_mode = 'production'; // production/development
//console.log(build_mode);

var configJSON = {
    'development': {
        URL:"https://khaliquesapi.hkinfoway.com",
        DOMAIN:"https://khareact.hkinfoway.com",
        POS:"https://pos.hkinfoway.com",
        ASSETS_URL:"https://khareact.hkinfoway.com",
        AWS_ASSETS_URL:"https://kha-pos.s3.ap-south-1.amazonaws.com",
        DEVICE_TYPE:"web",
        USER:"KhaliquesUser",
        T:"device_token",
        SECURE_TOKEN:"xToken",
        C_AUTH:"ChfmaHcxUntJlEJdHloehrUNRlQfJWXLnUtMXEgSBTrZkEirDbuUORKSZHgylAspiCImauUG",
        R_AUTH:"SbuUORKpiUtJlEJdHloXLnUtSnZHgSBTrZegylAsMXEhrUNirCImaRlQfJWkEuUGZhfmaHcx",
        HASH:"43fmQpTH6VxvF0ANlck0E1Dk28WKA/8OjqBJqNpAUHjIgkA",
        MODE:"production",
        GOOGLEAPIKEY:"AIzaSyBEp5V7iBeLhH17O8DnfQ0nZWVu7T9MyfY",
        GOOGLE_CAPTCHA_KEY:"6Lfp3ugcAAAAAEUzt2kjglnL-fUF8Gd8QdVMK0xI",
        FB_APP_ID:840388203562486,
        GOOGLE_APP_ID:"287423772468-5sgpap5kr0gaa0oeaa5jr3ph64v62kgg.apps.googleusercontent.com"
    },
    'production': {
        URL:"https://api.khaliques.co.za",
        DOMAIN:"https://www.khaliques.co.za",
        POS:"https://www.kha-pos.khaliques.co.za",
        ASSETS_URL:"https://www.khaliques.co.za",
        AWS_ASSETS_URL:"https://kha-pos.s3.ap-south-1.amazonaws.com",
        DEVICE_TYPE:"web",
        USER:"KhaliquesUser",
        T:"device_token",
        SECURE_TOKEN:"xToken",
        C_AUTH:"ChfmaHcxUntJlEJdHloehrUNRlQfJWXLnUtMXEgSBTrZkEirDbuUORKSZHgylAspiCImauUG",
        R_AUTH:"SbuUORKpiUtJlEJdHloXLnUtSnZHgSBTrZegylAsMXEhrUNirCImaRlQfJWkEuUGZhfmaHcx",
        HASH:"43fmQpTH6VxvF0ANlck0E1Dk28WKA/8OjqBJqNpAUHjIgkA",
        MODE:"production",
        GOOGLEAPIKEY:"AIzaSyBEp5V7iBeLhH17O8DnfQ0nZWVu7T9MyfY",
        GOOGLE_CAPTCHA_KEY:"6Lfp3ugcAAAAAEUzt2kjglnL-fUF8Gd8QdVMK0xI",
        FB_APP_ID:840388203562486,
        GOOGLE_APP_ID:"287423772468-5sgpap5kr0gaa0oeaa5jr3ph64v62kgg.apps.googleusercontent.com"
    }
};

export const Config = {
    URL: configJSON[build_mode].URL,
    DOMAIN: configJSON[build_mode].DOMAIN,
    POS: configJSON[build_mode].POS,
    assetsURL: configJSON[build_mode].ASSETS_URL,
    awsAssetsURL: configJSON[build_mode].AWS_ASSETS_URL,
    device_type: configJSON[build_mode].DEVICE_TYPE,
    device_token: (localStorage.getItem(configJSON[build_mode].T) !== null ? localStorage.getItem(configJSON[build_mode].T) : "null"),
    web_user: configJSON[build_mode].USER,
    mode: configJSON[build_mode].MODE,
    x_token: configJSON[build_mode].SECURE_TOKEN,
    countryAuth: configJSON[build_mode].C_AUTH,
    registerAuth: configJSON[build_mode].R_AUTH,
    googleAPIKey: configJSON[build_mode].GOOGLEAPIKEY,
    FB_APP_ID: configJSON[build_mode].FB_APP_ID,
    GOOGLE_APP_ID: configJSON[build_mode].GOOGLE_APP_ID,
    GOOGLE_CAPTCHA_KEY: configJSON[build_mode].GOOGLE_CAPTCHA_KEY,
    getAuthorization: (localStorage.getItem(configJSON[build_mode].SECURE_TOKEN) !== null ? localStorage.getItem(configJSON[build_mode].SECURE_TOKEN) : ''),
    setData: data => {
        return CryptoJS.AES.encrypt(data, configJSON[build_mode].HASH).toString();
    },
    getData: key => {
        var bytes = CryptoJS.AES.decrypt(key, configJSON[build_mode].HASH);
        return bytes.toString(CryptoJS.enc.Utf8);
    },
}